<div class="col-12 technical-specs-component componentWrapper" [class.lightBG]="brandFromParent=='MINI'">
    <div class="col-12 component-header">
        <h4>TECHNICAL SPECS</h4>
    </div>
    <div class="col-12 component-content">
        <div class="row">
            <div *ngFor="let techData of techDataToChild?.techInfo" class="row">
                <div class="col-8 table-title" >{{techData.techDescription}}:</div>
                <div class="col-4 table-value">{{techData.techValue}}</div>
                <div class="col-12 table-divider"><hr></div>
            </div>

            <!-- <div class="col-8 table-title">:</div>
            <div class="col-4 table-value"></div>4
            <div class="col-12 table-divider"><hr></div>
            <div class="col-8 table-title">Top Speed (km/h):</div>
            <div class="col-4 table-value">278</div>
            <div class="col-12 table-divider"><hr></div>
            <div class="col-8 table-title">Acceleration 0-100km/h (s):</div>
            <div class="col-4 table-value">7.2</div>
            <div class="col-12 table-header"><p>Fuel Consumption</p></div>
            <div class="col-8 table-title">In Town (l/100):</div>
            <div class="col-4 table-value">7.1</div>
            <div class="col-12 table-divider"><hr></div>
            <div class="col-8 table-title">Out of Town (l/100):</div>
            <div class="col-4 table-value">5.7</div> -->
        </div>
    </div>
    <div class="col-12 component-buttons-links link">
        <a href="javascript:void(0);" (click)="getTechPdf(techDataToChild.modelCode)" [class.simple-link]="brandFromParent==='MINI'">Download technical data<i class="icon icon-arrow-right" *ngIf="brandFromParent==='MINI'"></i></a>
    </div>
</div>
