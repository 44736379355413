<div class="modal fade" id="removeVehicle" aria-hidden="true" aria-labelledby="removeVehicle" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">VEHICLE REMOVAL CONFIRMATION</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="copy-wrapper">
                    <div class="alert notification" role="alert" *ngIf="request_result !='' "
                        [ngClass]="request_result==='success' ? 'alert-success':'alert-secondary'">
                        <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> {{alertMsg}} <button
                                type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>
                    </div>
                    <div *ngIf="request_result ===''">
                        <h3>Are you sure you want to remove this vehicle from your profile?</h3>
                        <p>Please note: The vehicle removal will not reflect until the next time you log in to your
                            account.
                        </p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="buttons button-ghost order-1 order-sm-0" data-bs-toggle="modal" data-bs-dismiss="modal" id="cancelRemoveBtn" [ngClass]="request_result==='success' ? 'd-none':''">Cancel</button>
                <button *ngIf="request_result ===''" type="button" class="buttons button-primary" (click)="removeVehicle(vehicleInfoFromParent)">Remove
                    vehicle</button>
                    <button class="buttons button-primary order-1 order-sm-0"  *ngIf="request_result==='success'"  (click)="cancelForm()">Ok</button>
            </div>
        </div>
    </div>
</div>