<div class="container main-container">
    <h1>Your BMW Service Hub.</h1>
    <div class="text-copy">
        <p>The BMW Service Hub keeps you updated on your servicing needs, provides useful tips and offers a wide range
            of service products to help you keep your BMW in showroom condition.</p>
    </div>
    <div class="col-lg-3 col-sm-12 component-buttons-links">
        <a class="buttons button-primary" routerLink="already-a-bmw-owner" role="link">
            Already a BMW owner?
        </a>
    </div>
</div>

<div class="container main-container">

    <div class="col-12">
        <h1>DISCOVER MORE DRIVING PLEASURE.</h1>
        <div class="text-copy"></div>
    </div>

    <div class="col-12 dashboard-components">
        <app-dash-teasers [brandFromParent]="brandToChild"></app-dash-teasers>
    </div>
</div>

<app-modals></app-modals>