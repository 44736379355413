import { Component, OnInit, Inject, Renderer2, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SwiperOptions } from 'swiper';
import Swiper, { Autoplay, Pagination, Navigation } from 'swiper';
import { auto } from '@popperjs/core';
Swiper.use([Autoplay, Pagination, Navigation]);

@Component({
  selector: 'app-dash-teasers',
  templateUrl: './dash-teasers.component.html',
  styleUrls: ['./dash-teasers.component.less']
})
export class DashTeasersComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2) { }

  @Input() 
  brandFromParent: any;

  ngOnInit(): void {
  }

  config: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 10,
    autoHeight: false,
    allowTouchMove: true,
    watchOverflow: true,
    autoplay: {
      delay: 2500,
    },
    centeredSlides: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: false,
        loop: false,
        centeredSlides: false,
      }
    },
    navigation: false,
    pagination: {
      clickable: true
    },
    loop: true
  }


  ngAfterViewInit() {
    let hamBugerBtn = this.document.getElementsByClassName('swiper-slide');
    for (let i = 0; i < hamBugerBtn.length; i++) {
      // this.renderer.setStyle(hamBugerBtn[i], 'height', 'auto');
    }
  }



}
