<div class="container main-container">
    <div *ngIf="incomingMsg!==''">
        <div class="alert notification mb-3" role="alert" *ngIf="incomingMsg!==''"
            [class.alert-success]="incomingMsg==='success'" [class.alert-secondary]="incomingMsg==='failed'">
            <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i>{{respMsg}}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </p>
        </div>
            <a routerLink="/" *ngIf="incomingMsg==='success'" class="buttons button-primary">Login</a>
    </div>

    <div *ngIf="incomingMsg=='' || incomingMsg==='failed'">
        <h1>Account Confirmation Successful.</h1>
        <div class="text-copy">
            <p>Your account is confirmed. Click on the button below to merge your account.</p>
            <p> It may take some time to consolidate your details, but we'll notify you via email as soon as your
                account is ready. We look forward to enhancing your online experience.</p>
        </div>
        <div class="col-lg-3 col-sm-12 component-buttons-links">
            <button type="button" class="buttons button-primary" (click)="activateLinked()">
                Merge now
            </button>
        </div>
    </div>

</div>