import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MyPlansService } from '../../_services/my-plans.service';
import { HelpersService } from '../../_helpers/helpers.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataServiceService } from '@services/data-service.service';
import { TronGenericPageTrackingService } from 'src/app/_helpers/TRON-Analytics-tracking/page-tracking/tron-generic-page-tracking.service';
// import { TronGenericFormsTrackingService } from 'src/app/_helpers/TRON-Analytics-tracking/forms-tracking/tron-generic-forms-tracking.service';

@Component({
  selector: 'app-my-plans',
  templateUrl: './my-plans.component.html',
  styleUrls: ['./my-plans.component.less']
})
@Injectable({
  providedIn: 'root'
})
export class MyPlansComponent implements OnInit {

  
  constructor(private myPlansSvc: MyPlansService, private helpersSvc: HelpersService, private activatedRoute: ActivatedRoute, private route: Router, private dataSvc: DataServiceService,
    private tronGenericPageTrackingService: TronGenericPageTrackingService,
    // private tronGenericFormsTrackingService: TronGenericFormsTrackingService
  ) {
    this.selectedVehicle = this.dataSvc.getSelectedVehicle();
    this.dealerId = this.dataSvc.getDealerId();
    this.brand = this.dataSvc.getBrand();
  }
  brand: string = '';
  myplans_type: any = {};
  result_data: any = {};
  header: string = '';
  submitted: boolean = false;
  loading: boolean = false;
  isMileageValide: boolean = false;
  active_services: any = {};
  myPlans: any = {};
  selectedVehicle: any;
  dealerId: string = '';
  static_data: any = {};
  zvehOdoReading: string = '';
  data_response: any = [
    {
      contractCategory: 'S',
      header: 'My BMW Plans',
      list: [
        {
          contractType: 'TC200',
          description: 'Designed to be a cut above the rest, our service plans take care of your out-of-Motorplan BMW with a range of exceptional aftersales solutions.',
          selected: true,

          package: {
            description: '*With BMW Condition Based Servicing (CBS), your BMW will tell you when it needs a service and thanks to My BMW Plans, you never have to compromise on the optimal care of your BMW. ',
            services: [
              {
                id: '01',
                description: 'For 3 years / 3 Services'
              },
              {
                id: '02',
                description: 'CBS indicated or elective'
              },
              {
                id: '03',
                description: 'Engine oil service only'
              }
            ]
          }
        },
        {
          contractType: 'TC210',
          description: 'Designed to be a cut above the rest, My BMW Service Plans take care of your out-of-Motorplan BMW with a range of exceptional aftersales solutions.',
          selected: false,
          package: {
            description: '*With BMW Condition Based Servicing (CBS), your BMW will tell you when it needs a service and thanks to My BMW Plans, you never have to compromise on the optimal care of your BMW.',
            services: [
              {
                id: '01',
                description: 'For 3 years / 3 Services'
              },
              {
                id: '02',
                description: 'CBS indicated or elective'
              },
              {
                id: '03',
                description: 'Vehicle check'
              },
              {
                id: '04',
                description: 'Engine oil service + spark plugs + air filter, pollen filterm, fuel filter, break fluid flush'
              }
            ]
          }
        },
        {
          contractType: 'TC220',
          description: 'Designed to be a cut above the rest, My BMW Maintenance Plans take care of your out-of-Motorplan BMW with a range of exceptional aftersales solutions.',
          selected: false,
          package: {
            description: '*With BMW Condition Based Servicing (CBS), your BMW will tell you when it needs a service and thanks to My BMW Plans, you never have to compromise on the optimal care of your BMW.',
            services: [
              {
                id: '01',
                description: 'For 3 years / 9 Services'
              },
              {
                id: '02',
                description: 'CBS indicated *'
              },
              {
                id: '03',
                description: 'Vehicle check'
              },
              {
                id: '04',
                description: 'Engine oil service + spark plugs + air filter, pollen filterm fuel filter, brake pads & discs (front & rear), break fluid flush'
              }
            ]
          }
        }
      ]
    },

    {
      contractCategory: 'O',
      header: 'BMW On call',
      list: [{
        contractType: 'TC900',
        description: 'Roadside assistance including 24-hour help in the event of a mechanical or electrical breakdown.',
        selected: true,
        package: {
          description: '',
          services: [
            {
              id: '01',
              description: 'For 3 years / 3 Services'
            },
            {
              id: '02',
              description: 'CBS indicated or elective'
            },
            {
              id: '03',
              description: 'Engine oil service only'
            }
          ]
        }
      },
      {
        contractType: '02',
        description: 'Roadside assistance including 24-hour help in the event of a mechanical or electrical breakdown. ',
        selected: false,
        package: {
          description: '*With BMW Condition Based Servicing (CBS), your BMW will tell you when it needs a service and thanks to My BMW Plans, you never have to compromise on the optimal care of your BMW.',
          services: [
            {
              id: '01',
              description: 'For 3 years / 3 Services'
            },
            {
              id: '02',
              description: 'CBS indicated or elective'
            },
            {
              id: '03',
              description: 'Vehicle check'
            },
            {
              id: '04',
              description: 'Engine oil service + spark plugs + air filter, pollen filterm, fuel filter, break fluid flush'
            }
          ]
        }
      },
      {
        contractType: '03',
        description: 'Roadside assistance including 24-hour help in the event of a mechanical or electrical breakdown. ',
        selected: false,
        package: {
          description: '*With BMW Condition Based Servicing (CBS), your BMW will tell you when it needs a service and thanks to My BMW Plans, you never have to compromise on the optimal care of your BMW.',
          services: [
            {
              id: '01',
              description: 'For 3 years / 9 Services'
            },
            {
              id: '02',
              description: 'CBS indicated *'
            },
            {
              id: '03',
              description: 'Vehicle check'
            },
            {
              id: '04',
              description: 'Engine oil service + spark plugs + air filter, pollen filterm fuel filter, brake pads & discs (front & rear), break fluid flush'
            }
          ]
        }
      }
      ]
    }
  ];



  myPlanForm = new FormGroup({
    myPlan: new FormControl('', Validators.required),
    mileage: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
    minMileage: new FormControl(this.zvehOdoReading)
  });

  get f() {
    return this.myPlanForm.controls;
  }

  ngOnInit(): void {
    const myplanstype = sessionStorage.getItem("planType");
    if (myplanstype !== null || myplanstype !== undefined) {
      let myPlansData = JSON.parse(this.activatedRoute.snapshot.data['data'])
      if (myPlansData !== undefined) {
        this.getPlansAll(myPlansData); //pass data through here
      }
    }

    // this.tronGenericPageTrackingService.updatePageNameFromUrl('MyPlansPage');
    // this.tronGenericPageTrackingService.setPageName('MyPlansPage');
    // console.log(this.tronGenericPageTrackingService.updatePageNameFromUrl, 'MyPlans Page Name')
    // this.tronGenericPageTrackingService.setFirstFormInteraction('MyPlansPage');
    // console.log(this.tronGenericPageTrackingService.setFirstFormInteraction, 'First Form Interaction')
  }

  getPlansAll(data: any) {
    this.result_data = data.myPlansData;
    this.zvehOdoReading = data.odometerReading;
    let getFirstPlan = data.myPlansData.find((x: any) => x.contractCategory !== '');
    this.header = this.getHeader(getFirstPlan.contractCategory, this.brand);

    this.getSingleService(getFirstPlan.contractType);
  }


  getHeader(categoryCode: string, brand: string): string {
    switch (categoryCode) {
      case 'S':
        return 'My '+ brand +' Plans';

      case 'O':
        return brand + ' On-Call';
      default:
        return '';
    }
  }

  getSingleService(contractType: string) {

    if (contractType != '' || contractType != null) {
      let findSelectedFromIncomingData: any;
      let _contractType: string;
      if (contractType.includes("_")) {
        let _period = contractType.split("_")[1];
        _contractType = contractType.split("_")[0];

        findSelectedFromIncomingData = this.result_data.find((i: any) => i.contractType === _contractType && i.contractMonths === _period);

      }
      else {
        _contractType = contractType;
        findSelectedFromIncomingData = this.result_data.find((i: any) => i.contractType === _contractType);
      }
      if (findSelectedFromIncomingData !== undefined) {
        this.active_services = findSelectedFromIncomingData;

        if (this.active_services?.contractCategory === 'O') {
          this.myPlanForm.controls['myPlan'].setValue(this.active_services?.contractType + '_' + this.active_services?.contractMonths);
        }
        else {
          this.myPlanForm.controls['myPlan'].setValue(this.active_services?.contractType);
        }
        let findStaticDataByCategory = this.data_response.find((i: any) => i.contractCategory === findSelectedFromIncomingData.contractCategory)
        if (findStaticDataByCategory !== undefined) {
          let findSelectedFromStaticData = findStaticDataByCategory.list.find((i: any) => i.contractType === _contractType);
          if (findSelectedFromStaticData !== undefined) {
            this.static_data = {
              description: findSelectedFromStaticData.description.replaceAll('BMW', this.brand),
              package: {
                description:findSelectedFromStaticData.package.description.replaceAll('BMW', this.brand),
                services: findSelectedFromStaticData.package.services
              }
            };
          }
        }
      }
    }
  }

  SubmitForm() {
    this.submitted = true;
      // Track form finish intent
      // const FormFinishIntent = `My Plans ${this.brand} Select Options Form Finish Intent`;
      // this.tronGenericPageTrackingService.setFormFinishIntent(FormFinishIntent);
    if (this.myPlanForm.invalid) {
      return;
    }
    else {
      if (Number(this.zvehOdoReading) > Number(this.myPlanForm.value.mileage)) {
        this.isMileageValide = true;
      }
      else {
        this.isMileageValide = false;
        this.loading = true;
        let form_params = {
          odometer: this.myPlanForm.value.mileage
        }
        let lowestPrice: any;
        let concat_params: any;
        if (this.active_services?.contractCategory === 'O') {
          lowestPrice = {
            contractCategory: this.active_services?.contractCategory,
            contractNo: '',
            contractSurchargeList: { list: [] },
            contractType: this.active_services?.contractType,
            description: this.active_services?.contractMonths.trim() + ' Months',
            idReq: '',
            lowestPrice: this.active_services,
            orderType: this.active_services?.quoteType,
            origContractType: this.active_services?.contractType
          }

          concat_params = { ...lowestPrice, ...form_params }
        }
        else {
          concat_params = { ...this.active_services, ...form_params }
        }
        this.dataSvc.setSelectedService(concat_params);
        // localStorage.setItem('selectedService', JSON.stringify(this.active_services));
        this.dataSvc.setCartSummery(concat_params);
        // Track form finish
        const FormFinish = `My Plans ${this.brand} Select Options Form Finish`;
        // this.tronGenericPageTrackingService.setFormFinish(FormFinish);
        this.route.navigate(['/checkout-options']);
        
      }
    }
  }

  flatten(arr: any) {
    return [].concat(...arr);
  }
}
