import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mini-footer',
  templateUrl: './mini-footer.component.html',
  styleUrls: ['./mini-footer.component.less']
})
export class MiniFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  currentYear(){
    const date = new Date();
    return date.getFullYear().toString();
  }
}
