
import { Injectable } from '@angular/core';
import { CustomerProfile } from '../_models/customer-profile.model';



@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

  sfPolicyList: any;
  nscPolicyList: any;
  selectedVehicle: any;
  ondemandDone!: string;
  policyMinorVersion!: string;
  serviceOnlineUnAvail!: string;
  currentVehicles: any;
  isProspect!: string;
  gcdmEmail!: string;
  gcdmCustTitle!: string;
  gcdmCustNameLast!: string;
  gcdmCustBp!: string;
  gcdmIdNo!: string;
  gcdmCustNameFirst!: string;
  city!: string;
  districtName!: string;
  postalCode!: string;
  strSuppl2!: string;
  street!: string;
  country!: string;
  emailValue!: string;
  phoneValue!: string;
  activeContract!: string;
  customerProfile!: CustomerProfile;
  selectedVin!: string;
  plansOffers: any;
  optionalTyreData: any;

  public process_env_bmw = {
    'SOL_hostname': JSON.stringify('https://forms.bmw.co.za/Aftersales/2016/service.html'),
    'CP_hostname': JSON.stringify('https://www.account-d.bmw.co.za'),
    'businessContext': JSON.stringify('customerportal/ZA-BMW-en-dev'),
    'redirect_hostname': JSON.stringify('https://www.account-d.bmw.co.za'),
    'ON_CALL_hostname': JSON.stringify('https://www.bmw.co.za/en/topics/offers-and-services/roadside-assistance/on-call.html'),
    'Black_oil_form': JSON.stringify('https://forms.bmw.co.za/Aftersales/BlackOilService/InterestRegister.html'),
    'locale': JSON.stringify('ZA-BMW-en-dev'),
    'state': JSON.stringify('mlkKWXGMmpJ1h2G11V8SR2Te1ebnsyHkDeIZjLcSVzoTz8o7JAe3SzqNLourxQOU'),
    'title': JSON.stringify('BMW Customer Portal'),
    'brand': JSON.stringify('BM'),
    'brandSyle': JSON.stringify('bmw'),
    'brand-head': JSON.stringify('bmw'),
    'brandUpperCase': JSON.stringify('BMW'),
    'vehicle_type': JSON.stringify('1'),
    'app_favicon': JSON.stringify('assets/img/favicon/bmw/favicon.ico'),
    'app_epaas': JSON.stringify('src/_content/js/epaasBmw.js'),
    'dealer_brand': JSON.stringify('BM'),
    'on_call_contact_no': JSON.stringify('+2780 060 0777'),
    'MP3_OPT_hostname': JSON.stringify('https://www.myservice-t.bmw.co.za'),
    'logoff_hostname': JSON.stringify('www.bmw.co.za/en/index.html'),
  }

  public process_env_mini = {
    // 'SOL_hostname': JSON.stringify('https://serviceonline.azurewebsites.net/minizatest'),
    'SOL_hostname': JSON.stringify('https://forms.bmw.co.za/current/book-service.html'),
      'CP_hostname': JSON.stringify('https://www.account-d.mini.co.za'),
      'businessContext': JSON.stringify('customerportal/ZA-MINI-en-dev'),
      'redirect_hostname': JSON.stringify('https://www.account-d.mini.co.za'),
      'ON_CALL_hostname': JSON.stringify('https://www.mini.co.za/en_ZA/home/services/mini-road-assistance.html'),
      'Black_oil_form': JSON.stringify('https://forms.bmw.co.za/Aftersales/BlackOilServiceMINI/CMB.html'),
      'locale': JSON.stringify('ZA-MINI-en-dev'),
      'state': JSON.stringify('uOkjjlgKFQjlghax3OckMAshsqTHwsUl4SbB0NgFBI0fl187V5zpsICFUn9jhoTA'),
      'title': JSON.stringify('MINI Customer Portal'),
      'brand': JSON.stringify('MI'),
      'brandSyle': JSON.stringify('mini'),
      'brand-head': JSON.stringify('mini'),
      'brandUpperCase': JSON.stringify('MINI'),
      'vehicle_type':JSON.stringify('1'),
      'SOL_rooftopId': JSON.stringify('9991r3'),
      'app_favicon':  JSON.stringify('assets/img/favicon/mini/favicon.ico'),
      'app_epaas': JSON.stringify('src/_content/js/epaasMini.js'),
      'dealer_brand':JSON.stringify('MI'),
      'on_call_contact_no': JSON.stringify('+2780 069 6464'),
      'MP3_OPT_hostname': JSON.stringify('https://www.myservice-t.mini.co.za'),
      'logoff_hostname': JSON.stringify('www.mini.co.za/en_ZA/home.html'),
  }
  public process_env_motorrad = {
      'SOL_hostname': JSON.stringify('https://forms.bmw.co.za/motorrad/ContactForms/BookAService.html'),
      'CP_hostname': JSON.stringify('https://www.account-d.bmw-motorrad.co.za'),
      'businessContext': JSON.stringify('customerportal/ZA-MOT-en-dev'),
      'redirect_hostname': JSON.stringify('https://www.account-d.bmw-motorrad.co.za'),
      'ON_CALL_hostname': JSON.stringify('https://www.bmw.co.za/en/topics/offers-and-services/roadside-assistance/on-call.html'),
      // 'ON_CALL_hostname': JSON.stringify('https://www.bmw-motorrad.co.za/en/engineering/detail/safety/ecall.html'),
      'locale': JSON.stringify('ZA-MOT-en-dev'),
      'state': JSON.stringify('BkkeDNO5xRoRgjikttsWBQceISjKICdwUjKjINtb0CMMFp4Nq4Xg9ekVOSHq7WWw'),
      'title': JSON.stringify('MOTORRAD Customer Portal'),
      'brand': JSON.stringify('MO'),
      'brand-head': JSON.stringify('mot'),
      'brandSyle': JSON.stringify('bmwmotorrad'),
      'brandUpperCase': JSON.stringify('MOTORRAD'),
      'vehicle_type':JSON.stringify('0'),
      'app_favicon': JSON.stringify('assets/img/favicon/bmw/favicon.ico'),
      'app_epaas': JSON.stringify('src/_content/js/epaasMot.js'),
      'dealer_brand':JSON.stringify('BM'),
      'on_call_contact_no': JSON.stringify('+2780 060 0777'),
      'logoff_hostname': JSON.stringify('www.bmw-motorrad.co.za/en/home.html'),

  }


  public setOptionalTyreData(optionalTyreData: any): void {
    sessionStorage.setItem("optionalTyreData", JSON.stringify(optionalTyreData));
  }

  public getOptionalTyreData(): any {
    let optionalTyreData = sessionStorage.getItem("optionalTyreData");
    if (optionalTyreData != null) {
      return JSON.parse(optionalTyreData);
    }
  }

  public setActiveContract(activeContract: string): void {
    sessionStorage.setItem("activeContract", activeContract);
  }

  public getActiveContract(): string {
    let activeContract = sessionStorage.getItem("activeContract");
    if (activeContract != null) {
      return activeContract;
    } else {
      return "";
    }
  }

  public setPhoneValue(phoneValue: string): void {
    sessionStorage.setItem("phoneValue", phoneValue);
  }

  public getPhoneValue(): string {
    let phoneValue = sessionStorage.getItem("phoneValue");
    if (phoneValue != null) {
      return phoneValue;
    } else {
      return "";
    }
  }

  public setEmailValue(emailValue: string): void {
    sessionStorage.setItem("emailValue", emailValue);
  }

  public getEmailValue(): string {
    let emailVal = sessionStorage.getItem("emailValue");
    if (emailVal != null) {
      return emailVal;
    } else {
      return "";
    }
  }

  public setCountry(country: string): void {
    sessionStorage.setItem("country", country);
  }

  public getCountry(): string {
    let country = sessionStorage.getItem("country");
    if (country != null) {
      return country;
    } else {
      return "";
    }
  }

  public setStreet(street: string): void {
    sessionStorage.setItem("street", street);
  }

  public getStreet(): string {
    let str = sessionStorage.getItem("street");
    if (str != null) {
      return str;
    } else {
      return "";
    }
  }

  public setStrSuppl2(strSuppl2: string): void {
    sessionStorage.setItem("strSuppl2", strSuppl2);
  }

  public getStrSuppl2(): string {
    let str1 = sessionStorage.getItem("strSuppl2");
    if (str1 != null) {
      return str1;
    } else {
      return "";
    }
  }

  public setPostalCode(postalCode: string): void {
    sessionStorage.setItem("postalCode", postalCode);
  }

  public getPostalCode(): string {
    let postCode = sessionStorage.getItem("postalCode");
    if (postCode != null) {
      return postCode;
    } else {
      return "";
    }
  }

  public setCity(city: string): void {
    sessionStorage.setItem("city", city);
  }

  public getCity(): string {
    let city = sessionStorage.getItem("city");
    if (city != null) {
      return city;
    } else {
      return "";
    }
  }

  public setDistrictName(districtName: string): void {
    sessionStorage.setItem("districtName", districtName);
  }

  public getDistrictName(): string {
    let districtName = sessionStorage.getItem("districtName");
    if (districtName != null) {
      return districtName;
    } else {
      return "";
    }
  }

  public setGcdmCustNameFirst(gcdmCustNameFirst: string): void {
    sessionStorage.setItem("GCDM_Cust_Name_First", gcdmCustNameFirst);
  }

  public getGcdmCustNameFirst(): string {
    let fname = sessionStorage.getItem("GCDM_Cust_Name_First");
    if (fname != null) {
      return fname;
    } else {
      return "";
    }
  }

  public setGcdmIdNo(gcdmIdNo: string): void {
    sessionStorage.setItem("GCDM_IdNo", gcdmIdNo);
  }

  public getGcdmIdNo(): string {
    let gcdmIdNum = sessionStorage.getItem("GCDM_IdNo");
    if (gcdmIdNum != null) {
      return gcdmIdNum;
    } else {
      return "";
    }
  }

  public setGcdmCustBp(gcdmCustBp: string): void {
    sessionStorage.setItem("GCDM_Cust_Bp", gcdmCustBp);
  }

  public getGcdmCustBp(): string {
    let gcdmCustBp = sessionStorage.getItem("GCDM_Cust_Bp");
    if (gcdmCustBp != null) {
      return gcdmCustBp;
    } else {
      return "";
    }
  }

  public setGcdmCustNameLast(gcdmCustNameLast: string): void {
    sessionStorage.setItem("GCDM_Cust_Name_Last", gcdmCustNameLast);
  }

  public getGcdmCustNameLast(): string {
    let gcdmLastName = sessionStorage.getItem("GCDM_Cust_Name_Last");
    if (gcdmLastName != null) {
      return gcdmLastName;
    } else {
      return "";
    }
  }

  public setGcdmCustTitle(gcdmCustTitle: string): void {
    sessionStorage.setItem("GCDM_Cust_Title", gcdmCustTitle);
  }

  public getGcdmCustTitle(): string {
    let custTitle = sessionStorage.getItem("GCDM_Cust_Title");
    if (custTitle != null) {
      return custTitle;
    } else {
      return "";
    }
  }


  public setGcdmEmail(gcdmEmail: string): void {
    sessionStorage.setItem("GCDM_Email", gcdmEmail);
  }

  public getGcdmEmail(): string {
    let gcdmemail = sessionStorage.getItem("GCDM_Email");
    if (gcdmemail != null) {
      return gcdmemail
    } else {
      return "";
    }
  }

  public setProcepect(isProspect: string): void {
    sessionStorage.setItem("isProspect", isProspect);
  }

  public getProcepect(): string {
    let isProspect = sessionStorage.getItem("isProspect");
    if (isProspect != null) {
      return isProspect;
    } else {
      return "";
    }
  }

  public getCurrentVehicles(): any {
    let currentVehicles = sessionStorage.getItem("currentVehicles");
    if (currentVehicles != null) {
      return JSON.parse(currentVehicles);
    } else {
      return "";
    }
  }

  public setCurrentVehicles(currentVehicles: any): void {
    sessionStorage.setItem("currentVehicles", JSON.stringify(currentVehicles));
  }

  public getServiceOnlineUnAvail(): string {
    let solUnavail = sessionStorage.getItem("serviceOnlineUnAvail");
    if (solUnavail != null) {
      return solUnavail;
    } else {
      return "";
    }
  }

  public setServiceOnlineUnAvail(serviceOnlineUnAvail: string): void {
    sessionStorage.setItem("serviceOnlineUnAvail", serviceOnlineUnAvail);
  }

  public getPolicyMinorVersion(): string {
    let policyMinorVer = sessionStorage.getItem("policyMinorVersion");
    if (policyMinorVer != null) {
      return policyMinorVer;
    } else {
      return "";
    }
  }

  public setPolicyMinorVersion(policyMinorVersion: string): void {
    sessionStorage.setItem("policyMinorVersion", policyMinorVersion);
  }

  public getOndemandDone(): string {
    let onDemandDone = sessionStorage.getItem("ondeamndDone");
    if (onDemandDone != null) {
      return onDemandDone;
    } else {
      return "";
    }
  }

  public setOndemandDone(ondemandDone: string): void {
    sessionStorage.setItem("ondemandDone", ondemandDone);
  }

  public getSelectedVehicle(): any {
    let pvtVehicle = sessionStorage.getItem("selectedVehicle");
    if (pvtVehicle != null) {
      return pvtVehicle;
    } else {
      return "";
    }
  }


  public setSelectedVehicle(selectedVehicle: any): void {
    sessionStorage.setItem("selectedVehicle", JSON.stringify(selectedVehicle));
  }

  public getDealerId(): string {
    let dealerId = sessionStorage.getItem("dealerId");
    if (dealerId != null) {
      return dealerId;
    } else {
      return "";
    }
  }


  public setDealerId(dealerId: string): void {
    sessionStorage.setItem("dealerId", dealerId);
  }

  public getVin(): string {
    let vin = sessionStorage.getItem("vin");
    if (vin != null) {
      return vin;
    } else {
      return "";
    }
  }

  public setVin(vin: string): void {
    sessionStorage.setItem("vin", vin);
  }

  public getSfPolicyList(): any {
    return this.sfPolicyList;
  }

  public setNscPolicyList(nscPolicyList: any): void {
    this.nscPolicyList = nscPolicyList;
  }

  public getNscPolicyList(): any {
    return this.nscPolicyList;
  }

  public setSfPolicyList(sfPolicyList: any): void {
    this.sfPolicyList = sfPolicyList;
  }

  public getCustomerProfile(): CustomerProfile {
    return this.customerProfile;
  }

  public setCustomerProfile(cp:CustomerProfile) {
    this.customerProfile = cp;
  }



  public getBpNo(): string {
    let bpNo = sessionStorage.getItem("bpNo");
    if (bpNo != null) {
      return bpNo;
    } else {
      return "";
    }
  }


  public setBpNo(bpNo: string): void {
    sessionStorage.setItem("bpNo", bpNo);
  }

  public getSelectedService(): any {
    let selectedService = sessionStorage.getItem("selectedService");
    if (selectedService != null || selectedService !== undefined) {
      return selectedService;
    } else {
      return "";
    }
  }

  public setSelectedService(selectedService: any): void {
    sessionStorage.setItem("selectedService", JSON.stringify(selectedService));
  }

  public getCartSummery(): any {
    let cartSummery = sessionStorage.getItem("cSummery");
    if (cartSummery != null || cartSummery !== undefined) {
      return cartSummery;
    } else {
      return "";
    }
  }

  public setCartSummery(cartSummery: any): void {
    sessionStorage.setItem("cSummery", JSON.stringify(cartSummery));
  }



  public getMyPlans(): any {
    let plans = sessionStorage.getItem("myPlans");

    if (plans != null || plans !== undefined) {
      return plans;
    } else {
      return "";
    }
  }

  public setMyPlans(plans: any, type:string): void {
    sessionStorage.setItem("myPlans", JSON.stringify(plans));
    sessionStorage.setItem("planType", type);
  }

  public getAllPlans(): any {
    return this.plansOffers;
  }

  public setAllPlans(plans: any): void {
    this.plansOffers = plans;
  }

  public setPageRestriction(restricted: string): void {
    sessionStorage.setItem("pageres", restricted);
  }

  public getPageRestriction(): string {
    let pagerestriction = sessionStorage.getItem("pageres");
    if (pagerestriction != null) {
      return pagerestriction;
    } else {
      return "";
    }
  }

  public getBrand(): string {
    let brand = sessionStorage.getItem("brand");
    if (brand != null) {
      return brand;
    } else {
      return "";
    }
  }


  public setBrand(brand: string): void {
    sessionStorage.setItem("brand", brand);
  }


  public removeItems(): void {
    sessionStorage.removeItem('ctiveContract');
    sessionStorage.removeItem('GCDM_IdNo');
    sessionStorage.removeItem('GCDM_Cust_Bp');
    sessionStorage.removeItem('GCDM_Cust_Name_Last');
    sessionStorage.removeItem('GCDM_Cust_Title');
    sessionStorage.removeItem('GCDM_Email');
    sessionStorage.removeItem("GCDM_Cust_Name_First");

    sessionStorage.removeItem('city');
    sessionStorage.removeItem('districtName');
    sessionStorage.removeItem('postalCode');
    sessionStorage.removeItem('strSuppl2');
    sessionStorage.removeItem('street');
    sessionStorage.removeItem('country');
    sessionStorage.removeItem('emailValue');
    sessionStorage.removeItem('phoneValue');

    sessionStorage.removeItem("isProcepect");
    sessionStorage.removeItem("currentVehicles");
    sessionStorage.removeItem("serviceOnlineUnAvail");
    sessionStorage.removeItem("policyMinorVersion");
    sessionStorage.removeItem("ondemandDone");
    sessionStorage.removeItem("selectedVehicle");
    sessionStorage.removeItem("dealerId");
    sessionStorage.removeItem("vin");
    sessionStorage.removeItem('bpNo');
    sessionStorage.removeItem("selectedService");
    sessionStorage.removeItem("CP_OAUTH_BEARER_TOKEN");
    sessionStorage.removeItem("cSummery");
    sessionStorage.removeItem("pageres");
  }

  public clearStore(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  public clearVehicleData(){
    sessionStorage.removeItem("currentVehicles");
    sessionStorage.removeItem("selectedVehicle");
    sessionStorage.removeItem("dealerId");
    sessionStorage.removeItem("vin");
    // sessionStorage.removeItem('bpNo');
    sessionStorage.removeItem("selectedService");
    sessionStorage.removeItem("cSummery");

  }
}
