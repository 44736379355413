<div class="col-12 on-call-component componentWrapper" [ngClass]="brandFromParent=='MINI'?'md-container':''">
    <div class="col-12 component-header-image">
        <div class="notification" *ngIf="onCallContractDataToChild && onCallContractDataToChild?.onCallData && onCallContractDataToChild?.onCallData?.contractStatus ==='CS00'">
            <h6><i class="iconImg icon-info-circle-o"></i> {{oncallActiveLabel}} {{onCallContractDataToChild?.onCallData?.validToDate}}</h6>
<!--            <p>You can purchase additional cover 6 months prior or after expiry. </p>-->
        </div>
        <img src="./assets/BMW/images/on-call.jpg" *ngIf="brandFromParent==='BMW'">
        <img src="./assets/MINI/images/road-side-assist.jpg" *ngIf="brandFromParent==='MINI'">
    </div>
    <div class="col-12 component-header">
        <h5 >{{oncallLabel}}</h5>
    </div>
    <div class="col-12 component-content">
        <p>Roadside assistance including 24-hour help in the event of a mechanical or electrical breakdown.</p>
        <span class="price" *ngIf="onCallContractDataToChild?.lowestPricePlan">Starting at <span>&nbsp;{{onCallContractDataToChild?.lowestPricePlan?.lowestPrice?.vatTotalPrice | currency: 'R '}}</span></span>
    </div>
    <div class="col-12 component-buttons-links link">
        <button (click)="gotMyPlans(onCallContractDataToChild)" class="buttons button-primary" *ngIf="onCallContractDataToChild?.displayButton && onCallContractDataToChild?.lowestPricePlan">
            Buy Now
        </button>
        <a href="https://www.bmw.co.za/en/topics/offers-and-services/roadside-assistance/on-call.html" class="buttons button-ghost" target="_blank" *ngIf="!onCallContractDataToChild?.displayButton && brandFromParent==='BMW' || !onCallContractDataToChild?.lowestPricePlan && brandFromParent==='BMW'">
            Learn more
        </a>
        <a href="https://www.mini.co.za/en_ZA/home/services/mini-road-assistance.html" class="buttons button-ghost" target="_blank" *ngIf="!onCallContractDataToChild?.displayButton && brandFromParent==='MINI' || !onCallContractDataToChild?.lowestPricePlan && brandFromParent==='MINI'">
            Learn more
        </a>
    </div>
</div>
