import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import {HttpBackend, HttpClient, HttpHeaders} from "@angular/common/http";
import {map, delay, switchMap} from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PolicyStoreConsent} from 'src/app/_models/policy-store-consent.model';
import { GcdmProfile} from '../_models/gcdm-profile.model';
import { User } from '../_models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private gcdmProfileSubject!: BehaviorSubject<GcdmProfile>;
  private shttpClient: HttpClient;

  constructor(private http: HttpClient,
    private authSvc: AuthenticationService, private sHttp: HttpBackend
    ) {
    this.shttpClient = new HttpClient(sHttp);
  }

  gcdmLogin(code: string): Observable<any> {

    return this.getApi().pipe(
      switchMap(data => {
        let b64string = btoa(data.gcdm_basic);
        let headers = new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic ' + b64string
        });

    let regObjectJson = "grant_type=authorization_code&code=" + code + "&redirect_uri=" + environment.apiURL + "/gcdm/index/auth";
    return this.http.post(environment.gcdm_env_hostname + "/gcdm/oauth/token", regObjectJson, { headers: headers });
      }),
      map(results => results)
    );
  };

  getApi(): Observable<any>{
    // let csrfToken = this.tokenExtractor.getToken() as string;
    let headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    });
    var configs = { headers: headers };

    return this.shttpClient.get(environment.apiURL +"/customerportalapi/rest/cp/crm/customer/getApi")
      .pipe(map(function (data) {
        console.log("inside get btoa in service data returned is ", data);
        return data;
      }))
  }

  getGcdmCustomerProfileOnePage(access_token: string): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + access_token });
    var configs = { headers: headers };
    return this.http.get<any>(environment.gcdm_env_hostname + "/gcdm/protected/v4/" + "customerportal/ZA-BMW-en" + "/customers/businessPartners", configs)
      .pipe(map(data => { return data as any }));
  }

  getGcdmUserAccount(access_token: string): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + access_token });
    var configs = { headers: headers };
    return this.http.get<any>(environment.gcdm_env_hostname + "/gcdm/protected/v4/" + "customerportal/ZA-BMW-en" + "/customers/userAccounts", configs)
      .pipe(map(data => { return data as any }));
  }

  getGcdmUserAccountFromASession(): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
    var configs = { headers: headers };
    return this.http.get<any>(environment.gcdm_env_hostname + "/gcdm/protected/v4/" + "customerportal/ZA-BMW-en" + "/customers/userAccounts", configs)
      .pipe(map(data => { return data as any }));
  }

  getGcdmCustomerProfile(): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN')  });
    var configs = { headers: headers };
    return this.http.get<any>(environment.gcdm_env_hostname + "/gcdm/protected/v4/" + "customerportal/ZA-BMW-en" + "/customers/businessPartners", configs)
      .pipe(map(data => { return data as any }));
  }

  updatePolicyStoreConsent(contactPolicyConsents: any): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
    var configs = { headers: headers };
    var contactPolicyConsentsJson = {
      "contactPolicyConsents": contactPolicyConsents
    };
    return this.http.put(environment.gcdm_env_hostname + "/gcdm/protected/v4/" + "customerportal/ZA-BMW-en" + "/customers", contactPolicyConsentsJson, configs)
      .pipe(map(function (data) { return data; }));
  };


  updateCustomerProfile(customerProfile: any) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
    var configs = { headers: headers };
    var custProfileObjectJson = {
      "businessPartner":
      {
        "salutation": customerProfile.title,
        "surname": customerProfile.surname,
        "givenName": customerProfile.names,
        // "birthday":customerProfile.dob,
        "partnerCategory": "PERSON"
      }
    }

    return this.http.put(environment.gcdm_env_hostname + "/gcdm/protected/v4/" + environment.bmw_businessContext + "/customers", custProfileObjectJson, configs)
      .pipe(map(function (data) { return data; }));
  };

  updateContactDetails(customerContact: any) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
    var configs = { headers: headers };
    var strSuppl1 = customerContact.strSuppl1 !== undefined ? customerContact.strSuppl1 : "";
    var custContactObjectJson = {
      "businessPartner": {
        "communications": {
          "communicationEMails": [
            {
              "communicationStatus": "PRIVATE",
              "value": customerContact.email,
              "communicationType": "EMAIL",
              "preferred": true
            }
          ],
          "communicationPhones": [
            {
              "communicationStatus": "PRIVATE",
              "communicationType": "MOB",
              "value": customerContact.mobilenumber,
              "preferred": true
            }
          ]
        },
        "addresses": {
          "addresses": [
            {
              "addressStatus": "PRIVATE",
              "city": customerContact.city,
              "districtName": customerContact.districtName,
              "postalCode": customerContact.postalcode,
              "strSuppl1": strSuppl1,
              "street": customerContact.street,
              "country": customerContact.CountryIso2,
              "preferred": true
            }
          ]
        },
        "partnerCategory": "PERSON"
      }
    };

    return this.http.put(environment.gcdm_env_hostname + "/gcdm/protected/v4/" + environment.bmw_businessContext + "/customers", custContactObjectJson, configs)
      .pipe(map(function (data) { return data; }));

    // return this.http.put(environment.gcdm_env_hostname + "/gcdm/protected/v4/" + this.authSvc.get_gcdm_config['businessContext'] + "/customers", custContactObjectJson, configs)
    //   .pipe(map(function (data) { return data; }));
  };

  updateLoginEmail(customerProfile: any) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
    var configs = { headers: headers };
    var customerProfileJson = {
      "customerProfile": customerProfile
    };
    return this.http.put(environment.gcdm_env_hostname + "/gcdm/protected/v4/" + this.authSvc.get_gcdm_config['businessContext'] + "/customers/userAccount/mail", customerProfileJson, configs)
      .pipe(map(function (data) { return data; }));
  };

  updatepassword(customerPassword: any) {
    var headers = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Accept': 'application/json, text/plain, */*', 'X-c2b-Authorization': customerPassword.oldPassword,
      'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN')
    });
    var configs = { headers: headers };

    return this.http.put(environment.gcdm_env_hostname + "/gcdm/protected/" + this.authSvc.get_gcdm_config['businessContext'] + "/customers/userAccount/password", customerPassword.password, configs)
      .pipe(map(function (data) { return data; }));
  };

  resendActivationMail = function (this: UserService, mail: any) {
    return this.http.post(environment.gcdm_env_hostname + "/gcdm/public/" + this.authSvc.get_gcdm_config['businessContext'] + "/workflows/mailAdministrations/customers/resend?loginId=" + mail, '')
      .pipe(map(function (data) { return data; }));
  };

  public get gcdmProfileValue(): GcdmProfile {
    return this.gcdmProfileSubject.value;
  }

  register(user: User, contactPolicyConsents: any) {

    var regObjectJson = {
      "userAccount":
      {
        "mail": user.mail,
        "password": user.password
      },
      "businessPartner":
      {
        "salutation": user.formOfAddress,
        "surname": user.surname,
        "givenName": user.firstName,
        "partnerCategory": "PERSON"
      }, "contactPolicyConsents": contactPolicyConsents
    }

    return this.http.post(environment.gcdm_env_hostname + "/gcdm/public/v4/" + this.authSvc.get_gcdm_config['businessContext'] + "/customers", regObjectJson)
      .pipe(map(results => {
        localStorage.setItem('gcdmProfile', JSON.stringify(results));
        localStorage.setItem('registeredEmail', user.mail);
        return results;
      }));
  }

  activate(token: string) {
    return this.http.post(environment.gcdm_env_hostname + "/gcdm/public/" + this.authSvc.get_gcdm_config['businessContext'] + "/workflows/mailAdministrations/tokens/" + token + "/activation", true);
  }

  checkEmail(email: string) {
    return this.http.get(environment.gcdm_env_hostname + "/gcdm/public/" + this.authSvc.get_gcdm_config['businessContext'] + "/userAccounts?loginId=" + email);
  }

  resetPassword(email: string) {
    localStorage.setItem('resetEmail', email);
    return this.http.post(environment.gcdm_env_hostname + "/gcdm/public/" + this.authSvc.get_gcdm_config['businessContext'] + "/customers/userAccount/password/reset?loginId=" + email, true);
  }

  // policyStoreReg(): Observable<PolicyStoreConsent> {
  //   let headers = new HttpHeaders({
  //     'Accept': 'application/json', 'Content-Type': 'application/json'
  //     , 'KeyId': environment.gcdm_key_id
  //   });
  //   let configs = { headers: headers }
  //   return this.http.get<PolicyStoreConsent>(environment.gcdm_env_hostname + "/pm2/pm-document-service/api/v1/documents/Privacy_Statement/ZA", configs)
  //     .pipe(map(data => data as PolicyStoreConsent), delay(2000));
  // }

  findAddress(addressSearch: string): Observable<any> {

    let Key = "WT81-UC77-BK95-BN59",
      IsMiddleware = false,
      Origin = "",
      Countries = "ZAF",
      Limit = "10",
      Language = "en";

    let params = '';
    params += "&Key=" + encodeURIComponent(Key);
    params += "&Text=" + encodeURIComponent(addressSearch);
    params += "&IsMiddleware=" + encodeURIComponent(IsMiddleware);
    params += "&Container=" + encodeURIComponent('');
    params += "&Origin=" + encodeURIComponent(Origin);
    params += "&Countries=" + encodeURIComponent(Countries);
    params += "&Limit=" + encodeURIComponent(Limit);
    params += "&Language=" + encodeURIComponent(Language);

    let headers = new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded' });
    let configs = { headers: headers }
    return this.http.post<any>("https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws", params, configs)
      .pipe(map(data => data as any));
  }

  retrieveAddress(Id: string): Observable<any> {

    let Key = "WT81-UC77-BK95-BN59";
    let Field1Format = "";

    let params = '';
    params += "&Key=" + encodeURIComponent(Key);
    params += "&Id=" + encodeURIComponent(Id);
    params += "&Field1Format=" + encodeURIComponent(Field1Format);

    let headers = new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded' });
    let configs = { headers: headers }
    return this.http.post<any>("https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws", params, configs)
      .pipe(map(data => data as any));
  }
}
