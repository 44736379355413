import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import { User } from '../_models/user.model';
import { Bp } from '../_models/bp.model';
import { environment } from "../../environments/environment";
import { DataServiceService } from '../_services/data-service.service'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  httpClient: HttpClient;
  private currentUserSubject!: BehaviorSubject<User>;
  private currentBpSubject!: BehaviorSubject<Bp>;
  private userProfileSubject!: BehaviorSubject<any>;
  public currentUser!: Observable<User>;
  public currentBp!: Observable<Bp>;
  public userProfile!: Observable<any>;
  public get_mini_brand;
  public get_bmw_brand;
  public get_motorrad_brand;
  public get_gcdm_config: any;
  private motorplayapi = environment.MOTORPLAN_API_URL;
  results: any;
  gcdmAuth: any;


  constructor(private http: HttpClient, private shttp: HttpClient,
    private bypasser: HttpBackend, private dataSvc: DataServiceService) {
    this.httpClient = http;
    this.shttp = new HttpClient(bypasser);

    //GCDM Logged in User
    let curUser = localStorage.getItem('currentUser');
    if (curUser != null) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(curUser));
      this.currentUser = this.currentUserSubject.asObservable();
    }

    //Business Partner from OC and My Account
    let bp = localStorage.getItem("currentBp");
    if (bp != null) {
      this.currentBpSubject = new BehaviorSubject<Bp>(JSON.parse(bp));
      this.currentBp = this.currentBpSubject.asObservable();
    }


    this.get_bmw_brand = dataSvc.process_env_bmw;
    this.get_mini_brand = dataSvc.process_env_mini;
    this.get_motorrad_brand = dataSvc.process_env_motorrad;
    this.get_gcdm_config = this.get_config();
  }

  // public get currentUserValue(): User {
  //   return this.currentUserSubject.value;
  // }

  public get currentBpValue(): Bp {
    return this.currentBpSubject.value;
  }

  public get userProfileValue(): any {
    return this.userProfileSubject.value;
  }
  get_config =  () => {
    var brand = {};
    if (window.location.href.indexOf("localhost") != -1) {
      brand = this.get_bmw_brand;
    //  require("style-loader!./../_content/BMW.less");
    }

    if (window.location.href.indexOf("bmw-motorrad") != -1) {
      brand = this.get_motorrad_brand;
    //  require("style-loader!./../_content/MOT.less");
    } else

      if (window.location.href.indexOf("mini") != -1) {
        brand = this.get_mini_brand;
     //   require("style-loader!./../_content/MINI.less");
      } else

        if (window.location.href.indexOf("bmw") != -1) {
          brand = this.get_bmw_brand;
      //    require("style-loader!./../_content/BMW.less");
        }

    return brand;
  }




  gcdmLogOff(): Observable<any> {
    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/x-www-form-urlencoded',
    //   'Authorization': 'Basic ' +  this.getApi()
    // });

    return this.getApi().pipe(
      switchMap(data => {
        let b64string = btoa(data.gcdm_basic);
        let headers = new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic ' + b64string
        });

    let configs = { headers: headers };
    let regObjectJson = "token=" + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN');
    return this.http.post(environment.gcdm_env_hostname + "/gcdm/oauth/revoke", regObjectJson, configs)
      }),
      map(results => results)
    );
  };


  getApi(): Observable<any>{
    // let csrfToken = this.tokenExtractor.getToken() as string;
    let headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    });
    var configs = { headers: headers };

    return this.http.get<any>(environment.apiURL +"/customerportalapi/rest/cp/crm/customer/getApi")
      .pipe(map(data =>  data as any));
  }



  // cpLogin(): Observable<any> {
  //   var headers = new HttpHeaders({
  //     'X-Frame-Options': 'sameorigin',
  //     'Strict-Transport-Security': 'max-age=31536000',
  //     'Authorization': 'Basic ' + btoa(environment.BASIC)
  //   });
  //   var configs = { headers: headers };
  //   return this.http.get(this.get_gcdm_config["CP_hostname"], configs)
  //     .pipe(map(function (results) {
  //       return results;
  //     }));
  // };


}
