<div class="tyre-plan-component componentWrapper" [ngClass]="brandFromParent=='MINI'?'md-container':''">
    <div class="col-12 component-header-image">
        <img src="./assets/{{brandFromParent}}/images/tyre-plan.jpg">
    </div>
    <div class="col-12 component-header">
        <h5>{{brandFromParent}} Tyre Plans</h5>
    </div>
    <div class="col-12 component-content">
        <p>Get one brand-new set of 4 tyres including wheel alignment, fitment and balancing, at any time during a
            36-month period.</p>

    </div>
    <div class="col-12 component-buttons-links link">
        <a href="https://www.bmw.co.za/en/topics/offers-and-services/accessories/bmw-wheels-tyres.html" class="buttons button-ghost" target="_blank" *ngIf="brandFromParent=='BMW'">
            Learn more
        </a>
        <a href="https://www.mini.co.za/en_ZA/home/accessories/wheels-and-tyres.html" class="buttons button-ghost" target="_blank" *ngIf="brandFromParent=='MINI'">
            Learn more
        </a>
    </div>
</div>