export const environment = {
  production: false,
  cosyUrl: 'https://digitalshow-i.bmwgroup.com/ds/Rockar/v1/cosyimages',
  apiURL: window.location.origin,
  apiURLMini: 'https://www.account-i.mini.co.za',
  apiURLMotorrad: 'https://www.account-i.bmw-motorrad.co.za',
  gcdm_env_hostname: 'https://customer-i.bmwgroup.com',
  gcdm_env_host: 'customer-i.bmwgroup.com',
  gcdm_client_id: '',
  gcdm_key_id: '',
  gcdm_secret_key: '',
  gcdm_login_env_hostname: 'https://customer-i.bmwgroup.com',
  bmw_businessContext: 'customerportal/ZA-BMW-en-dev',
  mini_businessContext: 'customerportal/ZA-MINI-en-dev',
  mot_businessContext: 'customerportal/ZA-MOT-en-dev',
  MObrand: 'https://www.account-i.bmw-motorrad.co.za',
  MIbrand: 'https://www.account-i.mini.co.za',
  BMbrand: 'https://www.account-i.bmw.co.za',
  API_DSP_BASE_URL: '',
  API_GCDM_BASE_URL: '',
  API_DLNET_BASE_URL: "",
  API_SOL_BASE_URL: "",
  API_SOL_MOT_BASE_URL: "",
  API_WEB_API_BASE_URL: "",
  OASIS_API: "https://oasisza-int.bmwgroup.net/oasis/BMWSAGlobalService/Dealers/Dealer",
  MOTORPLAN_API_URL: "https://motorplanza-int.bmwgroup.com/mp3/AfterSalesWebAPI",
  mockApi: false,
  brand: 'BMW',
  TECH_SPEC_URL: window.location.origin,
  state: 'mlkKWXGMmpJ1h2G11V8SR2Te1ebnsyHkDeIZjLcSVzoTz8o7JAe3SzqNLourxQOU',
  SOL_hostname: "https://forms.bmw.co.za/Aftersales/2016/service.html",
  logoffHostname: JSON.stringify('www.bmw.co.za%2Fen%2Findex.html'),
  paymentGateway: "https://motorplanza-int.bmwgroup.com/mp3/Mp3PaymentGateway",
  contact_API_URL: 'https://api-i.bmw.co.za/'
};
