import { Component, OnInit, Inject, ChangeDetectorRef, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ServiceEnquiryService } from '../../_services/service-enquiry.service';
import { LoaderService } from '../../_services/loader/loader.service';
import { CosyService } from '@services/cosy.service';
import { CampaignsService } from '@services/campaigns.service';
import { DataServiceService } from '@services/data-service.service';
import {ServiceOptionsService} from "@services/service-options.service";
import * as ServiceOptDescriptions from 'src/assets/BMW/json/serviceOptionsData/ServiceOptionsData.json';
import { UserService } from "@services/user.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-service-enquiry',
  templateUrl: './service-enquiry.component.html',
  styleUrls: ['./service-enquiry.component.less']
})
export class ServiceEnquiryComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  private router: Router,
   private myServiceEnquirySvc: ServiceEnquiryService,
   private sanitizer: DomSanitizer,
   private loaderSvc: LoaderService,
   private cosySvc: CosyService,
   private campaignsSvc: CampaignsService,
   private dataSvc: DataServiceService,
    private cdr: ChangeDetectorRef,
   private servOptSvc: ServiceOptionsService,
   private userSvc: UserService
   ) {
    this.brand = this.dataSvc.getBrand();
    this.formUrl = 'assets/'+this.brand+'/page/contactForm.html';
    }
   @HostListener('message', ['$event'])
   getMsg(event: MessageEvent){
  }



  accordion_two: boolean = false;

  serviceOptDescriptions = ServiceOptDescriptions;

  selected_services: any = [];
  result_options: any = [];
  // service_options:any = [];
  serviceType: string = '';
  estimatedTotal: string = '0';
  finalEstimatedTotal: string = '0';
  iframeUrl: string = '';
  vin: string = '';
  get_gcdm_config: string = '';
  dealer: string = '';
  iframeIsLoading: boolean = false;
  urlSafe: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
  show_more: boolean = false;
  selected_Vehicle: any = {};
  vehicle_result: any = {};
  last_service: string = '';
  vehicle_reg: string = '';
  vehicle_description: string = '';
  service_partner: string = '';
  cosyURL: string = '';
  plan_type:any = {};
  servRest:any = null;
  resetServ:any = null;
  tyreInformation: any;
  retailPrice: any;
  finalPrice: string = '0';
  showAccordionTwo: boolean = false;
  notes: any = [];
  showError: boolean = false;
  messageStus: string = '';
  brand: string = '';
  formUrl:string = '';

  ngOnInit(): void {
    this.plan_type = localStorage.getItem("se_type");
    let selectedVehicle = this.dataSvc.getSelectedVehicle();
    if (selectedVehicle !== undefined || selectedVehicle !== null) {
      this.selected_Vehicle = JSON.parse(selectedVehicle);
      this.getVehicleDetails(this.selected_Vehicle);
      window.addEventListener('message', this.handleMessage.bind(this));
    }

  }


  submitForm() {
    this.accordion_two = true;
    this.showNextAccordion('accordion-two');
  }

  handleMessage(event: MessageEvent){
    this.messageStus = event.data;
  }

  showNextAccordion(panelId: string) {
    if (panelId === '') {
      return;
    }
    else {
      let hasLink = this.document.querySelectorAll(".accordion-item a[href='#" + panelId + "']");
      if (hasLink != null) {
        this.showAccordionTwo = true;
        let triggerLink = hasLink[0] as HTMLElement;
        triggerLink.click();
        // this.selected_services = '';
        // this.notes = [];
        this.cdr.markForCheck();
      }
      else {
        return
      }
    }
  }

  selectServicePlan(i: string, finalPrice:string) {
    this.selected_services = i;
    this.finalPrice = finalPrice;

  }

  removeServicePlan(i: string) {
    if (this.selected_services === i) {
      let selected_s = this.selected_services.replace(i, '');
      this.selected_services = selected_s;
      this.finalPrice = '0';
    }
  }

  getVehicleDetails(selectedVehicle: any) {

      this.vin = selectedVehicle.vinNumber;
      this.vehicle_reg = selectedVehicle.vehLpno;
      this.vehicle_description = selectedVehicle.modelDesc;
      this.cosyURL = selectedVehicle.cosyURL;
      this.vehicle_result = selectedVehicle;

      if (this.plan_type != null) {
        this.serviceType = this.plan_type;
      if (this.plan_type === 'tyre-options') {
        this.getAllTyreOptions();
      }
      else {
        this.getAllServiceOptions();
      }
    }

  }

  async getCosyImage(vin: string) {
    let parms = { "cosyUrlRequest": [{ "bodyno": "", "doNotEncode": "", "exteriorCode": "", "externalIndicator": "X", "interiorCode": "", "modelCode": "", "options": [""], "productionDate": "", "prodweek": "", "qualityPercentage": "", "quickviewIndicator": "", "transparentIndicator": "X", "vin": vin, "width": "" }] };
    let result = "";

    let url = "";
    let res: any;
    this.cosySvc.doGetCosyImage(vin).subscribe((data => {
      res = data;
      result = res.cosyImageUrlList[0];
      if (result.indexOf("https://prod.cosy") == -1) {
        result = "../assets/BMW/images/cosy/bmw-no-image-available.png";
      }
      this.cosyURL = result;
    }), err => {
      this.cosyURL = "../assets/BMW/images/cosy/bmw-no-image-available.png";
      return null;
    });

    return null;
  }


  updateView(selectedVehicle: any) {
    this.getVehicleDetails(selectedVehicle.vinNumber);
  }

  getAllServiceOptions() {

    this.servOptSvc.getVehicleServiceOption(this.dataSvc.getDealerId(), this.vehicle_result.modelYear, this.vehicle_result.bodyNumber).subscribe(data => {

      let res = data.defect;
      let inclPrice;
      let seqnr;
      if (res.length > 0) {
        this.serviceOptDescriptions.serviceDescriptions.forEach((p, j) => {
          res.forEach((o: { defect: string; inclVat: string; seqnr: string; }, i: any) => {
           // console.log("********************" + this.vehicle_result.bodyNumber);
           // console.log(p);
            if(p.id === "99600999MP"){
              //console.log("defect> " + o.defect);
              if(o.defect === p.id){
                inclPrice = o.inclVat;
                seqnr =o.seqnr;
                this.resetServ = {defects: p, inclPrice: inclPrice, seqnr:seqnr};
                //console.log("Service reset is set ", this.resetServ);
              }
            }


            if(o.defect === p.id){
              inclPrice = o.inclVat;

              seqnr =o.seqnr;
              if(o.defect !== "99600999MP"){
                this.result_options.push({defects: p, inclPrice: inclPrice, seqnr:seqnr});
              }
            }

          })

        })
      }
    })

  }



  getAllTyreOptions() {

      let res = this.dataSvc.getOptionalTyreData().vehicleTyreSearch.z55TyreInfo;
      this.tyreInformation = this.dataSvc.getOptionalTyreData().tyreInformation;
      this.retailPrice = this.dataSvc.getOptionalTyreData().retailPrice;

    res.forEach((o: { rimdescr: string | string; fitted: string; matnr: any; retailPrice: any; manufacturer: any; }, i: number) => {
      let frontPrice;
      let rearPrice;
      let fullPrice;
      let singlePrice;

      if(o.matnr !== this.tyreInformation.matnr) {
        if (o.fitted === 'F') {

          frontPrice = (Number(o.retailPrice) * 2) * 1.15;
          const isRearPresent = res.find((p: { manufacturer: any; fitted: string; }) => p.manufacturer === o.manufacturer && p.fitted === 'R');
          rearPrice = (Number(isRearPresent.retailPrice) * 2) * 1.15;
          fullPrice = frontPrice + rearPrice;
          this.result_options.push({"tyreInfo": isRearPresent, "tyreFullPrice": fullPrice});
          this.result_options.push({"tyreInfo": o, "tyreFullPrice": (Number(o.retailPrice))*1.15, "productType":"single"});
        }

        if(o.fitted === 'R'){
          this.result_options.push({"tyreInfo": o, "tyreFullPrice": (Number(o.retailPrice))*1.15, "productType":"single"});
        }

        if (o.fitted === 'A') {
          this.result_options.push({"tyreInfo": o, "tyreFullPrice": (Number(o.retailPrice) * 4) * 1.15});
          this.result_options.push({"tyreInfo": o, "tyreFullPrice": (Number(o.retailPrice)) * 1.15, "productType":"single"});
        }
      }
      else{
        if (o.fitted === 'F') {
          this.result_options.push({"tyreInfo": o, "tyreFullPrice": (Number(o.retailPrice))*1.15, "productType":"single"});
        }

        if(o.fitted === 'R'){
          this.result_options.push({"tyreInfo": o, "tyreFullPrice": (Number(o.retailPrice))*1.15, "productType":"single"});
        }

        if (o.fitted === 'A') {
          this.result_options.push({"tyreInfo": o, "tyreFullPrice": (Number(o.retailPrice)) * 1.15, "productType":"single"});
        }
      }

    });
  }


  onCheckboxChange(e: string) {
   //console.log(e);
   // console.log(this.serviceType);

    if (this.serviceType != null) {
      if (this.serviceType == 'service-options') {
       // console.log("what is selected services ", this.selected_services)
        if (!this.selected_services.includes(e)) {
        //  console.log("selected service doesn't includes ", e);
          this.selected_services.push(e);
          this.estimateTotal(e)
        }
        else {
        //  console.log("selected service includes ", e);
          const index = this.selected_services.indexOf(e);
          if (index > -1) {
            this.subtractTotalEstimate(e)
            this.selected_services.splice(index, 1);
            if(this.selected_services.length === 0){
              this.finalEstimatedTotal = '0';
              this.servRest = null;
            }
          }
        }
      }
      else {
        if (this.selected_services === e) {
          let selected_s = this.selected_services.replace(e, '');
          this.selected_services = selected_s;
          // this.estimatedTotal = '0';
        }
        else {
          this.selected_services = e;

        }
      }
    }

  }


  submitsService() {
    if (this.serviceType == 'service-options') {
      this.submitsServiceOptions();
    }
    else {
      this.submitTyreOptions();
    }
  }


  submitTyreOptions() {
    let res = this.dataSvc.getOptionalTyreData().vehicleTyreSearch.z55TyreInfo;
    if (res != null) {
      if(this.finalPrice > '0'){
        this.showError = false;
      this.estimatedTotal = this.finalPrice;
      res.forEach((o: any) => {
        if(o.manufacturer.includes(this.selected_services)) {
          this.notes.push( { PartNo: o.matnr, Parts: o.manufdesc });
        }
      });

        if (this.notes != null) {
          this.getRequiredUserInfo(JSON.stringify(this.notes));

          this.iframeIsLoading = true;
          this.accordion_two = true;
          this.showNextAccordion('accordion-two');
        }
      }
      else{
        this.showError = true;
        setTimeout(()=>{
          this.showError = false;
        }, 10000);
      }
    }
    else {

    }
  }

  submitsServiceOptions() {
    if (this.finalEstimatedTotal > '0') {
      this.showError = false;
      let selected: any = this.flatten(this.getOptionsByDefect(this.selected_services));
      let notes_array: any = [];

      for (let i = 0; i < selected.length; i++) {
        let new_arr = {
          PartId:selected[i].defects.id,
          Parts: selected[i].defects.heading,
          PartPrice: selected[i].inclPrice
        }
        notes_array.push(new_arr)
      }

      let notes = JSON.stringify({
        notes: notes_array,
        TotalPrice: this.finalEstimatedTotal
      })

      if (notes != null) {
        this.getRequiredUserInfo(notes);

        this.iframeIsLoading = true;
        this.accordion_two = true;
        this.showNextAccordion('accordion-two');
      }
    }
    else {
      this.showError = true;
      setTimeout(()=>{
        this.showError = false;
      }, 10000);
    }
  }

  getRequiredUserInfo(notes:string) {
    let selectedVehicle = JSON.parse(this.dataSvc.getSelectedVehicle());
    let title:string, email_address:string, name:string, surname:string, vehicle_reg:string, preferred_dealer:string, vin:string;
    let userServiceObservable = this.userSvc.getGcdmCustomerProfile();
    userServiceObservable.subscribe((customerDetails) => {
      customerDetails.forEach((o: any) => {

        if (o !== undefined) {
          title = this.titleList(o.salutation);
          name = o.givenName;
          surname = o.surname;
          vehicle_reg = selectedVehicle.vehLpno;
          vin = selectedVehicle.vinNumber;
          preferred_dealer = this.dataSvc.getDealerId();


          if (o.communications !== undefined) {
            if (o.communications.communicationEMails !== undefined) {
              o.communications.communicationEMails.forEach((c: any, i: any) => {
                if (c.preferred || c.value) {
                  email_address = c.value;
                }
              });
            }
          } else {
            email_address = ""
          }

          var apiKey = "";

          // this.switchAPIKeys('someBrand', (apiKey) => {
          //   console.log('API Key: ', apiKey);
          // });
          this.switchAPIKeys(this.brand, (apiKey) => {
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.formUrl +'?wccomment=VIN number:' + this.vin + notes + '&apiKey=' + apiKey + '&title=' + title + '&name=' + name + '&surname=' + surname + '&email_address=' + email_address + '&preferred_dealer=' + preferred_dealer + '&vehicle_reg=' + vehicle_reg);
          });
        }
      })
    })
  }

  myLoadEvent() {
    this.iframeIsLoading = false;
  }

  estimateTotal(e: string) {
    //console.log("inside estimate totatl" , e);
    if (this.selected_services != null) {
      let new_arr: any = [];

      new_arr = this.flatten(this.getOptionsByDefect(this.selected_services))
      // console.log("in estimage total " , new_arr);
      // console.log("new arr length loop " + new_arr.length);
      // console.log(" what is the estimated totatl? ", this.estimatedTotal);



      let currentPrice = this.estimatedTotal;
      if (this.resetServ != null) {
        // console.log("reset serve is not null");
        this.servRest = this.resetServ.inclPrice;
      } else {
        // console.log("reset serve is null");
        this.servRest = 0;
      }


      for (let i = 0; i < new_arr.length; i++) {
        const sum_result = Number(currentPrice) + Number(new_arr[i].inclPrice);
       // console.log("loop "  + i + " price : " + currentPrice +  " includ price " + new_arr[i].inclPrice + " for " + new_arr[i].defects.heading);
        this.estimatedTotal = sum_result.toString();
        if(this.resetServ === 0){
          this.finalEstimatedTotal = (Number(this.estimatedTotal)).toString();
         // console.log("resetServ is 0 0" , this.finalEstimatedTotal);
        } else {

          this.finalEstimatedTotal = (Number(this.estimatedTotal) + Number(this.servRest)).toString();
        //  console.log("resetServ is not 0" , this.finalEstimatedTotal);
        }

      }
    }
  }


  subtractTotalEstimate(e: string) {
    if (this.selected_services != null) {
      let new_arr: any = [];
      new_arr = this.flatten(this.getOptionsByDefect(this.selected_services))
      let te = new_arr.filter((i: any) => i.defects.id == e)

      let currentPrice = this.estimatedTotal;
      const sum_result = Number(currentPrice) - Number(te[0].inclPrice);
      this.estimatedTotal = sum_result.toString();
      if(this.resetServ === 0){
        this.finalEstimatedTotal = (Number(this.estimatedTotal)).toString();
      } else {
        this.finalEstimatedTotal = (Number(this.estimatedTotal) + Number(this.servRest)).toString();
      }

    }
  }

  getOptionsByDefect(data: any) {
    if (data != null) {
      let filteredArr: any = [];
      data.forEach((x: any) => {
        filteredArr.push(this.result_options.filter((i: any) => i.defects.id.includes(x)))
      })

      return filteredArr;
    }
    return;
  }

  flatten(arr: any) {
    return [].concat(...arr);
  }


  showMore() {
    if (this.show_more === false) {
      this.show_more = true;
    }
    else {
      this.show_more = false;
    }
  }

  titleList(title: string) {
    if (title === 'SAL_MR') {
      return 'Mr'
    }

    if (title === 'SAL_MS') {
      return 'Ms'
    }

    if (title === 'SAL_MRS') {
      return 'Mrs'
    }

    if (title === 'SAL_MISS') {
      return 'Miss'
    }

    if (title === 'SAL_DR') {
      return 'Dr'
    }
    return "";
  }

  backToDash(){
    this.messageStus = '';
    window.removeEventListener('message', this.handleMessage.bind(this));
    this.router.navigate(['/service-dashboard']);
    // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('');
    // this.showAccordionTwo = false;
  }


  // switchAPIKeys(brand:string) {
  //   var answer = "";
  //   console.log("Brand contact: ", brand);
  //   if(!brand){
  //     brand = "BMW";
  //   }
  //
  //   let servOptObservable = this.servOptSvc.getContactApiKey(brand);
  //   servOptObservable.subscribe((key) => {
  //     console.log("Key: ", key.contactKey);
  //     answer = key.contactKey;
  //   });
  //   console.log("Answer: ", answer);
  //   return answer;
  // }

  switchAPIKeys(brand: string, callback: (key: string) => void) {
    console.log("Brand contact: ", brand);
    if (!brand) {
      brand = "BMW";
    }

    let servOptObservable = this.servOptSvc.getContactApiKey(brand);
    servOptObservable.subscribe((key) => {
      console.log("Key: ", key.contactKey);
      callback(key.contactKey);
    });
  }

}
