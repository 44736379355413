import { HttpClient, HttpHeaders, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, delay } from "rxjs/operators";
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
import { DataServiceService } from './data-service.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleInfoService {
  httpClient: HttpClient;
  get_gcdm_config: any;

  constructor(private http: HttpClient, private authSvc: AuthenticationService, private tokenExtractor: HttpXsrfTokenExtractor,
    private storageService: DataServiceService) {
    this.httpClient = http;
    this.get_gcdm_config = this.authSvc.get_config();
  }


  //GET VEHICLE INFO 
  getVehicleForProspect(token: any): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + token });
    var configs = { headers: headers };
    return this.http.get<any>(environment.apiURL + "/customerportalapi/rest/cp/crm/customer/on-demand/get-customer-vehicle-bp", configs)
      .pipe(map(data => data as any));
  }


  //GET VEHICLE INFO with oauth token
  getVehicleInfo(): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
    var configs = { headers: headers };
    return this.http.get<any>(environment.apiURL + "/customerportalapi/rest/cp/crm/customer/on-demand/get-customer-vehicle-bp", configs)
      .pipe(map(data => data as any), delay(1000));
  }

  //no longer own vehicle
  removeVehicle(contractFlag:string, bpNo: string, bodyNo: string, vin: string): Observable<any> {
    let csrfToken = this.tokenExtractor.getToken() as string;
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
    var configs = { headers: headers };
    // let VehicleChangeOfOwnership = { activeContract: this.storageService.getActiveContract(), bpNo: bpNo, bodyNo: bodyNo, vin: vin }
    let VehicleChangeOfOwnership = { activeContract: contractFlag, bpNo: bpNo, bodyNo: bodyNo, vin: vin }
    return this.http.post<any>(environment.apiURL + "/customerportalapi/rest/cp/vehicles/vehicle-change-ownership", VehicleChangeOfOwnership, configs)
      .pipe(map(data => data as any));
  }

  //add new vehicle

  addVehicle(addNewVehicle: any): Observable<any> {
    let csrfToken = this.tokenExtractor.getToken() as string;
    
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
    var configs = { headers: headers };
    let add = {
      "bpNo": this.storageService.getGcdmCustBp(),
      "communicationPreference": addNewVehicle.contactpreferences,
      "email": addNewVehicle.contactpreferences === 'E-mail' ? addNewVehicle.communicationMethod : "",
      "phone": addNewVehicle.contactpreferences === 'Phone' ? addNewVehicle.communicationMethod : "",
      "chassis": addNewVehicle.chassisnumber,
      "registration": addNewVehicle.registrationnumber,
      "year": addNewVehicle.year,
      "model": addNewVehicle.model,
      "vin": addNewVehicle.chassisnumber,
      "colour": "",
      "trim": "",
      "purchasedDate": "",
      "purchasedMileage": "",
      "currentMileage": ""
    }
    return this.http.post<any>(environment.apiURL + "/customerportalapi/rest/cp/vehicles/add-vehicle", add, configs)
      .pipe(map(data => data as any));
  }


  postActivateOnDamnd(guid: any): Observable<any> {
    return this.http.get<any>(environment.apiURL + "/customerportalapi/rest/cp/crm/customer/on-demand/activation/" + guid)
      .pipe(map(data => data as any));
  }
}
