<div class="col-12 motorplan-component componentWrapper">
    <div class="col-12 component-header">
        <h4>MOTORPLAN CONTRACT</h4>
    </div>
    <div class="col-12  component-content" *ngIf="!motorplanContractDatesToChild">Loading...</div>
    <div class="col-12 component-content" *ngIf="motorplanContractDatesToChild?.motorplanContractDates && motorplanContractDatesToChild?.motorplanContractDates?.contractStatus ==='CS00'">
        <p>Valid until</p>
        <h3>{{ motorplanContractDatesToChild?.motorplanContractDates?.endDate }}<br/>{{ motorplanContractDatesToChild?.motorplanContractDates?.currentDate }}</h3>
        <span class="motoplan-expiry-seperator">OR</span>
        <h3>{{ motorplanContractDatesToChild?.motorplanContractDates?.endKM }}</h3>
    </div>
    <div class="col-12 component-content" *ngIf="motorplanContractDatesToChild?.motorplanContractDates && motorplanContractDatesToChild?.motorplanContractDates?.contractStatus !=='CS00'" [ngClass]="!motorplanContractDatesToChild?.motorplanContractDates ? 'hasExpired': motorplanContractDatesToChild?.motorplanContractDates?.contractStatus !=='CS00' ? 'hasExpired':''">
        <h4>Contract Expired</h4>
        <span class="motoplan-expiry-seperator">ON</span>
        <h3><span *ngIf="motorplanContractDatesToChild?.motorplanContractDates?.contractStatus ==='CS07'">{{ motorplanContractDatesToChild?.motorplanContractDates?.endDate }}</span><span *ngIf="motorplanContractDatesToChild?.motorplanContractDates?.contractStatus ==='CS08'">{{ motorplanContractDatesToChild?.motorplanContractDates?.endKM }}</span></h3>
    </div>
  <div class="col-12 component-buttons-links">
    <button class="buttons button-primary" *ngIf="motorplanContractDatesToChild?.optContract  && brandFromParent !== 'MINI'" (click)="redirectToMotorPlanOptionalApp(motorplanContractDatesToChild)" >
        Buy optional Motorplan contract
    </button>
    <button class="buttons button-primary" *ngIf="motorplanContractDatesToChild?.optContract && brandFromParent === 'MINI'" (click)="goToRenewMotorPlan()" >
      Buy optional Motorplan contract
    </button>
    <!-- <button class="buttons button-primary" *ngIf="motorplanContractDatesToChild?.optContract" data-bs-toggle="modal" data-bs-target="#motorplanModal" >
      Buy optional Motorplan contract
    </button> -->
    <button class="buttons button-primary" *ngIf="!motorplanContractDatesToChild?.optContract" disabled>
      Buy optional Motorplan contract
    </button>
  </div>
</div>
