import { Component, OnInit, Input } from '@angular/core';
import { VehicleInfoService } from '@services/vehicle-info.service';
import { DataServiceService } from '@services/data-service.service';

@Component({
  selector: 'app-remove-vehicle-modal',
  templateUrl: './remove-vehicle-modal.component.html',
  styleUrls: ['./remove-vehicle-modal.component.less']
})
export class RemoveVehicleModalComponent implements OnInit {
  @Input()
  vehicleInfoFromParent: any;
  request_result:string = '';
  alertMsg: string = '';
  constructor(private vehicleInfoSvc: VehicleInfoService, private dataSvc: DataServiceService) { }

  ngOnInit(): void {
  }

  removeVehicle(_vehicleData: any): void {
    
    let bpNo = this.dataSvc.getBpNo();
    let contractFlag = '';

    if(_vehicleData.contractStatus === 'CS00'){
      contractFlag ='hasActiveContract';
    }
    this.vehicleInfoSvc.removeVehicle(contractFlag, bpNo, _vehicleData.bodyNumber, _vehicleData.vinNumber).subscribe((data)=>{
      if(data.success!=false){
        this.alertMsg = data.message;
        this.alertMsg = 'Vehicle has been successfully removed, contact centre will contact you.';
        this.request_result = "success";
        setTimeout(()=>{
          this.request_result = '';
          this.alertMsg = '';
          this.cancelForm();
        }, 5000);
      }
      else{
        this.alertMsg = 'This vehicle with this VIN number '+_vehicleData.vinNumber+' has fialed to be removed.';
        this.request_result = "error";
        setTimeout(()=>{
          this.request_result = '';
          this.alertMsg = '';
          this.cancelForm();
        }, 5000);
      }
    })
  }

  cancelForm(){
    let cancelBtn = document.getElementById('cancelRemoveBtn') as HTMLElement;
    cancelBtn.click();
    this.request_result = '';
    this.alertMsg = '';
  }
}
