import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable } from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
//no auth
export class CosyService {
  private httpClient: HttpClient;
  results: any = {};
  constructor( private router: Router, private http: HttpClient, handler: HttpBackend ) {
    this.httpClient = new HttpClient(handler);
  }




    // doGetCosyImage(vin:any): Observable <any> {
    //   let parms = { "cosyUrlRequest": [{ "bodyno": "", "doNotEncode": "", "exteriorCode": "", "externalIndicator": "X", "interiorCode": "", "modelCode": "", "options": [""], "productionDate": "", "prodweek": "", "qualityPercentage": "", "quickviewIndicator": "", "transparentIndicator": "X", "vin": vin, "width": "" }] };
    //   var headers = new HttpHeaders({
    //   //  'Host': "digitalshow-i.bmwgroup.com"
    // });
    // var configs = { headers: headers };
    //   let url =  "";
    //   //  let url =  environment.cosyUrl;
    //   return this.httpClient.post(url, parms,  {} )
    //   .pipe(map(results=> results));
    // }


  doGetCosyImage(vin:string): Observable<any>{

    let headers = new HttpHeaders({'Content-Type': 'application/json',  'accept': 'application/json'});
    let configs = {headers: headers};
    let regObjectJson={};

    regObjectJson ={
      "cosyUrlRequest": [
        {
          "bodyno": "",
          "doNotEncode": "",
          "exteriorCode": "",
          "externalIndicator": "",
          "interiorCode": "",
          "modelCode": "",
          "options": [
            ""
          ],
          "productionDate": "",
          "prodweek": "",
          "qualityPercentage": "",
          "quickviewIndicator": "",
          "transparentIndicator": "X",
          "vin": vin,
          "width": ""
        }
      ]
    }
    return this.http.post<any>( environment.cosyUrl, regObjectJson, configs)
      .pipe(map(VehicleCosyImage =>  {return VehicleCosyImage;}));
  }

    doGetAllCosyImages(parms:any): Observable <any> {
      let url =  "";
      //  let url =  environment.cosyUrl;
      return this.httpClient.post(url, parms,  {} )
          .pipe(map(results=> results));
    }


    getAllCosyImages(parms:any): Observable <any> {

      let url =  'https://digitalshow.bmwgroup.net/ds/Rockar/v1/cosyimages';
      return this.httpClient.post(url, parms,  {} )
      .pipe(map(results=> results));
    }
}
