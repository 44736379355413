<nav class="bmw-nav">
    <div class="bmw-nav-inner">
        <div class="bmw-nav-container">
            <div class="white-bg-nav bmw-nav-container-inner">
                <div class="bmw-nav-bar">
                    <div class="bmw-nav-bar-row">
                        <div class="bmw-close-mobile-menu">
                            <span class="icon-close-x"></span>
                        </div>
                        <div class="bmw-nav-items" [class.mergeAccount]="mergeAccout">
                            <div class="bmw-nav-item" *ngIf="!isProspect">
                                <a routerLink="/service-dashboard" routerLinkActive="active-link" role="link"
                                    title="Service Dashboard" class="bmw-nav-link">
                                    <span class="bmw-nav-label">Service Dashboard</span>
                                </a>
                            </div>
                          <div class="bmw-nav-item" *ngIf="!isProspect">
                            <a href="https://customer.bmwgroup.com/oneid/#/login?client=bmwwebcom&country=ZA&language=en&brand=bmw&scope=svds%20remote_services%20vehicle_data%20authenticate_user%20smacc%20fupo%20cesim%20perseus&response_type=code&redirect_uri=https%3A%2F%2Fwww.bmw.co.za%2Fstatic%2Flogin%2Ftoken-handler&state=eyJyZXR1cm5VUkwiOiJodHRwczovL3d3dy5ibXcuY28uemEvZW4vc2hvcC9scy9jcC9vbmxpbmUtYXBwb2ludG1lbnQ/Zmx5b3V0PW9wZW4iLCJkYXRhIjp7Imxhbmd1YWdlIjoiZW4iLCJjb3VudHJ5IjoiWkEiLCJicmFuZCI6ImJtdyJ9fQ==&acid=58256926270480782383445885253938270000&epaas_consenttoken=18de95842fe849b81296047039c000000030ZW5fWkF8Ym13X2NvX3phLV9lbl9zaG9w" target="_blank" role="link"
                               title="Book a Service" class="bmw-nav-link">
                              <span class="bmw-nav-label">Book a Service</span>
                            </a>
                          </div>
                          <div class="bmw-nav-item" *ngIf="isProspect">
                              <a routerLink="/prospect" routerLinkActive="active-link" role="link"
                                  title="Prospect" class="bmw-nav-link">
                                  <span class="bmw-nav-label">Prospect</span>
                              </a>
                          </div>

                            <div class="bmw-nav-item">
                                <a [href]="profileUrl" routerLinkActive="active-link" role="link" title="Profile" target="blank"
                                    class="bmw-nav-link">
                                    <span class="bmw-nav-label">Profile</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bmw-nav-icons-bar">
                    <div class="bmw-nav-burger-menu" (click)="showNav()" [class.mergeAccount]="mergeAccout">
                        <span class="icon icon-burger_menu" *ngIf="!show_nav"></span>
                        <span class="icon icon-close_x" *ngIf="show_nav"></span>
                    </div>

                    <!-- <div class="bmw-nav-icons-items">
                        <div class="bmw-nav-icons-item">
                            <a  (click)="doGcdmLogout()" class="bmw-nav-icons-link"
                                title="profile-icon">
                                <span class="icon icon-user"></span>
                                <span class="bmw-nav-icons-label">Logout Test</span>
                                <span class="bmw-green-dot hide_on_mobile" style="display: inline;"></span>
                            </a>
                        </div>
                    </div> -->
                    <div class="bmw-nav-icons-items"  [class.mergeAccount]="mergeAccout">
                        <div class="bmw-nav-icons-item">
                            <a  (click)="showSubMenu()" class="bmw-nav-icons-link"
                                title="profile-icon">
                                <span class="icon icon-user"></span>
                                <span class="bmw-nav-icons-label">test</span>
                                <span class="bmw-green-dot hide_on_mobile" style="display: inline;"></span>
                            </a>
                        </div>
                    </div>
                    <div class="bmw-nav-logo-container">
                        <div class="bmw-nav-logo">
                            <a href="#" title="BMW Logo">
                                <img alt="BMW Logo" class="bmw-nav-grey-logo" src="./assets/BMW/images/bmw_logo.svg">
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
            <div class="subMenu">
            <div class="container">
                <div class="subMenuWrapper">
                    <div class="hideBtn" (click)="hideSubMenu()"><span class="icon icon-close_x"></span></div>
                    <h2 *ngIf="headerCusName">Welcome, {{ headerCusName }}.</h2>
                    <h2 *ngIf="headerCustDataChild?.name">Welcome, {{ headerCustDataChild?.name }}.</h2>
                    <div class="row">
                        <div class="col-sm-4 col-md-3">
                            <h4>My Vehicles</h4>
                            <ul class="menuItem">
                                <li><a data-bs-toggle="modal" href="#addNewVehicle" class="link">Add Vehicle</a></li>
                            </ul>
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <h4>My Account</h4>
                            <ul class="menuItem">
                                <li><a href="javascript:void(0);" class="link" (click)="gotoProfilePage()">My Profile Data</a></li>
                                <li><a href="javascript:void(0);" class="link logout-link" (click)="doGcdmLogout()">Logout</a></li>

                            </ul>

                        </div>
                        <div class="col-sm-4 col-md-6">
                        </div>
                    </div>
                </div>
                </div>
            </div>
</nav>

<app-add-vehicle-modal ></app-add-vehicle-modal>
