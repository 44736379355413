<div class="container main-container">
  <h1>Already a BMW Owner.</h1>
  <div class="text-copy">
    <p>Provide us with the required information in the three easy steps below for us to find your accounts.</p>
  </div>

    <!-- <div class="form-step-indicator">
        <ul class="form-stepper form-stepper-horizontal text-center mx-auto pl-0">

            <li class="form-stepper-active text-center form-stepper-list" step="1">
                <a class="mx-2">
                    <span class="form-stepper-circle">
                        <span>1</span>
                    </span>
                    <div class="label">Enter you ID</div>
                </a>
            </li>

            <li class="form-stepper-unfinished text-center form-stepper-list" step="2">
                <a class="mx-2">
                    <span class="form-stepper-circle text-muted">
                        <span>2</span>
                    </span>
                    <div class="label text-muted">Select you Email</div>
                </a>
            </li>

            <li class="form-stepper-unfinished text-center form-stepper-list" step="3">
                <a class="mx-2">
                    <span class="form-stepper-circle text-muted">
                        <span>3</span>
                    </span>
                    <div class="label text-muted">Done</div>
                </a>
            </li>
        </ul>
    </div> -->

    <swiper [config]="config" class="formSwiper" #mySwiper>
      <div class="swiper-pagination"></div>
      <ng-template swiperSlide>
        <form [formGroup]="firstFormGroup">
          <p>To get started, please enter your {{idVersion}} number and we’ll return all known email addresses linked to your customer record.<br/></p>
        <div class="form-group step-1">
          <div class="row">
            <div class="col-lg-6 col-sm-12 form-field">
              <select id="srchV1" matNativeControl formControlName="idtype" (change)="passChange($event)" required>
                <option value="">Select ID type</option>
                <option value="FS0002" >Passport</option>
                <option value="SAID">SA ID</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-sm-12 form-field">
              <label class="form-label">Enter {{idVersion}} Number*</label>
              <input type="text" formControlName="firstCtrl" placeholder="" name="title">
              <p *ngIf="errorMessage" style="color:red;">{{errorMessage}}<br/></p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-9 col-sm-0">

            </div>
            <div class="col-lg-3 col-sm-12 component-buttons-links">
              <button class="buttons button-primary" (click)="nextSlide()">
                Next
              </button>
            </div>
          </div>
        </div>
        </form>
      </ng-template>
      <ng-template swiperSlide>
        <form [formGroup]="secondFormGroup">
        <div class="form-group step-2">
          <p>Please confirm the email address/es linked to the account/s you’d like to merge. We’ll send an email to your chosen address/es to authenticate you.</p>
          <label>Email Address/es</label>
          <p *ngIf="emailErrorMessage" style="color:red;">{{emailErrorMessage}}<br/></p>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-4 col-sm-12 form-field checkbox-align" formArrayName="emailList" *ngFor="let item of emailList; let i = index">
                  <input type="checkbox" class="form-check-input" placeholder="" name="title" [formControlName]="i"
                         [ngClass]="{'is-invalid': submitted && f.emailList.errors}"
                         data-md-icheck [(ngModel)]="item.checked"
                         (change)="list_change($event, item.emailAddress, item.bpNo)">
                  <label class="form-label">{{ item.maskEmail }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
<!--            <div class="col-lg-9 col-sm-0">-->

<!--            </div>-->
            <div class="col-lg-3 col-sm-12 component-buttons-links">
              <button class="buttons button-primary" (click)="submitForm()">
                Submit
              </button>
            </div>
          </div>
        </div>
        </form>
      </ng-template>
      <ng-template swiperSlide>
<!--        <h1>DONE</h1>-->
<!--        <button class="buttons button-primary" (click)="submitForm()">-->
<!--          Submit-->
<!--        </button>-->
        <label>Success!</label>
        <p>An authentication email has been sent to the following email address/es:<br/></p>
        <div class="col-lg-6 col-sm-12 pt-2" *ngFor="let item of customerEmailToBeMerge">
          <strong>{{ item.maskEmail }}</strong>
        </div>
        <p><br/>Kindly follow the link in the email to complete the authentication and continue with your account merge. If you no longer have access to the email address, you can contact us on 0800 600 555 for assistance.</p>

      </ng-template>

    </swiper>

</div>
