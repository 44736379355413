<div class="container main-container">
  <app-go-back></app-go-back>
</div>

<div class="vehicle-info">
  <div class="container main-container">
    <div class="row">
      <div class="col-lg-7">
        <h1 class="d-none d-md-block">{{vehicle_description}}</h1>
        <div class="detailsWrapper">

          <div class="img-wrapper d-md-none">
            <img src="{{cosyURL}}" class="img-fluid vehicle-img" alt="{{vehicle_description}}" />
          </div>

          <div class="vehicle-detail-wrapper" [ngClass]="show_more==true ? 'expand' : ''">
            <div class="vehicle-detail">
              <table>

                <tr>
                  <td>VIN:</td>
                  <td>
                    <span class="d-none d-md-block">{{vin}}</span>
                    <span (click)="showMore();" class="d-flex d-md-none">{{vin}} <i
                        class="icon icon-arrow-down"></i></span>
                  </td>
                </tr>
                <tr class="d-none d-md-table-row">
                  <td>Vehicle Registration:</td>
                  <td>{{vehicle_reg}}</td>
                </tr>
              </table>
              <table class="d-md-none">

                <tr class="d-table-row d-none">
                  <td>VIN:</td>
                  <td>
                    <span class="d-none d-md-block">{{vin}}</span>
                    <span (click)="showMore();" class="d-flex d-md-none">{{vin}} <i
                        class="icon icon-arrow-down"></i></span>
                  </td>
                </tr>
                <tr>
                  <td>Vehicle Registration:</td>
                  <td>{{vehicle_reg}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-sm-12 d-none d-md-block">
        <div class="img-wrapper">
          <img src="{{cosyURL}}" class="img-fluid vehicle-img" alt="{{vehicle_description}}" />
        </div>

      </div>
    </div>
  </div>
</div>
<div class="container main-container"  [ngClass]="brand=='MINI'?'md-container':''">
  <div class="container col-12 m-0"  *ngIf="messageStus!=='sent-successful'">
    <div class="col-12">
      <h1>YOUR SERVICE OPTIONS.</h1>
      <div class="text-copy">
        <p>Select the service options you are interested in before submitting your enquiry, and one of our
          trusted service partners will get back to you. </p>
      </div>
    </div>
    <div class="col-12 car-view">

    </div>
    <div class="col-12 service-enquiry-components">
      <div class="container car-service-options-container">
        <div class="accordion accordion-flush accordion-wrapper" id="myplans_accordion">
          <div class="accordion-item">
            <a href="#accordion-one" data-bs-toggle="collapse" role="button" aria-expanded="true"
              aria-controls="accordion-one" class="accordion-link">
              <span *ngIf="serviceType=='service-options'">Service Options</span>
              <span *ngIf="serviceType=='tyre-options'">Tyres</span>
              <i class="icon icon-arrow-down"></i></a>
            <div id="accordion-one" aria-labelledby="accordion-one" data-bs-parent="#myplans_accordion"
              class="accordion-description collapse show">
              <div class="accordion-body">

                <div class="car-service-options-items">

                  <div class="col-12 current-service" *ngIf="serviceType=='tyre-options'">
                    <div class="row">
                      <div class="col-md-6 plan-img">
                        <img src="../../../assets/{{brand}}/images/tyre-options.jpg" class="img-fluid" alt="">
                      </div>
                      <div class="col-md-6 text-description">
                        <h3>Your Current tyres</h3>
                        <p>{{tyreInformation?.manufacturer}} {{tyreInformation?.manufdesc}}</p>
                        <h4>{{retailPrice | currency:"R "}}*
                          <small>*Price is for 4 tyres, includes VAT and excludes
                            fitment.</small>
                        </h4>
                        <a href="javascript:void(0);" class="remove" (click)="removeServicePlan(tyreInformation?.manufacturer)"
                          *ngIf="selected_services==tyreInformation?.manufacturer && finalPrice == retailPrice"><span>-</span> Remove</a>
                        <a href="javascript:void(0);"  class="add"
                          (click)="selectServicePlan(tyreInformation?.manufacturer, retailPrice)"
                          *ngIf="selected_services!=tyreInformation?.manufacturer || finalPrice != retailPrice"><span>+</span> Add</a>
                      </div>
                    </div>
                  </div>
                  <!--                                    <div class="sub-heading" *ngIf="serviceType=='tyre-options'">-->
                  <!--                                        <p>OTHER TYRE OPTIONS</p>-->
                  <!--                                    </div>-->
                  <div class="row">
                    <div class="col-md-6 col-lg-4 service-options-container" *ngFor="let option of result_options">
                      <div *ngIf="serviceType=='tyre-options'" class="car-service-item"
                        [ngClass]="{'active': selected_services== option?.tyreInfo?.matnr}">
                        <h6>
                          {{option?.tyreInfo?.manufacturer}} {{option?.tyreInfo?.manufdesc}}
                          <span *ngIf="option?.tyreInfo?.fitted=='A'">Rear & Front</span>
                          <span *ngIf="option?.tyreInfo?.fitted=='R'">Rear</span>
                          <span *ngIf="option?.tyreInfo?.fitted=='F'">Front</span>
                        </h6>
                        <h4>
                          {{option?.tyreFullPrice | currency:"R "}}*
                          <small>*Price is for <span  *ngIf="!option?.productType">4 tyres</span><span  *ngIf="option?.productType">each tyre</span>, includes VAT and excludes
                            fitment.</small>
                        </h4>

                        <a href="javascript:void(0);" class="remove" (click)="removeServicePlan(option?.tyreInfo?.manufacturer)"
                          *ngIf="selected_services==option?.tyreInfo?.manufacturer && finalPrice==option?.tyreFullPrice"><span>-</span> Remove</a>
                        <a href="javascript:void(0);" class="add"
                          (click)="selectServicePlan(option?.tyreInfo?.manufacturer, option?.tyreFullPrice)"
                          *ngIf="selected_services!=option?.tyreInfo?.manufacturer || finalPrice!=option?.tyreFullPrice"><span>+</span> Add</a>
                      </div>


                      <div *ngIf="serviceType=='service-options'" class="car-service-options-item">
                        <div class="container">
                          <div class="row">
                            <div class="col-3 car-service-option-icon">
                              <img src="{{option.defects.image.replace('BMW', brand === 'MINI' ? 'MINI' : 'BMW')}}" />
                            </div>
                            <div class="col-9 car-service-option-info">
                              <div class="car-service-option-info-header">
                                {{option.defects.heading.replace('BMW', brand === 'MINI' ? 'MINI' : 'BMW').toLowerCase()}}
                              </div>
                              <div class="car-service-option-info-body">
                                <p class="description">{{option.defects.headliner.replace('BMW', brand === 'MINI' ? 'MINI' : 'BMW')}}</p>
                                <p>*Price includes VAT and fitment.</p>
                              </div>
                              <div class="car-service-option-info-footer">
                                <div class="container">
                                  <div class="row">
                                    <div class="col-6 car-service-option-price">
                                      {{option.inclPrice | currency:"R "}}*
                                    </div>
                                    <div class="col-6 car-service-option-action">
                                      <a href="javascript:void(0);" class="remove" (click)="onCheckboxChange(option.defects.id)"
                                        *ngIf="selected_services.includes(option.defects.id)"><span>-</span>
                                        Remove</a>
                                      <a href="javascript:void(0);" class="add" (click)="onCheckboxChange(option.defects.id)"
                                        *ngIf="!selected_services.includes(option.defects.id)"><span>+</span>
                                        Add</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-4" *ngIf="showError">
                      <div class="alert alert-secondary notification" role="alert">
                        <p class="bmw-alert-text text-danger"><i class="iconImg icon-info-circle danger"></i> Please
                          select at least one product above. <button type="button" class="btn-close  text-danger"
                            data-bs-dismiss="alert" aria-label="Close"></button></p>
                      </div>
                    </div>
                  </div>

                </div>


                <div class="car-service-options-total">
                  <div *ngIf="servRest != null" class="estimate-total-container">
                    <div class="estimated-total">
                      Service Reset: <span class="price">{{servRest | currency:"R "}}</span>
                    </div>
                  </div>
                  <div *ngIf="serviceType=='service-options'" class="estimate-total-container bt">
                    <div class="estimated-total">
                      Estimated total: <span class="price">{{finalEstimatedTotal | currency:"R "}}</span>
                    </div>
                  </div>
                  <div class="buttons-links">
                    <button class="buttons button-primary" (click)="submitsService()">
                      Save and continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item" [ngClass]="!showAccordionTwo?'d-none':''">
            <a href="#accordion-two" data-bs-toggle="collapse" role="button" aria-expanded="true"
              aria-controls="accordion-two" class="accordion-link collapsed"
              [ngClass]="accordion_two===false?'not-ready':''">Personal Details
              <i class="icon icon-arrow-down"></i></a>
            <div id="accordion-two" aria-labelledby="accordion-two" data-bs-parent="#myplans_accordion"
              class="accordion-description collapse">
              <div class="accordion-body">
                <div class="car-service-options-items">
                  <div *ngIf="iframeIsLoading" class="iframe-loader"><img
                      src="../../../assets/BMW/images/iframe-loading.svg" style="width: 50%;"><br>Loading....</div>
                  <iframe width="100%"  frameBorder="0" [src]='urlSafe' frameborder="0" id="contactADealerFrame"
                    (load)="myLoadEvent()"></iframe>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="terms-conditions" *ngIf="serviceOptDescriptions?.terms">
          <h6>Terms and conditions</h6>
          <ul>
            <li *ngFor="let term of serviceOptDescriptions.terms">{{term}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>


  <div class="response-message"  *ngIf="messageStus==='sent-successful'">
    <h1>Your request has been sent successfully</h1>
    <button type="button" class="buttons button-ghost" (click)="backToDash()">Back to Dashboard</button>
  </div>
</div>
