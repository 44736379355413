
<div class="container main-container">
    <h1>Your {{brandToChild}} Service Hub.</h1>
    <div class="text-copy">
        <p>The {{brandToChild}} Service Hub keeps you updated on your servicing needs, provides useful tips and offers a wide range of service products to help you keep your {{brandToChild}} in showroom condition.</p>
    </div>
</div>
<div class="col-12 car-view">
<!--    <app-vehicle-info  (vinChanged)="getVIN($event)"></app-vehicle-info>-->
  <app-vehicle-info (vinChanged)="getVIN($event)" [vehicleCampaignsChild]="vehicleCampaignsParent"  [vehicleInfoToChild]="vehicleInfoFromParent" [brandFromParent]="brandToChild"></app-vehicle-info>
</div>
<div class="container main-container">
    <div class="col-12 dashboard-components">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="row">
                    <app-dash-motorplan-contract [motorplanContractDatesToChild]="motorplanContractDataFromParent"  [brandFromParent]="brandToChild" style="z-index: 9;"></app-dash-motorplan-contract>
                </div>
            </div>
            <div class="col-lg-4  col-sm-6">
                <div class="row">
                    <app-dash-service-partner [servicePartnerDetailsToChild]="servicePartnerDetailsFromParent"  [brandFromParent]="brandToChild" style="z-index: 9;"></app-dash-service-partner>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 d-none d-md-block">
                <div class="row">
                    <app-dash-technical-specs [techDataToChild]="techDataFromParent" [brandFromParent]="brandToChild" style="z-index: 9;"></app-dash-technical-specs>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 d-md-none">
        <a href="javascript:void(0);" (click)="goToPlanOffer()" class="group-link"><img src="./assets/BMW/images/icons/svg/plans-offers.svg" alt="" class="imgIcon"><span>{{brandToChild}} Plans and Offers</span><i class="icon icon-arrow-right"></i></a>
    </div>

    <div class="col-12 d-none d-md-block">
        <h1>ADDITIONAL PLANS FOR YOUR {{brandToChild}}.</h1>
        <div class="text-copy">
            <p>Getting behind the wheel of your {{brandToChild}} is exhilarating, every single time. But that exhilaration doesn’t have to end when the Motorplan expires. Purchase one of our service products online to help you keep your {{brandToChild}}, that's out of Motorplan, running smoothly. </p>
        </div>
    </div>
    <div class="col-12 dashboard-components  d-none d-md-block">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div>
                    <!-- <app-dash-my-plans [incomingVIN]="selectedVehicleFromChild"></app-dash-my-plans> -->
                    <app-dash-my-plans [myPlanContractDataToChild]="myPlanContractDataFromParent" [brandFromParent]="brandToChild"></app-dash-my-plans>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div>
                            <!-- <app-dash-on-call [incomingVIN]="selectedVehicleFromChild"></app-dash-on-call> -->
                            <app-dash-on-call [onCallContractDataToChild]="onCallContractDataFromParent" [brandFromParent]="brandToChild"></app-dash-on-call>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div>
                            <!-- <app-dash-tyre-plan [incomingVIN]="selectedVehicleFromChild"></app-dash-tyre-plan> -->
                            <app-dash-tyre-plan [brandFromParent]="brandToChild"></app-dash-tyre-plan>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 d-none d-md-block">
        <h1>ONCE-OFF SERVICE OFFERS.</h1>
        <div class="text-copy">
            <p>With a once-off service from {{brandToChild}}, you benefit from {{brandToChild}} Original Parts and servicing expertise from our network of retailers. It's the {{brandToChild}} excellence you'd expect at an affordable price.</p>
        </div>
    </div>
    <div class="col-12 dashboard-components d-none d-md-block">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div>
                    <app-dash-service-options [serviceOptionalToChild]="serviceOptionalFromParent" [brandFromParent]="brandToChild"></app-dash-service-options>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div>
                    <!-- <app-dash-tyre-options  [incomingVIN]="selectedVehicleFromChild"></app-dash-tyre-options> -->
                    <app-dash-tyre-options [tyreOptionsToChild]="tyreOptionsFromParent" [brandFromParent]="brandToChild"></app-dash-tyre-options>
                </div>
            </div>
        </div>
    </div>


    <div class="col-12">
        <h1>DISCOVER MORE DRIVING PLEASURE.</h1>
        <div class="text-copy"></div>
    </div>

    <div class="col-12 dashboard-components">
        <app-dash-teasers [brandFromParent]="brandToChild"></app-dash-teasers>
    </div>
</div>

<button type="button" class="btn d-none" id="preferred_Retailer" data-bs-toggle="modal" data-bs-target="#preferredRetailer">Preferred</button>
<app-preferred-retailer [dealersDataToChild]="dealersFromParent"></app-preferred-retailer>
<app-motorplan-modal></app-motorplan-modal>
