import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VehicleMotorplan } from '../_models/vehicle-motorplan.model'
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MotorplanContractService {

  constructor(private http: HttpClient) { }
  httpClient!: HttpClient;
  vehicleMotorplan!: VehicleMotorplan;
  get_gcdm_config: any;
  // getMotorplanContract(params: any){
  //   return this.http.get<any>("assets/BMW/json/motorplan.json")
  //   .pipe(map(data => data))
  // }

  // getMotorplanContract(params: any) {
  //   let headers = new HttpHeaders({
  //     'X-API-KEY': '0515aeaa-ba88-47cf-b011-170277794afe',
  //     'Accept': 'application/json',
  //     Authorization: "" + localStorage.getItem("jwt")
  //   });

  //   return this.http.post<any>("https://motorplanza-int.bmwgroup.com/mp3/AfterSalesWebAPI/motorplan/ZA/EN/v3/ContractOptionAndQuote", params, { headers: headers })
  //     .pipe(map(data => data as any));
  // }


}
