import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { data } from 'jquery';
import { TechnicalSpecsService } from 'src/app/_services/technical-specs.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-dash-technical-specs',
  templateUrl: './dash-technical-specs.component.html',
  styleUrls: ['./dash-technical-specs.component.less']
})
export class DashTechnicalSpecsComponent implements OnInit {
  @Input() incomingVIN: any = {};
  @Input() brandFromParent: any;

  @Input()
  techDataToChild: any;
  selected_Vehicle: any = '';
  technicalData: any = '';
  techData: any = '';
  vin: string = '';

  constructor(private technicalSpecsSvc: TechnicalSpecsService) {

  }

  ngOnChanges(changes: SimpleChanges) {
   if(this.incomingVIN !== undefined){
   }
  }
  ngOnInit(): void {
  }

  getTechPdf(modelCode:string){
    const url = environment.TECH_SPEC_URL+'/dws/VTS/servlet/VtsPdfUI?modelCode='+modelCode;
    window.open(url, '_blank');
  };
}
