<div class="container go-back-container">
    <app-go-back></app-go-back>
</div>
<div class="container main-container">
    <div class="row">
        <div class="col-lg-12 col-sm-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
