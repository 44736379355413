import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router} from '@angular/router';

@Component({
  selector: 'app-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.less']
})
export class GoBackComponent implements OnInit {

  constructor(private location: Location, private router: Router) { }

  backToDashboardRoutes: any = ['/service-options-enquiry', '/profile/personal-data', '/profile/service-partner', '/profile/preferences']
  ngOnInit(): void {
    if (this.backToDashboardRoutes.includes(window.location.pathname)) {

    }
  }

  goBack() {
    if (this.backToDashboardRoutes.includes(window.location.pathname)) {
      let pageres = sessionStorage.getItem('pageres');
      if(pageres==='prospect'){
        this.location.back();
      }
      else{
      this.router.navigate(['/service-dashboard'])
      }
    }
    else {
      this.location.back();
    }
  }
}
