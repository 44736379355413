import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router";
import { MyPlansService } from '../../../_services/my-plans.service';
import { DataServiceService } from '../../../_services/data-service.service';
import {MyPlansComponent} from "../../my-plans/my-plans.component";

@Component({
  selector: 'app-dash-on-call',
  templateUrl: './dash-on-call.component.html',
  styleUrls: ['./dash-on-call.component.less']
})
export class DashOnCallComponent implements OnInit {

  constructor(private router: Router, private myPlansSvc: MyPlansService, private dataSvc: DataServiceService, private myPlan: MyPlansComponent,private cdr: ChangeDetectorRef) { }

  @Input() incomingVIN:any = {};
  @Input()
  onCallContractDataToChild: any;

  @Input()
  brandFromParent: any;

  start_price: string = '';
  selected_Vehicle: any = {};
  oncallLabel: string  = '';
  oncallActiveLabel: string = '';

  ngOnChanges(changes: SimpleChanges) {
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    if(this.brandFromParent.toLowerCase() == 'mini') {
      this.oncallLabel = "MINI Assist";
      this.oncallActiveLabel = "MINI Assist ACTIVE UNTIL";
    } else {
      this.oncallLabel = this.brandFromParent + " On Call"
      this.oncallActiveLabel = this.brandFromParent + " ON CALL ACTIVE UNTIL";
    }

  }

  gotMyPlans(myPlansData: any) {
    if(myPlansData){
    this.dataSvc.setMyPlans(myPlansData, "O");
    this.router.navigate(['/my-plans']);
    }
  }

}
