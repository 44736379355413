<footer>
    <div class="topNav">
        <div class="container">
            <div class="row accordion accordion-flush"  id="accordionGeneral">
                <div class="accordion-item navWrapper">
                    <h5 class="accordion-header collapsed" data-bs-toggle="collapse"
                        data-bs-target="#accordion_Models" aria-expanded="true"
                        aria-controls="accordion_Models">MODELS</h5>

                    <div id="accordion_Models" class="accordion-collapse collapse"  data-bs-parent="#accordionGeneral">
                        <div class="accordion-body accordionBody">
                            <ul>
                                <li><a href="https://www.mini.co.za/en_ZA/home/range/all-electric-mini-cooper.html"
                                        class="navItem" target="_blank">All-Electric MINI Cooper</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/range/all-electric-mini-countryman.html"
                                        class="navItem" target="_blank">All-Electric MINI Countryman</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/range/new-mini-countryman.html"
                                        class="navItem" target="_blank">New MINI Countryman</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/range/mini-electric.html" class="navItem"
                                        target="_blank">3-DOOR SE</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/range/mini-3-door-hatch.html"
                                        class="navItem" target="_blank">3-DOOR Hatch</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/range/mini-5-door-hatch.html"
                                        class="navItem" target="_blank">5-DOOR Hatch</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/range/mini-convertible.html"
                                        class="navItem" target="_blank">Convertible</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/range/mini-clubman.html" class="navItem"
                                        target="_blank">Clubman</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/range/mini-countryman.html"
                                        class="navItem" target="_blank">Countryman</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/range/mini-john-cooper-works.html"
                                        class="navItem" target="_blank">John Cooper Works</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item navWrapper">
                    <h5 class="accordion-header collapsed" data-bs-toggle="collapse"
                        data-bs-target="#accordion_Finance" aria-expanded="true"
                        aria-controls="accordion_Finance">FINANCE & INSURANCE</h5>

                    <div id="accordion_Finance" class="accordion-collapse collapse"  data-bs-parent="#accordionGeneral">
                        <div class="accordion-body accordionBody">
                            <ul>
                                <li><a href="https://www.mini.co.za/en_ZA/home/offer-section/New_Offers.html"
                                        class="navItem" target="_blank">Finance Offers</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/finance-and-insurance/find-my-finance.html"
                                        class="navItem" target="_blank">Find My Finance</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/finance-and-insurance/calculator.html"
                                        class="navItem" target="_blank">MINI Finance Calculator</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/financial-solutions.html" class="navItem"
                                        target="_blank">Financial Solutions</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/financial-solutions/insurance.html"
                                        class="navItem" target="_blank">MINI Insurance</a></li>
                                <li><a href="https://app.bmwfinance.co.za/sf/SFMyAccountMini/dist/index.html?sessionId=202009171628178131185#!/login"
                                        class="navItem" target="_blank">Log Into My Account</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/accessories/corporate-sales.html"
                                        class="navItem" target="_blank">MINI Corporate, Direct and Special Sales</a>
                                </li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/local-forms/diplomats.html"
                                        class="navItem" target="_blank">Diplomat Sales</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/local-forms/goverment.html"
                                        class="navItem" target="_blank">Government Sales</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item navWrapper">
                    <h5 class="accordion-header collapsed" data-bs-toggle="collapse"
                        data-bs-target="#accordion_News" aria-expanded="false"
                        aria-controls="accordion_News">NEWS & BRAND</h5>

                    <div id="accordion_News" class="accordion-collapse collapse"  data-bs-parent="#accordionGeneral">
                        <div class="accordion-body accordionBody">
                            <ul>
                                <li><a href="https://www.mini.co.za/en_ZA/home/explore/concept-cars/aceman-concept.html"
                                        class="navItem" target="_blank">The MINI Concept Aceman</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/explore/concept-cars/mini-superleggera-vision.html"
                                        class="navItem" target="_blank">MINI Superleggera Vision</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/explore/concept-cars/mini-vision-next100.html"
                                        class="navItem" target="_blank">MINI Vision Next 100</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/mini-driving-experience.html"
                                        class="navItem" target="_blank">The Home Of MINI Driving Experience</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/creative-club.html" class="navItem"
                                        target="_blank">Welcome To Creative Club</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/services/mini-sharing.html"
                                        class="navItem" target="_blank">What Is MINI Sharing?</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/services/mini-pod.html" class="navItem"
                                        target="_blank">Hybrid MINI Workspace</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/services/mini-pod.html" class="navItem"
                                        target="_blank">MINI POD</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item navWrapper">
                    <h5 class="accordion-header collapsed" data-bs-toggle="collapse"
                        data-bs-target="#accordion_Electromobility" aria-expanded="false"
                        aria-controls="accordion_Electromobility">ELECTROMOBILITY</h5>

                    <div id="accordion_Electromobility" class="accordion-collapse collapse"  data-bs-parent="#accordionGeneral">
                        <div class="accordion-body accordionBody">
                            <ul>
                                <li><a href="https://www.mini.co.za/en_ZA/home/electromobility.html" class="navItem"
                                        target="_blank">Overview</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/electromobility/electric-collective.html"
                                        class="navItem" target="_blank">Join The Collective</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/electromobility/The-Plug.html"
                                        class="navItem" target="_blank">The Plug</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item navWrapper">
                    <h5 class="accordion-header collapsed" data-bs-toggle="collapse"
                        data-bs-target="#accordion_HelpCenter" aria-expanded="true"
                        aria-controls="accordion_HelpCenter">HELP CENTER</h5>

                    <div id="accordion_HelpCenter" class="accordion-collapse collapse"  data-bs-parent="#accordionGeneral">
                        <div class="accordion-body accordionBody">
                            <ul>
                                <li><a href="https://www.mini.co.za/en_ZA/home/faqs.html" class="navItem"
                                        target="_blank">FAQ</a></li>
                                <li><a href="https://buy.mini.co.za/test-drives" class="navItem" target="_blank">Book A
                                        Test Drive</a></li>
                                <li><a href="https://www.mini.co.za/en_ZA/home/footer/contact-us.html" class="navItem"
                                        target="_blank">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="navWrapper socialWrapper">
                    <h5>FIND US ON</h5>
                    <ul class="socialLinks">
                        <li><a href="https://www.facebook.com/MINI.southafrica/" class="socialItem" target="_blank"><i
                                    class="icon md-icn-social-facebook"></i></a></li>
                        <li><a href="https://twitter.com/MiniSouthAfrica" class="socialItem" target="_blank"><i
                                    class="icon md-icn-social-twitter"></i></a></li>
                        <li><a href="https://www.youtube.com/user/MiniSouthAfrica" class="socialItem" target="_blank"><i
                                    class="icon md-icn-social-youtube"></i></a></li>
                        <li><a href="https://www.instagram.com/mini_southafrica/" class="socialItem" target="_blank"><i
                                    class="icon md-icn-social-instagram"></i></a></li>
                        <li><a href="https://www.linkedin.com/showcase/mini-south-africa" class="socialItem"
                                target="_blank"><i class="icon md-icn-social-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom">
        <div class="container btm_lnks bottomNav">
            <div class="accordion disclaimerSec">
                <div class="accordion-item accordionItem">
                    <a href="javascript:void(0);" class="accordion-header accordionHeader collapsed"
                        data-bs-toggle="collapse" data-bs-target="#accordion_DisclaimerOne" aria-expanded="false"
                        aria-controls="accordion_DisclaimerOne">
                        Disclaimers
                        <i class="icon icon-arrow-down"></i>
                    </a>
                    <div id="accordion_DisclaimerOne" class="accordion-collapse collapse">
                        <div class="accordion-body accordionBody">
                            <h5>Terms and conditions for the website and important product information.</h5>
                            <p>All performance related specifications released by MINI are normative values. The values
                                are measured and derived for each engine and gearbox vehicle variant through globally
                                accepted procedures. Vehicles which are used for determining technical specifications
                                are standard vehicles and do not have optional extras, like special wheel/tyre
                                combinations or those options adding extra weight.</p>
                            <p>Fuel consumption figures are measured in a laboratory, following a procedure prescribed
                                by UN ECE Regulation 83, with a standard vehicle and under laboratory conditions. The
                                conditions simulate sea level atmospheric pressures, controlled temperatures, a smooth
                                road surface, windless conditions and following acceleration, speed and deceleration
                                patterns on a rotating roller bed which simulate a combination of urban and extra-urban
                                travel. This standardised procedure allows comparison between various models and vehicle
                                makes.</p>
                            <ul>
                                <li>Fuel consumption figures are indicative values and they do not necessarily reflect
                                    fuel consumption which will be obtained under road driving conditions because of
                                    various factors which have an influence on fuel consumption. These factors are inter
                                    alia the following:</li>
                                <li>Fuel used: MINI petrol engines are designed for RON 98 unleaded petrol. Although the
                                    engines can tolerate fuels down to 91 RON, there is a difference in performance for
                                    different fuels. In South Africa, RON 95 metal free petrol is recommended for MINI
                                    vehicles.</li>
                                <li>Blending of ethanol, up to a maximum of 10% is tolerated, although ethanol blending
                                    influences the energy density of fuel, there will be loss of performance for blended
                                    petrol.</li>
                                <li>(Bio-diesel up to 5% blend can be used, though with a slight effect on performance.)
                                </li>
                                <li>Traffic and road conditions</li>
                                <li>Driving style</li>
                                <li>Optional extras ordered with the vehicle</li>
                                <li>Altitude has an influence as well but not as big for turbo-charged vehicles.</li>
                            </ul>

                            <p>Performance figures (speed and acceleration) are derived using standard vehicles for each
                                engine and gearbox combination. The test conditions are also standardised, performed at
                                low altitude, low wind conditions and on level, smooth, straight roads. Small deviations
                                from the approved indicated values can be experienced.</p>
                            <p>Weights for the various vehicle models are also measured on standard vehicles. The
                                vehicle design however takes heaviest options into account, e.g. maximum payload, axle
                                loading, trailer masses roof and tow-bar loading. The loading weights may never be
                                exceeded to ensure safe driving.</p>
                            <p>All figures relate to vehicles with standard six-speed manual transmission. Figures in [
                                ] are for vehicles with six-speed automatic transmission Steptronic.</p>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="legalNav">
                <li><a target="_blank" href="https://www.mini.co.za/en_ZA/home/footer/contact-us.html">Contact Us</a></li>
                <li><a [routerLink]="['cookiepolicy']">Cookies</a></li>
                <li><a target="_blank" href="https://www.mini.co.za/en_ZA/home/privacystatement.html">Privacy
                        Statement</a></li>
                <li><a target="_blank" href="https://www.mini.co.za/en_ZA/home/footer/paia-and-popia-manual.html">PAIA &
                        POPIA</a></li>
                <li><a target="_blank" href="https://www.mini.co.za/en_ZA/home/footer/Legal.html">Legal</a></li>
                <li><a target="_blank"
                        href="https://www.mini.co.za/en_ZA/home/terms-and-conditions/mini-finance-terms-and-conditions.html">Terms
                        & Conditions</a></li>
                <li><a target="_blank" href="https://www.mini.co.za/en_ZA/home/footer/bmw-group-websites.html">BMW Group
                        Websites</a></li>
            </ul>

            <div class="bottomLogo">
                <a routerLink="/"><i class="icon md-icn-mini-logo"></i></a>
                <span class="copyRight">&copy; MINI {{currentYear()}}
                </span>
            </div>
        </div>
    </div>
</footer>