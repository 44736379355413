<div class="modal fade" id="myPlansOptions" aria-hidden="true" aria-labelledby="myPlansOptions"
    tabindex="-1" [ngClass]="brandFromParent=='MINI'?'md-container':''">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">MY {{brandFromParent}} PLAN OPTIONS</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!-- <div class="notification">
                    <h6><i class="iconImg icon-info-circle-o"></i> MOTORPLAN CURRENTLY ACTIVE</h6>
                    <p>This option is only available once your Motorplan contract has expired.</p>
                </div> -->
                <div class="row">
                    <div class="col-md-4" *ngFor="let item of myPlanDataToChild?.myPlansData">
                        <div class="my-plan-item">
                            <img src="../../../assets/BMW/images/icons/svg/{{item?.contractType}}.svg" alt="">
                            <div class="plan-content">
                                <h6><span>My {{brandFromParent}}</span> {{item?.description}}
                                    <small> {{item?.lowestPrice.vatTotalPrice | currency :"R "}}</small>
                                </h6>
                                <ul *ngIf="item?.contractType==='TC200'">
                                    <li>For {{item?.lowestPrice.contractMonths | monthsToYear }} year<span *ngIf="item?.lowestPrice.contractMonths>12">s</span></li>
                                    <li>CBS indicated or elective</li>
                                    <li>Engine oil service only</li>
                                </ul>
                                <ul *ngIf="item?.contractType==='TC210'">
                                    <li>For {{item?.lowestPrice.contractMonths | monthsToYear }} year<span *ngIf="item?.lowestPrice.contractMonths>12">s</span></li>
                                    <li>CBS indicated</li>
                                    <li>Vehicle check</li>
                                    <li>Engine oil service + Spark plugs + Air filters, Pollen filters, Fuel filters, Brake fluid flush</li>
                                </ul>
                                <ul *ngIf="item?.contractType==='TC220'">
                                    <li>For {{item?.lowestPrice.contractMonths | monthsToYear }} year<span *ngIf="item?.lowestPrice.contractMonths>12">s</span></li>
                                    <li>CBS indicated</li>
                                    <li>Vehicle check</li>
                                    <li>Engine oil service + Spark plugs + Air filters, Pollen filters, Fuel filters + Brake pads (front & rear), Brake fluid flush</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-4">
                        <div class="my-plan-item">
                            <img src="../../../assets/BMW/images/icons/svg/inspection.svg" alt="">
                            <div class="plan-content">
                                <h6><span>My BMW</span> Service Plan<small>R 5 999.00</small></h6>
                                <ul>
                                    <li>3 years / 3 Services </li>
                                    <li>CBS indicated</li>
                                    <li>Vehicle check</li>
                                    <li>Engine oil service + Spark plugs + Air filters, Pollen filters, Fuel filters, Brake fluid flush</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="my-plan-item">
                            <img src="../../../assets/BMW/images/icons/svg/mechanical-repair.svg" alt="">
                            <div class="plan-content">
                                <h6><span>My BMW</span> Maintenance Plan<small>R 19 999.00</small></h6>
                                <ul>
                                    <li>3 years / 3 Services </li>
                                    <li>CBS indicated</li>
                                    <li>Vehicle check</li>
                                    <li>Engine oil service + Spark plugs + Air filters, Pollen filters, Fuel filters + Brake pads (front & rear), Brake fluid flush</li>
                                </ul>
                            </div>
                        </div>
                    </div> -->
                </div>

            </div>
            <div class="modal-footer">
                <button class="buttons button-ghost" data-bs-target="#myPlansOptions" data-bs-toggle="modal"
                    data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="buttons button-primary" (click)="gotMyPlans(myPlanDataToChild)" data-bs-toggle="modal"
                data-bs-dismiss="modal">Buy online</button>
            </div>
        </div>
    </div>
</div>
