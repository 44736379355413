<div class="container  main-container">
    <div class="alert alert-success notification" role="alert" *ngIf="incomingMsg==='success'">
        <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> Thank you, your payment was successful.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>
    </div>
    <div class="alert alert-secondary notification" role="alert" *ngIf="incomingMsg==='failed'">
        <p class="bmw-alert-text text-danger"><i class="iconImg icon-info-circle danger"></i> Sorry, your payment was
            unsuccessful. <button type="button" class="btn-close  text-danger" data-bs-dismiss="alert"
                aria-label="Close"></button></p>
    </div>

    <div class="mb-5">
        <p><strong>Next Steps to follow</strong></p>
        <p *ngIf="incomingMsg==='success'">You will receive your contract documents via email once the transaction has
            been
            processed.</p>
        <p *ngIf="incomingMsg==='failed'">You will be able to retry your payment by clicking the Retry button below</p>

    </div>
    <div class="buttons-section">
        <a [routerLink]="['/checkout-options']" class="buttons button-primary" *ngIf="incomingMsg==='failed'">Retry</a>
        <a [routerLink]="['/']" class="buttons button-ghost">Back to Dashboard</a>
    </div>
</div>