//::::::::::::::: - UNI fEb 2023
//::::::::::::::: - THIS IS MOVED TO JAVA LAYER DON'T CALL MOTORPLANAPI DIRECTLY FROM FRONTEND

import {HttpBackend, HttpClient, HttpHeaders, HttpXsrfTokenExtractor} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../_services/authentication.service';
@Injectable({
  providedIn: 'root'
})
export class MyPlansService {
  motorplanToken: any = '';
  httpClient: HttpClient;
  private shttpClient: HttpClient;
  busyGeneratingQuote: boolean = false;
  constructor(private http: HttpClient, private authService: AuthenticationService,
              private sHttp: HttpBackend, private tokenExtractor: HttpXsrfTokenExtractor) {
    // this.generateMotorplanToken();
    this.shttpClient = new HttpClient(sHttp);
    this.httpClient = http;
  }

  getCampaigns(vin: string): Observable<any> {
    let csrfToken = this.tokenExtractor.getToken() as string;
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
    var configs = { headers: headers };
    let params = { "vin": vin };
    return this.shttpClient.post<any>(environment.apiURL + "/customerportalapi/rest/cp/vehicles/motor-plan/get-campaigns", params, configs)
      .pipe(map(data => data as any));
  }

  getAvailContracts(dealer: string, exclude: boolean, odo: string, vin: string): Observable<any> {
    let csrfToken = this.tokenExtractor.getToken() as string;
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
    var configs = { headers: headers };
    let params = { "dealerCode": dealer, "excludeNonExtendableActiveContracts": exclude, "odometer": odo, "vin": vin };
    return this.http.post<any>(environment.apiURL + "/customerportalapi/rest/cp/vehicles/motor-plan/get-contracts", params, configs)
      .pipe(map(data => data as any));
  }

  doAcceptQuote(addData: any): Observable<any> {
    let csrfToken = this.tokenExtractor.getToken() as string;
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
    var configs = { headers: headers };
    let params = { addData };
    return this.http.post<any>(environment.apiURL + "/customerportalapi/rest/cp/vehicles/motor-plan/accept-quote", addData, configs)
      .pipe(map(data => data as any));
  }

  doGenerateQuote( type: string, docnumber: string, odovalue: string, salesDocumentType:string) {
      this.busyGeneratingQuote = true;
      let formdata = "billingDocument=" +  docnumber  + "&odometer=" +  odovalue   +"&proformaOrQuote=" + type + "&salesDocumentType=" + salesDocumentType;
      this.busyGeneratingQuote = this.makeAndOpenPDFHere(formdata);
  };

  makeAndOpenPDFHere(str: string): boolean {
    let csrfToken = this.tokenExtractor.getToken() as string;
    var request = new XMLHttpRequest();

    // Instantiating the request object
    request.open("POST", environment.apiURL + "/customerportalapi/rest/cp/vehicles/motor-plan/quotePdf");
    request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    request.setRequestHeader("X-XSRF-TOKEN", csrfToken);
    // Defining event listener for readystatechange event
    request.onreadystatechange = function() {
      // Check if the request is compete and was successful
      if(this.readyState === 4 && this.status === 200) {
        var blobSrc = window.URL.createObjectURL(new Blob([this.response], { type: 'application/pdf' }));
        window.open(blobSrc, '_blank');
        return true;
      } else {
        return false;
      }
    };

    request.send(str);
    return false;
  }


  getOptionalContracts(vin: string): Observable<any> {

    let csrfToken = this.tokenExtractor.getToken() as string;
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
    var configs = { headers: headers };

    let add = { "vin": vin };

    return this.http.post<any>(environment.apiURL + "/customerportalapi/rest/cp/vehicles/motor-plan/get-optional-contracts", add, configs)
      .pipe(map(data => data as any));
  }

  postToOptionalContractApp(motorplaninfo:any, vehicleInfo:any, contractTypeSelected:any, vin:string, dealerid:string, userInfo:any): Observable<any>{
    let csrfToken = this.tokenExtractor.getToken() as string;
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN" : csrfToken });
    var configs = { headers: headers };

    let  cosyurl = "";

    if(vehicleInfo.cosyURL === ""){
      cosyurl = "";
    } else {
      cosyurl = vehicleInfo.cosyURL;
    }

    let fwdApiKey;
    this.getFwdapikey().pipe().subscribe((data) => {
      console.log("fwdApiKey "+data.fwdApiKey);
      fwdApiKey = data.fwdApiKey;
    });

    let add = {"additionalData":{ "vin":vin,
        "fwdapikey":fwdApiKey,
        "modelDesc":vehicleInfo.modelDesc,
        "modelYear":vehicleInfo.modelYear,
        "dealercode": dealerid,
        "redirectUrl": environment.apiURL ,
        "cosyImage":cosyurl,
        "customerEmail": userInfo.email,
        "customerFirstName": userInfo.firstName,
        "currentConStartDate":motorplaninfo.validFromDate,
        "currentConEndDate":motorplaninfo.validToDate,
        "currentConExpiryKms":Number(motorplaninfo.validKmTo),
        "contracttype": contractTypeSelected
      }
    };

    return this.http.post<any>(environment.apiURL+"/customerportalapi/rest/cp/vehicles/motor-plan/post-to-optional-contract-app", JSON.stringify(add), configs)
      .pipe(map(data =>  data as any));
  }

  getFwdapikey(): Observable<any>{
    // let csrfToken = this.tokenExtractor.getToken() as string;
    let headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    });
    var configs = { headers: headers };

    return this.http.get<any>(environment.apiURL +"/customerportalapi/rest/cp/crm/customer/getFwdapikey")
      .pipe(map(data =>  data as any));
  }

  createPaymentRef(addData: any): Observable<any> {
    let csrfToken = this.tokenExtractor.getToken() as string;
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
    var configs = { headers: headers };
    return this.http.post<any>(environment.apiURL + "/customerportalapi/rest/cp/vehicles/motor-plan/create-payment-ref", addData, configs)
      .pipe(map(data => data as any));
  }

  latestContractDetail(vin: any): Observable<any> {
    let csrfToken = this.tokenExtractor.getToken() as string;
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
    var configs = { headers: headers };
    let params = { "vin": vin };
    return this.http.post<any>(environment.apiURL + "/customerportalapi/rest/cp/vehicles/motor-plan/latestContractDetail", params, configs)
      .pipe(map(data => data as any));
  }

  contractVisiblePrices(dealer: string, exclude: boolean, odo: string, vin: string): Observable<any> {
    let csrfToken = this.tokenExtractor.getToken() as string;
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
    var configs = { headers: headers };

    let params = {

      "contractType": {
        "contractCategory": "",
        "contractNo": "",
        "contractType": "TC900",
        "description": "",
        "idReq": "",
        "onCall": "",
        "orderType": "ZMQ1",
        "origContractType": "T220"
      },
      "dealerCode": dealer,
      "excludeNonExtendableActiveContracts": exclude,
      "odometer": odo,
      "vin": vin
    };
    return this.http.post<any>(environment.apiURL + "/customerportalapi/rest/cp/vehicles/motor-plan/get-contractVisiblePrices", params, configs)
      .pipe(map(data => data as any));
  }
}
