import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpXsrfTokenExtractor} from "@angular/common/http";
import {Observable} from "rxjs";
import { delay, map } from 'rxjs/operators';
import { DataServiceService } from './data-service.service';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnDemandLoginBpService {
  httpClient: HttpClient;
  get_gcdm_config: any;
  constructor(private http: HttpClient, private dataSvc:DataServiceService, private authenticationService: AuthenticationService, private tokenExtractor: HttpXsrfTokenExtractor) {
    this.httpClient = http;
    this.get_gcdm_config = this.authenticationService.get_config();
   }

  postCustomerRecordsToBeMerge(mergeCustomerRecords:any): Observable<any>{

    let csrfToken = this.tokenExtractor.getToken() as string;

    let headers = new HttpHeaders({
        'Accept': 'application/json',
        'Content-type': 'application/json'
        , "X-XSRF-TOKEN" : csrfToken
    });
    var configs = { headers: headers };

     return this.http.post<any>(environment.apiURL +"/customerportalapi/rest/cp/crm/customer/on-demand/customer-record-to-be-merge", mergeCustomerRecords, configs)
    .pipe(map(data =>  data as any));
}

sfProfile(): Observable<any>{

    let csrfToken = this.tokenExtractor.getToken() as string;

    let headers = new HttpHeaders({
        'Accept': 'application/json',
        'Content-type': 'application/json',
         "X-XSRF-TOKEN" : csrfToken
    });
    var configs = { headers: headers };

     return this.http.post<any>(environment.apiURL +"/customerportalapi/rest/cp/finance/user/9611150035082/profile", configs)
    .pipe(map(data =>  data as any));
}


postActivateOnDamnd(guid:any): Observable<any>{
    // let csrfToken = this.tokenExtractor.getToken() as string;
    let headers = new HttpHeaders({
        'Accept': 'application/json',
        'Content-type': 'application/json'
    });
    var configs = { headers: headers };

     return this.http.get<any>(environment.apiURL +"/customerportalapi/rest/cp/crm/customer/on-demand/activation/"+guid)
    .pipe(map(data =>  data as any));
}


postOnDemandStatus(): Observable<any>{
    let csrfToken = this.tokenExtractor.getToken() as string;
    let headers = new HttpHeaders({
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'X-XSRF-TOKEN': csrfToken
    });
    var configs = { headers: headers };
    return this.http.post<any>(environment.apiURL +"/customerportalapi/rest/cp/crm/customer/on-demand/status/save/"+this.dataSvc.getGcdmCustBp(), configs)
   .pipe(map(data =>  data as any));
}

getOnDamndStatus(token:string): Observable<any>{
var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + token });
var configs = { headers: headers };
    return this.http.get<any>(environment.apiURL +"/customerportalapi/rest/cp/crm/customer/on-demand/status", configs)
   .pipe(map(data =>  data as any));
}
}
