<div class="modal fade" id="addNewVehicle" aria-hidden="true" aria-labelledby="addNewVehicle" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">ADD A VEHICLE</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="alert notification" role="alert" *ngIf="request_result !='' "
                    [ngClass]="request_result==='success' ? 'alert-success':'alert-secondary'">
                    <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> {{alertMsg}} <button
                            type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>
                </div>

                <form [formGroup]="addNewVehicleForm" autocomplete="off"
                    *ngIf="request_result===''|| request_result==='error'">
                    <div class="row">
                        <div class="col-md-6 form-field">
                            <label class="form-label">Contact Preferences*</label>
                            <select name="contactPreference" (change)="toggleVisibility($event)"
                                formControlName="contactPreference"
                                [ngClass]="{ 'error': submitted && f['contactPreference'].errors }">
                                <option value="">Please Select</option>
                                <option [selected] value="E-mail">E-mail</option>
                                <option value="Phone">Phone</option>
                            </select>
                            <span *ngIf="submitted && f['contactPreference'].errors" class="help-block form-error">
                                <span *ngIf="f['contactPreference'].errors['required']">This is a required field</span>
                            </span>
                        </div>

                        <div class="col-md-6 form-field" *ngIf="!isHidden && selecttedComms === 'Phone'">
                            <label class="form-label">Phone*</label>
                            <input type="text" placeholder="" name="communicationMethod"
                                formControlName="communicationMethod"
                                [ngClass]="{ 'error': submitted && f['communicationMethod'].errors }">
                            <span *ngIf="submitted && f['communicationMethod'].errors" class="help-block form-error">
                                <span *ngIf="f['communicationMethod'].errors['required']">This is a required
                                    field</span>
                                    <span *ngIf="f['communicationMethod'].errors['pattern']">Phone Number is invalid</span>
                            </span>
                        </div>

                        <div class="col-md-6 form-field" *ngIf="!isHidden && selecttedComms === 'E-mail'">
                            <label class="form-label">Email*</label>
                            <input type="text" placeholder="" name="communicationMethod"
                                formControlName="communicationMethod" email
                                [ngClass]="{ 'error': submitted && f['communicationMethod'].errors }">
                            <span *ngIf="submitted && f['communicationMethod'].errors" class="help-block form-error">
                                <span *ngIf="f['communicationMethod'].errors['required']">This is a required
                                    field</span>
                                <span *ngIf="f['communicationMethod'].errors['email']">E-mail must be a valid email
                                    address</span>
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 form-field">
                            <label class="form-label">Chassis Number/VIN*</label>
                            <input type="text" placeholder="" name="vinNumber" formControlName="vinNumber"
                                [ngClass]="{ 'error': submitted && f['vinNumber'].errors }">
                            <span *ngIf="submitted && f['vinNumber'].errors" class="help-block form-error">
                                <span *ngIf="f['vinNumber'].errors['required']">This is a required field</span>
                            </span>
                        </div>

                        <div class="col-md-6 form-field">
                            <label class="form-label">Registration Number*</label>
                            <input type="text" placeholder="" name="regNumber" formControlName="regNumber"
                                [ngClass]="{ 'error': submitted && f['regNumber'].errors }">
                            <span *ngIf="submitted && f['regNumber'].errors" class="help-block form-error">
                                <span *ngIf="f['regNumber'].errors['required']">This is a required field</span>
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 form-field">
                            <label class="form-label">Model*</label>
                            <input type="text" placeholder="" name="model" formControlName="model"
                                [ngClass]="{ 'error': submitted && f['model'].errors }">
                            <span *ngIf="submitted && f['model'].errors" class="help-block form-error">
                                <span *ngIf="f['model'].errors['required']">This is a required field</span>
                            </span>
                        </div>

                        <div class="col-md-6 form-field">
                            <label class="form-label">Year</label>
                            <input type="text" placeholder="" name="year" formControlName="year"
                                [ngClass]="{ 'error': submitted && f['year'].errors }">
                            <span *ngIf="submitted && f['year'].errors" class="help-block form-error">
                                <span *ngIf="f['year'].errors['required']">This is a required field</span>
                                <span *ngIf="f['year'].errors['pattern']">Please provide valid model year.</span>
                            </span>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-12 mandatory mt-3">
                            *Mandatory Fields
                        </div>
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                <button class="buttons button-ghost order-1 order-sm-0" data-bs-toggle="modal" data-bs-dismiss="modal" id="cancelBtn" [ngClass]="request_result==='success' ? 'd-none':''">Cancel</button>
                <button *ngIf="request_result!=='success'" type="button" class="buttons button-primary" (click)="submitNewVehicle()"
                    [ngClass]="loading===true ? 'loading' : ''">Submit</button>
                    <!-- <button class="buttons button-primary order-1 order-sm-0"  *ngIf="request_result==='success'"  (click)="cancelForm()">Ok</button> -->
            </div>
        </div>
    </div>
</div>