
<div class="container main-container" [ngClass]="brand=='MINI'?'md-container':''">
    <app-go-back></app-go-back>
    <div class="row">
        <div class="col-lg-5">
            <img class="img-fluid" src="../../../assets/{{brand}}/images/{{active_services?.contractType}}.jpg" />

        </div>

        <div class="col-lg-7">
            <div class="plan-description">
                <h1 *ngIf="header!==''">{{header}}</h1>
                <p>{{static_data.description}}</p>
                <form [formGroup]="myPlanForm" (ngSubmit)="SubmitForm()" data-tron-form="myplans-form" data-step-name="Step 1 : My Plans Service Options">
                   <div class="form-item  form-field"  *ngFor="let option of result_data; let i = index">
                        <input *ngIf="option?.contractCategory==='S'" class="form-check-input radio-btn" type="radio" name="myPlan" formControlName="myPlan"
                            id="option_{{option?.contractType}}" value="{{option?.contractType}}" (change)="getSingleService(option?.contractType)" [ngClass]="{ 'error': submitted && f['myPlan'].errors }">

                            <input *ngIf="option?.contractCategory==='O'" class="form-check-input radio-btn" type="radio" name="myPlan" formControlName="myPlan"
                            id="option_{{i +option?.contractType}}" value="{{option?.contractType +'_'+option?.contractMonths}}" (change)="getSingleService(option?.contractType +'_'+option?.contractMonths)" [ngClass]="{ 'error': submitted && f['myPlan'].errors }">

                            <label *ngIf="option?.contractCategory==='S'" class="form-check-label" for="option_{{option?.contractType}}">
                               <span>{{option?.description}}</span>
                            </label>

                            
                            <label *ngIf="option?.contractCategory==='O'" class="form-check-label" for="option_{{i +option?.contractType}}">
                                <span>{{option?.contractMonths}} Months</span>
                             </label>
                            <span *ngIf="submitted && f['myPlan'].errors" class="help-block form-error">
                                <span *ngIf="f['myPlan'].errors['required']">This is a required
                                    field</span>
                            </span>
                    </div>

                    <div class="form-item form-field">
                        <p>* Your last Odometer reading was <strong>{{zvehOdoReading}}</strong>. Please enter current Odometer reading</p>
                        <input type="text" class="fomr-control" placeholder="" formControlName="mileage" 
                        [ngClass]="{ 'error': submitted && f['mileage'].errors || isMileageValide===true }">
                          <span *ngIf="submitted && f['mileage'].errors" class="help-block form-error">
                            <span *ngIf="f['mileage'].errors['required']">This is a required
                                field</span>
                                <span *ngIf="f['mileage'].errors['pattern']">Please enter only numbers</span>
                        </span>
                        <span *ngIf="isMileageValide && !f['mileage'].errors" class="help-block form-error">

                            <span *ngIf="isMileageValide==true">New mileage can not be less than current mileage.</span>
                        </span>
                    </div>

                    <!--
                    <div class="form-item">
                        <input class="form-check-input radio-btn" type="radio" name="myPlan" formControlName="myPlan"
                            id="selectedSP" value="SP">
                        <label class="form-check-label" for="selectedSP">
                            My BMW Service Plan
                        </label>
                    </div>

                    <div class="form-item">
                        <input class="form-check-input radio-btn" type="radio" name="myPlan" formControlName="myPlan"
                            id="selectedMP" value="MP">
                        <label class="form-check-label" for="selectedMP">
                            My BMW Maintenance Plan
                        </label>
                    </div>
                -->

                    <div class="form-action">
                        <h3>
                            <span *ngIf="active_services?.contractCategory==='S'">{{active_services?.lowestPrice.vatTotalPrice | currency :'R '}}</span>
                            <span *ngIf="active_services?.contractCategory==='O'">{{active_services?.vatTotalPrice | currency :'R '}}</span>
                            <small>(incl. VAT)</small></h3>
                        <button type="submit" class="buttons button-primary">Proceed to Checkout</button>
                    </div>
                </form>

                <div class="accordion accordion-flush accordion-wrapper" id="myplans_accordion">
                    <div class="accordion-item">
                        <a href="#packages" data-bs-toggle="collapse" role="button" aria-expanded="true"
                            aria-controls="packages" class="accordion-link">SERVICES INCLUDED IN THIS PACKAGE
                            <i class="icon icon-arrow-down"></i></a>
                        <div id="packages" aria-labelledby="packages" data-bs-parent="#myplans_accordion"
                            class="accordion-description collapse show">
                            <div class="accordion-body">
                                <!-- <ul>
                                    <li  *ngFor="let service of static_data?.package.services">{{service.description}}</li>
                                   <li>For 3 years / 3 Services</li>
                                    <li>CBS indicated or elective</li>
                                    <li>Engine oil service only</li> 
                                </ul>-->

                                <ul *ngIf="active_services?.contractType==='TC200'">
                                    <li>For {{active_services?.lowestPrice.contractMonths | monthsToYear }} year<span *ngIf="active_services?.lowestPrice.contractMonths>12">s</span></li>
                                    <li>CBS indicated or elective</li>
                                    <li>Engine oil service only</li>
                                </ul>
                                <ul *ngIf="active_services?.contractType==='TC210'">
                                    <li>For {{active_services?.lowestPrice.contractMonths | monthsToYear }} year<span *ngIf="active_services?.lowestPrice.contractMonths>12">s</span></li>
                                    <li>CBS indicated</li>
                                    <li>Vehicle check</li>
                                    <li>Engine oil service + Spark plugs + Air filters, Pollen filters, Fuel filters, Brake fluid flush</li>
                                </ul>
                                <ul *ngIf="active_services?.contractType==='TC220'">
                                    <li>For {{active_services?.lowestPrice.contractMonths | monthsToYear }} year<span *ngIf="active_services?.lowestPrice.contractMonths>12">s</span></li>
                                    <li>CBS indicated</li>
                                    <li>Vehicle check</li>
                                    <li>Engine oil service + Spark plugs + Air filters, Pollen filters, Fuel filters + Brake pads (front & rear), Brake fluid flush</li>
                                </ul>
                                
                                <ul *ngIf="active_services?.contractType==='TC900'">
                                    <li>For {{active_services?.contractMonths | monthsToYear }} year<span *ngIf="active_services?.contractMonths>12">s</span></li>
                                    <li>Roadside assistance for the period selected above.</li>
                                </ul>
                                <p>{{static_data?.package.description}}</p><br>
                                <p  *ngIf="active_services?.contractType!=='TC900'"><a href="https://www.bmw.co.za/content/dam/bmw/marketZA/bmw_co_za/Documents/legal/Motorplan_Terms_and_Conditions_BMW_Group.pdf.asset.1658137189694.pdf" target="_blank">Terms and Conditions apply.</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item" *ngIf="active_services?.contractType==='TC900'">
                        <a href="#terms-conditions" data-bs-toggle="collapse" role="button" aria-expanded="false"
                            aria-controls="terms-conditions" class="accordion-link collapsed">Terms and conditions<i
                                class="icon icon-arrow-down"></i></a>
                        <div id="terms-conditions" aria-labelledby="terms-conditions" data-bs-parent="#myplans_accordion"
                            class="accordion-description collapse">
                            <div class="accordion-body">
                                <div *ngIf="active_services?.contractType!=='TC900'">
                                    <p><a href="https://www.bmw.co.za/content/dam/bmw/marketZA/bmw_co_za/Documents/legal/Motorplan_Terms_and_Conditions_BMW_Group.pdf.asset.1658137189694.pdf" target="_blank">Terms and Conditions apply.</a></p>
                                 </div>
                                <div *ngIf="active_services?.contractType==='TC900'">
                                    <ol>
                                        <li>
                                            Roadside Assistance: {{brand}} Approved service providers offer 24hour roadside assistance.
                                        </li>
                                        <li>
                                            Tow-in service:
                                            <ol type="a">
                                                <li>In an event of a mechanical/electrical breakdown, {{brand}} On Call will arrange for your {{brand}} to be towed to the nearest {{brand}} Approved dealership</li>
                                                <li>In an event of an accident, {{brand}} On Call will tow the vehicle to the nearest {{brand}} Approved Repair Centre</li>
                                            </ol>
                                        </li>
                                        <li>
                                            Accommodation: Should you be stranded as a result of a product related defect, {{brand}} On Call will cover accommodation costs to the value of R850 per person, for one night up to a maximum of 5 people.
                                            <br/>
                                            Exclusion:
                                            <ol type="a">
                                                <li>All accident related damages are excluded.</li>
                                                <li>All meals are excluded.</li>
                                                <li>Tyre and pothole related damage is excluded.</li>
                                            </ol>
                                        </li>
                                        <li>
                                            Car hire: In relation to product related defects, {{brand}} will offer free car hire up to a maximum of 5 days. The customer will be liable for the payment of the fuel deposit which is refundable when the vehicle is returned to the rental company with a full tank.
                                            <br/>
                                            Exclusion:
                                            <ol type="a">
                                                <li>All accident related damages, including pot holes, tyre related cases, etc are excluded from car hire.</li>
                                                <li>{{brand}} On Call’s commitment is to keep the customer mobile. Therefore, {{brand}} rental vehicles are subject to availability with the relevant car rental agency.</li>
                                            </ol>
                                        </li>
                                        <li>Emergency medical assistance: In the event of an accident, {{brand}} On Call will arrange for medical assistance.</li>
                                    </ol>
                                    <p>The administration of accommodation and car hire remains at the sole discretion of {{brand}} South Africa.</p>
                                    <p>Exclusion: {{brand}} On Call is not liable for any medical costs incurred.</p>
                                 </div>
                                 
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <a href="#support" data-bs-toggle="collapse" role="button" aria-expanded="false"
                            aria-controls="support" class="accordion-link collapsed">Contact and support <i
                                class="icon icon-arrow-down"></i></a>
                        <div id="support" aria-labelledby="support" data-bs-parent="#myplans_accordion"
                            class="accordion-description collapse">
                            <div class="accordion-body">
                                <div *ngIf="active_services?.contractType==='TC900'">
                                    <p>Save our number for 24/7 roadside assistance from {{brand}} trained technicians.</p>
                                    <h5>Telephone:</h5>
                                    <p><a href="tel:+27800600777" target="_blank" class="simple-link">0800 600 777</a></p>

                                    <h5>Operating hours</h5>
                                    <p>24-hour assistance, 365 days a year</p>
                                </div>
                                <div *ngIf="active_services?.contractType!=='TC900'">
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- tracking scripts container -->
    <!-- <div id="tracking-scripts-container" style="display: none;"></div> -->

</div>
