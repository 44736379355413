import { ChangeDetectorRef, Component, OnInit, SimpleChanges, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataServiceService } from '@services/data-service.service';
import { DealerService } from "@services/dealer.service";

@Component({
  selector: 'app-preferred-retailer',
  templateUrl: './preferred-retailer.component.html',
  styleUrls: ['./preferred-retailer.component.less']
})
export class PreferredRetailerComponent implements OnInit {

  @Input()
  dealersDataToChild: any;
  markerPositions: any = {};
  alertData: string = '';
  alertResponse: string = '';
  alertMsg: string = '';
  loading: boolean = false;
  submitted: boolean = false;

  constructor(private serviceDealerSvc: DealerService, private dataSvc: DataServiceService, private cdr: ChangeDetectorRef) {


  }
  retailerForm = new FormGroup({
    retailerCode: new FormControl('', Validators.required),
  });

  get f() {
    return this.retailerForm.controls;
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    // this.getDealers();
  }

  getDealers() {
    this.serviceDealerSvc.getDealerList("BMW").subscribe((data) => {
      data.forEach((o: any) => {
        o.dealers.forEach((d: any) => {
          this.markerPositions.push(
            {
              dealerCode: d.dealerCode,
              dealerName: d.dealerName

            });
        })

      })
            console.log('markerPositions  :::', this.markerPositions)
    })
  }

  submitPreferredRetailer() {
    this.submitted = true;
    if (this.retailerForm.invalid) {
      return;
    }
    else {
      this.loading = true;
      let formData = {
        dealerCode: this.retailerForm.value.retailerCode,

      }
      
      this.serviceDealerSvc.updateServiceDealer(formData.dealerCode).subscribe((data: any) => {
        let res = data;
        const cancelBtn = window.document.getElementById('cancelBtn') as HTMLElement;
        if (res.evResult === "S") {
          this.dataSvc.setDealerId(formData.dealerCode);

          this.alertResponse = res.evMessage.toLowerCase();
          this.alertMsg = 'Retailer has been updated successfully';
          setTimeout(() => {
            this.alertMsg = '';
            cancelBtn.click();
            window.location.reload();
          }, 2000);
        }
        else {
          this.alertResponse = res.evMessage.toLowerCase();
          this.alertMsg = 'Retailer update has failed';
          setTimeout(() => {
            this.alertMsg = '';
          }, 2000);
        }
        this.loading = false;
      })
    }
  }

  showMessage(target: string, message: string, status: string) {
    if (message != '') {
      this.alertMsg = message;
    }

    if (status != '') {
      this.alertData = target;
      this.alertResponse = status;
    }
    setTimeout(() => {
      this.alertData = '';
      this.alertMsg = '';
      this.alertResponse = '';
    }, 2000);
    this.loading = false;
  }
}
