<div class="modal fade" id="preferredRetailer" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true"
    aria-labelledby="preferredRetailer" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <h1>Service Partner</h1>
                <p>Here you can select your preferred Service Partner.</p>
                <div class="alert notification" role="alert" *ngIf="alertMsg !='' && alertResponse !='' "
                    [ngClass]="alertResponse==='success' ? 'alert-success':'alert-secondary'">
                    <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> {{alertMsg}}
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </p>
                </div>
                <form [formGroup]="retailerForm" *ngIf="alertResponse !='success'">
                    <div class="form-field">
                        <select name="retailerCode" formControlName="retailerCode"
                            [ngClass]="{ 'error': submitted && f['retailerCode'].errors }">
                            <option value="">Select Preferred Dealer</option>
                            <option *ngFor="let option of dealersDataToChild" value="{{option?.dealerCode}}">
                                {{option?.dealerName}}</option>
                        </select>
                        <!-- <input type="text" placeholder="" name="title" formControlName="title"  [ngClass]="{ 'error': submitted && f['title'].errors }" > -->
                        <span *ngIf="submitted && f['retailerCode'].errors" class="help-block form-error">
                            <span *ngIf="f['retailerCode'].errors['required']">This is a required
                                field</span>
                        </span>
                    </div>

                    <button type="button" id="cancelBtn" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                    <button class="buttons button-primary" (click)="submitPreferredRetailer()"
                        [ngClass]="loading==true?'loading':''" [disabled]="retailerForm.value.retailerCode === ''">
                        Save
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>