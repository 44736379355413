import {Component, Injectable, OnInit, ViewChild} from '@angular/core';
import {Router, NavigationStart, Event as NavigationEvent} from '@angular/router';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {environment} from 'src/environments/environment';
import {AuthenticationService} from '@services/authentication.service';
import {UserService} from '@services/user.service';
import {PolicyStoreConsent} from 'src/app/_models/policy-store-consent.model';
import {CustomerActivateModalComponent} from '../customer-activate-modal/customer-activate-modal.component';
import {DataServiceService} from '@services/data-service.service';
import {
  CustomerDetailsPolicyStoreComponent
} from '../customer-policy-store-modal/customer-details-policy-store.component';
import {PolicyStoreService} from "@services/policy-store.service";
import {VehicleInfoService} from "@services/vehicle-info.service";


@Component({
  selector: 'app-gcdm',
  templateUrl: './gcdm-worker.component.html',
  styleUrls: ['./gcdm-worker.component.less']
})
@Injectable({
  providedIn: 'root'
})
export class GcdmWorkerComponent implements OnInit {

  @ViewChild('content', {static: true}) private content: any;


  closeResult: string = '';
  response: String = '';
  public href: string = '';
  status = 'A';
  submitted: boolean = false;
  termsAndConditions: any = [];
  policyStoreConsentData: any = [];
  policyConsentData: any = [];
  usageDefinitions: any = [];
  usages: any = [];
  usageTexts: any = [];
  isMandatory: boolean = false;
  consent: string = '';
  country: string = '';
  language: string = '';
  majorVersion: number = 0;
  minorVersion: number = 0;
  policyId: string = '';
  touchpointId: string = '';
  perpose: string = '';
  register: any;
  loadAppPage: boolean = false;
  isMandatoryCheckBox: boolean = false;
  loading: boolean = false;
  http: any;
  get_gcdm_config: any;
  userService: any;
  gcdm_login_env_hostname!: string;
  prospect: any;

  constructor(private modalService: NgbModal,
              private router: Router,
              private authSvc: AuthenticationService,
              private userSvc: UserService,
              private dataSvc: DataServiceService,
              private policystoreSvc: PolicyStoreService,
              private vehSvc: VehicleInfoService) {


  }


  ngOnInit(): void {

  }


  getCustomerProfile(customerDetails: any, userAccount: any, pageRendering: string) {
    // let ngbModalOptions: NgbModalOptions = {
    //   backdrop: 'static',
    //   keyboard: false,
    //   windowClass: "gcdmModal",
    //   centered: true,
    //   size: 'lg',
    //   modalDialogClass: 'testClass',
    // };

    // customerDetails.forEach((o: { businessPartner: { givenName: any; surname: any; salutation: any; addresses: { addresses: any[] | undefined; } | undefined; communications: { communicationEMails: any[] | undefined; communicationPhones: any[] | undefined; } | undefined; profile: { personalIdentifications: { personalIdentifications: any[]; } | undefined; } | undefined; };  }, i: any) => {

            if (pageRendering === 'prospect') {
              this.loading = true;
              this.dataSvc.setPageRestriction('prospect');
              this.router.navigate(['/prospect']);
              return;
            } else {
              this.loading = true;
              this.dataSvc.setPageRestriction('');
              this.router.navigate(['/service-dashboard']);
              return;
            }


    // }, (err: any) => {
    //   this.loading = false;
    //   this.logout();
    // });

  }


  logout() {
    this.dataSvc.removeItems();
    this.dataSvc.clearStore();
    sessionStorage.clear();
    this.authSvc.gcdmLogOff().subscribe();
    window.location.href = environment.gcdm_login_env_hostname + "/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2Fwww.bmw.co.za%2Fen%2Findex.html";
  }


  titleList(title: string) {
    if (title === 'SAL_MR') {
      return 'Mr'
    }

    if (title === 'SAL_MS') {
      return 'Ms'
    }

    if (title === 'SAL_MRS') {
      return 'Mrs'
    }

    if (title === 'SAL_MISS') {
      return 'Miss'
    }

    if (title === 'SAL_DR') {
      return 'Dr'
    }
    return "";
  }

  loadCPData(custDetails: any, userAccount: any, pageRendering: string) {
    this.getCustomerProfile(custDetails, userAccount, pageRendering);
  }

}
