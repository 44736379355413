import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from "@angular/google-maps";
import { DealerService } from "@services/dealer.service";
import { DataServiceService } from "@services/data-service.service";

@Component({
  selector: 'app-dash-service-partner',
  templateUrl: './dash-service-partner.component.html',
  styleUrls: ['./dash-service-partner.component.less']
})
export class DashServicePartnerComponent implements OnInit {

  @Input()
  servicePartnerDetailsToChild: any;

  @Input()
  brandFromParent: any;

  @ViewChild(GoogleMap)
  public map!: GoogleMap;
  markerPositions: any[] = [];
  markerOptions: google.maps.MarkerOptions = {};

  constructor(private serviceDealerSvc: DealerService, private dataSvc: DataServiceService) {

    this.brand = this.dataSvc.getBrand();
    this.markerOptions = {
      draggable: false,
      icon: {
        url: './assets/' + this.brand + '/images/location-pin.png'
      }
    }
  }

  display: any;
  // center!: google.maps.LatLngLiteral;
  center: google.maps.LatLngLiteral = {
    lat: -28.521469312909,
    lng: 25.369652965690502
  };
  zoom = 6;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDefaultUI: true,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    streetViewControl: false,
  };
  brand: string = 'BMW';

  ngOnInit() {
    navigator.geolocation.getCurrentPosition((position) => {
      let res: any;
      this.serviceDealerSvc.getServiceCodeDealer().subscribe((data) => {
        res = data;

        this.serviceDealerSvc.getDealerList(this.brand).subscribe((data) => {

          data.forEach((o: any) => {
            o.dealers.forEach((d: any) => {
              if (d.dealerCode === res.evDealerRelation.dealerId) {
                this.center = {
                  lat: Number(d.geoCoordinates.latitude),
                  lng: Number(d.geoCoordinates.longitude)
                }
                this.zoom = 12;
              }
            })
          })
        })
      })
    });
  }

}
