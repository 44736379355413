import { Component, OnInit } from '@angular/core';
import { DataServiceService } from '@services/data-service.service';

@Component({
  selector: 'app-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.less']
})
export class ProspectComponent implements OnInit {

  constructor(private dataSvc: DataServiceService,) {
    this.brandToChild = this.dataSvc.getBrand(); 
  }
    
  brandToChild: string = '';

  ngOnInit(): void {
  }

}
