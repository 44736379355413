<div class="tyre-options-component componentWrapper" [ngClass]="brandFromParent=='MINI'?'lightBG md-container':''">
    <div class="col-12 component-header-image">
        <img src="./assets/{{brandFromParent}}/images/tyre-options.jpg">
    </div>

    <div class="col-12 component-header">
        <h5>{{brandFromParent}} Tyre Options</h5>
    </div>
    <div class="col-12  component-header" *ngIf="!tyreOptionsToChild">Loading...</div>

    <div class="col-12 component-content" *ngIf="tyreOptionsToChild">
        <p>{{tyreOptionsToChild?.tyreInformation?.manufacturer}} {{tyreOptionsToChild?.tyreInformation?.manufdesc}}</p>
    </div>
    <div class="col-12 component-price" *ngIf="tyreOptionsToChild">
        <span>{{tyreOptionsToChild?.retailPrice | currency:"R "}}*</span>
        <p>*Price is for 4 tyres, includes VAT and excludes fitment.</p>
    </div>
    <div class="col-12 component-buttons-links link" *ngIf="tyreOptionsToChild">
        <div class="container mx-0">
            <div class="row">
                <div class="col-12 col-sm-8 p-0">
                    <button class="buttons button-ghost" (click)="gotServiceOptionsEnquiry()">
                        View all options
                    </button>
                </div>
                <!-- <div class="col-0 p-0">
                    <button class="buttons button-grey">
                        Book a service
                    </button>
                </div> -->
            </div>
        </div>
        
    </div>
</div>
