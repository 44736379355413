import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpXsrfTokenExtractor } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(private http: HttpClient, private tokenExtractor: HttpXsrfTokenExtractor) { }


  //DO NOT USE REPLACED WITH SECURED API BELOW
  // getCampaigns(params: any) {
  //   let headers = new HttpHeaders({
  //     'X-API-KEY': '0515aeaa-ba88-47cf-b011-170277794afe',
  //     'Accept': 'application/json',
  //     Authorization: "" + localStorage.getItem("jwt")
  //   });

  //   return this.http.post<any>("https://motorplanza-int.bmwgroup.com/mp3/AfterSalesWebAPI/motorplan/ZA/EN/v2/Campaigns", params, { headers: headers })
  //     .pipe(map(data => data as any));
  // }

  // getCampaigns(vin: string): Observable<any> {
  //
  //   let csrfToken = this.tokenExtractor.getToken() as string;
  //   var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', "X-XSRF-TOKEN": csrfToken });
  //   var configs = { headers: headers };
  //
  //   let add = { "vin": vin };
  //
  //   return this.http.post<any>(environment.apiURL + "/customerportalapi/rest/cp/vehicles/motor-plan/get-optional-contracts", add, configs)
  //     .pipe(map(data => data as any));
  // }


  getVehicleCampaign(vin:string): Observable<any>{
    return this.http.get<any>(environment.apiURL +"/customerportalapi/rest/cp/vehicles/${vehicleId}/vehicle-recall-campaign?vehicleId="+vin)
   .pipe(map(data =>  data as any));
}
}
