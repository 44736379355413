import { Component, OnInit } from '@angular/core';
// import { CustomerProfileService } from '@services/customer-profile.service';
// import { CustomerProfile } from '../../../_models/customer-profile.model';
// import { DataServiceService } from '@services/data-service.service';
// import { FormGroup, FormControl, Validators  } from '@angular/forms';
// import { DatePipe } from "@angular/common";
import {UserService} from "@services/user.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { DataServiceService } from '@services/data-service.service';
@Component({
  selector: 'app-profile-personal-data',
  templateUrl: './profile-personal-data.component.html',
  styleUrls: ['./profile-personal-data.component.less']
})
export class ProfilePersonalDataComponent implements OnInit {

  // customerProfile: CustomerProfile;

  constructor(
    // private customerProfileSvc: CustomerProfileService, _profile: CustomerProfile, private dataServiceSvc: DataServiceService
    private userSvc: UserService,
    private dataSvc: DataServiceService,
  ) {
    // this.customerProfile = _profile;
    // this.maxDate = new Date();
    // this.maxDate.setFullYear(this.maxDate.getFullYear() - 15);
    // this.maxDate.setDate(this.maxDate.getDate());
    // this.minDate = new Date();
    // this.minDate.setFullYear(this.minDate.getFullYear() - 120);
    // this.minDate.setDate(this.minDate.getDate());
    this.brand = this.dataSvc.getBrand();
  }

  PersonalData = 'PersonalData';
  ContactInfo = 'ContactInfo';
  Address = 'Address';
  EditPersonalData: boolean = false;
  EditContactInfo: boolean = false;
  EditAddress: boolean = false;
  submitted: boolean = false;
  loading: boolean = false;
  alertData: string = '';
  alertResponse: string = '';
  alertMsg: string ='';
  personalDetails: any;
  title: string = '';
  firstName: string = '';
  lastName: string = '';
  fullName: string = ''
  dob: string = '';
  idNo: string = '';
  gender: string = '';
  initials: string = '';
  lang: string = '';
  // idType: string = '';
  // prefCom: string = '';
  // partCat: string = '';
  // pType: string = '';
  emailAddress: string = '';
  contactNo: string = '';
  city: string = '';
  country: string = '';
  districtName: string = '';
  postalCode: string = '';
  strSuppl1: string = '';
  street: string = '';
  userEmail: string = '';
  userAccount: any = {};
  brand: string = '';
  // maxDate: Date;
  // minDate: Date;
  //
  getTitle(title: string):string {
    switch (title) {
      case 'SAL_MR':
        return 'Mr';

      case 'SAL_MS':
        return 'Ms';

      case 'SAL_MRS':
        return 'Mrs';

      case 'SAL_MISS':
        return 'Miss';

      case 'SAL_DR':
        return 'Dr';

      default:
        return '';
    }
  }


  getTitleDesc(title: string):string {
    switch (title) {
      case 'Mr':
        return 'SAL_MR';

      case 'Ms':
        return 'SAL_MS';

      case 'Mrs':
        return 'SAL_MRS';

      case 'Miss':
        return 'SAL_MISS';

      case 'Dr':
        return 'SAL_DR';

      default:
        return '';
    }
  }

  personalForm = new FormGroup({
    title: new FormControl('', Validators.required),
    // initial: new FormControl('', Validators.required),
    firstName: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z\s\p{P}]+$/u)]),
    lastName: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z\s\p{P}]+$/u)]),
    dob: new FormControl('', Validators.required),
    idNo: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.pattern(/^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))$/)]),
   });

   get f() {
    return this.personalForm.controls;
  }

  communicationForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required, Validators.email]),
    contactNo: new FormControl('', [Validators.required, Validators.pattern(/^(?:\+27\s?\d{2}\s?\d{3}\s?\d{4}|0?\d{2}\s?\d{3}\s?\d{4})$/)])
   });

   get c() {
    return this.communicationForm.controls;
  }

  addressForm = new FormGroup({
    street: new FormControl('', [Validators.required, Validators.required]),
    strSuppl1: new FormControl('', [Validators.required, Validators.required]),
    districtName: new FormControl('', [Validators.required, Validators.required]),
    city: new FormControl('', [Validators.required, Validators.required]),
    postalCode: new FormControl('', [Validators.required, Validators.required]),
    country: new FormControl('', [Validators.required, Validators.required]),
   });

   get a() {
    return this.addressForm.controls;
  }

  ngOnInit(): void {
    const token = sessionStorage.getItem("CP_OAUTH_BEARER_TOKEN");
    if (token !== null) {
      this.getPersonalDetails(token);
    }
  }

  Edit(WhatToEDit: any) {

    if (WhatToEDit === "PersonalData") {
      this.assignPersonalDetails();
      this.EditPersonalData = !this.EditPersonalData
    }
    if (WhatToEDit === "ContactInfo") {
      this.EditContactInfo = !this.EditContactInfo
      this.assignContactFormData();
    }
    if (WhatToEDit === "Address") {
      this.EditAddress = !this.EditAddress
      this.assignAddressFormData();
    }
  }

  getPersonalDetails(token: string) {
  //
  //   // let datePipe = new DatePipe("en-US");
    let custGcdmServiceObservable = this.userSvc.getGcdmCustomerProfileOnePage(token);
    custGcdmServiceObservable.subscribe((customerDetails) => {

      customerDetails.forEach((o: any) => {

        this.personalDetails = o;

        if (this.personalDetails !== undefined) {
          this.title = this.getTitle(this.personalDetails.salutation);
          this.firstName = this.personalDetails.givenName;
          this.lastName = this.personalDetails.surname;
          this.dob = this.personalDetails.birthday;
          const adjustTitle = this.title !== '' ? this.title + '.' : '';
          this.fullName = adjustTitle + ' ' + this.firstName + ' ' + this.lastName,
            this.lang = this.personalDetails.correspondLanguageISO;
          this.initials = this.personalDetails.initialsName
        }

        let userAccountObservable = this.userSvc.getGcdmUserAccountFromASession();
        userAccountObservable.subscribe((account) => {

          this.userAccount = account;
        });


        if (this.personalDetails.profile !== undefined) {
          if (this.personalDetails.profile.personalIdentifications !== undefined) {
            this.personalDetails.profile.personalIdentifications.personalIdentifications.forEach((x: any) => {
              this.idNo = x.id;
            });
          }
        }

        if (this.personalDetails.communications !== undefined) {
          if (this.personalDetails.communications.communicationPhones !== undefined) {
            const isPresent = this.personalDetails.communications.communicationPhones.find((x: any) => x.communicationType === 'MOB' && x.preferred);
            if (isPresent) {
              this.contactNo = isPresent.value;
            } else {
              this.contactNo = "";
            }
          }
          if (this.personalDetails.communications.communicationEMails !== undefined) {
            const isPresent = this.personalDetails.communications.communicationEMails.find((x: any) => x.preferred);
            if (isPresent) {
              this.emailAddress = isPresent.value;
            }
            else {
                if(isPresent.value !== undefined){
                    this.emailAddress = isPresent.value;
                }
            }
          }


          if (this.personalDetails.addresses !== undefined) {
            this.personalDetails.addresses.addresses.forEach((i: any) => {
              if (i.preferred) {
                this.city = i.city;
                this.country = i.country;
                this.districtName = i.districtName;
                this.postalCode = i.postalCode;
                this.strSuppl1 = i.strSuppl1;
                this.street = i.street;
              }
            });
          }

          if (this.userAccount !== undefined) {
            this.userEmail = this.userAccount.mail;
          }
        }
      })
    })

  }
  //
  //
  assignPersonalDetails() {
    this.personalForm.controls['title'].setValue(this.title);
    this.personalForm.controls['firstName'].setValue(this.firstName);
    this.personalForm.controls['lastName'].setValue(this.lastName);
    this.personalForm.controls['dob'].setValue(this.dob);
    this.personalForm.controls['idNo'].setValue(this.idNo);

  }


  assignContactFormData() {
    this.communicationForm.controls['emailAddress'].setValue(this.emailAddress);
    this.communicationForm.controls['contactNo'].setValue(this.contactNo);

  }

  assignAddressFormData() {
    this.addressForm.controls['strSuppl1'].setValue(this.strSuppl1);
    this.addressForm.controls['street'].setValue(this.street);
    this.addressForm.controls['city'].setValue(this.city);
    this.addressForm.controls['postalCode'].setValue(this.postalCode);
    this.addressForm.controls['country'].setValue(this.country);
    this.addressForm.controls['districtName'].setValue(this.districtName);
  }



  submitPersonalData(){
    this.submitted = true;
    if (this.personalForm.invalid) {

      return;
    }
    else {
      this.loading = true;
      let formData = {
        title: this.getTitleDesc(this.personalForm.value.title),
        names: this.personalForm.value.firstName,
        surname: this.personalForm.value.lastName,
        // birthday: this.personalForm.value.dob,
        // initialsName: this.personalForm.value.initial
      }
      this.userSvc.updateCustomerProfile(formData).subscribe((data:any)=>{
        if(data!== null){
          this.personalDetails = data.businessPartner;

        if (this.personalDetails !== undefined) {

        if (this.personalDetails !== undefined) {
          this.title = this.getTitle(this.personalDetails.salutation);
          this.firstName = this.personalDetails.givenName;
          this.lastName = this.personalDetails.surname;
          this.dob = this.personalDetails.birthday;
          const adjustTitle = this.title !== '' ? this.title + ' .' : '';
          this.fullName = adjustTitle + ' ' + this.firstName + ' ' + this.lastName,
            this.lang = this.personalDetails.correspondLanguageISO;
          this.initials = this.personalDetails.initialsName
        }
        let Msg = 'Thank you, your personal information was updated successfully.';
        this.showMessage(this.PersonalData, Msg, 'success')
        }
      }
      else{
        let Msg = 'Sorry, your personal information update was unsuccessful.';
        this.showMessage(this.PersonalData, Msg, 'error')
      }
      });
    }
  }


  submitContactData(){
    this.submitted = true;
    if (this.communicationForm.invalid) {

      return;
    }
    else {
      this.loading = true;
      let formData = {
        email: this.communicationForm.value.emailAddress,
        mobilenumber: this.communicationForm.value.contactNo,
        city:this.city,
        districtName: this.districtName,
        postalcode:this.postalCode,
        strSuppl1: this.strSuppl1,
        street: this.street,
        CountryIso2: this.country
      }

      this.userSvc.updateContactDetails(formData).subscribe((data:any)=>{
        if(data!== null){
          this.personalDetails = data.businessPartner;


          if (this.personalDetails.communications !== undefined) {
            if (this.personalDetails.communications.communicationPhones !== undefined) {
              const isPresent = this.personalDetails.communications.communicationPhones.find((x: any) => x.communicationType === 'MOB' && x.preferred);
              if (isPresent) {
                this.contactNo = isPresent.value;
              } else {
                this.contactNo = "";
              }
            }
            if (this.personalDetails.communications.communicationEMails !== undefined) {
              const isPresent = this.personalDetails.communications.communicationEMails.find((x: any) => x.preferred);
              if (isPresent) {
                this.emailAddress = isPresent.value;
              }
              else {
                  if(isPresent.value !== undefined){
                      this.emailAddress = isPresent.value;
                  }
              }
            }
        let Msg = 'Thank you, your contact information was updated successfully.';
        this.showMessage(this.ContactInfo, Msg, 'success')
        }
      }
      else{
        let Msg = 'Sorry, your contact information update was unsuccessful.';
        this.showMessage(this.ContactInfo, Msg, 'error')
      }
      });
    }
  }

  submitAddressData(){
    this.submitted = true;
    if (this.addressForm.invalid) {

      return;
    }
    else {
      this.loading = true;
      let formData = {
        email: this.emailAddress,
        mobilenumber: this.contactNo,
        city:this.addressForm.value.city,
        districtName: this.addressForm.value.districtName,
        postalcode:this.addressForm.value.postalCode,
        strSuppl1: this.addressForm.value.strSuppl1,
        street: this.addressForm.value.street,
        CountryIso2: this.addressForm.value.country
      }

      this.userSvc.updateContactDetails(formData).subscribe((data:any)=>{
        if(data!== null){
          this.personalDetails = data.businessPartner;


          if (this.personalDetails.addresses !== undefined) {
            this.personalDetails.addresses.addresses.forEach((i: any) => {
              if (i.preferred) {
                this.city = i.city;
                this.country = i.country;
                this.districtName = i.districtName;
                this.postalCode = i.postalCode;
                this.strSuppl1 = i.strSuppl1;
                this.street = i.street;
              }
            });

        let Msg = 'Thank you, your address information was updated successfully.';
        this.showMessage(this.Address, Msg, 'success');
        }
      }
      else{
        let Msg = 'Sorry, your address information update was unsuccessful.';
        this.showMessage(this.Address, Msg, 'error');
      }
      });
    }
  }



  resetForm(value: any = undefined, form: FormGroup): void {
    form.reset(value);
    (this as { submitted: boolean }).submitted = false;
  }

showMessage(target:string, message: string, status:string){
  if(message!=''){
  this.alertMsg = message;
  }

  if(status!=''){
  this.alertData = target;
  this.alertResponse = status;
  }

  this.Edit(target)
  setTimeout(()=>{
    this.alertData = '';
    this.alertMsg = '';
    this.alertResponse  = '';
  }, 10000);
  this.loading = false;
}
}
