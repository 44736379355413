import {HttpClient, HttpHeaders, HttpParams, HttpXsrfTokenExtractor} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, delay } from "rxjs/operators";
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
import { DataServiceService } from './data-service.service';

@Injectable({
  providedIn: 'root'
})
export class DealerService {
  httpClient: HttpClient;
  get_gcdm_config: any;
  gcdm_env_hostname: any;

  constructor(private http: HttpClient, private authSvc: AuthenticationService, private tokenExtractor: HttpXsrfTokenExtractor) {
    this.httpClient = http;
    this.get_gcdm_config = this.authSvc.get_config();
  }

  updateServiceDealer(dealerCode: string) {

    let csrfToken = this.tokenExtractor.getToken() as string;

    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN'), "X-XSRF-TOKEN": csrfToken });
    var configs = { headers: headers };

    let dealerCodeInfo = {
      updateFlag: "X",
      getFlag: "",
      dealerCode: dealerCode
    }

    return this.http.put(environment.apiURL + "/customerportalapi/rest/cp/crm/customer/get-maintain/dealer", dealerCodeInfo, configs)
      .pipe(map(function (data) { return data; }));
  };

  getServiceCodeDealer() {

    let csrfToken = this.tokenExtractor.getToken() as string;

    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN'), "X-XSRF-TOKEN": csrfToken });
    var configs = { headers: headers };

    let dealerCodeInfo = {
      updateFlag: "",
      getFlag: "X",
      dealerCode: ""
    }
    return this.http.put(environment.apiURL + "/customerportalapi/rest/cp/crm/customer/get-maintain/dealer", dealerCodeInfo, configs)
      .pipe(map(function (data) { return data; }));
  };

  getDealerList(dealerBrand:string): Observable<any>{
    let csrfToken = this.tokenExtractor.getToken() as string;
    let queryParams = new HttpParams(); queryParams = queryParams.append("brand",dealerBrand);
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', "X-XSRF-TOKEN": csrfToken });
    var configs = { headers: headers,  params: queryParams };
    return this.http.get<any>(environment.apiURL+"/customerportalapi/rest/cp/vehicles/"+dealerBrand+"/hst-dealer-info", configs)
      .pipe(map(data =>  data as any));
  }

  getDealerAgent(dealerBrand:string, dealerCode:string): Observable<any>{
    return this.http.get<any>(environment.OASIS_API+"/"+dealerBrand+"/"+"1"+"/"+dealerCode)
      .pipe(map(data =>  data as any));
  }

}
