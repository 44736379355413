import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import { Ng2SearchPipeModule } from 'ng2-search-filter';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './_components/loader/loader.component';
import { LoadingInterceptor } from './_interceptors/loading.interceptor';
import { HttpRequestInterceptorInterceptor } from './_interceptors/http-request-interceptor.interceptor';
import { BmwHeaderComponent } from './_components/headers-footers/bmw/bmw-header/bmw-header.component';
import { MiniHeaderComponent } from './_components/headers-footers/mini/mini-header/mini-header.component';
import { MotHeaderComponent } from './_components/headers-footers/mot/mot-header/mot-header.component';
import { BmwFooterComponent } from './_components/headers-footers/bmw/bmw-footer/bmw-footer.component';
import { MiniFooterComponent } from './_components/headers-footers/mini/mini-footer/mini-footer.component';
import { MotFooterComponent } from './_components/headers-footers/mot/mot-footer/mot-footer.component';
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { DashMotorplanContractComponent } from './_components/dashboard/dash-motorplan-contract/dash-motorplan-contract.component';
import { DashServicePartnerComponent } from './_components/dashboard/dash-service-partner/dash-service-partner.component';
import { ProfileServicePartnerComponent } from './_components/profile/profile-service-partner/profile-service-partner.component';
import { DashTechnicalSpecsComponent } from './_components/dashboard/dash-technical-specs/dash-technical-specs.component';
import { DashOnCallComponent } from './_components/dashboard/dash-on-call/dash-on-call.component';
import { DashTyrePlanComponent } from './_components/dashboard/dash-tyre-plan/dash-tyre-plan.component';
import { DashMyPlansComponent } from './_components/dashboard/dash-my-plans/dash-my-plans.component';
import { MyPlansComponent } from './_components/my-plans/my-plans.component';
import { DashServiceOptionsComponent } from './_components/dashboard/dash-service-options/dash-service-options.component';
// import { ServiceOptionsComponent } from './_components/service-options/service-options.component';
import { DashTyreOptionsComponent } from './_components/dashboard/dash-tyre-options/dash-tyre-options.component';
// import { TyreOptionsComponent } from './_components/dashboard/dash-tyre-options/dash-tyre-options.component';
import { routes } from './app-routing.module';
import { ServiceEnquiryComponent } from './_components/service-enquiry/service-enquiry.component';
import { VehicleInfoComponent } from './_components/vehicle-info/vehicle-info.component';
import { VehicleSearchComponent } from './_components/vehicle-search/vehicle-search.component';
import { ProfileComponent } from './_components/profile/profile.component';
import { ProfilePersonalDataComponent } from './_components/profile/profile-personal-data/profile-personal-data.component';
import { ProfilePreferencesComponent } from './_components/profile/profile-preferences/profile-preferences.component';
import { GoBackComponent } from './_components/go-back/go-back.component';
import { DashTeasersComponent } from './_components/dashboard/dash-teasers/dash-teasers.component';
import { CheckoutPayOptionsComponent } from './_components/checkout-pay-options/checkout-pay-options.component';
import { MakePaymentComponent } from './_components/checkout-pay-options/make-payment/make-payment.component';
import { NextStepComponent } from './_components/checkout-pay-options/next-step/next-step.component';
import { ModalsComponent } from './_components/modals/modals.component';
import { DatePipe } from '@angular/common';
import {RouterModule} from "@angular/router";
import { PlansOffersComponent } from './_components/plans-offers/plans-offers.component';
import { CustomerActivateModalComponent } from './_components/customer-activate-modal/customer-activate-modal.component';
import { CustomerDetailsPolicyStoreComponent } from './_components/customer-policy-store-modal/customer-details-policy-store.component';
import { AuthenticationComponent } from './_components/authentication/authentication.component';
import { GcdmWorkerComponent } from './_components/gcdm-worker/gcdm-worker.component';
import { ServiceOnlineComponent } from './_components/service-online/service-online.component';
import { MyPlansPtionsModalComponent } from './_components/modals/my-plans-ptions-modal/my-plans-ptions-modal.component';
import { AddVehicleModalComponent } from './_components/modals/add-vehicle-modal/add-vehicle-modal.component';
import { RemoveVehicleModalComponent } from './_components/modals/remove-vehicle-modal/remove-vehicle-modal.component';
import { OnDemandComponent } from './_components/on-demand/on-demand.component';
import { ProspectComponent } from './_components/prospect/prospect.component';
import { MonthsToYearPipe } from './_pipes/months-to-year.pipe';
import {GoogleMapsModule} from "@angular/google-maps";
import { PstemlComponent } from './_components/psteml/psteml.component';
import { PreferredRetailerComponent } from './_components/modals/preferred-retailer/preferred-retailer.component';
import { environment } from 'src/environments/environment';
import { MockModule } from 'src/mock/mock.module';
import { MotorplanModalComponent } from './_components/modals/motorplan-modal/motorplan-modal.component';
import { RenewMotorplanComponent } from './_components/renew-motorplan/renew-motorplan.component';
import {ErrorInterceptor} from "./_interceptors/error.interceptor";
import { MobicredModalComponent } from './_components/modals/mobicred-modal/mobicred-modal.component';
import { TronGenericPageTrackingService } from './_helpers/TRON-Analytics-tracking/page-tracking/tron-generic-page-tracking.service';

let extraModules = environment.mockApi ? [MockModule] : [];

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    BmwHeaderComponent,
    MiniHeaderComponent,
    MotHeaderComponent,
    BmwFooterComponent,
    MiniFooterComponent,
    MotFooterComponent,
    DashboardComponent,
    DashMotorplanContractComponent,
    DashServicePartnerComponent,
    ProfileServicePartnerComponent,
    DashServiceOptionsComponent,
    DashOnCallComponent,
    DashTyrePlanComponent,
    DashTechnicalSpecsComponent,
    DashMyPlansComponent,
    MyPlansComponent,
    DashServiceOptionsComponent,
    DashTyreOptionsComponent,
    // ServiceOptionsComponent,
    // TyreOptionsComponent,
    ServiceEnquiryComponent,
    VehicleInfoComponent,
    VehicleSearchComponent,
    ProfileComponent,
    ProfilePersonalDataComponent,
    ProfilePreferencesComponent,
    GoBackComponent,
    GoBackComponent,
    DashTeasersComponent,
    CheckoutPayOptionsComponent,
    MakePaymentComponent,
    NextStepComponent,
    ModalsComponent,
    PlansOffersComponent,
    CustomerActivateModalComponent,
    CustomerDetailsPolicyStoreComponent,
    AuthenticationComponent,
    GcdmWorkerComponent,
    ServiceOnlineComponent,
    MyPlansPtionsModalComponent,
    AddVehicleModalComponent,
    RemoveVehicleModalComponent,
    OnDemandComponent,
    ProspectComponent,
    MonthsToYearPipe,
    PstemlComponent,
    PreferredRetailerComponent,
    MotorplanModalComponent,
    RenewMotorplanComponent,
    MobicredModalComponent,
  ],
  exports: [RouterModule],
  imports: [
    // RouterModule.forRoot(routes),
    BrowserModule,
    GoogleMapsModule,
    AppRoutingModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    SwiperModule,
    ...extraModules
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: Document, useValue: document },
    TronGenericPageTrackingService,
    TronGenericPageTrackingService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
