<div>
    <h1>Personal Data</h1>
    <div class="text-copy">
        <p>You can edit any of your details below so your profile is up to date.</p>
    </div>
</div>
<div class="data-container">
    <!--=====: PERSONAL DETAILS SECTION :=====-->
    <div class="personal-info">
        <div class="container data-header">
            <div class="row">
                <div class="col-10 data-header-align">
                    <i class="icon icon-user"></i>
                    <span>Personal Information</span>
                </div>
                <div class="col-2 data-header-align data-edit">
                    <div class="edit-link" (click)="Edit(PersonalData)"><i class="icon icon-arrow-right"></i>
                        <span>{{!EditPersonalData?"Edit":"Cancel"}}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- EDIT -->
        <div class="container data-content" *ngIf="!EditPersonalData">
            <div class="alert notification" role="alert" *ngIf="alertData==='PersonalData' && alertResponse !='' "
                [ngClass]="alertResponse==='success' ? 'alert-success':'alert-secondary'">
                <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> {{alertMsg}} <button type="button"
                        class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>
            </div>
            <!-- <div class="alert alert-secondary notification" role="alert">
                <p class="bmw-alert-text text-danger"><i class="iconImg icon-info-circle danger"></i> Sorry, your personal information update was unsuccessful. <button type="button" class="btn-close  text-danger" data-bs-dismiss="alert" aria-label="Close"></button></p>
            </div> -->
            <div class="col-12">
                <div class="row">
                    <div class="col-4 data-content-header">
                        Full Name
                    </div>
                    <div class="col-4 data-content-header">
                        Date of birth
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        {{fullName}}
                    </div>
                    <div class="col-4">
                        {{dob}}
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4 data-content-header">
                        ID Number
                    </div>
                    <div class="col-4 data-content-header">
                        Initials
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        {{idNo}}
                    </div>
                    <div class="col-4">
                        {{initials}}
                    </div>
                </div>
            </div>
        </div>
        <!-- END READ ONLY -->

        <!-- EDIT -->
        <div class="container edit" *ngIf="EditPersonalData">
            <form [formGroup]="personalForm">
                <div class="row">
                    <div class="col-12 form-field">
                        <label class="form-label">Salutation*</label>
                        <select name="title" formControlName="title"
                            [ngClass]="{ 'error': submitted && f['title'].errors }">
                            <option value="">Select Salutation</option>
                            <option value="Mr">Mr</option>
                            <option value="Ms">Ms</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Miss">Miss</option>
                            <option value="Dr">Dr</option>
                        </select>
                        <!-- <input type="text" placeholder="" name="title" formControlName="title"  [ngClass]="{ 'error': submitted && f['title'].errors }" > -->
                        <span *ngIf="submitted && f['title'].errors" class="help-block form-error">
                            <span *ngIf="f['title'].errors['required']">This is a required field</span>
                        </span>
                    </div>
                    <div class="col-12 form-field">
                        <label class="form-label">First Name*</label>
                        <input type="text" placeholder="" name="firstName" formControlName="firstName"
                            [ngClass]="{ 'error': submitted && f['firstName'].errors }" required>
                        <span *ngIf="submitted && f['firstName'].errors" class="help-block form-error">
                            <span *ngIf="f['firstName'].errors['required']">This is a required field</span>
                            <span *ngIf="f['firstName'].errors['pattern']">First name is invalid</span>
                        </span>
                    </div>
                    <div class="col-12 form-field">
                        <label class="form-label">Surname*</label>
                        <input type="text" placeholder="" name="lastName" formControlName="lastName"
                            [ngClass]="{ 'error': submitted && f['lastName'].errors }">
                        <span *ngIf="submitted && f['lastName'].errors" class="help-block form-error">
                            <span *ngIf="f['lastName'].errors['required']">This is a required field</span>
                            <span *ngIf="f['lastName'].errors['pattern']">Surname is invalid</span>
                        </span>
                    </div>
                    <!-- <div class="col-12 form-field">
                        <label class="form-label">Initials*</label>
                        <input type="text" placeholder="" name="initials" formControlName="initial"
                            [ngClass]="{ 'error': submitted && f['initial'].errors }">
                        <span *ngIf="submitted && f['initial'].errors" class="help-block form-error">
                            <span *ngIf="f['initial'].errors['required']">This is a required field</span>
                        </span>
                    </div> -->
                    <!-- <div class="col-12 form-field">
                        <label class="form-label">Nickname</label>
                        <input type="text" placeholder="" name="nickname">
                    </div> -->
                    <div class="col-12 form-field">
                        <label class="form-label">Birthday*</label>
                        <input type="text" placeholder="" name="dob" formControlName="dob"
                            [ngClass]="{ 'error': submitted && f['dob'].errors }" readonly>
                        <span *ngIf="submitted && f['dob'].errors" class="help-block form-error">
                            <span *ngIf="f['dob'].errors['required']">This is a required field</span>
                        </span>
                    </div>
                    <div class="col-12 mandatory">
                        *Mandatory Fields
                    </div>
                    <div class="component-buttons-links link">
                        <div class="row">
                            <div class="col-6">
                                <button class="buttons button-ghost" (click)="Edit(PersonalData)">
                                    Cancel
                                </button>
                            </div>
                            <div class="col-6">
                                <button class="buttons button-primary" (click)="submitPersonalData()"
                                    [ngClass]="loading==true?'loading':''">
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- END EDIT -->
    </div>
    <!--=====: END PERSONAL DETAILS SECTION :=====-->

    <!--=====: CONTACT SECTION :=====-->
    <div class="contact-info">
        <div class="container data-header">
            <div class="row">
                <div class="col-10 data-header-align">
                    <i class="icon icon-contact"></i>
                    <span>Contact Information</span>
                </div>
                <div class="col-2 data-header-align data-edit">
                    <div class="edit-link" (click)="Edit(ContactInfo)"><i class="icon icon-arrow-right"></i>
                        <span>{{!EditContactInfo?"Edit":"Cancel"}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- EDIT -->
        <div class="container data-content" *ngIf="!EditContactInfo">

            <div class="alert alert-success notification" role="alert"
                *ngIf="alertData==='ContactInfo' && alertResponse !=''"
                [ngClass]="alertResponse==='success' ? 'alert-success':'alert-secondary'">
                <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> {{alertMsg}} <button type="button"
                        class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4 data-content-header">
                        Email
                    </div>
                    <div class="col-4 data-content-header">
                        Mobile Number
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        {{emailAddress}}
                    </div>
                    <div class="col-4">
                        {{contactNo}}
                    </div>
                </div>
            </div>
        </div>
        <!-- END READ ONLY -->

        <!-- EDIT -->
        <div class="container edit" *ngIf="EditContactInfo">
            <form [formGroup]="communicationForm">
                <div class="row">
                    <div class="col-12 form-field">
                        <label class="form-label">Email Address*</label>
                        <input type="email" placeholder="" name="emailAddress" formControlName="emailAddress"
                            [ngClass]="{ 'error': submitted && c['emailAddress'].errors }">
                        <span *ngIf="submitted && c['emailAddress'].errors" class="help-block form-error">
                            <span *ngIf="c['emailAddress'].errors['required']">This is a required field</span>
                            <span *ngIf="c['emailAddress'].errors['pattern']">E-mail must be a valid email
                                address</span>
                        </span>
                    </div>

                    <div class="col-12 form-field">
                        <label class="form-label">Phone Number*</label>
                        <input type="tel" placeholder="" name="contactNo" formControlName="contactNo"
                            [ngClass]="{ 'error': submitted && c['contactNo'].errors }">
                        <span *ngIf="submitted && c['contactNo'].errors" class="help-block form-error">
                            <span *ngIf="c['contactNo'].errors['required']">This is a required field</span>
                            <span *ngIf="c['contactNo'].errors['pattern']">Phone Number is invalid</span>
                        </span>
                    </div>

                    <div class="col-12 mandatory">
                        *Mandatory Fields
                    </div>

                    <div class="component-buttons-links link">
                        <div class="row">
                            <div class="col-6">
                                <button class="buttons button-ghost" (click)="Edit(ContactInfo)">
                                    Cancel
                                </button>
                            </div>

                            <div class="col-6">
                                <button class="buttons button-primary" (click)="submitContactData()"
                                    [ngClass]="loading==true?'loading':''">
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- END EDIT -->
    </div>
    <!--=====: END CONTACT SECTION :=====-->


    <!--=====: ADDRESS SECTION :=====-->
    <!-- READ ONLY -->
    <div class="address-info">
        <div class="alert alert-success notification" role="alert"
            *ngIf="alertData==='contactInfo' && alertResponse !=''"
            [ngClass]="alertResponse==='success' ? 'alert-success':'alert-secondary'">
            <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> {{alertMsg}} <button type="button"
                    class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>
        </div>
        <div class="container data-header">
            <div class="row">
                <div class="col-10 data-header-align">
                    <i class="icon icon-location"></i>
                    <span>Address Book</span>
                </div>
                <div class="col-2 data-header-align data-edit">
                    <div class="edit-link" (click)="Edit(Address)"><i class="icon icon-arrow-right"></i>
                        <span>{{!EditAddress?"Edit":"Cancel"}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container data-content" *ngIf="!EditAddress">
            <div class="alert alert-success notification" role="alert"
                *ngIf="alertData==='Address' && alertResponse !=''"
                [ngClass]="alertResponse==='success' ? 'alert-success':'alert-secondary'">
                <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> {{alertMsg}} <button type="button"
                        class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4 data-content-header">
                        Private
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4">
                        {{street}}<br />
                        {{strSuppl1}}<br />
                        {{districtName}}<br />
                        {{city}}<br />
                        {{postalCode}}<br />
                    </div>
                </div>
            </div>
        </div>
        <!-- END READ ONLY -->


        <!-- EDIT -->
        <div class="container edit" *ngIf="EditAddress">
            <form [formGroup]="addressForm">
                <div class="row">
                    <div class="col-12 form-field">
                        <label class="form-label">Street*</label>
                        <input type="text" placeholder="" name="street" formControlName="street"
                            [ngClass]="{ 'error': submitted && a['street'].errors }">
                        <span *ngIf="submitted && a['street'].errors" class="help-block form-error">
                            <span *ngIf="a['street'].errors['required']">This is a required field</span>
                        </span>
                    </div>
                    <div class="col-12 form-field">
                        <label class="form-label">House Number</label>
                        <input type="text" placeholder="" name="house-number">
                    </div>
                    <div class="col-12 form-field">
                        <label class="form-label">Additional Address Line 1</label>
                        <input type="text" placeholder="" name="additional-address-line-1" formControlName="strSuppl1">
                    </div>
                    <div class="col-12 form-field">
                        <label class="form-label">Suburb*</label>
                        <input type="text" placeholder="" name="districtName" formControlName="districtName"
                            [ngClass]="{ 'error': submitted && a['districtName'].errors }">
                        <span *ngIf="submitted && a['districtName'].errors" class="help-block form-error">
                            <span *ngIf="a['districtName'].errors['required']">This is a required field</span>
                        </span>
                    </div>
                    <div class="col-12 form-field">
                        <label class="form-label">City*</label>
                        <input type="text" placeholder="" name="city" formControlName="city"
                            [ngClass]="{ 'error': submitted && a['city'].errors }">
                        <span *ngIf="submitted && a['city'].errors" class="help-block form-error">
                            <span *ngIf="a['city'].errors['required']">This is a required field</span>
                        </span>
                    </div>
                    <div class="col-12 form-field">
                        <label class="form-label">Postal Code*</label>
                        <input type="text" placeholder="" name="postalCode" formControlName="postalCode"
                            [ngClass]="{ 'error': submitted && a['postalCode'].errors }">
                        <span *ngIf="submitted && a['postalCode'].errors" class="help-block form-error">
                            <span *ngIf="a['postalCode'].errors['required']">This is a required field</span>
                        </span>
                    </div>
                    <div class="col-12 form-field">
                        <label class="form-label">Country</label>
                        <select name="country" formControlName="country"
                            [ngClass]="{ 'error': submitted && a['country'].errors }">
                            <option value="Select Country">Select Country</option>
                            <option value="ZA">South Africa</option>
                        </select>
                        <span *ngIf="submitted && a['country'].errors" class="help-block form-error">
                            <span *ngIf="a['country'].errors['required']">This is a required field</span>
                        </span>
                    </div>
                    <!-- <div class="col-12 form-field">
                        <label class="form-label">Region Code</label>
                        <input type="text" placeholder="" name="city" formControlName="initial"  [ngClass]="{ 'error': submitted && a['initial'].errors }">
                        <span *ngIf="submitted && a['dob'].errors" class="help-block form-error">
                          <span *ngIf="a['dob'].errors['required']">This is a required field</span>
                        </span>
                    </div> -->
                    <div class="col-12 mandatory">
                        *Mandatory Fields
                    </div>
                    <div class="component-buttons-links link">
                        <div class="row">
                            <div class="col-6">
                                <button class="buttons button-ghost" (click)="Edit(Address)">
                                    Cancel
                                </button>
                            </div>
                            <div class="col-6">
                                <button class="buttons button-primary" (click)="submitAddressData()"
                                    [ngClass]="loading==true?'loading':''">
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- END EDIT -->
    </div>
    <!--=====: END ADDRESS SECTION :=====-->
</div>