<div class="modal fade" id="mobicredModal" tabindex="-1" role="dialog" aria-labelledby="mobicredModal" aria-hidden="true" [ngClass]="brand=='MINI'?'md-container':''">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="mobicredModalLabel">MOBICRED</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p>Mobicred is an online credit facility that allows you to make a purchase upfront and repay the amount in monthly instalments. If you don't have a Mobicred account yet, you'll need to register on Mobicred's website and complete your application. Once approved, you can purchase using Mobicred. </p>
                <p><strong>Please Note:</strong> Your Mobicred application may take up to 2 business days to process</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="buttons btn-secondary" data-bs-dismiss="modal" style="max-width: 285; width: 285px;">Cancel</button>
                <a href="https://www.mobicred.co.za" class="buttons button-primary" target="_blank" [ngClass]="{'text-color-mini': brand === 'MINI'}">Visit Mobicred Website</a>
            </div>
        </div>
    </div>
</div>
