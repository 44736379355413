import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router";
import { MyPlansService } from '../../../_services/my-plans.service';
import { DataServiceService } from '../../../_services/data-service.service';

@Component({
  selector: 'app-dash-my-plans',
  templateUrl: './dash-my-plans.component.html',
  styleUrls: ['./dash-my-plans.component.less']
})
export class DashMyPlansComponent implements OnInit {

  constructor(private router: Router, private myPlansSvc: MyPlansService, private dataSvc: DataServiceService, 
    private cdr: ChangeDetectorRef) { }
  @Input() incomingVIN: any = {};

  @Input()
  myPlanContractDataToChild: any;

  @Input() 
  brandFromParent: any;

  start_price: any = '';
  selected_Vehicle: any = {};
  ngOnChanges(changes: SimpleChanges) {
    this.cdr.markForCheck();
  }

  ngOnInit(): void {

  }

  gotMyPlans(data: any) {
    if (data) {
      this.dataSvc.setMyPlans(data, "S");
      this.router.navigate(['/my-plans']);
    }
  }
}
