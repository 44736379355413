import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { map, delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerProfileService {
  httpClient: HttpClient;
  get_gcdm_config: any;
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.httpClient = http;
    this.get_gcdm_config = this.authenticationService.get_config();
  }
  getCustomerInfo(token: string): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + token });
    var configs = { headers: headers };
    return this.http.get<any>(environment.apiURL + "/customerportalapi/rest/cp/crm/customer/get-customer/customer", configs)
      .pipe(map(data => data as any));
  }

  getOnDemandGetCustInfo(id: string, idType: string): Observable<any> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN'),
      'idNo': id, 'idType': idType
    });
    var configs = { headers: headers };
    return this.http.get<any>(environment.apiURL+ "/customerportalapi/rest/cp/crm/customer/search-customer/customer", configs)
      .pipe(map(data => data as any));
  }

}
