<div>
    <h1>Preferences</h1>
    <div class="text-copy">
        <p>Your preferences are important to us. Here you can manage how we use your data and how you'd like to be contacted by us.</p>
    </div>
</div>
<div class="data-container" [ngClass]="brand=='MINI'?'md-container':''">
    <div class="bmw-nsc-info">
        <div class="container data-header">
            <div class="row">
                <div class="col-10 data-header-align">
                    <span>{{brand}} (South Africa) (Pty) Ltd</span>
                </div>
                <div class="col-2 data-header-align data-edit">
                    <div class="edit-link" (click)="Edit('BMW_Data')" *ngIf="!Edit_BMW_Data"><i class="icon icon-arrow-right"></i> Edit</div>
                    <div class="edit-link" (click)="Cancel('BMW_Data')"*ngIf="Edit_BMW_Data"><i class="icon icon-arrow-right"></i> Cancel</div>
                </div>
            </div>
        </div>
        <div class="container data-content">
            <div class="alert notification" role="alert" *ngIf="alertData==='BMW_Data' && alertResponse !='' " [ngClass]="alertResponse==='success' ? 'alert-success':'alert-secondary'">
                <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> {{alertMsg}} <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>
            </div>

            <div class="row">
                <div class="col-12 preference-container" *ngFor="let nsc_preference of nscConsentsData">
                    <div class="row"  *ngIf="nsc_preference?.usageId !== 'DATAPROCESSING'">
                        <div class="col-9 p-0">
                            {{nsc_preference?.label}}
                        </div>
                        <div class="col-3 p-0 toggle">
                            <label class="switch" >
                                <input type="checkbox" [checked]="nsc_preference?.consent === 'ACCEPT'" (change)="toggleVisibilityNsc($event, nsc_preference)"  [disabled]="!Edit_BMW_Data">
                                <span class="slider"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="action-button" *ngIf="Edit_BMW_Data">
                <button class="buttons button-primary" (click)="updateNscConsentsData()">Submit</button>
            </div>
        </div>
    </div>
    <div class="bmw-sf-info">
        <div class="container data-header">
            <div class="row">
                <div class="col-10 data-header-align">
                    <span>{{brand}} Finance (South Africa) (Pty) Ltd</span>
                </div>
                <div class="col-2 data-header-align data-edit">
                    <div class="edit-link" (click)="Edit('BMW_Finance_Data')" *ngIf="!Edit_BMW_Finance_Data"><i class="icon icon-arrow-right"></i> Edit</div>
                    <div class="edit-link" (click)="Cancel('BMW_Finance_Data')"*ngIf="Edit_BMW_Finance_Data"><i class="icon icon-arrow-right"></i> Cancel</div>
                </div>
            </div>
        </div>
        <div class="container data-content">
            <div class="alert notification" role="alert" *ngIf="alertData==='BMW_Finance_Data' && alertResponse !='' " [ngClass]="alertResponse==='success' ? 'alert-success':'alert-secondary'">
                <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> {{alertMsg}} <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>
            </div>
            <div class="row">
                <div class="col-12 preference-container" *ngFor="let sf_preference of sfConsentsData">
                    <div class="row"  *ngIf="sf_preference?.usageId !== 'DATAPROCESSING'">
                        <div class="col-9 p-0">
                            {{sf_preference?.label}}
                        </div>
                      <div class="col-3 p-0 toggle">
                        <label class="switch" >
                          <input type="checkbox" [checked]="sf_preference?.consent === 'ACCEPT'" (change)="toggleVisibilitySf($event, sf_preference)"  [disabled]="!Edit_BMW_Finance_Data">
                          <span class="slider"></span>
                        </label>
                      </div>
                    </div>
                </div>
            </div>
            <div class="action-button" *ngIf="Edit_BMW_Finance_Data">
                <button class="buttons button-primary" (click)="updateSfConsentsData()">Submit</button>
            </div>
        </div>
    </div>
</div>
