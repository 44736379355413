import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from "@services/user.service";
import {environment} from "../../../environments/environment";
import {DataServiceService} from "@services/data-service.service";
import {AuthenticationService} from "@services/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  state: string | undefined;
  brand: string | undefined;

  constructor(
    private router: Router,
    private userSvc: UserService,
    private dataSvc: DataServiceService,
    private authSvc:AuthenticationService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // if(window.location.href.indexOf("localhost") != -1){
    //   return true;
    // }
    // else {
      let userServiceObservable =  this.userSvc.getGcdmCustomerProfile();
      userServiceObservable.subscribe((customerDetails) => {
        customerDetails.forEach((o: any) => {

          if( o !== undefined){
            return true;
          } else {
            if(window.location.href.indexOf("bmw-motorrad") != -1){
              this.state = 'BkkeDNO5xRoRgjikttsWBQceISjKICdwUjKjINtb0CMMFp4Nq4Xg9ekVOSHq7WWw';
              this.brand = "bmwmotorrad";
            } else
            if(window.location.href.indexOf("mini") != -1){
              this. state = 'uOkjjlgKFQjlghax3OckMAshsqTHwsUl4SbB0NgFBI0fl187V5zpsICFUn9jhoTA';
              this.brand = "mini";
            } else
            if(window.location.href.indexOf("bmw") != -1){
              this.state = 'mlkKWXGMmpJ1h2G11V8SR2Te1ebnsyHkDeIZjLcSVzoTz8o7JAe3SzqNLourxQOU';
              this.brand = "bmw";
            }
            this.router.navigate(["/"]).then(result=>{window.location.href = environment.gcdm_login_env_hostname+"/oneid/#/login?client=customerportal&country=ZA&language=en&brand="+this.brand+"&scope=authenticate_user%20openid&response_type=code&redirect_uri="+window.location.origin+"%2Fgcdm%2Findex%2Fauth&state="+this.state;});
            return false;
          }
        })
      }, (error: any) => {
        if(error.status === 401){
          this.doGcdmLogout();
        }
        if(window.location.href.indexOf("bmw-motorrad") != -1){
          this.state = 'BkkeDNO5xRoRgjikttsWBQceISjKICdwUjKjINtb0CMMFp4Nq4Xg9ekVOSHq7WWw';
          this.brand = "bmwmotorrad";
        } else
        if(window.location.href.indexOf("mini") != -1){
          this. state = 'uOkjjlgKFQjlghax3OckMAshsqTHwsUl4SbB0NgFBI0fl187V5zpsICFUn9jhoTA';
          this.brand = "mini";
        } else
        if(window.location.href.indexOf("bmw") != -1){
          this.state = 'mlkKWXGMmpJ1h2G11V8SR2Te1ebnsyHkDeIZjLcSVzoTz8o7JAe3SzqNLourxQOU';
          this.brand = "bmw";
        }
        this.router.navigate(["/"]).then(result=>{window.location.href = environment.gcdm_login_env_hostname+"/oneid/#/login?client=customerportal&country=ZA&language=en&brand="+this.brand+"&scope=authenticate_user%20openid&response_type=code&redirect_uri="+window.location.origin+"%2Fgcdm%2Findex%2Fauth&state="+this.state;});
        return false;
      })
      return true;
    }
  doGcdmLogout() {
    this.dataSvc.removeItems();
    this.dataSvc.clearStore();
    sessionStorage.clear();
    this.authSvc.gcdmLogOff().subscribe();
    window.location.href = environment.gcdm_login_env_hostname+"/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2Fwww.bmw.co.za%2Fen%2Findex.html";
  }
  // }

}
