import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { ServiceEnquiryComponent } from './_components/service-enquiry/service-enquiry.component';
import { ProfileComponent } from './_components/profile/profile.component';
import { ProfilePersonalDataComponent } from './_components/profile/profile-personal-data/profile-personal-data.component';
import { ProfileServicePartnerComponent } from './_components/profile/profile-service-partner/profile-service-partner.component';
import { ProfilePreferencesComponent } from './_components/profile/profile-preferences/profile-preferences.component';
import { MyPlansComponent } from './_components/my-plans/my-plans.component';
import { CheckoutPayOptionsComponent } from './_components/checkout-pay-options/checkout-pay-options.component';
import { PlansOffersComponent } from "./_components/plans-offers/plans-offers.component";
import { GcdmWorkerComponent } from './_components/gcdm-worker/gcdm-worker.component';
import { ServiceOnlineComponent } from "./_components/service-online/service-online.component";
import { MyPlansResolver } from './_resolvers/my-plans.resolver';
import { OnDemandComponent } from './_components/on-demand/on-demand.component';
import { ProspectComponent } from './_components/prospect/prospect.component';
import { PlansOffersResolver } from './_resolvers/plans-offers.resolver';
import {AuthGuardGuard} from "./_helpers/_guard/auth-guard.guard";
import {NextStepComponent} from "./_components/checkout-pay-options/next-step/next-step.component";
import { PstemlComponent } from './_components/psteml/psteml.component';
import { RenewMotorplanComponent } from './_components/renew-motorplan/renew-motorplan.component';

export const routes: Routes = [
  {
    path: 'gcdm/index/auth',
    component: GcdmWorkerComponent,
  },

  {
    path: 'service-dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'prospect',
    component: ProspectComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'already-a-bmw-owner',
    component: OnDemandComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'prospect/already-a-bmw-owner',
    redirectTo: '/already-a-bmw-owner',
    pathMatch: 'full',
    canActivate: [AuthGuardGuard]
  },
  {
    path: '',
    redirectTo: 'service-dashboard',
    pathMatch: 'full',
    component: DashboardComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'service-options-enquiry',
    component: ServiceEnquiryComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'serviceonline',
    component: ServiceOnlineComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'renew-motorplan',
    component: RenewMotorplanComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardGuard],
    children: [
      {
        path: 'personal-data',
      component: ProfilePersonalDataComponent,
        canActivate: [AuthGuardGuard]
      },
      {
        path: 'service-partner',
        component: ProfileServicePartnerComponent,
        canActivate: [AuthGuardGuard]
      },
      {
        path: 'preferences',
        component: ProfilePreferencesComponent,
        canActivate: [AuthGuardGuard]
      },
      // {
      //   path: '',
      //   redirectTo: '/profile/personal-data',
      //   pathMatch: 'full',
      // }
      {
        path: '', redirectTo:'gcdm/index/auth',  pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: 'gcdm/index/auth',
        pathMatch: 'full'
      }
    ],
  },
  {
    path: 'my-plans',
      component: MyPlansComponent,
    canActivate: [AuthGuardGuard],
      resolve: {
        data: MyPlansResolver
      }
  },
  {
    path: 'checkout-options',
      component: CheckoutPayOptionsComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'payment-status',
    component: NextStepComponent,
  },
  {
    path: 'plans-offers',
      component: PlansOffersComponent,
    canActivate: [AuthGuardGuard],
      resolve: {
        data: PlansOffersResolver
      }
  },
  {
    path: 'psteml/:guid', 
    component: PstemlComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
