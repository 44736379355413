import {HttpBackend, HttpClient, HttpHeaders, HttpXsrfTokenExtractor} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TyreOptionsService {
  httpClient: HttpClient;
  get_gcdm_config: any;
  private shttpClient: HttpClient;

  constructor(private http:HttpClient, private sHttp: HttpBackend, private authSvc: AuthenticationService,  private tokenExtractor: HttpXsrfTokenExtractor) {
    this.httpClient = http;
    this.shttpClient = new HttpClient(sHttp);
    this.get_gcdm_config = this.authSvc.get_config();
  }

  getVehicleTyreSearch(vin:string): Observable<any>{
    let csrfToken = this.tokenExtractor.getToken() as string;
    let headers = new HttpHeaders({'Content-Type': 'application/json',  'accept': 'application/json', "X-XSRF-TOKEN" : csrfToken, 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
    let configs = {headers: headers};

    return this.http.get<any>(environment.apiURL+"/customerportalapi/rest/cp/vehicles/${vehicleId}/vehicle-tyre-search?vehicleId="+vin, configs)
   .pipe(map(data =>  data as any));
  }

}
