import {Component, OnInit, Inject, Renderer2, EventEmitter, Output, Input, ChangeDetectorRef,  HostListener, ElementRef, ViewChild, SimpleChanges} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CosyService } from '../../_services/cosy.service';
import { DOCUMENT } from '@angular/common';
import {VehicleInfoService} from "@services/vehicle-info.service";
import { DataServiceService } from '@services/data-service.service';

@Component({
  selector: 'app-vehicle-search',
  templateUrl: './vehicle-search.component.html',
  styleUrls: ['./vehicle-search.component.less']
})
export class VehicleSearchComponent implements OnInit {

  @Input()
  searchVehicleInfoFromParent: any;

  @Output()
  vehicleChanged: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('vehicleSearch') vehicleSearch!: ElementRef | null;

  constructor(@Inject(DOCUMENT) private document: Document,
  private cosySvc: CosyService,   private vehSvc: VehicleInfoService, private elRef: ElementRef, private dataSvc: DataServiceService,private cdr: ChangeDetectorRef) { }
  searchResults: boolean = false;
  vehicle_results: any = [];
  searchVIN: string = '';
  currentVehicle: string = '';
  filterargs = {};

  ngOnChanges(changes: SimpleChanges) {
    this.cdr.markForCheck();
  }
  
  ngAfterViewInit() {
    // Get a reference to the form element after it has been initialized
    if (this.vehicleSearch) {
      this.vehicleSearch.nativeElement;
    }
  }

  searchForm = new FormGroup({
    searchField: new FormControl('', Validators.required)
  });

  

  get f() {
    return this.searchForm.controls;
  }

  ngOnInit(): void {

  }

  showSearch() {
    this.searchResults = true;
    if(this.searchResults){
    this.searchForm.setValue({ searchField: '' });

    }
  }

  hideSearch() {
    this.searchResults = false;

  }

  searchVehicle(i: any) {

    if (i.target.value.length > 0) {
      this.searchResults = true;
    }
    else {
    }
  }

  goToOverview(data:any) {
    if (data != null) {
      this.dataSvc.clearVehicleData();
      this.searchResults = false;
      this.vehicleChanged.emit(data);
    }
  }
}
