<div class="modal fade" id="motorplanModal" aria-hidden="true" aria-labelledby="motorplanModal" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">EXTEND MOTORPLAN</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <iframe width="100%" height="1000" frameBorder="0"  [src]="urlSafe" #myIframe></iframe>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</div>