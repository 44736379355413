<div class="col-12 service-partner-component componentWrapper" [ngClass]="brandFromParent==='MINI'?' d-none d-sm-block':''">
    <div class="col-12 component-header">
        <h4>SERVICE PARTNER</h4>
    </div>
    <div class="col-12 component-content">
        <div class="service-partner-map">
          <div class="map-container">
            <google-map #myGoogleMap  height="100%" width="100%" [center]="center" [zoom]="zoom" [options]="options" >
              <map-marker #marker="mapMarker" [position]="center" [options]="markerOptions" >
              </map-marker>
            </google-map>
          </div>
            <div class="retailer-details">
                <div class="preferred-indicator">
                    <div class="preferred-indicator-label">Preferred</div>
                </div>
                <div class="row detailsWrapper">
                    <div class="col-6 detailItem">
                        <i class="icon iconImg " [ngClass]="brandFromParent==='MINI'?'md-icn-map-pin-user':'icon-location-pin'"></i>
                        <div class="retailer-name">{{servicePartnerDetailsToChild?.dealerName}}</div>
                    </div>
                    <div class="col-6 detailItem">
                        <i class="icon iconImg " [ngClass]="brandFromParent==='MINI'?'md-icn-phone':'icon-telephone'"></i>
                        <!-- <div>{{servicePartnerDetailsToChild.dealerTel}}</div> -->
                        <a href="tel:{{servicePartnerDetailsToChild?.dealerTel}}" target="_blank">{{servicePartnerDetailsToChild?.dealerTel}}</a>
                    </div>
                    <div class="col-6 detailItem">
                        <i class="icon iconImg " [ngClass]="brandFromParent==='MINI'?'md-icn-email-open':'icon-email'"></i>
                        <a href="mailto:{{servicePartnerDetailsToChild?.dealerEmail}}" target="_blank">Contact via e-mail</a>
                    </div>
                    <div class="col-6 detailItem">
                        <i class="icon iconImg " [ngClass]="brandFromParent==='MINI'?'md-icn-globe':'icon-internet'"></i>
                        <!-- <div>{{servicePartnerDetailsToChild.dealerWebsite}}</div> -->
                        <a href="{{servicePartnerDetailsToChild?.dealerWebsite}}" target="_blank">Visit retailer website</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 component-buttons-links link">
        <a routerLink="/profile/service-partner" [class.simple-link]="brandFromParent==='MINI'">Change {{brandFromParent}} Service Partner <i class="icon icon-arrow-right" *ngIf="brandFromParent==='MINI'"></i></a>
    </div>
    <!-- <a class="simple-link" data-bs-toggle="modal" href="#preferredRetailer"><i
        class="icon icon-arrow-right"></i>
    View Retailer</a> -->
</div>

<div class="d-sm-none service-partner-sm-component componentWrapper" *ngIf="brandFromParent==='MINI'">
    <div class="component-header">
        <h4>SERVICE PARTNER</h4> 
        <a routerLink="/profile/service-partner"><i class="icon iconImg md-icn-edit"></i></a>
    </div>

    <div class="retailer-details">
        <div class="row detailsWrapper mx-0">
            <div class="col-6 detailItem">
                <i class="icon iconImg " [ngClass]="brandFromParent==='MINI'?'md-icn-map-pin-user':'icon-location-pin'"></i>
                <div class="retailer-name">{{servicePartnerDetailsToChild?.dealerName}}</div>
            </div>
            <div class="col-6 detailItem">
                <i class="icon iconImg " [ngClass]="brandFromParent==='MINI'?'md-icn-phone':'icon-telephone'"></i>
                <!-- <div>{{servicePartnerDetailsToChild.dealerTel}}</div> -->
                <a href="tel:{{servicePartnerDetailsToChild?.dealerTel}}" target="_blank">{{servicePartnerDetailsToChild?.dealerTel}}</a>
            </div>
            <div class="col-6 detailItem">
                <i class="icon iconImg " [ngClass]="brandFromParent==='MINI'?'md-icn-email-open':'icon-email'"></i>
                <a href="mailto:{{servicePartnerDetailsToChild?.dealerEmail}}" target="_blank">Contact via e-mail</a>
            </div>
            <div class="col-6 detailItem">
                <i class="icon iconImg " [ngClass]="brandFromParent==='MINI'?'md-icn-globe':'icon-internet'"></i>
                <!-- <div>{{servicePartnerDetailsToChild.dealerWebsite}}</div> -->
                <a href="{{servicePartnerDetailsToChild?.dealerWebsite}}" target="_blank">Visit retailer website</a>
            </div>
        </div>
    </div>

    </div>