import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { DataServiceService } from '@services/data-service.service';
import { DealerService } from "@services/dealer.service";

@Component({
  selector: 'app-profile-service-partner',
  templateUrl: './profile-service-partner.component.html',
  styleUrls: ['./profile-service-partner.component.less']
})
export class ProfileServicePartnerComponent implements OnInit {

  title = 'Charging Station Locator';

  @ViewChild('search')
  public searchElementRef!: ElementRef;

  @ViewChild('dealerSearch')
  public dealerSearchElementRef!: ElementRef;

  @ViewChild(GoogleMap)
  public map!: GoogleMap;

  @ViewChild(MapInfoWindow, { static: false })
  infoWindow!: MapInfoWindow | undefined;

  markerPositions: any[] = [];
  nearestDealerDistances: any = [];
  showNearestDistances: boolean = false;
  dealerSearchInput: any = "";
  searchInput: any = "";
  brand: string = "";

  constructor(private ngZone: NgZone, private serviceDealerSvc: DealerService, private dataSvc: DataServiceService) {

    this.brand = this.dataSvc.getBrand();

    this.serviceDealerSvc.getDealerList(this.brand).subscribe((data) => {

      let testdata;
      data.forEach((o: any) => {
        o.dealers.forEach((d: any) => {
          testdata = d;
          this.markerPositions.push(
            {
              lat: Number(d.geoCoordinates.latitude),
              lng: Number(d.geoCoordinates.longitude),
              dealerDetails: {
                dealerCode: d.dealerCode,
                dealerName: d.dealerName,
                emailAddress: d.emailAddress,
                faxNumber: d.faxNumber,
                postalAddress: d.postalAddress,
                streetAddress: d.streetAddress,
                telephoneNumber1: d.telephoneNumber1,
                telephoneNumber2: d.telephoneNumber2,
                Website: d.url
              }
            });
        })
      })

      this.selectedDealer();
    })

    this.markerOptions = {
      draggable: false,
      icon: {
        url: './assets/'+this.brand+'/images/location-pin.png'
      }
    };

  }

  display: any;
  alertData: string = '';
  alertResponse: string = '';
  alertMsg: string = '';
  loading: boolean = false;
  center: google.maps.LatLngLiteral = {
    lat: -28.521469312909,
    lng: 25.369652965690502
  };
  zoom = 6;
  maxZoom = 15;
  minZoom = 4;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDefaultUI: true,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    streetViewControl: false,
    maxZoom: this.maxZoom,
    minZoom: this.minZoom,
  };

  latitude!: any;
  longitude!: any;
  country!: any;
  marker!: any;

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }
  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }
  markerOptions: google.maps.MarkerOptions = {};

  addMarker(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.markerPositions.push(event.latLng.toJSON());
  }

  openInfoWindow(marker: MapMarker) {
    if (this.infoWindow != undefined) this.infoWindow.open(marker);
  }

  showChargeDetailsCard: boolean = false;
  showPreferedDealer: boolean = false;
  dealerDetails: any;
  preferedDetails: any;

  showchargeDetails(dealerDetailsPayload: any, chargeID: any) {
    this.dealerDetails = dealerDetailsPayload.dealerDetails;
    this.showChargeDetailsCard = true;
    this.showNearestDistances = false;
    this.dealerSearchInput = "";
    this.searchInput = "";
  }

  Goback() {
    this.showChargeDetailsCard = false;
    this.showNearestDistances = true;
  }


  ngAfterViewInit() {
    let autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
      { componentRestrictions: { country: ['ZA'] } }
    );
    let dealerSearchAutocomplete = new google.maps.places.Autocomplete(
      this.dealerSearchElementRef.nativeElement,
      { componentRestrictions: { country: ['ZA'] } }
    );

    dealerSearchAutocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        this.searchInput = "";
        //get the place result
        let dealerLocation: google.maps.places.PlaceResult = dealerSearchAutocomplete.getPlace();

        //verify result
        if (dealerLocation.geometry === undefined || dealerLocation.geometry === null) {
          return;
        }

        //set latitude, longitude and zoom
        this.latitude = dealerLocation.geometry.location?.lat();
        this.longitude = dealerLocation.geometry.location?.lng();
        this.center = {
          lat: this.latitude,
          lng: this.longitude,
        };
        let isDealerList = false;
        this.zoom = 12;
        this.getNearestDealers(isDealerList, this.latitude, this.longitude);
      });
    });

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        //get the place result
        this.dealerSearchInput = "";
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        //set latitude, longitude and zoom
        this.latitude = place.geometry.location?.lat();
        this.longitude = place.geometry.location?.lng();
        this.center = {
          lat: this.latitude,
          lng: this.longitude,
        };
        let isDealerList = true;
        this.zoom = 12;
        this.getNearestDealers(isDealerList, this.latitude, this.longitude);
      });
    });

  }

  getNearestDealers(isDealerList: boolean, latitude: any, longitude: any) {

    let nearestDistances: any = [];
    this.markerPositions.forEach((o: any) => {
      let loc = new google.maps.LatLng(o.lat, o.lng);
      let place = new google.maps.LatLng(latitude, longitude);
      let distance = Math.round(google.maps.geometry.spherical.computeDistanceBetween(place, loc));
      let km = (distance / 1000).toFixed(1);


      if (!isDealerList) {
        if (Math.round(Number(Number(km) < 1))) {
          nearestDistances.push({ dealer: o, distance: Math.round(Number(km)) })
        }
      } else {
        if (Math.round(Number(Number(km) < 50))) {
          nearestDistances.push({ dealer: o, distance: Math.round(Number(km)) })
        }
      }


    });
    this.showNearestDistances = true;
    this.showChargeDetailsCard = false;
    this.showPreferedDealer = false;


    const sortByDistance = nearestDistances.sort((a: { distance: any; }, b: { distance: any }) => {
      let distanceA = new Number(a.distance);
      let distanceB = new Number(b.distance);
      // @ts-ignore
      return distanceA - distanceB;
    });

    this.nearestDealerDistances = sortByDistance;
  }

  ngOnInit() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      this.zoom = 12;
    });

  }

  selectedDealer() {
    let dealerCode = this.dataSvc.getDealerId();
    let getPrefferedDealer = this.markerPositions.find((x) => x.dealerDetails.dealerCode === dealerCode);
    if (getPrefferedDealer) {
      this.preferedDetails = getPrefferedDealer.dealerDetails;
      this.showPreferedDealer = true;
      this.showNearestDistances = false;
      this.showChargeDetailsCard = false;
    }
  }

  setAsPreferedDealer(dealer: any) {
    this.serviceDealerSvc.updateServiceDealer(dealer.dealerCode).subscribe((data: any) => {
      let res = data;
      if (res.evResult === "S") {
        this.dataSvc.setDealerId(dealer.dealerCode);
        this.preferedDetails = dealer;
        this.alertResponse = res.evMessage.toLowerCase();
        this.alertMsg = 'Retailer has been updated successfully';
        setTimeout(() => {
          this.alertMsg = '';
        }, 10000);
      }
      else {
        this.alertResponse = res.evMessage.toLowerCase();
        this.alertMsg = 'Retailer update has failed';
        setTimeout(() => {
          this.alertMsg = '';
        }, 10000);
      }
      this.loading = false;
      this.showPreferedDealer = true;
      this.showNearestDistances = false;
      this.showChargeDetailsCard = false;
    })
  }


  showMessage(target: string, message: string, status: string) {
    if (message != '') {
      this.alertMsg = message;
    }

    if (status != '') {
      this.alertData = target;
      this.alertResponse = status;
    }
    setTimeout(() => {
      this.alertData = '';
      this.alertMsg = '';
      this.alertResponse = '';
    }, 10000);
    this.loading = false;
  }
}