<div>
<h1>Service Partner</h1>
<div class="text-copy">
<p>Here you can select your preferred Service Partner.</p>
</div>
</div>
<div class="data-container" [ngClass]="brand=='MINI'?'md-container':''">
  <div class="container data-header">
    <div class="row">
      <div class="col-12 service-partner-component">
        <div class="alert notification" role="alert" *ngIf="alertMsg !='' && alertResponse !='' "
          [ngClass]="alertResponse==='success' ? 'alert-success':'alert-secondary'">
          <p class="bmw-alert-text"><i class="iconImg icon-info-circle"></i> {{alertMsg}} <button type="button"
              class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></p>
        </div>
        <div class="col-12 component-content">
          <div class="service-partner-map">
            <div class="map-container">
              <google-map #myGoogleMap height="100%" width="100%" [center]="center" [zoom]="zoom" [options]="options"
                (mapClick)="moveMap($event)" (mapMousemove)="move($event)">
                <map-marker #marker="mapMarker" *ngFor="let markerPosition of markerPositions"
                  [position]="markerPosition" [options]="markerOptions"
                  (mapClick)="showchargeDetails(markerPosition,markerPosition.dealerDetails.dealerCode)">
                </map-marker>
                <!-- <map-info-window>{{ markerPositions.lat.titlelat }}</map-info-window> -->
              </google-map>
            </div>
            <!-- <span style="margin-top: 170px;">MAP Section</span> -->
            <div class="retailer-details">
              <div class="service-partner-search">
                <div class="container">
                  <div class="row">
                    <!-- <div class="col-12 form-field">
                                    <input type="text" placeholder="Nearby your current location">
                                </div> -->
                    <!--                                <div>-->
                    <!--                                  <h2>Find a Charging Station</h2>-->
                    <!--                                </div>-->
                    <div class="col-12 form-field">
                      <input type="text" (keydown.enter)="$event.preventDefault()" [(ngModel)]="searchInput"
                        placeholder="Nearby your current location" type="text" #search>
                    </div>
                    <div class="col-12 form-field">
                      <input type="text" (keydown.enter)="$event.preventDefault()" [(ngModel)]="dealerSearchInput"
                        placeholder="Search by Name of Retailer" type="text" #dealerSearch>
                    </div>
                  </div>
                </div>
              </div>
              <!--                            <div class="service-partner-search">-->
              <!--                                <div class="container">-->
              <!--                                    <div class="row">-->
              <!--                                        <div class="col-12 form-field">-->
              <!--                                            <input type="text" placeholder="Nearby your current location">-->
              <!--                                        </div>-->
              <!--                                        <div class="col-12 form-field">-->
              <!--                                            <input type="text" placeholder="Search by Name of Retailer">-->
              <!--                                        </div>-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                            </div>-->
              <div class="service-partner-results" *ngIf="showChargeDetailsCard">
                <div class="row">
                  <div class="col-12">
                    <div class="chargeDetails-close" (click)="Goback()"><i class="icon icon-arrow-left"></i>
                      <span>Back</span></div>
                  </div>
                  <div class="col-12">
                    <div class="result-retailer-container">
                      <h6>{{dealerDetails.dealerName}}</h6>
                      <p>{{dealerDetails.streetAddress.addressLine1}}</p>
                      <div class="contact-info mb-4">
                        <div class="col-12 mb-2">
                          <i class="icon iconImg " [ngClass]="brand=='MINI'?'md-icn-phone':'icon-telephone'"></i>
                          <a href="tel:{{dealerDetails.telephoneNumber1}}"
                            target="_blank">{{dealerDetails.telephoneNumber1}}</a>
                        </div>
                        <div class="col-12 mb-2">
                          <i class="icon iconImg " [ngClass]="brand==='MINI'?'md-icn-email':'icon-email'"></i>
                          <a href="mailto:{{dealerDetails.emailAddress}}"
                            target="_blank">{{dealerDetails.emailAddress}}</a>
                        </div>
                        <div class="col-12">
                          <i class="icon iconImg " [ngClass]="brand==='MINI'?'md-icn-globe':'icon-internet'"></i>
                          <a href="{{dealerDetails.Website}}" target="_blank">{{dealerDetails.Website}}</a>
                        </div>
                      </div>
                      <button type="button" class="buttons button-primary"
                        (click)="setAsPreferedDealer(dealerDetails)">Save as preferred</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="service-partner-results" *ngIf="showPreferedDealer">
                <div class="row">
                  <div class="col-12">
                    <div class="result-retailer-container">
                    <div class="preferred-indicator">
                        <div class="preferred-indicator-label">Preferred</div>
                    </div>
                      <h6>{{preferedDetails.dealerName}}</h6>
                      <p>{{preferedDetails.streetAddress.addressLine1}}</p>
                      <div class="contact-info mb-4">
                        <div class="col-12 mb-2">
                          <i class="icon iconImg " [ngClass]="brand=='MINI'?'md-icn-phone':'icon-telephone'"></i>
                          <a href="tel:{{preferedDetails.telephoneNumber1}}"
                            target="_blank">{{preferedDetails.telephoneNumber1}}</a>
                        </div>
                        <div class="col-12 mb-2">
                          <i class="icon iconImg " [ngClass]="brand==='MINI'?'md-icn-email':'icon-email'"></i>
                          <a href="mailto:{{preferedDetails.emailAddress}}"
                            target="_blank">{{preferedDetails.emailAddress}}</a>
                        </div>
                        <div class="col-12">
                          <i class="icon iconImg " [ngClass]="brand==='MINI'?'md-icn-globe':'icon-internet'"></i>
                          <a href="{{preferedDetails.Website}}" target="_blank">{{preferedDetails.Website}}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="service-partner-results" *ngIf="showNearestDistances">

                <div class="result-retailer-container" *ngFor="let nearestDealerDistance of nearestDealerDistances" [ngClass]="nearestDealerDistance.dealer.dealerDetails.dealerCode === preferedDetails.dealerCode?'preferred':''">

                  <div class="preferred-indicator" *ngIf="nearestDealerDistance.dealer.dealerDetails.dealerCode === preferedDetails.dealerCode">
                    <div class="preferred-indicator-label">Preferred</div>
                  </div>

                  <h6>{{nearestDealerDistance.dealer.dealerDetails.dealerName}}</h6>
                  <p>{{nearestDealerDistance.dealer.dealerDetails.streetAddress.addressLine1}}</p>
                  <span class="action"
                    (click)="showchargeDetails(nearestDealerDistance.dealer, nearestDealerDistance.dealer.dealerDetails.dealerCode)">Select
                    retailer</span>
                </div>
                <!-- <div class="row">
                              <div class="col-12" *ngFor="let nearestDealerDistance of nearestDealerDistances">
                                <div class="result-retailer-container">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="chargeDetails-title">{{nearestDealerDistance.dealer.dealerDetails.dealerName}}</div>
                                    </div>
                                    <div class="col-12">
                                      <div class="chargeDetails-address">{{nearestDealerDistance.dealer.dealerDetails.streetAddress.addressLine1}}</div>
                                    </div>
                                    <div class="col-12">
                                      <div class="chargeDetails-chargeType">Website: {{nearestDealerDistance.dealer.dealerDetails.telephoneNumber1}}</div>
                                    </div>
                                    <div class="col-12">
                                      <div class="chargeDetails-chargeType">Contact No: {{nearestDealerDistance.dealer.dealerDetails.Website}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> -->
              </div>
              <!--                            <div class="service-partner-results">-->
              <!--                                <div class="result-retailer-container">-->
              <!--                                    <div class="row">-->
              <!--                                        <div class="col-12 pb-2">-->
              <!--                                            <div class="retailer-name">BMW Midrand</div>-->
              <!--                                        </div>-->
              <!--                                        <div class="col-12">-->
              <!--                                            <div class="retailer-address">Cnr Allandale & Pretorius RD<br/>Waterfall Ridge</div>-->
              <!--                                        </div>-->
              <!--                                        <div class="col-12 action">-->
              <!--                                            <a href="#">Select retailer</a>-->
              <!--                                        </div>-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                                <div class="result-retailer-container">-->
              <!--                                    <div class="row">-->
              <!--                                        <div class="col-12 pb-2">-->
              <!--                                            <div class="retailer-name">BMW Midrand</div>-->
              <!--                                        </div>-->
              <!--                                        <div class="col-12">-->
              <!--                                            <div class="retailer-address">Cnr Allandale & Pretorius RD<br/>Waterfall Ridge</div>-->
              <!--                                        </div>-->
              <!--                                        <div class="col-12 action">-->
              <!--                                            <a href="#">Select retailer</a>-->
              <!--                                        </div>-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                                <div class="result-retailer-container">-->
              <!--                                    <div class="row">-->
              <!--                                        <div class="col-12 pb-2">-->
              <!--                                            <div class="retailer-name">BMW Midrand</div>-->
              <!--                                        </div>-->
              <!--                                        <div class="col-12">-->
              <!--                                            <div class="retailer-address">Cnr Allandale & Pretorius RD<br/>Waterfall Ridge</div>-->
              <!--                                        </div>-->
              <!--                                        <div class="col-12 action">-->
              <!--                                            <a href="#">Select retailer</a>-->
              <!--                                        </div>-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                                <div class="result-retailer-container">-->
              <!--                                    <div class="row">-->
              <!--                                        <div class="col-12 pb-2">-->
              <!--                                            <div class="retailer-name">BMW Midrand</div>-->
              <!--                                        </div>-->
              <!--                                        <div class="col-12">-->
              <!--                                            <div class="retailer-address">Cnr Allandale & Pretorius RD<br/>Waterfall Ridge</div>-->
              <!--                                        </div>-->
              <!--                                        <div class="col-12 action">-->
              <!--                                            <a href="#">Select retailer</a>-->
              <!--                                        </div>-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                            </div>-->
              <!-- <div class="preferred-indicator">
                                <div class="preferred-indicator-label">Preferred</div>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
