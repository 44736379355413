import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
import { DataServiceService } from '@services/data-service.service';
import { UserService } from "@services/user.service";
import {ServiceOptionsService} from "@services/service-options.service";

@Component({
  selector: 'app-service-online',
  templateUrl: './service-online.component.html',
  styleUrls: ['./service-online.component.less']
})
export class ServiceOnlineComponent implements OnInit {

  serviceFlag: boolean = false;
  urlSafe: SafeResourceUrl | undefined;
  serviceOnlineError: string = '';

  formUrl: string = '';
  brand: string = '';
  constructor(public sanitizer: DomSanitizer,
    private dataSvc: DataServiceService, private userSvc: UserService, private servOpt: ServiceOptionsService) {
    this.brand = this.dataSvc.getBrand();
    this.formUrl = 'assets/'+this.brand+'/page/service.html';
     }

  ngOnInit(): void {

    this.serviceFlag = false;
    this.getRequiredUserInfo();
  }


  getRequiredUserInfo() {
    let selectedVehicle = JSON.parse(this.dataSvc.getSelectedVehicle());
    let userInfo;
    let title:string, email_address:string, name:string, surname:string, vehicle_reg:string, preferred_dealer:string;
    let userServiceObservable = this.userSvc.getGcdmCustomerProfile();
    userServiceObservable.subscribe((customerDetails) => {
      customerDetails.forEach((o: { businessPartner: any; }, i: any) => {

        if (o.businessPartner !== undefined) {
          title = this.titleList(o.businessPartner.salutation);
          name = o.businessPartner.givenName;
          surname = o.businessPartner.surname;
          vehicle_reg = selectedVehicle.vehLpno;
          preferred_dealer = this.dataSvc.getDealerId();


          if (o.businessPartner.communications !== undefined) {
            if (o.businessPartner.communications.communicationEMails !== undefined) {
              o.businessPartner.communications.communicationEMails.forEach((c: any, i: any) => {
                if (c.preferred || c.value) {
                  email_address = c.value;
                }
              });
            }
          } else {
            email_address = ""
          }
          this.switchAPIKeys(this.brand, (apiKey) => {
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.formUrl + '?apiKey=' + apiKey +'title=' + title + '&name=' + name + '&surname=' + surname + '&email_address=' + email_address + '&preferred_dealer=' + preferred_dealer + '&vehicle_reg=' + vehicle_reg);
          })
        }
      })
    })



  }

  titleList(title: string) {
    if (title === 'SAL_MR') {
      return 'Mr'
    }

    if (title === 'SAL_MS') {
      return 'Ms'
    }

    if (title === 'SAL_MRS') {
      return 'Mrs'
    }

    if (title === 'SAL_MISS') {
      return 'Miss'
    }

    if (title === 'SAL_DR') {
      return 'Dr'
    }
    return "";
  }


  switchAPIKeys(brand: string, callback: (key: string) => void) {
    console.log("Brand contact: ", brand);
    if (!brand) {
      brand = "BMW";
    }

    let servOptObservable = this.servOpt.getServiceApiKey(brand);
    servOptObservable.subscribe((key) => {
      callback(key.serviceKey);
    });
  }

}
