import {Component, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, Data, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from '@services/authentication.service';
import { environment } from 'src/environments/environment';
import { DataServiceService  } from '@services/data-service.service';
import { UserService } from '@services/user.service';
@Component({
  selector: 'app-bmw-header',
  templateUrl: './bmw-header.component.html',
  styleUrls: ['./bmw-header.component.less']
})
export class BmwHeaderComponent implements OnInit {

  profileUrl: string = "";
  @Input() headerCustDataChild: any;

  isProspect: boolean = false;
  headerCusName: string= '';
  mergeAccout: boolean = false;
  constructor(private router: Router,
    private authSvc:AuthenticationService,
    private dataSvc: DataServiceService,
    private userSvc: UserService,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute) {
    this.checkIfProspect();
    this.checkIfAccountMerge();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
        this.hideNav();;
        this.checkIfProspect();
        this.checkIfAccountMerge();
      }
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const subMenu = this.elRef.nativeElement.querySelector('.subMenu');
    if (subMenu && subMenu.contains(target) && target.tagName.toLowerCase() === 'a') {
      event.preventDefault();
      this.hideNav();
    }
  }

  loggedinuser: any =  '';
  show_nav: boolean = false;
  show_submenu: boolean = false;

  ngOnInit(): void {
    this.checkIfProspect();
    this.checkIfAccountMerge();
    this.profileUrl = environment.gcdm_login_env_hostname+"/oneid/#/login?client=customerportal&brand=bmw&country=ZA&language=en&redirectFrom=profile";
    let userServiceObservable = this.userSvc.getGcdmCustomerProfile();
    if(sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') ) {
      userServiceObservable.subscribe((customerDetails) => {
        customerDetails.forEach((o:any) => {
            this.headerCusName =  o.givenName;
        })
      })
    }

  }

  showNav() {
    document.body.classList.toggle('nav-open');
    this.show_nav = !this.show_nav;
  }


  hideNav() {
    document.body.classList.remove('nav-open');
    document.body.classList.remove('subMenu-open');
    this.show_nav = false;
  }

  showSubMenu(){
    document.body.classList.toggle('subMenu-open');
    this.show_submenu = !this.show_submenu;
  }


  hideSubMenu(){
    document.body.classList.remove('subMenu-open');
    this.show_submenu = false;
  }

  gotoProfilePage() {
    console.log(this.profileUrl);
    window.open(this.profileUrl, '_blank');
  }

  doGcdmLogout() {
      this.dataSvc.removeItems();
      this.dataSvc.clearStore();
      sessionStorage.clear();
      this.authSvc.gcdmLogOff().subscribe();
      window.location.href = environment.gcdm_login_env_hostname+"/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2Fwww.bmw.co.za%2Fen%2Findex.html";
  }

  checkIfProspect(){
    let prospect = this.dataSvc.getPageRestriction();
    if(prospect === 'prospect'){
      this.isProspect = true;
      this.cdr.markForCheck();
    }
    else{
      this.isProspect = false;
      this.cdr.markForCheck();
    }
  }

  checkIfAccountMerge(){
    if(window.location.pathname.indexOf('psteml')!=-1){
      this.mergeAccout = true;
    }
    else{
      this.mergeAccout = false;

    }
  }
}
