import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataServiceService } from '../../../_services/data-service.service';

@Component({
  selector: 'app-my-plans-ptions-modal',
  templateUrl: './my-plans-ptions-modal.component.html',
  styleUrls: ['./my-plans-ptions-modal.component.less']
})
export class MyPlansPtionsModalComponent implements OnInit {

  @Input()  myPlanDataToChild: any;
  @Input() brandFromParent: any;

  constructor(private dataSvc: DataServiceService, private router: Router) { }

  ngOnInit(): void {
  }
  
  gotMyPlans(data: any) {
    if (data) {     
      this.dataSvc.setMyPlans(data, "S");
      this.router.navigate(['/my-plans']);
    }
  }
}
