import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { VehicleInfoService } from '@services/vehicle-info.service';

@Component({
  selector: 'app-add-vehicle-modal',
  templateUrl: './add-vehicle-modal.component.html',
  styleUrls: ['./add-vehicle-modal.component.less']
})
export class AddVehicleModalComponent implements OnInit {

  constructor(private vehicleInfoSvc: VehicleInfoService) { }

  request_result: string = '';
  alertMsg: string = '';
  submitted: boolean = false;
  loading: boolean = false;
  isHidden:boolean = true;
  selecttedComms: string = '';
  addNewVehicleForm = new FormGroup({
    contactPreference: new FormControl('', Validators.required),
    communicationMethod: new FormControl('', Validators.required),
    vinNumber: new FormControl('', Validators.required),
    regNumber: new FormControl('', Validators.required),
    model: new FormControl('', Validators.required),
    year: new FormControl('', [Validators.required, Validators.pattern(/^(19[5-9]\d|20[0-4]\d|2050)$/)])
  });

  get f() {
    return this.addNewVehicleForm.controls;
  }
  
  @HostListener('hidden.bs.modal') onModalHidden() {    
    this.resetForm();
  }
  ngOnInit(): void {
  }

  submitNewVehicle() {
    this.submitted = true;
    if (this.addNewVehicleForm.invalid) {

      return;
    }
    else {
      this.loading = true;
      let formData = {
        contactpreferences: this.addNewVehicleForm.value.contactPreference,
        communicationMethod: this.addNewVehicleForm.value.communicationMethod,
        chassisnumber: this.addNewVehicleForm.value.vinNumber,
        registrationnumber: this.addNewVehicleForm.value.regNumber,
        year: this.addNewVehicleForm.value.year,
        model: this.addNewVehicleForm.value.model,

      }
      
      this.vehicleInfoSvc.addVehicle(formData).subscribe((data) => {
        
       this.loading = false;
        if (data.saveResult === "I") {
          this.alertMsg = 'Request submitted. One of our Agents will contact U shortly to complete your vehicle addition.';
          this.request_result = "success";
          setTimeout(() => {
            this.request_result = '';
            this.alertMsg = '';
            this.cancelForm();
          }, 5000);
        }
        else {
          this.alertMsg = data.saveMessage;
          this.request_result = "error";
          setTimeout(() => {
            this.request_result = '';
            this.alertMsg = '';
          }, 5000);

        }
        
      });
    }
  }

  cancelForm() {
    let cancelBtn = document.getElementById('cancelBtn') as HTMLElement;
    cancelBtn.click();
  }

  resetForm(value: any = undefined): void {
    this.addNewVehicleForm.reset(value);
    (this as { submitted: boolean }).submitted = false;
    this.request_result = '';
    this.alertMsg = '';
  }

  toggleVisibility(event: any){
    this.isHidden = false;
    this.selecttedComms = event.target.value;
    let phoneField = this.addNewVehicleForm.get('communicationMethod');
    if(this.selecttedComms == 'Phone'){
      phoneField?.setValidators([Validators.required, Validators.pattern(/^(?:\+27\s?\d{2}\s?\d{3}\s?\d{4}|0?\d{2}\s?\d{3}\s?\d{4})$/)])
    }
    else{
      phoneField?.setValidators([Validators.required,  Validators.email])
    }
  }
}
