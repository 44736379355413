<div class="my-plans-component componentWrapper" [ngClass]="brandFromParent=='MINI'?'lightBG md-container':''">
    <div class="row mx-0">
        <div class="col-lg-6 col-md-12 component-header-image">
            <div class="notification" *ngIf="myPlanContractDataToChild && myPlanContractDataToChild?.motorPlanData && myPlanContractDataToChild?.motorPlanData?.contractStatus ==='CS00'">
                <!-- <h6><i class="iconImg icon-info-circle-o"></i> MOTORPLAN CURRENTLY ACTIVE</h6> -->
                <h6><i class="iconImg icon-info-circle-o"></i> MOTORPLAN ACTIVE UNTIL {{myPlanContractDataToChild?.motorPlanData?.endDate}}</h6>
                <!-- <p>This option is only available once your Motorplan contract has expired.</p> -->
            </div>
            <img src="./assets/{{brandFromParent}}/images/my-plans.jpg">
        </div>
        <div class="col-lg-6 col-md-12 component-content-container"  [class.ps-lg-0]="brandFromParent != 'MINI'">
            <div class="col-12 component-header">
                <h3>My {{brandFromParent}} Plans</h3>
            </div>
            <div class="col-12 component-content" *ngIf="myPlanContractDataToChild?.latestPlansData">
                <div class="plan-head">
                    <img src="../../../assets/BMW/images/icons/svg/{{myPlanContractDataToChild?.latestPlansData?.contractType}}.svg" alt="">
                    <div class="head-content">
                        <span>Current Plan:</span>
                        <h5>{{myPlanContractDataToChild?.latestPlansData?.contractTypeDescription}}</h5>
                        <small>Valid From {{myPlanContractDataToChild?.latestPlansData?.validFromDate}} To {{myPlanContractDataToChild?.latestPlansData?.validToDate}}</small>
                    </div>
                </div>
                <ul>
                    <li>For {{myPlanContractDataToChild?.latestPlansData?.contractPeriod | monthsToYear }} year<span *ngIf="myPlanContractDataToChild?.latestPlansData?.contractPeriod>12">s</span></li>
                    <li>CBS indicated<span *ngIf="myPlanContractDataToChild?.latestPlansData?.contractType==='TC200'"> or elective</span></li>
                    <li *ngIf="myPlanContractDataToChild?.latestPlansData?.contractType !=='TC200'">Vehicle check</li>
                    <li *ngIf="myPlanContractDataToChild?.latestPlansData?.contractType ==='TC200'">Engine oil service only</li>
                    <li *ngIf="myPlanContractDataToChild?.latestPlansData?.contractType !=='TC200'">Engine oil service + Spark plugs + Air filters, Pollen filters, Fuel filters<span *ngIf="myPlanContractDataToChild?.latestPlansData?.contractType==='TC220'"> + Brake pads (front & rear)</span>, Brake fluid flush</li>
                </ul>
            </div>

            <div class="col-12 component-content" *ngIf="!myPlanContractDataToChild?.latestPlansData">
                <!-- <p  *ngIf="myPlanContractDataToChild?.motorPlanData?.contractStatus !=='CS00'">Get one brand-new set of 4 tyres including wheel alignment, fitment and balancing, at any time during
                    a {{myPlanContractDataToChild?.lowestPricePlan?.lowestPrice?.contractMonths}}-month period.</p> -->
<!--                    <p>My {{brandFromParent}} Plans offer oil, service or maintenance plans for one upfront payment.</p>-->
              <p>Designed to be a cut above the rest, My {{brandFromParent}} Service Plans take care of your out-of-Motorplan {{brandFromParent}} with a range of exceptional aftersales solutions.</p><p>Choose between 3 years' worth of oil, service or maintenance plans for one upfront payment or monthly installments.</p>
                <a class="simple-link" data-bs-toggle="modal" href="#myPlansOptions" *ngIf="myPlanContractDataToChild?.displayButton"><i
                        class="icon icon-arrow-right"></i>
                    View details</a>
            </div>

            <div class="col-12 component-price" *ngIf="myPlanContractDataToChild?.lowestPricePlan">
                <p>Starting at</p>
                <span>{{myPlanContractDataToChild?.lowestPricePlan?.lowestPrice?.vatTotalPrice | currency :"R "}}</span>
            </div>
            <div class="col-12 container component-buttons-links link" *ngIf="!myPlanContractDataToChild?.latestPlansData">
                <div class="row">
                    <div class="col-12 col-sm-8">
                        <a href="https://www.bmw.co.za/en/topics/offers-and-services/service-workshop/my-bmw-plans.html" class="buttons button-ghost" target="_blank" *ngIf="!myPlanContractDataToChild?.displayButton && brandFromParent==='BMW'">
                            Learn more
                        </a>

                        <a href="https://www.mini.co.za/en_ZA/home/explore/myminiplans.html" class="buttons button-ghost" target="_blank" *ngIf="!myPlanContractDataToChild?.displayButton && brandFromParent==='MINI'">
                            Learn more
                        </a>

                        <button (click)="gotMyPlans(myPlanContractDataToChild)" class="buttons button-primary" *ngIf="myPlanContractDataToChild?.displayButton">
                            Buy now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-my-plans-ptions-modal [myPlanDataToChild]="myPlanContractDataToChild" [brandFromParent]="brandFromParent"></app-my-plans-ptions-modal>
