<footer class="bmw-footer">
    <div class="col-12 m-0 bmw-footer-container">
        <div class="container bmw-footer-inner-container">
            <div class="bmw-footer-upper-part">
                <section class="bmw-footer-social-section">
                    <div class="bmw-social-icons">
                        <h4>Social Media</h4>
                        <ul class="soc_right">
                            <li>
                                <a href="https://www.youtube.com/user/BMWSouthAfrica" target="_blank">
                                    <img class="social-icon" src="/assets/BMW/images/icons/socials/youtube-default@2x.png">
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/BMW_SA" target="_blank">
                                    <img class="social-icon" src="/assets/BMW/images/icons/socials/twitter-default@2x.png">
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/BMWSA" target="_blank">
                                    <img class="social-icon" src="/assets/BMW/images/icons/socials/facebook-default@2x.png">
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/bmwsouthafrica" target="_blank">
                                    <img class="social-icon" src="/assets/BMW/images/icons/socials/linkedin-default@2x.png">
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/bmwsouthafrica/" target="_blank">
                                    <img class="social-icon" src="/assets/BMW/images/icons/socials/instagram-default@2x.png">
                                </a>
                            </li>
                            <!-- <li>
                                <a href="https://www.bmw.co.za/en/topics/owners/my-bmw/my-bmw-app-overview.html" target="_blank">
                                    <img class="social-icon" src="/assets/BMW/images/icons/socials/mybmwapp-default@2x.png">
                                </a>
                            </li> -->
                        </ul>
                    </div>
                </section>
            </div>
            
            <!-- <div class="row">
                <div class="divider">&nbsp;</div>
            </div> -->
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="ftr_h">
                        Buyer's Choice
                    </div>
                    <ul class="ftr_lst">
                        <li><a href="https://www.bmw.co.za/en/all-models.html">Build now</a></li>
                        <li><a href="http://www.bmwgroup-media.co.za/digital/documents/pricelist/BMW_Range_Price_List.pdf">Range Price List</a></li>
                        <li><a href="http://www.bmwgroup-media.co.za/digital/documents/brochure/BMW-FullRange.pdf">Range Brochure</a></li>
                    </ul>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="ftr_h">
                        Explore BMW
                    </div>
                    <ul class="ftr_lst">
                        <li><a href="https://www.bmwgroup.jobs/za/en.html">Careers</a></li>
                        <li><a href="https://www.bmw.co.za/en/footer/sitemap.html">Sitemap</a></li>
                        <li><a href="https://www.b2b.bmw.co.za/sap/bc/bsp/sap/zcrmpublic">BMW B2B Portal</a></li>
                        <li><a href="https://www.bmw-motorrad.co.za/en/home.html">BMW Motorrad</a></li>
                        <li><a href="https://www.bmw.com/en/index.html">BMW.com</a></li>
                    </ul>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="ftr_h">
                        Visit Us
                    </div>
                    <ul class="ftr_lst">
                        <li><a href="https://www.youtube.com/user/BMWSouthAfrica">YouTube</a></li>
                        <li><a href="https://twitter.com/BMW_SA">Twitter</a></li>
                        <li><a href="https://www.facebook.com/BMWSA">Facebook</a></li>
                        <li><a href="http://linkd.in/17I4hQ2">LinkedIn</a></li>
                        <li><a href="https://www.instagram.com/bmwsouthafrica/">Instagram</a></li>
                        <li><a href="https://www.bmw.co.za/en/topics/owners/my-bmw/my-bmw-app-overview.html">My BMW App</a></li>
                    </ul>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="ftr_h">
                        Financial Services
                    </div>
                    <ul class="ftr_lst">
                        <li><a href="https://www.bmw.co.za/en/topics/offers-and-services/bmw-financial-services/investor-relations.html">Investor Relations</a></li>
                        <li><a href="https://www.bmw.co.za/en/topics/offers-and-services/bmw-financial-services/terms.html">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="bottom">
        <div class="container btm_lnks bottomNav">
            <span class="copyRight">© BMW SA <!-- {{currentYear()}} -->
                </span>
            <ul class="btm_lst nav">
                <li><a target="_blank" href="https://www.bmw.co.za/en/integration/contact-us.html">Contact Us</a></li>
                <li><a target="_blank" href="https://www.bmw.co.za/en/footer/metanavigation/legal-disclaimer-pool/legal-disclaimer.html">Legal Notice</a></li>
                <li><a target="_blank" href="https://www.bmw.co.za/en/footer/metanavigation/legal-disclaimer-pool/paia-manual.html">PAIA Manual</a></li>
                <li><a target="_blank" href="https://www.bmw.co.za/en/footer/metanavigation/legal-disclaimer-pool/privacy-statement.html">Privacy Statement</a></li>
                <li><a target="_blank" href="https://forms.bmw.co.za/documents/BMW-BBBEE-Certificate.pdf">B-BBEE Certificate</a></li>
                <li><a target="_blank" href="https://www.bmw.co.za/en/footer/metanavigation/legal-disclaimer-pool/notice.html">Notice Board</a></li>
                <li><a target="_blank" href="https://www.bmw.co.za/en/topics/offers-and-services/bmw-financial-services/legislation.html">Compliance</a></li>
                <!-- <li><a ng-class="{active: $state.includes('Cookiepolicy')}" ui-sref="Cookiepolicy">Cookies Policy</a></li> -->
                <li><a [routerLink]="['cookiepolicy']">Cookie Policy</a></li>
            </ul>
        </div>
    </div>
</footer>
