import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HelpersService } from '../../_helpers/helpers.service';
import { MyPlansService } from '../../_services/my-plans.service';
import { DataServiceService } from '../../_services/data-service.service';
import { CosyService } from '../../_services/cosy.service';
import {environment} from "../../../environments/environment";
import {UserService} from "@services/user.service";
import { TronGenericPageTrackingService } from 'src/app/_helpers/TRON-Analytics-tracking/page-tracking/tron-generic-page-tracking.service';
// import { TronGenericFormsTrackingService } from 'src/app/_helpers/TRON-Analytics-tracking/forms-tracking/tron-generic-forms-tracking.service';

@Component({
  selector: 'app-checkout-pay-options',
  templateUrl: './checkout-pay-options.component.html',
  styleUrls: ['./checkout-pay-options.component.less']
})
export class CheckoutPayOptionsComponent implements OnInit {

  constructor(private myPlansSvc: MyPlansService,
    private helpersSvc: HelpersService,
    private dataSvc: DataServiceService,
    private cosySvc: CosyService, private userSvc: UserService,
    private tronGenericPageTrackingService: TronGenericPageTrackingService,
    // private tronGenericFormsTrackingService: TronGenericFormsTrackingService,
    private route: ActivatedRoute) {
      this.brand = this.dataSvc.getBrand();
    }
  @Output() accountTypeChanged = new EventEmitter<String>();
  make_payment: boolean = false;
  payment_res: boolean = false;
  show_res: boolean = false;
  submitted: boolean = false;
  res_msg: string = '';
  cart_result: any = [];
  selected_Service: any = [];
  quotes_result: any = [];
  show_more: boolean = false;
  vehicle_data: any = {};
  vehicle_vin: string = '';
  vehicle_desc: string = '';
  last_service: string = '';
  vehicle_reg: string = '';
  cosyURL: string = '';
  selectedVehicle: any = {};
  downloadInvoice: boolean = false;
  displayBtn: boolean = false;
  checkOutForm = new FormGroup({
    paymentOption: new FormControl('', Validators.required),
    tandcs: new FormControl(false, Validators.required)
  });

  gcdmEmail: string = '';
  brand: string = '';

  get f() {
    return this.checkOutForm.controls;
  }

  ngOnInit(): void {
    console.log("inside ng init");
    let userUserAccountObservable = this.userSvc.getGcdmUserAccountFromASession();
    console.log("oaut toke "+ sessionStorage.getItem("CP_OAUTH_BEARER_TOKEN"));
    if(sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') ) {
      userUserAccountObservable.subscribe((customerDetails) => {
        customerDetails.forEach((o:any) => {
          this.gcdmEmail = o.mail;
        })
      })
    } else {
      this.gcdmEmail = sessionStorage.getItem("customerEmail") ?? '';
    }



    let current_Vehicle = this.dataSvc.getSelectedVehicle();
    if(current_Vehicle !== null && current_Vehicle != undefined) {
      this.selectedVehicle = JSON.parse(current_Vehicle);
      this.getVehicleDetails(this.selectedVehicle);
    }

    let selectedService = this.dataSvc.getSelectedService();
    if(selectedService!== undefined){
      this.selected_Service = JSON.parse(selectedService);
      let paramsData = {
        'productName': this.selected_Service.description,
        'productPrice': this.selected_Service.lowestPrice.orgTotalPrice,
        'cartTotal': this.selected_Service.lowestPrice.vatTotalPrice
      }

      this.checkOutForm.controls['paymentOption'].setValue('C');
      this.cart_result = paramsData;
    }

    // this.tronGenericPageTrackingService.setPageName(`'Checkout Pay Options | Customer Portal'`);
    // this.tronGenericPageTrackingService.setFirstFormInteraction(`'Checkout Pay option Form'`);
  }

  onFormInteraction(): void {
    //setFormStep when the user starts interacting with the form
    // this.tronGenericPageTrackingService.setFormSteps(`'Checkout Pay option Form'`, 2);
  }

  submitQuote() {

    this.submitted = true;
    if (this.checkOutForm.invalid) {
      return;
    }
    else {

      let params = {
        dealerCode: this.dataSvc.getDealerId(),
        lowestPrice: this.selected_Service.lowestPrice,
        odometer: this.selected_Service.odometer,
        vin: this.selected_Service.lowestPrice.vin,
      }

      this.myPlansSvc.doAcceptQuote(params).subscribe((res: { quoteAccepted: boolean; quoteCreated: boolean; }) => {

        if (res.quoteAccepted === true && res.quoteCreated === true) {
          this.quotes_result = res;
          this.make_payment = true;
        }
        else {
          console.log('Something went wrong.');
        }
      })
    }
  }

  createAcceptQuote() {

    this.submitted = true;
    if (this.checkOutForm.invalid) {
      return;
    }
    else {

      let params = {
        dealerCode: this.dataSvc.getDealerId(),
        lowestPrice: this.selected_Service.lowestPrice,
        odometer: this.selected_Service.odometer,
        vin: this.selected_Service.lowestPrice.vin,
      }

      this.myPlansSvc.doAcceptQuote(params).subscribe((res: { quoteAccepted: boolean; quoteCreated: boolean; }) => {

        if (res.quoteAccepted === true && res.quoteCreated === true) {
          this.quotes_result = res;
          this.myPlansSvc.doGenerateQuote("P", this.quotes_result.salesDocument, this.selected_Service.odometer, this.selected_Service.orderType);
        }
        else {
          console.log('Something went wrong.');
        }
      })
    }
  }


  doPayment(){


    this.submitted = true;
    // this.tronGenericPageTrackingService.setFormFinishIntent(`'Checkout Pay option Form'`);
    if (this.checkOutForm.invalid) {
      return;
    }
    else {
      let params = {
        dealerCode: this.dataSvc.getDealerId(),
        lowestPrice: this.selected_Service.lowestPrice,
        odometer: this.selected_Service.odometer,
        vin: this.selected_Service.lowestPrice.vin,
      }
      this.myPlansSvc.doAcceptQuote(params).subscribe((res: {
        acceptedQuote: any;
        quoteAccepted: boolean; quoteCreated: boolean; }) => {


        if (res.quoteAccepted === true && res.quoteCreated === true) {
          if (this.gcdmEmail === undefined) {
            this.gcdmEmail = localStorage.getItem("customerEmail") ?? '';

          }

          let payload = {
            applicationReturnToNOKurl: window.location.origin+"/payment-status?paymentstatus=failed",
            applicationReturnToOKurl: window.location.origin+"/payment-status?paymentstatus=success",
            customerEmail: this.gcdmEmail,
            dealerCode:this.dataSvc.getDealerId(),
             odometer:this.selected_Service.odometer,
            vin:this.selected_Service.lowestPrice.vin,
            quote: res.acceptedQuote,
            customerReturnToURL: window.location.origin+"/payment-status"
          };
          this.quotes_result = res;
          this.make_payment = true;
          this.myPlansSvc.createPaymentRef(payload).subscribe((res: any ) => {
            //setFormFinish before redirecting to the payment gateway
            // this.tronGenericPageTrackingService.setFormFinish(`'${this.brand} Checkout Pay option Form'`);
            window.location.replace(environment.paymentGateway + '/index.html?uuid=' + res.paygateCcTransUuid);
          });
        }
        else {
          console.log('Something went wrong.');
        }
      })
    }

  }

  getVehicleDetails(data: any) {
      if (data !== null || data !== undefined) {
        this.vehicle_vin = data.vinNumber;
        this.vehicle_reg = data.vehLpno;
        this.vehicle_desc = data.modelDesc;
        this.cosyURL = data.cosyURL
      }

  }





  showMore() {
    if(this.show_more === false) {
      this.show_more = true;
    }
    else{
      this.show_more = false;
    }
  }
}
