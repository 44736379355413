import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnDemandLoginBpService } from '@services/on-demand-login-bp.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-psteml',
  templateUrl: './psteml.component.html',
  styleUrls: ['./psteml.component.less']
})
export class PstemlComponent implements OnInit {
  accordion: any;
  loading: boolean = false;
  vehicleServiceHistory: any;
  vehicleServiceHists: any = [];
  guid: string = '';
  returnMessage: any = [];
  state: string = '';
  brand: string = '';
  gcdm_env_hostname: string = '';
  web_site: string = '';
  respMsg: string = '';
  incomingMsg: string = '';

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private ondemandLoginBpService: OnDemandLoginBpService) {
    this.gcdm_env_hostname = environment.gcdm_env_hostname;
    this.activatedRoute.params.subscribe(params => {
      this.guid = params.guid;
    });
  }

  async loadActivationMail(guid: string) {
    let customerProfileObservable = this.ondemandLoginBpService.postActivateOnDamnd(guid);
    customerProfileObservable.subscribe((returnMessage: any) => {
      this.returnMessage = returnMessage;
      this.loading = true;
      this.returnMessage.messages.forEach((l: any) => {
        if (l.msgType === "E") {
          this.incomingMsg = 'failed'
          this.respMsg = "MERGE UNSUCCESSFUL, Your account cannot be merged at sthis time. Please contact 0800 600 555 for assistance.";
        } else {
          this.incomingMsg = 'success'
          this.respMsg = "MERGE IN PROGRESS, We'll notify you via email when the merge is complete. In the meantime, you can still log in to your account.";
        }
      });


      if (window.location.href.indexOf("bmw-motorrad") != -1) {
        // this.state = 'mlkKWXGMmpJ1h2G11V8SR2Te1ebnsyHkDeIZjLcSVzoTz8o7JAe3SzqNLourxQOU';
        // this.brand = "bmw";
        this.web_site = "https://www.bmw-motorrad.co.za";
      }

      if (window.location.href.indexOf("mini") != -1) {
        //    this. state = 'uOkjjlgKFQjlghax3OckMAshsqTHwsUl4SbB0NgFBI0fl187V5zpsICFUn9jhoTA';
        //    this.brand = "mini";
        this.web_site = "https://www.mini.co.za";
      }

      if (window.location.href.indexOf("bmw") != -1) {
        // this.state = 'BkkeDNO5xRoRgjikttsWBQceISjKICdwUjKjINtb0CMMFp4Nq4Xg9ekVOSHq7WWw';
        // this.brand = "bmwmotorrad";
        this.web_site = "https://www.bmw.co.za";
      }

      setTimeout(() => {
        setTimeout(() => {
          this.router.navigate(["/"]).then(result => { window.location.href = this.web_site; });
        });
      }, 6000);

    });
  }
  ngOnInit(): void {
  }

  activateLinked() {
    this.loadActivationMail(this.guid);
  }
}
