import { HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
const mockEndpoints: any = {
    GET: {
        'https://customer-i.bmwgroup.com/gcdm/protected/v4/customerportal/ZA-BMW-en/customers': {
            handler: getGcdmCustomerProfile, getGcdmCustomerProfileOnePage
        },
        'https://www.account-d.bmw.co.za/customerportalapi/rest/cp/crm/customer/on-demand/get-customer-vehicle-bp': {
            handler: getCustomerVehicleBp
        },
        'https://customer-i.bmwgroup.com/pm2/pm-document-service/api/v1/documents/Privacy_Statement/ZA': {
            handler: getPrivacyStatement
        },
        'https://www.account-i.bmw.co.za/customerportalapi/rest/cp/vehicles/$%7BvehicleId%7D/vehicle-tyre-search?vehicleId=WBA5R12080AK73435':{
            handler: vehicleTyreSearch
        },
        'https://www.account-d.bmw.co.za/customerportalapi/rest/cp/vehicles/$%7Bbrand%7D/$%7BmodelCode%7D/vts-data?modelCode=5R12&brand=BM':{
            handler: getVtsdata
        },
        'https://www.account-d.bmw.co.za/WebAPI/vehicle/v1/detail/getciamodelkits?agmodel=&dealerCode=32717&modelYear=2019&pricing=X&vehicleBodyNo=0AK73435':{
            handler: getCiamodelKits
        },
        'https://oasisza-int.bmwgroup.net/oasis/BMWSAGlobalService/Dealers/Dealer/BM/1/32717':{
            handler: getDealerInfo
        },
        'https://www.account-d.bmw.co.za/customerportalapi/rest/cp/vehicles/BMW/hst-dealer-info?brand=BMW':{
            handler: hstDealerInfo
        }
    },
    PUT: {
        'https://www.account-d.bmw.co.za/customerportalapi/rest/cp/crm/customer/get-maintain/dealer': {
            handler: getServiceCodeDealer
        }

    },
    POST: {
        'https://customer-i.bmwgroup.com/gcdm/oauth/token': {
            handler: gcdmLogin
        },
        'https://www.account-d.bmw.co.za/customerportalapi/rest/cp/vehicles/model-from-body': {
            handler: getModelFromBody
        },
        'https://www.account-d.bmw.co.za/customerportalapi/rest/cp/vehicles/motor-plan/get-campaigns': {
            handler: getCampaigns
        },
        'https://www.account-d.bmw.co.za/customerportalapi/rest/cp/vehicles/motor-plan/latestContractDetail': {
            handler: latestContractDetail
        },
        'https://www.account-d.bmw.co.za/customerportalapi/rest/cp/vehicles/motor-plan/get-optional-contracts':{
            handler: getOptionalContracts
        },
        'https://www.account-d.bmw.co.za/customerportalapi/rest/cp/vehicles/motor-plan/get-contracts':{
            handler: getContracts
        },
        'https://www.account-d.bmw.co.za/customerportalapi/rest/cp/vehicles/motor-plan/get-contractVisiblePrices':{
            handler: getContractVisiblePrices
        }
    }
}
export default mockEndpoints; 

function gcdmLogin() {
    return of(new HttpResponse({
        status: 200,
        body: {
            access_token: "A9hQXdE-6yBxhrl-xyI6B6b0KOY",
            expires_in: 3599,
            gcid: "b09ba166-8578-4526-b0da-34307895b1fc",
            id_token: "eyJ0eXAiOiJKV1QiLCJraWQiOiJ0K0NmRnRVMkd2VzMzMGpodnVCQUNZeHJqdkE9IiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiSlA1YVZVZXZsOGZ5R3ktcE82UUZfdyIsInN1YiI6ImIwOWJhMTY2LTg1NzgtNDUyNi1iMGRhLTM0MzA3ODk1YjFmYyIsImF1ZGl0VHJhY2tpbmdJZCI6ImU0NzBmZWIwLTA3ZjYtNDJlMy1iNTBlLTBlYjAzZjQ2YmU0ZC0xMDE4ODQ1IiwiZ2NpZCI6ImIwOWJhMTY2LTg1NzgtNDUyNi1iMGRhLTM0MzA3ODk1YjFmYyIsInN1Ym5hbWUiOiJiMDliYTE2Ni04NTc4LTQ1MjYtYjBkYS0zNDMwNzg5NWIxZmMiLCJpc3MiOiJodHRwczovL2N1c3RvbWVyLWkuYm13Z3JvdXAuY29tL2FtL29hdXRoMiIsInRva2VuTmFtZSI6ImlkX3Rva2VuIiwic2lkIjoicVJFUFdCdEgyL2J5RGNZbkU1ZFJjRFN0cjlEemZub2M2cmZKbGFsOU5wcz0iLCJhdWQiOiJmNjA2M2QzYy00ZGIwLTQ0N2YtYTRiNy1iMGRjYjYyYzNjODYiLCJjX2hhc2giOiJjYWhUaW5yWnJoMkdnVU1ac3pfbHlRIiwiYWNyIjoiMCIsIm9yZy5mb3JnZXJvY2sub3BlbmlkY29ubmVjdC5vcHMiOiItMkdkS2xIU2Foa3NPRE9SX3hnbGNMZm9HNGsiLCJzX2hhc2giOiJsUGZzalg2Wmx1YjFNcW16UE91YWpRIiwibG9naW5fbmFtZSI6ImRpY2suYmVuLnRlc3RAYnlvbS5kZSIsImF6cCI6ImY2MDYzZDNjLTRkYjAtNDQ3Zi1hNGI3LWIwZGNiNjJjM2M4NiIsImF1dGhfdGltZSI6MTY0ODcwOTgwOSwicmVhbG0iOiIvY3VzdG9tZXIiLCJleHAiOjE2NDg3MTM0MTIsInRva2VuVHlwZSI6IkpXVFRva2VuIiwiaWF0IjoxNjQ4NzA5ODEyfQ.nA_hC5gMbl8kjAmBWbv3dkitf1ie-HXmdgXgGWDGl_UdcEIAIfPce-vcgc6vdE5JKeKMfv5Ze5HR9YLikaIjZOzTIky0EkhmUapbp1cp-V7yNLp6LdOvWMfb4kZ29jkn8VGN6Jy5E70YAMiXyZKMrq-NTz7vyCpNxRXZfnTICEab27dMFlPuP8Z4duA4zj79rheHSjWlHlms53BOepX3ohSAThRGGSQmGj-xYuThoQcUqKbPUmLPU0jzi70KsTvBJHxpZ7RX59YgQaGKZERIcDFl8DZe4hPbh3xpaqt4KaAeFLdMA7vDLv2QiGi-T7f7lMd9qQpXNH22wonMw1KyPQ",
            refresh_token: "04CSdbRYRt3_NNoluHJc-HhobEo",
            scope: "openid authenticate_user",
            token_type: "Bearer"
        }
    }))
}


function getServiceCodeDealer() {
    return of(new HttpResponse({
        status: 200,
        body: {
            "evDealerRelation": {
                "bpNo": "0009000390",
                "relationCat": "",
                "dealerId": "32717",
                "validDate": "0000-00-00"
            },
            "evMessage": "Success",
            "evResult": "S"
        }
    }))
}

function getCustomerVehicleBp() {
    return of(new HttpResponse({
        status: 200,
        body: {
            "vehicles": {
                "findvehicle": [
                    {
                        "bpOwner": "0013809002",
                        "bpDriver": "0013809002",
                        "bpDealer": "0009000050",
                        "bodyNumber": "0AK73435",
                        "vinNumber": "WBA5R12080AK73435",
                        "brand": "BM",
                        "modelCode": "B-5R12",
                        "modelYear": "2019",
                        "intVehNo": "AK73435",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "",
                            "frgGuid": "00000000000000000000000000000000",
                            "validFrom": "0",
                            "validTo": "0",
                            "upname": "",
                            "histex": "",
                            "logsys": "",
                            "zsfDealRef": "",
                            "zsfAssetCode": "",
                            "zsfAssetDescr": "",
                            "zsfAssetInsurer": "00",
                            "zsfAssetPolno": "",
                            "zsfStartDate": "0000-00-00",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "",
                            "zsfChassisNo": "",
                            "zsfRegistrNo": "",
                            "zsfRegistrYear": "0000",
                            "zsfNewUsed": "",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "",
                            "zsfAssetOrgid": "",
                            "zsfCrmExtvehNo": "",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "WBA5R12080AK73435",
                        "productGuid": "00163E103B101ED99F925D70D6DE9A68",
                        "related": "",
                        "vehLpno": "JG34TWGP",
                        "modelDesc": "G20 330i Sedan",
                        "activeMp": "X"
                    },
                    {
                        "bpOwner": "0013809002",
                        "bpDriver": "0013809002",
                        "bpDealer": "0009000050",
                        "bodyNumber": "0AK71677",
                        "vinNumber": "WBA5R12000AK71677",
                        "brand": "BM",
                        "modelCode": "B-5R12",
                        "modelYear": "2019",
                        "intVehNo": "AK71677",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "",
                            "frgGuid": "00000000000000000000000000000000",
                            "validFrom": "0",
                            "validTo": "0",
                            "upname": "",
                            "histex": "",
                            "logsys": "",
                            "zsfDealRef": "",
                            "zsfAssetCode": "",
                            "zsfAssetDescr": "",
                            "zsfAssetInsurer": "00",
                            "zsfAssetPolno": "",
                            "zsfStartDate": "0000-00-00",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "",
                            "zsfChassisNo": "",
                            "zsfRegistrNo": "",
                            "zsfRegistrYear": "0000",
                            "zsfNewUsed": "",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "",
                            "zsfAssetOrgid": "",
                            "zsfCrmExtvehNo": "",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "AK71677",
                        "productGuid": "00163E103B121ED9948A2DD676745081",
                        "related": "",
                        "vehLpno": "HZ30MSGP",
                        "modelDesc": "G20 330i Sedan",
                        "activeMp": "X"
                    },
                    {
                        "bpOwner": "0013809002",
                        "bpDriver": "0013809002",
                        "bpDealer": "0009000111",
                        "bodyNumber": "07F58660",
                        "vinNumber": "WBY2Z420807F58660",
                        "brand": "BI",
                        "modelCode": "B-2Z42",
                        "modelYear": "2020",
                        "intVehNo": "7F58660",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "300",
                            "frgGuid": "00163E103BE91EEAB88C662279759FB5",
                            "validFrom": "20200817082525",
                            "validTo": "99991231235959",
                            "upname": "CRMFS",
                            "histex": "X",
                            "logsys": "",
                            "zsfDealRef": "200800102335799",
                            "zsfAssetCode": "05018500",
                            "zsfAssetDescr": "BMW i8 (I12)",
                            "zsfAssetInsurer": "38",
                            "zsfAssetPolno": "74721356207",
                            "zsfStartDate": "2020-08-13",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "D021H099",
                            "zsfChassisNo": "WBY2Z420807F58660",
                            "zsfRegistrNo": "TBA",
                            "zsfRegistrYear": "2020",
                            "zsfNewUsed": "N",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "BMW",
                            "zsfAssetOrgid": "O 50233883",
                            "zsfCrmExtvehNo": "07F58660",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "WBY2Z420807F58660",
                        "productGuid": "00163E103B261EDA998684EECC5DC010",
                        "related": "",
                        "vehLpno": "MUI8GP",
                        "modelDesc": "I12 i8 LCI Coupé",
                        "activeMp": "X"
                    },
                    {
                        "bpOwner": "0013809002",
                        "bpDriver": "0013809002",
                        "bpDealer": "0009000021",
                        "bodyNumber": "0NC59956",
                        "vinNumber": "WBATX36010NC59956",
                        "brand": "BM",
                        "modelCode": "B-TX36",
                        "modelYear": "2019",
                        "intVehNo": "NC59956",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "300",
                            "frgGuid": "00163E103BE81ED999FF641296D1172D",
                            "validFrom": "20190427010042",
                            "validTo": "99991231235959",
                            "upname": "CRMFS",
                            "histex": "X",
                            "logsys": "",
                            "zsfDealRef": "190400102062139",
                            "zsfAssetCode": "05046200",
                            "zsfAssetDescr": "BMW X3 xDRIVE20d (G01)",
                            "zsfAssetInsurer": "38",
                            "zsfAssetPolno": "00195300*011",
                            "zsfStartDate": "2019-04-24",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "54925609",
                            "zsfChassisNo": "WBATX36010NC59956",
                            "zsfRegistrNo": "TBA",
                            "zsfRegistrYear": "2019",
                            "zsfNewUsed": "N",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "BMW",
                            "zsfAssetOrgid": "O 50233883",
                            "zsfCrmExtvehNo": "0NC59956",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "WBATX36010NC59956",
                        "productGuid": "00163E103B261EE996F722B6CD6A14C9",
                        "related": "",
                        "vehLpno": "HY47ZCGP",
                        "modelDesc": "G01 X3 xDrive20d SAV",
                        "activeMp": "X"
                    },
                    {
                        "bpOwner": "0013809002",
                        "bpDriver": "0013809002",
                        "bpDealer": "0009000014",
                        "bodyNumber": "00G94389",
                        "vinNumber": "WBSKW820500G94389",
                        "brand": "BM",
                        "modelCode": "B-KW82",
                        "modelYear": "2016",
                        "intVehNo": "0G94389",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "300",
                            "frgGuid": "00163E103C881ED683CD1530F3B48852",
                            "validFrom": "20171104185209",
                            "validTo": "99991231235959",
                            "upname": "CRMFS",
                            "histex": "X",
                            "logsys": "",
                            "zsfDealRef": "161100101467355",
                            "zsfAssetCode": "05065502",
                            "zsfAssetDescr": "BMW X6 M (F16)",
                            "zsfAssetInsurer": "08",
                            "zsfAssetPolno": "TBA",
                            "zsfStartDate": "2016-11-29",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "21011216",
                            "zsfChassisNo": "WBSKW820500G94389",
                            "zsfRegistrNo": "TBA",
                            "zsfRegistrYear": "2016",
                            "zsfNewUsed": "N",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "BMW",
                            "zsfAssetOrgid": "O 50233883",
                            "zsfCrmExtvehNo": "00G94389",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "0G94389",
                        "productGuid": "00163E103B281EE5A5EB3F377548BFA4",
                        "related": "",
                        "vehLpno": "FG27ZVGP",
                        "modelDesc": "F86 X6 M Sport Activity Coupé",
                        "activeMp": "X"
                    },
                    {
                        "bpOwner": "0013809002",
                        "bpDriver": "0013809002",
                        "bpDealer": "",
                        "bodyNumber": "00B29565",
                        "vinNumber": "WBAWX320500B29565",
                        "brand": "BM",
                        "modelCode": "B-WX32",
                        "modelYear": "2016",
                        "intVehNo": "0B29565",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "300",
                            "frgGuid": "00163E103C881EE79C8FE0E1C5C38149",
                            "validFrom": "20191206185205",
                            "validTo": "99991231235959",
                            "upname": "CRMFS",
                            "histex": "X",
                            "logsys": "",
                            "zsfDealRef": "170700101644975",
                            "zsfAssetCode": "05047434",
                            "zsfAssetDescr": "BMW X3 xDRIVE20i  A/T (F25) LCI",
                            "zsfAssetInsurer": "06",
                            "zsfAssetPolno": "557325046",
                            "zsfStartDate": "2017-07-21",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "B3361319",
                            "zsfChassisNo": "WBAWX320500B29565",
                            "zsfRegistrNo": "TBA",
                            "zsfRegistrYear": "2016",
                            "zsfNewUsed": "U",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "BMW",
                            "zsfAssetOrgid": "O 50233883",
                            "zsfCrmExtvehNo": "00B29565",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "0B29565",
                        "productGuid": "00163E103B281EE5B98391F63D74D248",
                        "related": "",
                        "vehLpno": "CAA900166",
                        "modelDesc": "F25 X3 xDrive20i SAV",
                        "activeMp": ""
                    },
                    {
                        "bpOwner": "0013809002",
                        "bpDriver": "0013809002",
                        "bpDealer": "0009000208",
                        "bodyNumber": "0LB13209",
                        "vinNumber": "WBACV02050LB13209",
                        "brand": "BM",
                        "modelCode": "B-CV02",
                        "modelYear": "2018",
                        "intVehNo": "LB13209",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "300",
                            "frgGuid": "00163E103BE31ED8BD974EA3C938C9F1",
                            "validFrom": "20190521051108",
                            "validTo": "99991231235959",
                            "upname": "CRMFS",
                            "histex": "X",
                            "logsys": "",
                            "zsfDealRef": "190500102073802",
                            "zsfAssetCode": "05061452",
                            "zsfAssetDescr": "BMW X5 M50d A/T (G05)",
                            "zsfAssetInsurer": "63",
                            "zsfAssetPolno": "4000504906",
                            "zsfStartDate": "2019-05-11",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "90293100",
                            "zsfChassisNo": "WBACV02050LB13209",
                            "zsfRegistrNo": "USED",
                            "zsfRegistrYear": "2018",
                            "zsfNewUsed": "U",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "BMW",
                            "zsfAssetOrgid": "O 50233883",
                            "zsfCrmExtvehNo": "0LB13209",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "LB13209",
                        "productGuid": "00163E103B281EE8B8CB9B0FA2DDC6F8",
                        "related": "",
                        "vehLpno": "HY91MKGP",
                        "modelDesc": "G05 X5 M50d SAV",
                        "activeMp": "X"
                    },
                    {
                        "bpOwner": "0013809002",
                        "bpDriver": "0013809002",
                        "bpDealer": "0009000050",
                        "bodyNumber": "0V790592",
                        "vinNumber": "WBS1H92060V790592",
                        "brand": "BM",
                        "modelCode": "B-1H92",
                        "modelYear": "2016",
                        "intVehNo": "V790592",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "300",
                            "frgGuid": "00163E103BE81ED683C45A81D8C7927F",
                            "validFrom": "20191206205858",
                            "validTo": "99991231235959",
                            "upname": "CRMFS",
                            "histex": "X",
                            "logsys": "",
                            "zsfDealRef": "190400102063888",
                            "zsfAssetCode": "05015550",
                            "zsfAssetDescr": "BMW M2 COUPE M-DCT (F87)",
                            "zsfAssetInsurer": "27",
                            "zsfAssetPolno": "OT111111",
                            "zsfStartDate": "2019-04-26",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "04439558",
                            "zsfChassisNo": "WBS1H92060V790592",
                            "zsfRegistrNo": "TBA",
                            "zsfRegistrYear": "2016",
                            "zsfNewUsed": "U",
                            "zsfBrokerName": "OUTSURANCE",
                            "zsfBrokerTel": "860060000",
                            "zsfAssetBrand": "BMW",
                            "zsfAssetOrgid": "O 50233883",
                            "zsfCrmExtvehNo": "0V790592",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "V790592",
                        "productGuid": "00163E103B2C1ED682BDF8C9F02D5F50",
                        "related": "",
                        "vehLpno": "NTU23021",
                        "modelDesc": "F87 M2 Coupé",
                        "activeMp": "X"
                    },
                    {
                        "bpOwner": "0013809002",
                        "bpDriver": "0013809002",
                        "bpDealer": "0009000344",
                        "bodyNumber": "00C95748",
                        "vinNumber": "WBSKT620X00C95748",
                        "brand": "BM",
                        "modelCode": "B-KT62",
                        "modelYear": "2019",
                        "intVehNo": "0C95748",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "300",
                            "frgGuid": "00163E103C881EDA86875CF23F0001FF",
                            "validFrom": "20191206164745",
                            "validTo": "99991231235959",
                            "upname": "CONTROL_PCR",
                            "histex": "X",
                            "logsys": "",
                            "zsfDealRef": "190900102154330",
                            "zsfAssetCode": "05061460",
                            "zsfAssetDescr": "BMW X5 M",
                            "zsfAssetInsurer": "08",
                            "zsfAssetPolno": "1",
                            "zsfStartDate": "2019-09-23",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "21121841",
                            "zsfChassisNo": "WBSKT620X00C95748",
                            "zsfRegistrNo": "NEW",
                            "zsfRegistrYear": "2019",
                            "zsfNewUsed": "N",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "",
                            "zsfAssetOrgid": "O 50233883",
                            "zsfCrmExtvehNo": "00C95748",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "0C95748",
                        "productGuid": "00163E103B2C1ED885F41B40C7CD4041",
                        "related": "",
                        "vehLpno": "PREGZ4GP",
                        "modelDesc": "F85 X5 M SAV",
                        "activeMp": "X"
                    },
                    {
                        "bpOwner": "0013809002",
                        "bpDriver": "0013809002",
                        "bpDealer": "0009000050",
                        "bodyNumber": "0LM56919",
                        "vinNumber": "WBACV62080LM56919",
                        "brand": "BM",
                        "modelCode": "B-CV62",
                        "modelYear": "2019",
                        "intVehNo": "LM56919",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "",
                            "frgGuid": "00000000000000000000000000000000",
                            "validFrom": "0",
                            "validTo": "0",
                            "upname": "",
                            "histex": "",
                            "logsys": "",
                            "zsfDealRef": "",
                            "zsfAssetCode": "",
                            "zsfAssetDescr": "",
                            "zsfAssetInsurer": "00",
                            "zsfAssetPolno": "",
                            "zsfStartDate": "0000-00-00",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "",
                            "zsfChassisNo": "",
                            "zsfRegistrNo": "",
                            "zsfRegistrYear": "0000",
                            "zsfNewUsed": "",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "",
                            "zsfAssetOrgid": "",
                            "zsfCrmExtvehNo": "",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "LM56919",
                        "productGuid": "00163E103B2C1ED8BFD6890AA3019661",
                        "related": "",
                        "vehLpno": "HTM635FS",
                        "modelDesc": "G05 X5 xDrive30d SAV",
                        "activeMp": "X"
                    },
                    {
                        "bpOwner": "0013809002",
                        "bpDriver": "0013809002",
                        "bpDealer": "0009000050",
                        "bodyNumber": "05R43334",
                        "vinNumber": "WBAYN120905R43334",
                        "brand": "BM",
                        "modelCode": "B-YN12",
                        "modelYear": "2021",
                        "intVehNo": "5R43334",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "",
                            "frgGuid": "00000000000000000000000000000000",
                            "validFrom": "0",
                            "validTo": "0",
                            "upname": "",
                            "histex": "",
                            "logsys": "",
                            "zsfDealRef": "",
                            "zsfAssetCode": "",
                            "zsfAssetDescr": "",
                            "zsfAssetInsurer": "00",
                            "zsfAssetPolno": "",
                            "zsfStartDate": "0000-00-00",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "",
                            "zsfChassisNo": "",
                            "zsfRegistrNo": "",
                            "zsfRegistrYear": "0000",
                            "zsfNewUsed": "",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "",
                            "zsfAssetOrgid": "",
                            "zsfCrmExtvehNo": "",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "WBAYN120905R43334",
                        "productGuid": "00163E103B2C1EDA9BCF94F2AEB646C0",
                        "related": "",
                        "vehLpno": "KMC545NW",
                        "modelDesc": "F39 X2 M35i Sport Activity Coupé",
                        "activeMp": "X"
                    },
                    {
                        "bpOwner": "0013809002",
                        "bpDriver": "0013809002",
                        "bpDealer": "0009000050",
                        "bodyNumber": "02P90978",
                        "vinNumber": "WMW52DH0202P90978",
                        "brand": "MI",
                        "modelCode": "B-52DH",
                        "modelYear": "2021",
                        "intVehNo": "2P90978",
                        "currentVehicle": "X",
                        "zsfVehicle": {
                            "client": "",
                            "frgGuid": "00000000000000000000000000000000",
                            "validFrom": "0",
                            "validTo": "0",
                            "upname": "",
                            "histex": "",
                            "logsys": "",
                            "zsfDealRef": "",
                            "zsfAssetCode": "",
                            "zsfAssetDescr": "",
                            "zsfAssetInsurer": "00",
                            "zsfAssetPolno": "",
                            "zsfStartDate": "0000-00-00",
                            "zsfEndDate": "0000-00-00",
                            "zsfEngineNo": "",
                            "zsfChassisNo": "",
                            "zsfRegistrNo": "",
                            "zsfRegistrYear": "0000",
                            "zsfNewUsed": "",
                            "zsfBrokerName": "",
                            "zsfBrokerTel": "",
                            "zsfAssetBrand": "",
                            "zsfAssetOrgid": "",
                            "zsfCrmExtvehNo": "",
                            "zsfCrmAltvehno": ""
                        },
                        "productId": "WMW52DH0202P90978",
                        "productGuid": "EEE6FEC0CE551EEBB3A118AFE54B0855",
                        "related": "",
                        "vehLpno": "KC08WKGP",
                        "modelDesc": "F56 Cooper S 3-Door Hatch",
                        "activeMp": "X"
                    }
                ]
            },
            "bpNo": "0013809002"
        }
    }))
}

function getGcdmCustomerProfile() {
    return of(new HttpResponse({
        status: 200,
        body: [{
            "businessPartner": {
                "gcid": "bfe640c5-ecd2-4736-bc83-69e1bc059d80",
                "ucid": "S002678959",
                "partnerType": "PAR_TYP_PRIVATE",
                "partnerCategory": "PERSON",
                "surname": "Long Ts",
                "givenName": "Toms",
                "salutation": "SAL_MRS",
                "birthday": "2003-12-29",
                "gender": "FEMALE",
                "correspondLanguageISO": "EN",
                "customerStatus": "ACTIVE",
                "homeMarket": "ZA",
                "profile": {
                    "personalIdentifications": {
                        "personalIdentifications": [{
                            "id": "0312292323085",
                            "type": "PER_IDE_SOUTH_AFRICA_NATIONAL_ID"
                        }]
                    },
                    "communicationTypePreferred": "TEL"
                },
                "addresses": {
                    "addresses": [{
                        "addressGUID": "8d20ea92-6ebd-42f8-abaa-d741bda351af",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferredFor": "PRIVATE",
                        "preferred": true,
                        "updatedAt": "2023-11-24T06:48:30.000Z"
                    }, {
                        "addressGUID": "5739d689-b954-483c-b6da-432ecbc99387",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-11-07T06:22:30.480Z"
                    }, {
                        "addressGUID": "e165e36e-4872-4386-8b93-ac8964a3a870",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "d1d980fd-75b5-4c1e-8d27-587c231d7440",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-06-09T14:28:47.268Z"
                    }, {
                        "addressGUID": "08073f59-657a-451f-ae4b-96398c065e5e",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "b9f9f850-d697-4da2-a9a7-6ab8070b897e",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "7f3c8207-d665-4c92-89f7-ca4b19cd8882",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "13374b1c-ff79-4295-a1d4-38f28a46ab12",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "7a858564-5df2-49e7-b183-c6762c17094c",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "ce9845ed-3386-46c5-ac0e-fb574958d250",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "16e95e48-25a5-4f43-882c-df03216af413",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "Tswane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-05-19T07:56:06.465Z"
                    }, {
                        "addressGUID": "f443ba43-6059-48b9-ba99-18284516bc77",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "Tswane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "9850135a-55ea-4545-8f24-2ad73ebd9e0c",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretorias",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "ee58cec0-48bc-4943-a286-ec910f40d92c",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretorias",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-05-08T11:44:07.659Z"
                    }, {
                        "addressGUID": "28b6b2c1-1765-48ad-80d1-37639aa5c345",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-05-08T11:43:11.006Z"
                    }, {
                        "addressGUID": "e655aa3e-3a75-4e0d-ae80-c6e16a3e7d7a",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City of Tshwane",
                        "districtName": "City of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-04-25T08:37:32.917Z"
                    }, {
                        "addressGUID": "7a951ef2-37b8-48c8-a9b6-960ec29366f6",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City of Tshwane",
                        "districtName": "City of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "ae3cd639-461e-4257-b7ca-015120f32d84",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "58365074-4ef0-4241-800c-c6a389e8f929",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "aeb4c263-344c-4229-9f50-d13502723fa8",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "6bef6c4e-7014-45f7-bff6-8f49d97a70a6",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "9753e961-5563-4ccf-b47f-f3468dcd99d2",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "36f60ff8-8210-4677-9873-e7121d9313c1",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "3717ffc4-43fc-4081-9b0d-c7c1ceaf1499",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "2d4444ca-34e6-45b7-92a5-9a70520847bf",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "a035a738-86f8-4e35-b4dd-1b8dfe360a6e",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "8fb874d7-a6af-401d-bdd7-302afc2a75c1",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "3d509a12-e53a-4b6c-94bb-81396c270406",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "d9fa2953-313a-448d-bcdc-fd73690bdaae",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "268e43aa-1e9b-4437-b9ea-17d2df043df9",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "591fe147-59d5-4f91-a61d-c5e922cca918",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "c60e0560-515a-46cf-a910-0ea98f64d869",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "a6636764-70fe-4c09-be87-ec97b87e5521",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "dfa4469b-7655-4c2e-8c4d-41f9c8b84ed6",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "8ae03026-a3bb-4dbc-89af-31d1400f5fb1",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-05-19T08:16:54.837Z"
                    }, {
                        "addressGUID": "ec7b9f48-9d88-4813-ba12-7f8e5c855b00",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City of Tshwane",
                        "districtName": "City of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "b847102e-47df-4817-b7a1-bb15311e984d",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "085cd339-747f-47b5-8c74-1c3ae01bc360",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "5c2b212d-3493-42eb-8bc9-1a12357de410",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "442766ce-5b64-41e2-a3ab-4774a7fc1a04",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }]
                },
                "communications": {
                    "communicationPhones": [{
                        "consGUID": "ac32b99b-cff8-4591-9596-bf7f73be22f9",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": true,
                        "value": "+27 81 568 5257",
                        "updatedAt": "2023-11-24T06:48:30.000Z"
                    }, {
                        "consGUID": "719f8b36-c1df-477e-9159-7215f3d9a32f",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "6d08a3b0-622d-4b3b-9188-db77ba8776ba",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641",
                        "updatedAt": "2023-05-19T08:16:54.837Z"
                    }, {
                        "consGUID": "1fc37948-0d00-4153-a289-5399757cd19c",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641",
                        "updatedAt": "2023-04-25T08:37:32.917Z"
                    }, {
                        "consGUID": "53364493-4336-437b-9980-e72fe2140453",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "f8854ee6-4c34-454f-b69e-0a53069d351b",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "8abf10b9-c040-47a4-a9d6-5bf750c25db0",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "4ece6150-0b1e-4a72-ac12-1465a9f46342",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "7276c0af-4fda-4245-ac4d-eaa220981157",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "5c0d9314-eea9-43f8-89cb-19438a304e84",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "7bd582ed-8469-4710-b687-031ef2120153",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "a6b7599e-e3c2-4e74-904e-fa4baca73c03",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "d7a0ec5b-b980-4d61-90e7-75bd4ac8991d",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "5e992df9-4ddf-4be5-b107-a04fcd2dcdde",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "bf528dd4-423b-4c84-a29d-a7eaff4af0a6",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "45ea63fc-5943-47e4-b0d4-9aa85647b75d",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "a866559c-b615-4a32-b197-ae7d2983087d",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "4f5cd8c0-496f-4759-b711-aeb6e6ada0bd",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "a976fab5-696e-4a37-9b1d-2d3faf71b060",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "1c24243b-e664-4393-9c02-162db239e532",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "aa5820ff-d5b8-425e-951d-669927845706",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "9fb896b9-682b-45cb-ae5d-558485c56ce9",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "dfb9dca7-ae43-4faa-a378-fc63264dfaa5",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "98416564-b95d-4501-b3a6-e1eb750d627f",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "31822e74-77fe-44e8-b9ea-e61a5aecf930",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "80c2e3fa-0843-4e40-aa7c-345069a1b9b8",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2640"
                    }, {
                        "consGUID": "715de95f-c0ec-4dcf-94e1-d127444ec72f",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2640"
                    }, {
                        "consGUID": "7f2462e2-5153-4d56-b89f-444b51d6ee14",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "7970c2de-839e-4ef9-8ce4-e02475e4cdf4",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "b22ffbc2-5be1-44d7-bb1c-b5e9de5812d2",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "7b191e43-2b37-40e4-beff-5f941971b56a",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "e1707a4c-62ec-4219-a83e-522f2a17a778",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "6b1df7d9-2635-4755-ace8-fcf8be977ae0",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "9cdb539f-b786-4c3b-8341-48b0e4d68478",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "3f5d18ed-5bbb-42ef-94de-7f2ab35bd03f",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "89d0a3d4-c384-405d-9a73-7636e4449613",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "db65cb86-3d70-4632-9e88-928036e172eb",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "20674cbd-1ced-43b2-92d3-8a52084044aa",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "60c277f0-f791-465b-aea3-0d87db239763",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2640"
                    }, {
                        "consGUID": "2a025c9f-332b-480c-87c3-69ddc38f8366",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "ece5b600-78bb-4d0f-a447-648d931f374c",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2640"
                    }, {
                        "consGUID": "16964570-c27d-487d-9e81-75ad5e4d498c",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 436 9011"
                    }, {
                        "consGUID": "245d821d-e81b-4e3f-bcc8-fd21a54ff85a",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 234 5678"
                    }, {
                        "consGUID": "0030d027-1eac-4a12-95e9-1aeef95f2844",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "d72e22de-72fc-4ca0-8fbc-687ef3dd5818",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2640"
                    }, {
                        "consGUID": "9032a03a-9de8-45f4-96eb-a3ef7266ccaf",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 234 5678"
                    }, {
                        "consGUID": "bd33ce3b-adeb-4162-a8f8-4750274758c3",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 436 9011"
                    }, {
                        "consGUID": "a914455b-1e06-4c4e-9531-d399a1e9c360",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 27 156 8525"
                    }, {
                        "consGUID": "af304363-0769-4cf6-9228-4627551933bc",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 71 111 1111"
                    }, {
                        "consGUID": "8d377607-c39a-4ace-852c-1c07987c55f6",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 27 156 8525"
                    }, {
                        "consGUID": "c3ccea1c-9d95-47d3-880c-ef82e30dd016",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 71 111 1111"
                    }, {
                        "consGUID": "eea82749-f01c-4a2d-864d-9a6b1756eebf",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 27 156 8525"
                    }, {
                        "consGUID": "cc9068ac-7727-4d40-948e-4a98085c3f63",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 71 111 1111"
                    }, {
                        "consGUID": "b99cbfd8-888a-4777-b504-e2ccfc36678c",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 71 111 1111"
                    }, {
                        "consGUID": "57e3315b-de7b-4f6a-a02e-e659b7b99a58",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 234 5678"
                    }, {
                        "consGUID": "da14aee2-0a18-41b8-a020-40a04529009f",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 436 9011"
                    }, {
                        "consGUID": "42d4d588-fef0-4126-85b9-eeafb2024785",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2640"
                    }, {
                        "consGUID": "dc310d8f-b55b-4ee5-901e-bc8990bc790c",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 11 522 2114"
                    }, {
                        "consGUID": "98ec2192-657a-4040-bc40-9c956e48c2be",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }],
                    "communicationEMails": [{
                        "consGUID": "3a3df3e4-c4c8-4947-8b78-f9bfb7a2f579",
                        "communicationStatus": "PRIVATE",
                        "preferred": true,
                        "value": "longt@byom.de",
                        "communicationType": "EMAIL",
                        "updatedAt": "2023-06-09T14:28:47.268Z"
                    }]
                },
                "lastUpdated": {
                    "at": "2023-12-06T07:30:30.130Z",
                    "bySource": "CUSTOMER"
                },
                "claims": [{
                    "id": "ZA",
                    "timestamp": "2023-04-25T08:09:12.841Z"
                }]
            },
            "userAccount": {
                "mail": "longt@byom.de",
                "notificationLevel": "NEW_DEVICE",
                "authenticationLevel": "ONE_FACTOR",
                "gcid": "bfe640c5-ecd2-4736-bc83-69e1bc059d80",
                "secretKnowledgeActivated": false,
                "status": "ACTIVATED"
            },
            "contactPolicyConsents": [{
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "EMAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "REJECT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "APP",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "ANALYTICS",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "IN_CAR",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE_MESSENGER_SERVICES",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "MAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "APP",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "IN_CAR",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "ANALYTICS",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "EMAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "MAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE_MESSENGER_SERVICES",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE_MESSENGER_SERVICES",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "EMAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "EMAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "REJECT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "APP",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "IN_CAR",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "IN_CAR",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "ANALYTICS",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "MAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "REJECT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "APP",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "MAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE_MESSENGER_SERVICES",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "ANALYTICS",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Privacy_Statement",
                "country": "ZA",
                "majorVersion": 3,
                "minorVersion": 0,
                "usageId": "DATAPROCESSING",
                "purposeId": "DATA_PRIVACY",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "779e9fd4-c8f6-492a-8b29-7ce9f2bf987f"
            }, {
                "consent": "ACCEPT",
                "policyId": "Privacy_Statement",
                "country": "ZA",
                "majorVersion": 3,
                "minorVersion": 0,
                "usageId": "DATAPROCESSING",
                "purposeId": "DATA_PRIVACY",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "779e9fd4-c8f6-492a-8b29-7ce9f2bf987f"
            }, {
                "consent": "ACCEPT",
                "policyId": "Privacy_Statement",
                "country": "ZA",
                "majorVersion": 3,
                "minorVersion": 0,
                "usageId": "DATAPROCESSING",
                "purposeId": "DATA_PRIVACY",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "4a7aafe1-5ec1-4c55-a86a-9afd30189209"
            }, {
                "consent": "ACCEPT",
                "policyId": "Privacy_Statement",
                "country": "ZA",
                "majorVersion": 3,
                "minorVersion": 0,
                "usageId": "DATAPROCESSING",
                "purposeId": "DATA_PRIVACY",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "4a7aafe1-5ec1-4c55-a86a-9afd30189209"
            }],
            "gcid": "bfe640c5-ecd2-4736-bc83-69e1bc059d80",
            "ucid": "S002678959"
        }]
    }))
}

function getGcdmCustomerProfileOnePage() {
    return of(new HttpResponse({
        status: 200,
        body: [{
            "businessPartner": {
                "gcid": "bfe640c5-ecd2-4736-bc83-69e1bc059d80",
                "ucid": "S002678959",
                "partnerType": "PAR_TYP_PRIVATE",
                "partnerCategory": "PERSON",
                "surname": "Long Ts",
                "givenName": "Toms",
                "salutation": "SAL_MRS",
                "birthday": "2003-12-29",
                "gender": "FEMALE",
                "correspondLanguageISO": "EN",
                "customerStatus": "ACTIVE",
                "homeMarket": "ZA",
                "profile": {
                    "personalIdentifications": {
                        "personalIdentifications": [{
                            "id": "0312292323085",
                            "type": "PER_IDE_SOUTH_AFRICA_NATIONAL_ID"
                        }]
                    },
                    "communicationTypePreferred": "TEL"
                },
                "addresses": {
                    "addresses": [{
                        "addressGUID": "8d20ea92-6ebd-42f8-abaa-d741bda351af",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferredFor": "PRIVATE",
                        "preferred": true,
                        "updatedAt": "2023-11-24T06:48:30.000Z"
                    }, {
                        "addressGUID": "5739d689-b954-483c-b6da-432ecbc99387",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-11-07T06:22:30.480Z"
                    }, {
                        "addressGUID": "e165e36e-4872-4386-8b93-ac8964a3a870",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "d1d980fd-75b5-4c1e-8d27-587c231d7440",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-06-09T14:28:47.268Z"
                    }, {
                        "addressGUID": "08073f59-657a-451f-ae4b-96398c065e5e",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "b9f9f850-d697-4da2-a9a7-6ab8070b897e",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "7f3c8207-d665-4c92-89f7-ca4b19cd8882",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "13374b1c-ff79-4295-a1d4-38f28a46ab12",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "7a858564-5df2-49e7-b183-c6762c17094c",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "ce9845ed-3386-46c5-ac0e-fb574958d250",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "16e95e48-25a5-4f43-882c-df03216af413",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "Tswane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-05-19T07:56:06.465Z"
                    }, {
                        "addressGUID": "f443ba43-6059-48b9-ba99-18284516bc77",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "Tswane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "9850135a-55ea-4545-8f24-2ad73ebd9e0c",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretorias",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "ee58cec0-48bc-4943-a286-ec910f40d92c",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretorias",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-05-08T11:44:07.659Z"
                    }, {
                        "addressGUID": "28b6b2c1-1765-48ad-80d1-37639aa5c345",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-05-08T11:43:11.006Z"
                    }, {
                        "addressGUID": "e655aa3e-3a75-4e0d-ae80-c6e16a3e7d7a",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City of Tshwane",
                        "districtName": "City of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-04-25T08:37:32.917Z"
                    }, {
                        "addressGUID": "7a951ef2-37b8-48c8-a9b6-960ec29366f6",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City of Tshwane",
                        "districtName": "City of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "ae3cd639-461e-4257-b7ca-015120f32d84",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "58365074-4ef0-4241-800c-c6a389e8f929",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "aeb4c263-344c-4229-9f50-d13502723fa8",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "6bef6c4e-7014-45f7-bff6-8f49d97a70a6",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "9753e961-5563-4ccf-b47f-f3468dcd99d2",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "36f60ff8-8210-4677-9873-e7121d9313c1",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "3717ffc4-43fc-4081-9b0d-c7c1ceaf1499",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "2d4444ca-34e6-45b7-92a5-9a70520847bf",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "a035a738-86f8-4e35-b4dd-1b8dfe360a6e",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "8fb874d7-a6af-401d-bdd7-302afc2a75c1",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "3d509a12-e53a-4b6c-94bb-81396c270406",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "d9fa2953-313a-448d-bcdc-fd73690bdaae",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "268e43aa-1e9b-4437-b9ea-17d2df043df9",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "591fe147-59d5-4f91-a61d-c5e922cca918",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "c60e0560-515a-46cf-a910-0ea98f64d869",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "a6636764-70fe-4c09-be87-ec97b87e5521",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "dfa4469b-7655-4c2e-8c4d-41f9c8b84ed6",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "8ae03026-a3bb-4dbc-89af-31d1400f5fb1",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false,
                        "updatedAt": "2023-05-19T08:16:54.837Z"
                    }, {
                        "addressGUID": "ec7b9f48-9d88-4813-ba12-7f8e5c855b00",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City of Tshwane",
                        "districtName": "City of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "b847102e-47df-4817-b7a1-bb15311e984d",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "085cd339-747f-47b5-8c74-1c3ae01bc360",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "5c2b212d-3493-42eb-8bc9-1a12357de410",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "strSuppl2": "City Of Tshwane",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }, {
                        "addressGUID": "442766ce-5b64-41e2-a3ab-4774a7fc1a04",
                        "street": "16 Sisulu Street",
                        "strSuppl1": "Pretoria",
                        "postalCode": "0002",
                        "city": "City Of Tshwane",
                        "districtName": "City Of Tshwane",
                        "country": "ZA",
                        "addressStatus": "PRIVATE",
                        "preferred": false
                    }]
                },
                "communications": {
                    "communicationPhones": [{
                        "consGUID": "ac32b99b-cff8-4591-9596-bf7f73be22f9",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": true,
                        "value": "+27 81 568 5257",
                        "updatedAt": "2023-11-24T06:48:30.000Z"
                    }, {
                        "consGUID": "719f8b36-c1df-477e-9159-7215f3d9a32f",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "6d08a3b0-622d-4b3b-9188-db77ba8776ba",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641",
                        "updatedAt": "2023-05-19T08:16:54.837Z"
                    }, {
                        "consGUID": "1fc37948-0d00-4153-a289-5399757cd19c",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641",
                        "updatedAt": "2023-04-25T08:37:32.917Z"
                    }, {
                        "consGUID": "53364493-4336-437b-9980-e72fe2140453",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "f8854ee6-4c34-454f-b69e-0a53069d351b",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "8abf10b9-c040-47a4-a9d6-5bf750c25db0",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "4ece6150-0b1e-4a72-ac12-1465a9f46342",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "7276c0af-4fda-4245-ac4d-eaa220981157",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "5c0d9314-eea9-43f8-89cb-19438a304e84",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "7bd582ed-8469-4710-b687-031ef2120153",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "a6b7599e-e3c2-4e74-904e-fa4baca73c03",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "d7a0ec5b-b980-4d61-90e7-75bd4ac8991d",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "5e992df9-4ddf-4be5-b107-a04fcd2dcdde",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "bf528dd4-423b-4c84-a29d-a7eaff4af0a6",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "45ea63fc-5943-47e4-b0d4-9aa85647b75d",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "a866559c-b615-4a32-b197-ae7d2983087d",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "4f5cd8c0-496f-4759-b711-aeb6e6ada0bd",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "a976fab5-696e-4a37-9b1d-2d3faf71b060",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "1c24243b-e664-4393-9c02-162db239e532",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "aa5820ff-d5b8-425e-951d-669927845706",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "9fb896b9-682b-45cb-ae5d-558485c56ce9",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "dfb9dca7-ae43-4faa-a378-fc63264dfaa5",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "98416564-b95d-4501-b3a6-e1eb750d627f",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "31822e74-77fe-44e8-b9ea-e61a5aecf930",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "80c2e3fa-0843-4e40-aa7c-345069a1b9b8",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2640"
                    }, {
                        "consGUID": "715de95f-c0ec-4dcf-94e1-d127444ec72f",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2640"
                    }, {
                        "consGUID": "7f2462e2-5153-4d56-b89f-444b51d6ee14",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "7970c2de-839e-4ef9-8ce4-e02475e4cdf4",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "b22ffbc2-5be1-44d7-bb1c-b5e9de5812d2",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "7b191e43-2b37-40e4-beff-5f941971b56a",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "e1707a4c-62ec-4219-a83e-522f2a17a778",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "6b1df7d9-2635-4755-ace8-fcf8be977ae0",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "9cdb539f-b786-4c3b-8341-48b0e4d68478",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "3f5d18ed-5bbb-42ef-94de-7f2ab35bd03f",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "89d0a3d4-c384-405d-9a73-7636e4449613",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "db65cb86-3d70-4632-9e88-928036e172eb",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "20674cbd-1ced-43b2-92d3-8a52084044aa",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "60c277f0-f791-465b-aea3-0d87db239763",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2640"
                    }, {
                        "consGUID": "2a025c9f-332b-480c-87c3-69ddc38f8366",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "ece5b600-78bb-4d0f-a447-648d931f374c",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2640"
                    }, {
                        "consGUID": "16964570-c27d-487d-9e81-75ad5e4d498c",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 436 9011"
                    }, {
                        "consGUID": "245d821d-e81b-4e3f-bcc8-fd21a54ff85a",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 234 5678"
                    }, {
                        "consGUID": "0030d027-1eac-4a12-95e9-1aeef95f2844",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }, {
                        "consGUID": "d72e22de-72fc-4ca0-8fbc-687ef3dd5818",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2640"
                    }, {
                        "consGUID": "9032a03a-9de8-45f4-96eb-a3ef7266ccaf",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 234 5678"
                    }, {
                        "consGUID": "bd33ce3b-adeb-4162-a8f8-4750274758c3",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 436 9011"
                    }, {
                        "consGUID": "a914455b-1e06-4c4e-9531-d399a1e9c360",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 27 156 8525"
                    }, {
                        "consGUID": "af304363-0769-4cf6-9228-4627551933bc",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 71 111 1111"
                    }, {
                        "consGUID": "8d377607-c39a-4ace-852c-1c07987c55f6",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 27 156 8525"
                    }, {
                        "consGUID": "c3ccea1c-9d95-47d3-880c-ef82e30dd016",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 71 111 1111"
                    }, {
                        "consGUID": "eea82749-f01c-4a2d-864d-9a6b1756eebf",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 27 156 8525"
                    }, {
                        "consGUID": "cc9068ac-7727-4d40-948e-4a98085c3f63",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 71 111 1111"
                    }, {
                        "consGUID": "b99cbfd8-888a-4777-b504-e2ccfc36678c",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 71 111 1111"
                    }, {
                        "consGUID": "57e3315b-de7b-4f6a-a02e-e659b7b99a58",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 234 5678"
                    }, {
                        "consGUID": "da14aee2-0a18-41b8-a020-40a04529009f",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 436 9011"
                    }, {
                        "consGUID": "42d4d588-fef0-4126-85b9-eeafb2024785",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2640"
                    }, {
                        "consGUID": "dc310d8f-b55b-4ee5-901e-bc8990bc790c",
                        "communicationType": "TEL",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 11 522 2114"
                    }, {
                        "consGUID": "98ec2192-657a-4040-bc40-9c956e48c2be",
                        "communicationType": "MOB",
                        "communicationStatus": "PRIVATE",
                        "preferred": false,
                        "value": "+27 81 674 2641"
                    }],
                    "communicationEMails": [{
                        "consGUID": "3a3df3e4-c4c8-4947-8b78-f9bfb7a2f579",
                        "communicationStatus": "PRIVATE",
                        "preferred": true,
                        "value": "longt@byom.de",
                        "communicationType": "EMAIL",
                        "updatedAt": "2023-06-09T14:28:47.268Z"
                    }]
                },
                "lastUpdated": {
                    "at": "2023-12-06T07:30:30.130Z",
                    "bySource": "CUSTOMER"
                },
                "claims": [{
                    "id": "ZA",
                    "timestamp": "2023-04-25T08:09:12.841Z"
                }]
            },
            "userAccount": {
                "mail": "longt@byom.de",
                "notificationLevel": "NEW_DEVICE",
                "authenticationLevel": "ONE_FACTOR",
                "gcid": "bfe640c5-ecd2-4736-bc83-69e1bc059d80",
                "secretKnowledgeActivated": false,
                "status": "ACTIVATED"
            },
            "contactPolicyConsents": [{
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "EMAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "REJECT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "APP",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "ANALYTICS",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "IN_CAR",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE_MESSENGER_SERVICES",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "MAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "APP",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "IN_CAR",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "ANALYTICS",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "EMAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "MAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE_MESSENGER_SERVICES",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "80bfd2ee-4405-484a-87bb-a9533e55f61e"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE_MESSENGER_SERVICES",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "EMAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "EMAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "REJECT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "APP",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "IN_CAR",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "IN_CAR",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "ANALYTICS",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "MAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "REJECT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "APP",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "MAIL",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "PHONE_MESSENGER_SERVICES",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Marketing",
                "country": "ZA",
                "majorVersion": 2,
                "minorVersion": 0,
                "usageId": "ANALYTICS",
                "purposeId": "MARKETING",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "2440cd5c-9ccc-4b7d-90be-025ec8d26188"
            }, {
                "consent": "ACCEPT",
                "policyId": "Privacy_Statement",
                "country": "ZA",
                "majorVersion": 3,
                "minorVersion": 0,
                "usageId": "DATAPROCESSING",
                "purposeId": "DATA_PRIVACY",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "779e9fd4-c8f6-492a-8b29-7ce9f2bf987f"
            }, {
                "consent": "ACCEPT",
                "policyId": "Privacy_Statement",
                "country": "ZA",
                "majorVersion": 3,
                "minorVersion": 0,
                "usageId": "DATAPROCESSING",
                "purposeId": "DATA_PRIVACY",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-12-06T09:30:29.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-12-06T09:30:29.000Z",
                "consentGroupId": "779e9fd4-c8f6-492a-8b29-7ce9f2bf987f"
            }, {
                "consent": "ACCEPT",
                "policyId": "Privacy_Statement",
                "country": "ZA",
                "majorVersion": 3,
                "minorVersion": 0,
                "usageId": "DATAPROCESSING",
                "purposeId": "DATA_PRIVACY",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "4a7aafe1-5ec1-4c55-a86a-9afd30189209"
            }, {
                "consent": "ACCEPT",
                "policyId": "Privacy_Statement",
                "country": "ZA",
                "majorVersion": 3,
                "minorVersion": 0,
                "usageId": "DATAPROCESSING",
                "purposeId": "DATA_PRIVACY",
                "touchpointId": "CUSTOMER_PORTAL_WEB_SITE_ZA",
                "language": "en",
                "consentDate": "2023-10-25T10:12:13.000Z",
                "legalEntityId": "BMW_SOUTH_AFRICA_PTY_LTD_",
                "expirationDate": "3022-10-25T10:12:13.000Z",
                "consentGroupId": "4a7aafe1-5ec1-4c55-a86a-9afd30189209"
            }],
            "gcid": "bfe640c5-ecd2-4736-bc83-69e1bc059d80",
            "ucid": "S002678959"
        }]
    }))
}

function getPrivacyStatement() {
    return of(new HttpResponse({
        status: 200,
        body: {
            "id": 40523,
            "name": "Privacy_Statement",
            "validFrom": "2023-12-14T00:00:00Z",
            "country": "ZA",
            "majorVersion": 3,
            "minorVersion": 2,
            "description": "For E2E Testing - Policy for consent text (processing of personal information and Marketing) shown on all online and offline touchpoints",
            "textBlocks": [{
                "id": 15016,
                "language": "en",
                "usageDefinitions": [{
                    "id": 18586,
                    "textPrimary": "Mandatory: Consent to processing of personal information",
                    "textSecondary": "By voluntarily submitting any personal information to us, you consent to the processing of your personal information as set out in our \n<a title=\"Privacy Statement\" href=\"https://www.bmw.co.za/en/footer/metanavigation/legal-disclaimer-pool/privacy-statement.html\" target=\"_blank\" rel=\"noopener noreferrer\">Privacy Statement</a>. For further information, please refer to our \n<a title=\"Privacy Statement \" href=\"https://www.bmw.co.za/en/footer/metanavigation/legal-disclaimer-pool/privacy-statement.html\" target=\"_blank\" rel=\"noopener noreferrer\">Privacy Statement </a>or contact our Information Officer on \n<a title=\"InformationOfficer@bmw.co.za\" href=\"mailto:InformationOfficer@bmw.co.za\" target=\"_blank\" rel=\"noopener noreferrer\">InformationOfficer@bmw.co.za</a>",
                    "ordinalNumber": 1,
                    "usages": [{
                        "id": 23710,
                        "variant": "MANDATORY",
                        "ordinalNumber": 1,
                        "usage": {
                            "id": 10,
                            "name": "DATAPROCESSING",
                            "description": "Datenverarbeitung",
                            "semantic": "Erlaubnis zur Datenverarbeitung",
                            "lockable": true,
                            "implicit": true,
                            "mandatory": true,
                            "optional": true,
                            "visible": true,
                            "allowedVariants": ["IMPLICIT", "MANDATORY", "OPTIONAL"]
                        }
                    }],
                    "legalEntities": [{
                        "id": 5864,
                        "name": "BMW_SOUTH_AFRICA_PTY_LTD_",
                        "label": "BMW (South Africa) (Pty) Ltd.",
                        "country": "ZA",
                        "visible": true
                    }, {
                        "id": 11616,
                        "name": "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_",
                        "label": "BMW Financial Services (South Africa) (Pty) Ltd.",
                        "country": "ZA",
                        "visible": true
                    }],
                    "purposes": [{
                        "id": 19545,
                        "validityInDays": 364877,
                        "purpose": {
                            "id": 1360,
                            "name": "DATA_PRIVACY",
                            "visible": true
                        }
                    }]
                }],
                "usageText": [{
                    "id": 20844,
                    "value": "",
                    "mimeType": "text/html",
                    "textBlockId": 15016,
                    "ordinalNumber": 0,
                    "tag": ""
                }]
            }],
            "contactFrequency": {
                "frequencyDays": 0
            },
            "touchpointLinks": [],
            "encodedPolicyDocuments": []

        }
    }))
}

function getModelFromBody() {
    return of(new HttpResponse({
        status: 200,
        body: {
            "zmessage": "",
            "zmodel": "5R12"
        }
    }))
}

function getCampaigns() {
    return of(new HttpResponse({
        status: 200,
        body: {
            "messageList": [],
            "etReturn": {
                "debug": false,
                "information": false,
                "warning": false,
                "error": false,
                "messages": [],
                "debugMessages": [],
                "infoMessages": [],
                "warningMessages": [],
                "errorMessages": []
            },
            "systMessage": " ",
            "userMessage": " ",
            "campaignList": {
                "list": []
            },
            "contractList": {
                "list": [
                    {
                        "startDate": "20190430",
                        "endDate": "20240429",
                        "startKm": "00000000",
                        "endKm": "00100000",
                        "dealer": "44046MPL",
                        "contractStatus": "CS04",
                        "contractType": "ZMP1",
                        "contractTechnicalType": "TC010"
                    },
                    {
                        "startDate": "20231106",
                        "endDate": "20261105",
                        "startKm": "00090000",
                        "endKm": "00190000",
                        "dealer": "48003MPL",
                        "contractStatus": "CS05",
                        "contractType": "ZMP6",
                        "contractTechnicalType": "TC210"
                    }
                ]
            },
            "claimList": {
                "list": [
                    {
                        "claimDate": "2022-11-18",
                        "claimPartner": "23389MPL",
                        "claimPartnerName": "BMW Joburg South"
                    },
                    {
                        "claimDate": "2022-11-18",
                        "claimPartner": "23389MPL",
                        "claimPartnerName": "BMW Joburg South"
                    },
                    {
                        "claimDate": "2021-06-09",
                        "claimPartner": "23389MPL",
                        "claimPartnerName": "BMW Joburg South"
                    },
                    {
                        "claimDate": "2020-11-12",
                        "claimPartner": "23389MPL",
                        "claimPartnerName": "BMW Joburg South"
                    },
                    {
                        "claimDate": "2019-12-09",
                        "claimPartner": "23389MPL",
                        "claimPartnerName": "BMW Joburg South"
                    },
                    {
                        "claimDate": "2019-09-16",
                        "claimPartner": "31954MPL",
                        "claimPartnerName": "BMW Montana"
                    },
                    {
                        "claimDate": "2019-09-16",
                        "claimPartner": "31954MPL",
                        "claimPartnerName": "BMW Montana"
                    }
                ]
            },
            "vehicleDescription": {
                "modelDescription": "F85 X5 M SAV",
                "trimCol": "Individual leather",
                "bodyCol": "Black Sapphire Metallic",
                "bodyNumber": "00C95748",
                "brand": "BM",
                "saleDealer": "31954MPL",
                "regNumber": "PREGZ4GP",
                "vin": "WBSKT620X00C95748"
            }
        }
    }))
}

function latestContractDetail() {
    return of(new HttpResponse({
        status: 200,
        body: {
            "messageList": [],
            "etReturn": {
                "debug": false,
                "information": false,
                "warning": false,
                "error": false,
                "messages": [],
                "debugMessages": [],
                "infoMessages": [],
                "warningMessages": [],
                "errorMessages": []
            },
            "systMessage": " ",
            "categoryList": [
                {
                    "contractCategory": "M",
                    "contractCategoryDescription": "Motorplan",
                    "activeContractList": {
                        "list": [
                            {
                                "vinNumber": "WBAWX320500B29565",
                                "contractNumber": "0253827087",
                                "contractType": "TC010",
                                "contractTypeDescription": "Built-In",
                                "contractCategory": "M",
                                "contractPeriod": "060",
                                "validFromDate": "2016-04-29",
                                "validToDate": "2021-04-28",
                                "validKmFrom": "000000000",
                                "validKmTo": "000100000",
                                "contractStatus": "CS07",
                                "contractStatusDescription": "Expired Time",
                                "allowedContractTypesForContractPricing": {
                                    "list": []
                                }
                            }
                        ]
                    }
                },
                {
                    "contractCategory": "O",
                    "contractCategoryDescription": "On-Call",
                    "activeContractList": {
                        "list": [
                            {
                                "vinNumber": "WBAWX320500B29565",
                                "contractNumber": "0253827087",
                                "contractType": "TC900",
                                "contractTypeDescription": "On Call Service",
                                "contractCategory": "O",
                                "contractPeriod": "060",
                                "validFromDate": "2016-04-29",
                                "validToDate": "2021-04-28",
                                "validKmFrom": "000000000",
                                "validKmTo": "000100000",
                                "contractStatus": "CS07",
                                "contractStatusDescription": "Expired Time",
                                "allowedContractTypesForContractPricing": {
                                    "list": []
                                }
                            }
                        ]
                    }
                }
            ]
        }
    }))
}

function getOptionalContracts() {
    return of(new HttpResponse({
        status: 200,
        body: {
            "messageList": [],
            "etReturn": {
                "debug": false,
                "information": false,
                "warning": false,
                "error": false,
                "messages": [],
                "debugMessages": [],
                "infoMessages": [],
                "warningMessages": [],
                "errorMessages": []
            },
            "systMessage": " ",
            "userMessage": " ",
            "contractTypeList": {
                "list": [
                    {
                        "contractType": "TC200",
                        "contractNo": "",
                        "description": "Oil Service Only",
                        "onCall": "X",
                        "orderType": "ZMQ2",
                        "idReq": "",
                        "origContractType": "TC010",
                        "contractCategory": ""
                    },
                    {
                        "contractType": "TC210",
                        "contractNo": "",
                        "description": "My Service",
                        "onCall": "",
                        "orderType": "ZMQ6",
                        "idReq": "",
                        "origContractType": "TC010",
                        "contractCategory": ""
                    },
                    {
                        "contractType": "TC220",
                        "contractNo": "",
                        "description": "My Maintenance",
                        "onCall": "",
                        "orderType": "ZMQ7",
                        "idReq": "",
                        "origContractType": "TC010",
                        "contractCategory": ""
                    },
                    {
                        "contractType": "TC240",
                        "contractNo": "",
                        "description": "Oil Service Only",
                        "onCall": "X",
                        "orderType": "ZMQ9",
                        "idReq": "",
                        "origContractType": "TC010",
                        "contractCategory": ""
                    },
                    {
                        "contractType": "TC900",
                        "contractNo": "",
                        "description": "On-Call Only",
                        "onCall": "",
                        "orderType": "ZMQ1",
                        "idReq": "",
                        "origContractType": "TC010",
                        "contractCategory": ""
                    }
                ]
            }
        }
    }))
}

function getContracts() {
    return of(new HttpResponse({
        status: 200,
        body: {
            "messageList": [],
            "etReturn": {
                "debug": false,
                "information": false,
                "warning": false,
                "error": false,
                "messages": [],
                "debugMessages": [],
                "infoMessages": [],
                "warningMessages": [],
                "errorMessages": []
            },
            "invalid": " ",
            "systMessage": " ",
            "userMessage": " ",
            "vehicle": {
                "mandt": "012",
                "z08VBrand": "BM",
                "z08Invoice": "0192031543",
                "z08InvDte": "2017-04-07",
                "z08Credit": "",
                "z08Vin": "WBAWX320500B29565",
                "zvehBodyNo": "00B29565",
                "zvehOrigDlr": "10231MPL",
                "zvehStatus": "VH003",
                "zvehTypeMp": "VTA",
                "zvehProdType": "VP001",
                "zorigSalesDate": "2016-04-29",
                "zcontKey": "0254000301",
                "zvehCreditDate": "0000-00-00",
                "zvehBaseKm": "00000000",
                "zvehBaseKmDte": "0000-00-00",
                "zgearType": "A",
                "zchangeDate": "2023-08-18",
                "zchangeOperator": "ZVAD",
                "zactDte": "2016-01-08",
                "zmatnr": "B-WX32",
                "zvehPrevEngine": "",
                "zvehPrevEngDte": "0000-00-00",
                "zvehPrevEngKm": "00000000",
                "zvehOwnerSeq": "03",
                "zvehTrimCol": "KCSW",
                "zvehBodyCol": "0A14",
                "ztcar": "",
                "zagModel": "WX42",
                "zdrivKkey": "0013809002",
                "zsalesDate": "2020-09-28",
                "zsalesType": "L",
                "zregNo": "CAA900166",
                "zregDate": "2016-04-22",
                "zregDist": "CA",
                "zsaleDealerKey": "28153MPL",
                "zvehOdoReading": "00091652",
                "zvehLstOdoDte": "2023-01-09",
                "zvehEngineNo": "B3361319",
                "zclntKkey": "0013809002"
            },
            "contract": {
                "contractNumber": "0253827087",
                "contractPeriodInMonths": "060",
                "contractKmLimit": "0000000",
                "contractStartDate": "20160429",
                "contractEndDate": "20210428",
                "contractStartKm": "00000000",
                "contractEndKm": "00100000",
                "customerNumber": "0013315504",
                "dealerNumber": "10231MPL",
                "orderType": "ZMP1",
                "equipmentNumber": "000000000010641179",
                "requestedCancellationDate": "2021-04-28",
                "status": "CS07",
                "vehicleBodyNumber": "00B29565",
                "contractType": "TC010",
                "contractCost": "45654.89",
                "onCallCost": "2503.00",
                "totalCost": "48157.89",
                "customerPurchaseOrderNumber": "00B29565",
                "surchargeCode": "",
                "customerIdNumber": "",
                "orderNumber": "00A253827087",
                "createDate": "2016-04-29",
                "createTime": "10:13:17",
                "createdBy": "ZA_V2_SYSTEM",
                "branchingName": "",
                "vinNumber": "WBAWX320500B29565",
                "odometerReading": "00000000",
                "contractFrequency": "",
                "salesExecIdNumber": "",
                "motorplanStatus": "CS07"
            },
            "vehicleDescription": {
                "modelDescription": "F25 X3 xDrive20i SAV",
                "trimCol": "Leathertec Black / Black",
                "bodyCol": "Mineral Silver"
            },
            "categoryList": [
                {
                    "contractCategory": "S",
                    "contractCategoryDescription": "My Plans",
                    "activeContractList": {
                        "list": [
                            {
                                "vinNumber": "WBAWX320500B29565",
                                "contractNumber": "9999999999",
                                "contractType": "TC200",
                                "contractTypeDescription": "BMW Black : Oil Service Only",
                                "contractCategory": "S",
                                "contractPeriod": "060",
                                "validFromDate": "2016-04-29",
                                "validToDate": "2021-04-28",
                                "validKmFrom": "000000000",
                                "validKmTo": "000100000",
                                "contractStatus": "CS07",
                                "contractStatusDescription": "Expired Time",
                                "allowedContractTypesForContractPricing": {
                                    "list": [
                                        {
                                            "contractCategory": "S",
                                            "contractCategoryDescription": "My Plans",
                                            "contractTypeList": {
                                                "list": [
                                                    {
                                                        "contractType": "TC200",
                                                        "contractNo": "",
                                                        "description": "Oil Service Only",
                                                        "onCall": "X",
                                                        "orderType": "ZMQ2",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "24 ",
                                                            "contractExpiryDate": "20260111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "3651.3000",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "3651.3000",
                                                            "vatTotalPrice": "4198.9950",
                                                            "orgTotalPrice": "3651.3000",
                                                            "contractFrequency": "3",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ2",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC200",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC200",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "24 ",
                                                                "contractExpiryDate": "20260111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "3651.3000",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "3651.3000",
                                                                "vatTotalPrice": "4198.9950",
                                                                "orgTotalPrice": "3651.3000",
                                                                "contractFrequency": "3"
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC210",
                                                        "contractNo": "",
                                                        "description": "My Service",
                                                        "onCall": "",
                                                        "orderType": "ZMQ6",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "10733.9700",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "10733.9700",
                                                            "vatTotalPrice": "12344.0655",
                                                            "orgTotalPrice": "10733.9700",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ6",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC210",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC210",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "10733.9700",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "10733.9700",
                                                                "vatTotalPrice": "12344.0655",
                                                                "orgTotalPrice": "10733.9700",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC220",
                                                        "contractNo": "",
                                                        "description": "My Maintenance",
                                                        "onCall": "",
                                                        "orderType": "ZMQ7",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "34171.9500",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "34171.9500",
                                                            "vatTotalPrice": "39297.7425",
                                                            "orgTotalPrice": "34171.9500",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ7",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC220",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC220",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "34171.9500",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "34171.9500",
                                                                "vatTotalPrice": "39297.7425",
                                                                "orgTotalPrice": "34171.9500",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC240",
                                                        "contractNo": "",
                                                        "description": "Oil Service Only",
                                                        "onCall": "X",
                                                        "orderType": "ZMQ9",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "4635.6700",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "4635.6700",
                                                            "vatTotalPrice": "5331.0205",
                                                            "orgTotalPrice": "4635.6700",
                                                            "contractFrequency": "3",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ9",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC240",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC240",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "4635.6700",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "4635.6700",
                                                                "vatTotalPrice": "5331.0205",
                                                                "orgTotalPrice": "4635.6700",
                                                                "contractFrequency": "3"
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            "contractCategory": "O",
                                            "contractCategoryDescription": "On-Call",
                                            "contractTypeList": {
                                                "list": [
                                                    {
                                                        "contractType": "TC900",
                                                        "contractNo": "",
                                                        "description": "On-Call Only",
                                                        "onCall": "",
                                                        "orderType": "ZMQ1",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "O",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "12 ",
                                                            "contractExpiryDate": "20250112",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "0.0000",
                                                            "contractOnCallPrice": "869.5600",
                                                            "contractTotalPrice": "869.5600",
                                                            "vatTotalPrice": "999.9940",
                                                            "orgTotalPrice": "869.5600",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ1",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC900",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "O",
                                                            "contractType": "TC900",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "12 ",
                                                                "contractExpiryDate": "20250112",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "0.0000",
                                                                "contractOnCallPrice": "869.5600",
                                                                "contractTotalPrice": "869.5600",
                                                                "vatTotalPrice": "999.9940",
                                                                "orgTotalPrice": "869.5600",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                "vinNumber": "WBAWX320500B29565",
                                "contractNumber": "9999999999",
                                "contractType": "TC210",
                                "contractTypeDescription": "MY : SERVICE PLAN",
                                "contractCategory": "S",
                                "contractPeriod": "060",
                                "validFromDate": "2016-04-29",
                                "validToDate": "2021-04-28",
                                "validKmFrom": "000000000",
                                "validKmTo": "000100000",
                                "contractStatus": "CS07",
                                "contractStatusDescription": "Expired Time",
                                "allowedContractTypesForContractPricing": {
                                    "list": [
                                        {
                                            "contractCategory": "S",
                                            "contractCategoryDescription": "My Plans",
                                            "contractTypeList": {
                                                "list": [
                                                    {
                                                        "contractType": "TC200",
                                                        "contractNo": "",
                                                        "description": "Oil Service Only",
                                                        "onCall": "X",
                                                        "orderType": "ZMQ2",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "24 ",
                                                            "contractExpiryDate": "20260111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "3651.3000",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "3651.3000",
                                                            "vatTotalPrice": "4198.9950",
                                                            "orgTotalPrice": "3651.3000",
                                                            "contractFrequency": "3",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ2",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC200",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC200",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "24 ",
                                                                "contractExpiryDate": "20260111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "3651.3000",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "3651.3000",
                                                                "vatTotalPrice": "4198.9950",
                                                                "orgTotalPrice": "3651.3000",
                                                                "contractFrequency": "3"
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC210",
                                                        "contractNo": "",
                                                        "description": "My Service",
                                                        "onCall": "",
                                                        "orderType": "ZMQ6",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "10733.9700",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "10733.9700",
                                                            "vatTotalPrice": "12344.0655",
                                                            "orgTotalPrice": "10733.9700",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ6",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC210",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC210",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "10733.9700",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "10733.9700",
                                                                "vatTotalPrice": "12344.0655",
                                                                "orgTotalPrice": "10733.9700",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC220",
                                                        "contractNo": "",
                                                        "description": "My Maintenance",
                                                        "onCall": "",
                                                        "orderType": "ZMQ7",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "34171.9500",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "34171.9500",
                                                            "vatTotalPrice": "39297.7425",
                                                            "orgTotalPrice": "34171.9500",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ7",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC220",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC220",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "34171.9500",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "34171.9500",
                                                                "vatTotalPrice": "39297.7425",
                                                                "orgTotalPrice": "34171.9500",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC240",
                                                        "contractNo": "",
                                                        "description": "Oil Service Only",
                                                        "onCall": "X",
                                                        "orderType": "ZMQ9",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "4635.6700",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "4635.6700",
                                                            "vatTotalPrice": "5331.0205",
                                                            "orgTotalPrice": "4635.6700",
                                                            "contractFrequency": "3",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ9",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC240",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC240",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "4635.6700",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "4635.6700",
                                                                "vatTotalPrice": "5331.0205",
                                                                "orgTotalPrice": "4635.6700",
                                                                "contractFrequency": "3"
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            "contractCategory": "O",
                                            "contractCategoryDescription": "On-Call",
                                            "contractTypeList": {
                                                "list": [
                                                    {
                                                        "contractType": "TC900",
                                                        "contractNo": "",
                                                        "description": "On-Call Only",
                                                        "onCall": "",
                                                        "orderType": "ZMQ1",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "O",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "12 ",
                                                            "contractExpiryDate": "20250112",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "0.0000",
                                                            "contractOnCallPrice": "869.5600",
                                                            "contractTotalPrice": "869.5600",
                                                            "vatTotalPrice": "999.9940",
                                                            "orgTotalPrice": "869.5600",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ1",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC900",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "O",
                                                            "contractType": "TC900",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "12 ",
                                                                "contractExpiryDate": "20250112",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "0.0000",
                                                                "contractOnCallPrice": "869.5600",
                                                                "contractTotalPrice": "869.5600",
                                                                "vatTotalPrice": "999.9940",
                                                                "orgTotalPrice": "869.5600",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                "vinNumber": "WBAWX320500B29565",
                                "contractNumber": "9999999999",
                                "contractType": "TC220",
                                "contractTypeDescription": "MY : MAINTENANCE PLAN",
                                "contractCategory": "S",
                                "contractPeriod": "060",
                                "validFromDate": "2016-04-29",
                                "validToDate": "2021-04-28",
                                "validKmFrom": "000000000",
                                "validKmTo": "000100000",
                                "contractStatus": "CS07",
                                "contractStatusDescription": "Expired Time",
                                "allowedContractTypesForContractPricing": {
                                    "list": [
                                        {
                                            "contractCategory": "S",
                                            "contractCategoryDescription": "My Plans",
                                            "contractTypeList": {
                                                "list": [
                                                    {
                                                        "contractType": "TC200",
                                                        "contractNo": "",
                                                        "description": "Oil Service Only",
                                                        "onCall": "X",
                                                        "orderType": "ZMQ2",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "24 ",
                                                            "contractExpiryDate": "20260111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "3651.3000",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "3651.3000",
                                                            "vatTotalPrice": "4198.9950",
                                                            "orgTotalPrice": "3651.3000",
                                                            "contractFrequency": "3",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ2",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC200",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC200",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "24 ",
                                                                "contractExpiryDate": "20260111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "3651.3000",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "3651.3000",
                                                                "vatTotalPrice": "4198.9950",
                                                                "orgTotalPrice": "3651.3000",
                                                                "contractFrequency": "3"
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC210",
                                                        "contractNo": "",
                                                        "description": "My Service",
                                                        "onCall": "",
                                                        "orderType": "ZMQ6",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "10733.9700",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "10733.9700",
                                                            "vatTotalPrice": "12344.0655",
                                                            "orgTotalPrice": "10733.9700",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ6",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC210",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC210",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "10733.9700",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "10733.9700",
                                                                "vatTotalPrice": "12344.0655",
                                                                "orgTotalPrice": "10733.9700",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC220",
                                                        "contractNo": "",
                                                        "description": "My Maintenance",
                                                        "onCall": "",
                                                        "orderType": "ZMQ7",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "34171.9500",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "34171.9500",
                                                            "vatTotalPrice": "39297.7425",
                                                            "orgTotalPrice": "34171.9500",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ7",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC220",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC220",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "34171.9500",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "34171.9500",
                                                                "vatTotalPrice": "39297.7425",
                                                                "orgTotalPrice": "34171.9500",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC240",
                                                        "contractNo": "",
                                                        "description": "Oil Service Only",
                                                        "onCall": "X",
                                                        "orderType": "ZMQ9",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "4635.6700",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "4635.6700",
                                                            "vatTotalPrice": "5331.0205",
                                                            "orgTotalPrice": "4635.6700",
                                                            "contractFrequency": "3",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ9",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC240",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC240",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "4635.6700",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "4635.6700",
                                                                "vatTotalPrice": "5331.0205",
                                                                "orgTotalPrice": "4635.6700",
                                                                "contractFrequency": "3"
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            "contractCategory": "O",
                                            "contractCategoryDescription": "On-Call",
                                            "contractTypeList": {
                                                "list": [
                                                    {
                                                        "contractType": "TC900",
                                                        "contractNo": "",
                                                        "description": "On-Call Only",
                                                        "onCall": "",
                                                        "orderType": "ZMQ1",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "O",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "12 ",
                                                            "contractExpiryDate": "20250112",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "0.0000",
                                                            "contractOnCallPrice": "869.5600",
                                                            "contractTotalPrice": "869.5600",
                                                            "vatTotalPrice": "999.9940",
                                                            "orgTotalPrice": "869.5600",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ1",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC900",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "O",
                                                            "contractType": "TC900",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "12 ",
                                                                "contractExpiryDate": "20250112",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "0.0000",
                                                                "contractOnCallPrice": "869.5600",
                                                                "contractTotalPrice": "869.5600",
                                                                "vatTotalPrice": "999.9940",
                                                                "orgTotalPrice": "869.5600",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            },
                            {
                                "vinNumber": "WBAWX320500B29565",
                                "contractNumber": "9999999999",
                                "contractType": "TC240",
                                "contractTypeDescription": "BMW Black : Oil Service Only",
                                "contractCategory": "S",
                                "contractPeriod": "060",
                                "validFromDate": "2016-04-29",
                                "validToDate": "2021-04-28",
                                "validKmFrom": "000000000",
                                "validKmTo": "000100000",
                                "contractStatus": "CS07",
                                "contractStatusDescription": "Expired Time",
                                "allowedContractTypesForContractPricing": {
                                    "list": [
                                        {
                                            "contractCategory": "S",
                                            "contractCategoryDescription": "My Plans",
                                            "contractTypeList": {
                                                "list": [
                                                    {
                                                        "contractType": "TC200",
                                                        "contractNo": "",
                                                        "description": "Oil Service Only",
                                                        "onCall": "X",
                                                        "orderType": "ZMQ2",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "24 ",
                                                            "contractExpiryDate": "20260111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "3651.3000",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "3651.3000",
                                                            "vatTotalPrice": "4198.9950",
                                                            "orgTotalPrice": "3651.3000",
                                                            "contractFrequency": "3",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ2",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC200",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC200",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "24 ",
                                                                "contractExpiryDate": "20260111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "3651.3000",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "3651.3000",
                                                                "vatTotalPrice": "4198.9950",
                                                                "orgTotalPrice": "3651.3000",
                                                                "contractFrequency": "3"
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC210",
                                                        "contractNo": "",
                                                        "description": "My Service",
                                                        "onCall": "",
                                                        "orderType": "ZMQ6",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "10733.9700",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "10733.9700",
                                                            "vatTotalPrice": "12344.0655",
                                                            "orgTotalPrice": "10733.9700",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ6",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC210",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC210",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "10733.9700",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "10733.9700",
                                                                "vatTotalPrice": "12344.0655",
                                                                "orgTotalPrice": "10733.9700",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC220",
                                                        "contractNo": "",
                                                        "description": "My Maintenance",
                                                        "onCall": "",
                                                        "orderType": "ZMQ7",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "34171.9500",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "34171.9500",
                                                            "vatTotalPrice": "39297.7425",
                                                            "orgTotalPrice": "34171.9500",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ7",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC220",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC220",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "34171.9500",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "34171.9500",
                                                                "vatTotalPrice": "39297.7425",
                                                                "orgTotalPrice": "34171.9500",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC240",
                                                        "contractNo": "",
                                                        "description": "Oil Service Only",
                                                        "onCall": "X",
                                                        "orderType": "ZMQ9",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "4635.6700",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "4635.6700",
                                                            "vatTotalPrice": "5331.0205",
                                                            "orgTotalPrice": "4635.6700",
                                                            "contractFrequency": "3",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ9",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC240",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC240",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "4635.6700",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "4635.6700",
                                                                "vatTotalPrice": "5331.0205",
                                                                "orgTotalPrice": "4635.6700",
                                                                "contractFrequency": "3"
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            "contractCategory": "O",
                                            "contractCategoryDescription": "On-Call",
                                            "contractTypeList": {
                                                "list": [
                                                    {
                                                        "contractType": "TC900",
                                                        "contractNo": "",
                                                        "description": "On-Call Only",
                                                        "onCall": "",
                                                        "orderType": "ZMQ1",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "O",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "12 ",
                                                            "contractExpiryDate": "20250112",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "0.0000",
                                                            "contractOnCallPrice": "869.5600",
                                                            "contractTotalPrice": "869.5600",
                                                            "vatTotalPrice": "999.9940",
                                                            "orgTotalPrice": "869.5600",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ1",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC900",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "O",
                                                            "contractType": "TC900",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "12 ",
                                                                "contractExpiryDate": "20250112",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "0.0000",
                                                                "contractOnCallPrice": "869.5600",
                                                                "contractTotalPrice": "869.5600",
                                                                "vatTotalPrice": "999.9940",
                                                                "orgTotalPrice": "869.5600",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                },
                {
                    "contractCategory": "M",
                    "contractCategoryDescription": "Motorplan",
                    "activeContractList": {
                        "list": [
                            {
                                "vinNumber": "WBAWX320500B29565",
                                "contractNumber": "0253827087",
                                "contractType": "TC010",
                                "contractTypeDescription": "Built-In",
                                "contractCategory": "M",
                                "contractPeriod": "060",
                                "validFromDate": "2016-04-29",
                                "validToDate": "2021-04-28",
                                "validKmFrom": "000000000",
                                "validKmTo": "000100000",
                                "contractStatus": "CS07",
                                "contractStatusDescription": "Expired Time",
                                "allowedContractTypesForContractPricing": {
                                    "list": [
                                        {
                                            "contractCategory": "S",
                                            "contractCategoryDescription": "My Plans",
                                            "contractTypeList": {
                                                "list": [
                                                    {
                                                        "contractType": "TC200",
                                                        "contractNo": "",
                                                        "description": "Oil Service Only",
                                                        "onCall": "X",
                                                        "orderType": "ZMQ2",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "24 ",
                                                            "contractExpiryDate": "20260111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "3651.3000",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "3651.3000",
                                                            "vatTotalPrice": "4198.9950",
                                                            "orgTotalPrice": "3651.3000",
                                                            "contractFrequency": "3",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ2",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC200",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC200",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "24 ",
                                                                "contractExpiryDate": "20260111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "3651.3000",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "3651.3000",
                                                                "vatTotalPrice": "4198.9950",
                                                                "orgTotalPrice": "3651.3000",
                                                                "contractFrequency": "3"
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC210",
                                                        "contractNo": "",
                                                        "description": "My Service",
                                                        "onCall": "",
                                                        "orderType": "ZMQ6",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "10733.9700",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "10733.9700",
                                                            "vatTotalPrice": "12344.0655",
                                                            "orgTotalPrice": "10733.9700",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ6",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC210",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC210",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "10733.9700",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "10733.9700",
                                                                "vatTotalPrice": "12344.0655",
                                                                "orgTotalPrice": "10733.9700",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC220",
                                                        "contractNo": "",
                                                        "description": "My Maintenance",
                                                        "onCall": "",
                                                        "orderType": "ZMQ7",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "34171.9500",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "34171.9500",
                                                            "vatTotalPrice": "39297.7425",
                                                            "orgTotalPrice": "34171.9500",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ7",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC220",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC220",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "34171.9500",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "34171.9500",
                                                                "vatTotalPrice": "39297.7425",
                                                                "orgTotalPrice": "34171.9500",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "contractType": "TC240",
                                                        "contractNo": "",
                                                        "description": "Oil Service Only",
                                                        "onCall": "X",
                                                        "orderType": "ZMQ9",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "S",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "36 ",
                                                            "contractExpiryDate": "20270111",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "4635.6700",
                                                            "contractOnCallPrice": " .0000",
                                                            "contractTotalPrice": "4635.6700",
                                                            "vatTotalPrice": "5331.0205",
                                                            "orgTotalPrice": "4635.6700",
                                                            "contractFrequency": "3",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ9",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC240",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "S",
                                                            "contractType": "TC240",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "36 ",
                                                                "contractExpiryDate": "20270111",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "4635.6700",
                                                                "contractOnCallPrice": " .0000",
                                                                "contractTotalPrice": "4635.6700",
                                                                "vatTotalPrice": "5331.0205",
                                                                "orgTotalPrice": "4635.6700",
                                                                "contractFrequency": "3"
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            "contractCategory": "O",
                                            "contractCategoryDescription": "On-Call",
                                            "contractTypeList": {
                                                "list": [
                                                    {
                                                        "contractType": "TC900",
                                                        "contractNo": "",
                                                        "description": "On-Call Only",
                                                        "onCall": "",
                                                        "orderType": "ZMQ1",
                                                        "idReq": "",
                                                        "origContractType": "TC010",
                                                        "contractCategory": "O",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "12 ",
                                                            "contractExpiryDate": "20250112",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "0.0000",
                                                            "contractOnCallPrice": "869.5600",
                                                            "contractTotalPrice": "869.5600",
                                                            "vatTotalPrice": "999.9940",
                                                            "orgTotalPrice": "869.5600",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ1",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC900",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "O",
                                                            "contractType": "TC900",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "12 ",
                                                                "contractExpiryDate": "20250112",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "0.0000",
                                                                "contractOnCallPrice": "869.5600",
                                                                "contractTotalPrice": "869.5600",
                                                                "vatTotalPrice": "999.9940",
                                                                "orgTotalPrice": "869.5600",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                },
                {
                    "contractCategory": "O",
                    "contractCategoryDescription": "On-Call",
                    "activeContractList": {
                        "list": [
                            {
                                "vinNumber": "WBAWX320500B29565",
                                "contractNumber": "0253827087",
                                "contractType": "TC900",
                                "contractTypeDescription": "On Call Service",
                                "contractCategory": "O",
                                "contractPeriod": "060",
                                "validFromDate": "2016-04-29",
                                "validToDate": "2021-04-28",
                                "validKmFrom": "000000000",
                                "validKmTo": "000100000",
                                "contractStatus": "CS07",
                                "contractStatusDescription": "Expired Time",
                                "allowedContractTypesForContractPricing": {
                                    "list": [
                                        {
                                            "contractCategory": "O",
                                            "contractCategoryDescription": "On-Call",
                                            "contractTypeList": {
                                                "list": [
                                                    {
                                                        "contractType": "TC900",
                                                        "contractNo": "",
                                                        "description": "On-Call Only",
                                                        "onCall": "",
                                                        "orderType": "",
                                                        "idReq": "",
                                                        "origContractType": "",
                                                        "contractCategory": "O",
                                                        "contractSurchargeList": {
                                                            "list": []
                                                        },
                                                        "lowestPrice": {
                                                            "contractMonths": "12 ",
                                                            "contractExpiryDate": "20250112",
                                                            "contractExpiryMileage": "191652",
                                                            "contractPrice": "0.0000",
                                                            "contractOnCallPrice": "869.5600",
                                                            "contractTotalPrice": "869.5600",
                                                            "vatTotalPrice": "999.9940",
                                                            "orgTotalPrice": "869.5600",
                                                            "contractFrequency": "",
                                                            "quoteNumber": "",
                                                            "contKmLimit": "",
                                                            "startDate": "",
                                                            "startKm": "",
                                                            "endKm": "",
                                                            "contractCKunnr": "",
                                                            "inputDealerNo": "",
                                                            "quoteType": "ZMQ1",
                                                            "equnr": "",
                                                            "vwundat": "",
                                                            "quoteLoadStatus": "",
                                                            "vin": "WBAWX320500B29565",
                                                            "inputContractType": "TC900",
                                                            "inputSurchargeId": "",
                                                            "contractCategory": "O",
                                                            "contractType": "TC900",
                                                            "z18ContractPrice": {
                                                                "contractMonths": "12 ",
                                                                "contractExpiryDate": "20250112",
                                                                "contractExpiryMileage": "191652",
                                                                "contractPrice": "0.0000",
                                                                "contractOnCallPrice": "869.5600",
                                                                "contractTotalPrice": "869.5600",
                                                                "vatTotalPrice": "999.9940",
                                                                "orgTotalPrice": "869.5600",
                                                                "contractFrequency": ""
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                }
            ],
            "quoteList": [
                {
                    "contractCategory": "S",
                    "contractCategoryDescription": "My Plans",
                    "quoteInclusiveList": {
                        "list": [
                            {
                                "vbeln": "0255732875",
                                "zContPeriod": "036",
                                "zContKmLimit": "0000000",
                                "zStartDate": "2023/11/24",
                                "zEndDate": "2026/11/23",
                                "zStartKm": "00092000",
                                "zEndKm": "  192000",
                                "cKunnr": "0013809002",
                                "dKunnr": "21730MPL",
                                "auart": "ZMQ9",
                                "equNr": "000000000010641179",
                                "vwunDat": "0000-00-00",
                                "txt04": "CS01",
                                "zBodyNo": "00B29565",
                                "matNr": "TC240",
                                "cost": "3000.00",
                                "onCallCost": "1000.00",
                                "totalCost": "4000.00",
                                "totalIncl": "4600.00",
                                "bstnk": "00B29565",
                                "zSurdis": "",
                                "totalOuts": "",
                                "cName": "",
                                "angDt": "2023-11-24",
                                "bndDt": "2023-12-15",
                                "zZvin": "WBAWX320500B29565",
                                "onCall": "",
                                "contractCategory": "S",
                                "contractCategoryDescription": "BMW Black : Oil Service Only"
                            },
                            {
                                "vbeln": "0255732876",
                                "zContPeriod": "036",
                                "zContKmLimit": "0000000",
                                "zStartDate": "2023/11/24",
                                "zEndDate": "2026/11/23",
                                "zStartKm": "00092000",
                                "zEndKm": "  192000",
                                "cKunnr": "0013809002",
                                "dKunnr": "21730MPL",
                                "auart": "ZMQ9",
                                "equNr": "000000000010641179",
                                "vwunDat": "0000-00-00",
                                "txt04": "CS01",
                                "zBodyNo": "00B29565",
                                "matNr": "TC240",
                                "cost": "3000.00",
                                "onCallCost": "",
                                "totalCost": "3000.00",
                                "totalIncl": "3450.00",
                                "bstnk": "00B29565",
                                "zSurdis": "",
                                "totalOuts": "",
                                "cName": "",
                                "angDt": "2023-11-24",
                                "bndDt": "2023-12-15",
                                "zZvin": "WBAWX320500B29565",
                                "onCall": "",
                                "contractCategory": "S",
                                "contractCategoryDescription": "BMW Black : Oil Service Only"
                            }
                        ]
                    }
                },
                {
                    "contractCategory": "O",
                    "contractCategoryDescription": "On-Call",
                    "quoteInclusiveList": {
                        "list": [
                            {
                                "vbeln": "0255732847",
                                "zContPeriod": "036",
                                "zContKmLimit": "0000000",
                                "zStartDate": "2023/10/31",
                                "zEndDate": "2026/10/30",
                                "zStartKm": "00008448",
                                "zEndKm": "  113447",
                                "cKunnr": "0013809002",
                                "dKunnr": "48003MPL",
                                "auart": "ZMQ1",
                                "equNr": "000000000010641179",
                                "vwunDat": "0000-00-00",
                                "txt04": "CS02",
                                "zBodyNo": "00B29565",
                                "matNr": "TC900",
                                "cost": "",
                                "onCallCost": "2608.68",
                                "totalCost": "2608.68",
                                "totalIncl": "2999.98",
                                "bstnk": "00B29565",
                                "zSurdis": "",
                                "totalOuts": "",
                                "cName": "",
                                "angDt": "2023-10-31",
                                "bndDt": "2023-11-21",
                                "zZvin": "WBAWX320500B29565",
                                "onCall": "",
                                "contractCategory": "O",
                                "contractCategoryDescription": "On Call Service"
                            }
                        ]
                    }
                }
            ]
        }
    }))
}

function getContractVisiblePrices() {
    return of(new HttpResponse({
        status: 200,
        body: {
            "messageList": [],
    "etReturn": {
        "debug": false,
        "information": false,
        "warning": false,
        "error": false,
        "messages": [],
        "debugMessages": [],
        "infoMessages": [],
        "warningMessages": [],
        "errorMessages": []
    },
    "invalid": " ",
    "systMessage": " ",
    "userMessage": " ",
    "contractPriceList": {
        "list": [
            {
                "contractMonths": "12 ",
                "contractExpiryDate": "20261104",
                "contractExpiryMileage": "100100",
                "contractPrice": "0.0000",
                "contractOnCallPrice": "869.5600",
                "contractTotalPrice": "869.5600",
                "vatTotalPrice": "869.5600",
                "orgTotalPrice": "869.5600",
                "contractFrequency": "",
                "quoteNumber": "          ",
                "contKmLimit": "0100100",
                "startDate": "20240109",
                "startKm": "1       ",
                "endKm": "100100  ",
                "contractCKunnr": "          ",
                "inputDealerNo": "32717",
                "quoteType": "ZMQ1",
                "equnr": "                  ",
                "vwundat": "00000000",
                "quoteLoadStatus": "CS01",
                "vin": "WBA5R12080AK73435",
                "inputContractType": "TC900",
                "inputSurchargeId": "",
                "z18ContractPrice": {
                    "contractMonths": "12 ",
                    "contractExpiryDate": "20261104",
                    "contractExpiryMileage": "100100",
                    "contractPrice": "0.0000",
                    "contractOnCallPrice": "869.5600",
                    "contractTotalPrice": "869.5600",
                    "vatTotalPrice": "869.5600",
                    "orgTotalPrice": "869.5600",
                    "contractFrequency": ""
                }
            },
            {
                "contractMonths": "24 ",
                "contractExpiryDate": "20271104",
                "contractExpiryMileage": "100100",
                "contractPrice": "0.0000",
                "contractOnCallPrice": "1739.1200",
                "contractTotalPrice": "1739.1200",
                "vatTotalPrice": "1739.1200",
                "orgTotalPrice": "1739.1200",
                "contractFrequency": "",
                "quoteNumber": "          ",
                "contKmLimit": "0100100",
                "startDate": "20240109",
                "startKm": "1       ",
                "endKm": "100100  ",
                "contractCKunnr": "          ",
                "inputDealerNo": "32717",
                "quoteType": "ZMQ1",
                "equnr": "                  ",
                "vwundat": "00000000",
                "quoteLoadStatus": "CS01",
                "vin": "WBA5R12080AK73435",
                "inputContractType": "TC900",
                "inputSurchargeId": "",
                "z18ContractPrice": {
                    "contractMonths": "24 ",
                    "contractExpiryDate": "20271104",
                    "contractExpiryMileage": "100100",
                    "contractPrice": "0.0000",
                    "contractOnCallPrice": "1739.1200",
                    "contractTotalPrice": "1739.1200",
                    "vatTotalPrice": "1739.1200",
                    "orgTotalPrice": "1739.1200",
                    "contractFrequency": ""
                }
            },
            {
                "contractMonths": "36 ",
                "contractExpiryDate": "20281104",
                "contractExpiryMileage": "100100",
                "contractPrice": "0.0000",
                "contractOnCallPrice": "2608.6800",
                "contractTotalPrice": "2608.6800",
                "vatTotalPrice": "2608.6800",
                "orgTotalPrice": "2608.6800",
                "contractFrequency": "",
                "quoteNumber": "          ",
                "contKmLimit": "0100100",
                "startDate": "20240109",
                "startKm": "1       ",
                "endKm": "100100  ",
                "contractCKunnr": "          ",
                "inputDealerNo": "32717",
                "quoteType": "ZMQ1",
                "equnr": "                  ",
                "vwundat": "00000000",
                "quoteLoadStatus": "CS01",
                "vin": "WBA5R12080AK73435",
                "inputContractType": "TC900",
                "inputSurchargeId": "",
                "z18ContractPrice": {
                    "contractMonths": "36 ",
                    "contractExpiryDate": "20281104",
                    "contractExpiryMileage": "100100",
                    "contractPrice": "0.0000",
                    "contractOnCallPrice": "2608.6800",
                    "contractTotalPrice": "2608.6800",
                    "vatTotalPrice": "2608.6800",
                    "orgTotalPrice": "2608.6800",
                    "contractFrequency": ""
                }
            }
        ]
    },
    "salesExecList": {
        "list": []
    }
        }
    }))
}

function vehicleTyreSearch() {
    return of(new HttpResponse({
        status: 200,
        body: {
            "etReturn": null,
    "z55Description": [
        {
            "fieldInd": "0",
            "description": "Ex-Fact"
        },
        {
            "fieldInd": "1",
            "description": "Alternate"
        },
        {
            "fieldInd": "A",
            "description": "All"
        },
        {
            "fieldInd": "F",
            "description": "Front"
        },
        {
            "fieldInd": "R",
            "description": "Rear"
        },
        {
            "fieldInd": "Y",
            "description": "Yes"
        },
        {
            "fieldInd": "N",
            "description": "No"
        }
    ],
    "z55TyreInfo": [
        {
            "zvbrand": "",
            "zvinno": "0AK73435",
            "zvintype": "5R12",
            "zbasictypeno": "5R12",
            "zbasictype": "330i Sedan",
            "zseriesdevt": "G20",
            "ztyresupplcode": "B",
            "tyreind": "1",
            "fitted": "F",
            "rimcode": "1PL",
            "rimdescr": "19\" M Lt/Aly wheels Double-spoke 791 M Bicolour / MT, runflat tyres",
            "runflat": "N",
            "tyresize": "225/40 R19 093Y",
            "manufacturer": "Michelin",
            "manufdesc": "Pilot Sport 4S*",
            "matnr": "36122464344",
            "maktx": "225/40R19 93Y PILOT SPORT 4S*",
            "supersession": "",
            "freestockLmdc": "16.000",
            "freestockKzn": "1.000",
            "freestockCpt": "0.000",
            "nextEta": "NoPurchOrd",
            "retailPrice": "4382.40",
            "zvin": "WBA5R12080AK73435"
        },
        {
            "zvbrand": "",
            "zvinno": "0AK73435",
            "zvintype": "5R12",
            "zbasictypeno": "5R12",
            "zbasictype": "330i Sedan",
            "zseriesdevt": "G20",
            "ztyresupplcode": "B",
            "tyreind": "1",
            "fitted": "R",
            "rimcode": "1PL",
            "rimdescr": "19\" M Lt/Aly wheels Double-spoke 791 M Bicolour / MT, runflat tyres",
            "runflat": "N",
            "tyresize": "255/35 R19 096Y",
            "manufacturer": "Michelin",
            "manufdesc": "Pilot Sport 4S*",
            "matnr": "36122464345",
            "maktx": "255/35R19 96Y PILOT SPORT 4S*",
            "supersession": "",
            "freestockLmdc": "12.000",
            "freestockKzn": "3.000",
            "freestockCpt": "0.000",
            "nextEta": "NoPurchOrd",
            "retailPrice": "4339.20",
            "zvin": "WBA5R12080AK73435"
        }
    ]
        }
    }))
}

function getVtsdata() {
    return of(new HttpResponse({
        status: 200,
        body: [
            {
                "techDescription": "Capacity (cc)",
                "techValue": "1998"
            },
            {
                "techDescription": "Rated Power/Rated Engine Speed (kW/rpm)",
                "techValue": "190/5000 - 6500"
            },
            {
                "techDescription": "Top Speed (km/h)",
                "techValue": "250"
            },
            {
                "techDescription": "Acceleration 0-100km/h (s)",
                "techValue": "5.8"
            },
            {
                "techDescription": "In Town (l/100km)",
                "techValue": "7.9"
            },
            {
                "techDescription": "Out of Town (l/100km)",
                "techValue": "5.6"
            }
        ]
        
        
    }))

}

function getCiamodelKits() {
    return of(new HttpResponse({
        status: 200,
        body:{
            "altpart": [
                {
                    "altdate": "20110402",
                    "altdescr": "HEX BOLT M12X1.5X43",
                    "altpart": "34111162783",
                    "defect": "99110054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34106864424",
                    "partqty": "000",
                    "price": "97.46",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20081101",
                    "altdescr": "HEX BOLT M12X1.5X43 E65",
                    "altpart": "34116754644",
                    "defect": "99110054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34106864424",
                    "partqty": "000",
                    "price": "17.78",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20170711",
                    "altdescr": "HEX BOLT M12X1.5X43 E65",
                    "altpart": "34116772117",
                    "defect": "99110054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34106864424",
                    "partqty": "000",
                    "price": "63.75",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20230410",
                    "altdescr": "BRAKE DISC  LIGHTWEIGHT VENTILATED RIGHT",
                    "altpart": "34116860912",
                    "defect": "99110054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34108858706",
                    "partqty": "000",
                    "price": "3939.00",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20161106",
                    "altdescr": "REPAIR KIT  BRAKE PADS ASBESTOS-FREE NLA",
                    "altpart": "34116874430",
                    "defect": "99110054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34116889570",
                    "partqty": "000",
                    "price": "4602.38",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20180327",
                    "altdescr": "REPAIR KIT  BRAKE PADS ASBESTOS-FREE",
                    "altpart": "34116880734",
                    "defect": "99110054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34116889570",
                    "partqty": "000",
                    "price": "4717.58",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20221105",
                    "altdescr": "REPAIR KIT  BRAKE PADS ASBESTOS-FREE",
                    "altpart": "34116888457",
                    "defect": "99110054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34116889570",
                    "partqty": "000",
                    "price": "5040.29",
                    "tprice": "0.00"
                },
                {
                    "altdate": "19950905",
                    "altdescr": "BOLT M8X12",
                    "altpart": "34111157343",
                    "defect": "99110054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34211161806",
                    "partqty": "000",
                    "price": "7.92",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20091101",
                    "altdescr": "PLASTILUBE- 5,5ML",
                    "altpart": "81229407103",
                    "defect": "99110054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "83192158851",
                    "partqty": "000",
                    "price": "10.94",
                    "tprice": "0.00"
                },
                {
                    "altdate": "19990302",
                    "altdescr": "PLASTI LUBE(1=100)",
                    "altpart": "81229407628",
                    "defect": "99110054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "83192158851",
                    "partqty": "000",
                    "price": "36.51",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20161106",
                    "altdescr": "REPAIR KIT  BRAKE PADS ASBESTOS-FREE NLA",
                    "altpart": "34116874430",
                    "defect": "99110475MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34116889570",
                    "partqty": "000",
                    "price": "4602.38",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20180327",
                    "altdescr": "REPAIR KIT  BRAKE PADS ASBESTOS-FREE",
                    "altpart": "34116880734",
                    "defect": "99110475MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34116889570",
                    "partqty": "000",
                    "price": "4717.58",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20221105",
                    "altdescr": "REPAIR KIT  BRAKE PADS ASBESTOS-FREE",
                    "altpart": "34116888457",
                    "defect": "99110475MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34116889570",
                    "partqty": "000",
                    "price": "5040.29",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20091101",
                    "altdescr": "PLASTILUBE- 5,5ML",
                    "altpart": "81229407103",
                    "defect": "99110475MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "83192158851",
                    "partqty": "000",
                    "price": "10.94",
                    "tprice": "0.00"
                },
                {
                    "altdate": "19990302",
                    "altdescr": "PLASTI LUBE(1=100)",
                    "altpart": "81229407628",
                    "defect": "99110475MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "83192158851",
                    "partqty": "000",
                    "price": "36.51",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20190428",
                    "altdescr": "SPARK PLUG  HIGH POWER",
                    "altpart": "12120046646",
                    "defect": "99122521MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "12122455258",
                    "partqty": "000",
                    "price": "314.44",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20210406",
                    "altdescr": "BRAKE DISC  LIGHTWEIGHT  VENTILATED",
                    "altpart": "34216860925",
                    "defect": "99210054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34208853554",
                    "partqty": "000",
                    "price": "2869.33",
                    "tprice": "0.00"
                },
                {
                    "altdate": "19950905",
                    "altdescr": "BOLT M8X12",
                    "altpart": "34111157343",
                    "defect": "99210054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "34211161806",
                    "partqty": "000",
                    "price": "7.92",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20091101",
                    "altdescr": "PLASTILUBE- 5,5ML",
                    "altpart": "81229407103",
                    "defect": "99210054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "83192158851",
                    "partqty": "000",
                    "price": "10.94",
                    "tprice": "0.00"
                },
                {
                    "altdate": "19990302",
                    "altdescr": "PLASTI LUBE(1=100)",
                    "altpart": "81229407628",
                    "defect": "99210054MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "83192158851",
                    "partqty": "000",
                    "price": "36.51",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20210211",
                    "altdescr": "SET OF WIPER BLADES F30",
                    "altpart": "61612241357",
                    "defect": "99510249MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "61615A43586",
                    "partqty": "000",
                    "price": "619.35",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20000712",
                    "altdescr": "BRAKE FLUID DOT-4 500ML",
                    "altpart": "07536752631",
                    "defect": "99510440MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "81220142684",
                    "partqty": "000",
                    "price": "23.65",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20011204",
                    "altdescr": "BRAKE FLUID 500ML",
                    "altpart": "81229407511",
                    "defect": "99510440MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "81220142684",
                    "partqty": "000",
                    "price": "14.60",
                    "tprice": "0.00"
                },
                {
                    "altdate": "20230510",
                    "altdescr": "BMW MOTOR OIL SAE 5W-30 LONGLIFE 01",
                    "altpart": "83210305686",
                    "defect": "99600668MP",
                    "discount": "0.00",
                    "inclVat": "0.00",
                    "partind": "S",
                    "partkey": "01885A4F8E5",
                    "partqty": "000",
                    "price": "14.55",
                    "tprice": "0.00"
                }
            ],
            "defect": [
                {
                    "defect": "99011329MP",
                    "descr": "CARWASHBODYANDINSIDE",
                    "inclVat": "231.73",
                    "myear": "2021",
                    "price": "201.50",
                    "seqnr": "1"
                },
                {
                    "defect": "99110054MP",
                    "descr": "FRT BRAKE DISCS SCORED/WORN",
                    "inclVat": "12788.39",
                    "myear": "2021",
                    "price": "11120.34",
                    "seqnr": "1"
                },
                {
                    "defect": "99110475MP",
                    "descr": "FRONT BRAKE PADS WORN(AND FEELER)",
                    "inclVat": "7274.60",
                    "myear": "2021",
                    "price": "6325.74",
                    "seqnr": "1"
                },
                {
                    "defect": "99122521MP",
                    "descr": "REPLACE SPARK PLUGS",
                    "inclVat": "1990.96",
                    "myear": "2021",
                    "price": "1731.27",
                    "seqnr": "1"
                },
                {
                    "defect": "99210054MP",
                    "descr": "REAR BRAKE DISCS SCORED/WORN",
                    "inclVat": "11474.38",
                    "myear": "2021",
                    "price": "9977.72",
                    "seqnr": "1"
                },
                {
                    "defect": "99210429MP",
                    "descr": "REAR BRAKE PADS WORN(AND FEELER)",
                    "inclVat": "3943.89",
                    "myear": "2021",
                    "price": "3429.47",
                    "seqnr": "1"
                },
                {
                    "defect": "99210440MP",
                    "descr": "CHARGING BATTERY",
                    "inclVat": "0.00",
                    "myear": "2021",
                    "price": "0.00",
                    "seqnr": "1"
                },
                {
                    "defect": "99510249MP",
                    "descr": "WIPER RUBBER INSERTS",
                    "inclVat": "733.99",
                    "myear": "2021",
                    "price": "638.25",
                    "seqnr": "1"
                },
                {
                    "defect": "99510440MP",
                    "descr": "BRAKE FLUID FLUSH",
                    "inclVat": "704.02",
                    "myear": "2021",
                    "price": "612.19",
                    "seqnr": "1"
                },
                {
                    "defect": "99600668MP",
                    "descr": "OIL CHANGE CBS",
                    "inclVat": "1585.70",
                    "myear": "2021",
                    "price": "1378.87",
                    "seqnr": "1"
                },
                {
                    "defect": "99600670MP",
                    "descr": "VEHICLE CHECK CBS",
                    "inclVat": "695.16",
                    "myear": "2021",
                    "price": "604.49",
                    "seqnr": "1"
                },
                {
                    "defect": "99600671MP",
                    "descr": "AIR CLEANER CHANGE",
                    "inclVat": "825.88",
                    "myear": "2021",
                    "price": "718.16",
                    "seqnr": "3"
                },
                {
                    "defect": "99600999MP",
                    "descr": "STANDARD SCOPE CBS",
                    "inclVat": "231.73",
                    "myear": "2021",
                    "price": "201.50",
                    "seqnr": "1"
                },
                {
                    "defect": "99643151MP",
                    "descr": "AIRCON MICRO-FILTER IN/SERVICE",
                    "inclVat": "860.72",
                    "myear": "2021",
                    "price": "748.45",
                    "seqnr": "1"
                }
            ],
            "labour": [
                {
                    "defect": "99011329MP",
                    "descr": "Vehicle surface wash and interior vehicl",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "labourkey": "0011329",
                    "labourunit": "2",
                    "price": "100.75",
                    "tprice": "201.50"
                },
                {
                    "defect": "99110054MP",
                    "descr": "R&R BOTH FRNT BRAKE DISCS",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "labourkey": "3411220",
                    "labourunit": "12",
                    "price": "100.75",
                    "tprice": "1208.97"
                },
                {
                    "defect": "99110475MP",
                    "descr": "Service, front brakes",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "labourkey": "0000612",
                    "labourunit": "7",
                    "price": "100.75",
                    "tprice": "705.23"
                },
                {
                    "defect": "99122521MP",
                    "descr": "Spark plug service",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "labourkey": "0000622",
                    "labourunit": "4",
                    "price": "100.75",
                    "tprice": "402.99"
                },
                {
                    "defect": "99210054MP",
                    "descr": "R/R BOTH REAR DISCS",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "labourkey": "3421320",
                    "labourunit": "12",
                    "price": "100.75",
                    "tprice": "1208.97"
                },
                {
                    "defect": "99210429MP",
                    "descr": "Service, rear brakes",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "labourkey": "0000614",
                    "labourunit": "8",
                    "price": "100.75",
                    "tprice": "805.98"
                },
                {
                    "defect": "99510440MP",
                    "descr": "Brake fluid service",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "labourkey": "0000618",
                    "labourunit": "4",
                    "price": "100.75",
                    "tprice": "402.99"
                },
                {
                    "defect": "99600668MP",
                    "descr": "Engine oil service",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "labourkey": "0000610",
                    "labourunit": "3",
                    "price": "100.75",
                    "tprice": "302.24"
                },
                {
                    "defect": "99600670MP",
                    "descr": "Service, vehicle check",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "labourkey": "0000624",
                    "labourunit": "6",
                    "price": "100.75",
                    "tprice": "604.49"
                },
                {
                    "defect": "99600671MP",
                    "descr": "Service - air filter element",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "labourkey": "0000602",
                    "labourunit": "1",
                    "price": "100.75",
                    "tprice": "100.75"
                },
                {
                    "defect": "99600999MP",
                    "descr": "Service - standard scope",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "labourkey": "0000105",
                    "labourunit": "2",
                    "price": "100.75",
                    "tprice": "201.50"
                },
                {
                    "defect": "99643151MP",
                    "descr": "Microfilter service",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "labourkey": "0000616",
                    "labourunit": "2",
                    "price": "100.75",
                    "tprice": "201.50"
                }
            ],
            "parts": [
                {
                    "defect": "99110054MP",
                    "descr": "TORX SCREW WITH WASHER",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34106864424",
                    "partqty": "4",
                    "price": "78.80",
                    "tprice": "315.20"
                },
                {
                    "defect": "99110054MP",
                    "descr": "",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34108858706",
                    "partqty": "1",
                    "price": "0.00",
                    "tprice": "0.00"
                },
                {
                    "defect": "99110054MP",
                    "descr": "BRAKE DISC  LIGHTWEIGHT  VENTILATED LEFT",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34116860911",
                    "partqty": "1",
                    "price": "3939.00",
                    "tprice": "3939.00"
                },
                {
                    "defect": "99110054MP",
                    "descr": "REPAIR KIT, BRAKE PADS ASBESTOS-FREE",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34116889570",
                    "partqty": "1",
                    "price": "5191.63",
                    "tprice": "5191.63"
                },
                {
                    "defect": "99110054MP",
                    "descr": "HEX BOLT M8 X 14",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34211161806",
                    "partqty": "2",
                    "price": "18.33",
                    "tprice": "36.66"
                },
                {
                    "defect": "99110054MP",
                    "descr": "BRAKE PAD WEAR SENSOR, FRONT",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34356870349",
                    "partqty": "1",
                    "price": "390.54",
                    "tprice": "390.54"
                },
                {
                    "defect": "99110054MP",
                    "descr": "BRAKE-PAD PASTE 3g",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "83192158851",
                    "partqty": "2",
                    "price": "13.27",
                    "tprice": "26.54"
                },
                {
                    "defect": "99110054MP",
                    "descr": "BRAKE CLEANER 2.0 - Accounting purposes",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "83192413695",
                    "partqty": "1",
                    "price": "11.80",
                    "tprice": "11.80"
                },
                {
                    "defect": "99110475MP",
                    "descr": "REPAIR KIT, BRAKE PADS ASBESTOS-FREE",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34116889570",
                    "partqty": "1",
                    "price": "5191.63",
                    "tprice": "5191.63"
                },
                {
                    "defect": "99110475MP",
                    "descr": "BRAKE PAD WEAR SENSOR, FRONT",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34356870349",
                    "partqty": "1",
                    "price": "390.54",
                    "tprice": "390.54"
                },
                {
                    "defect": "99110475MP",
                    "descr": "BRAKE-PAD PASTE 3g",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "83192158851",
                    "partqty": "2",
                    "price": "13.27",
                    "tprice": "26.54"
                },
                {
                    "defect": "99110475MP",
                    "descr": "BRAKE CLEANER 2.0 - Accounting purposes",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "83192413695",
                    "partqty": "1",
                    "price": "11.80",
                    "tprice": "11.80"
                },
                {
                    "defect": "99122521MP",
                    "descr": "SPARK PLUG, HIGH POWER",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "12122455258",
                    "partqty": "4",
                    "price": "332.07",
                    "tprice": "1328.28"
                },
                {
                    "defect": "99210054MP",
                    "descr": "TORX SCREW WITH WASHER",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34108838589",
                    "partqty": "4",
                    "price": "82.90",
                    "tprice": "331.60"
                },
                {
                    "defect": "99210054MP",
                    "descr": "REPAIR KIT, BRAKE PADS ASBESTOS-FREE",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34206888835",
                    "partqty": "1",
                    "price": "2232.95",
                    "tprice": "2232.95"
                },
                {
                    "defect": "99210054MP",
                    "descr": "BREMSSCHEIBE",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34208853554",
                    "partqty": "2",
                    "price": "2869.33",
                    "tprice": "5738.66"
                },
                {
                    "defect": "99210054MP",
                    "descr": "HEX BOLT M8 X 14",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34211161806",
                    "partqty": "2",
                    "price": "18.33",
                    "tprice": "36.66"
                },
                {
                    "defect": "99210054MP",
                    "descr": "BRAKE-PAD SENSOR, REAR",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34356870354",
                    "partqty": "1",
                    "price": "390.54",
                    "tprice": "390.54"
                },
                {
                    "defect": "99210054MP",
                    "descr": "BRAKE-PAD PASTE 3g",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "83192158851",
                    "partqty": "2",
                    "price": "13.27",
                    "tprice": "26.54"
                },
                {
                    "defect": "99210054MP",
                    "descr": "BRAKE CLEANER 2.0 - Accounting purposes",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "83192413695",
                    "partqty": "1",
                    "price": "11.80",
                    "tprice": "11.80"
                },
                {
                    "defect": "99210429MP",
                    "descr": "REPAIR KIT, BRAKE PADS ASBESTOS-FREE",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34206888835",
                    "partqty": "1",
                    "price": "2232.95",
                    "tprice": "2232.95"
                },
                {
                    "defect": "99210429MP",
                    "descr": "BRAKE-PAD SENSOR, REAR",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "34356870354",
                    "partqty": "1",
                    "price": "390.54",
                    "tprice": "390.54"
                },
                {
                    "defect": "99510249MP",
                    "descr": "SET OF WIPER BLADES",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "61615A43586",
                    "partqty": "1",
                    "price": "638.25",
                    "tprice": "638.25"
                },
                {
                    "defect": "99510440MP",
                    "descr": "BRAKE FLUID DOT4 500ML",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "81220142684",
                    "partqty": "2",
                    "price": "104.60",
                    "tprice": "209.20"
                },
                {
                    "defect": "99600668MP",
                    "descr": "BMW LL01 5W30",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "01885A4F8E5",
                    "partqty": "53",
                    "price": "14.55",
                    "tprice": "771.15"
                },
                {
                    "defect": "99600668MP",
                    "descr": "SET OIL-FILTER ELEMENT F26",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "11428575211",
                    "partqty": "1",
                    "price": "283.08",
                    "tprice": "283.08"
                },
                {
                    "defect": "99600668MP",
                    "descr": "DIP-WINDSHIELD WASHER ANTIFREEZE (COST)",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "83122410973",
                    "partqty": "1",
                    "price": "22.40",
                    "tprice": "22.40"
                },
                {
                    "defect": "99600671MP",
                    "descr": "AIR FILTER ELEMENT",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "13718580428",
                    "partqty": "1",
                    "price": "617.41",
                    "tprice": "617.41"
                },
                {
                    "defect": "99643151MP",
                    "descr": "MICROFILTER/ACTIVATED CARBON CONTAINER",
                    "discount": "0.00",
                    "discountp": "",
                    "inclVat": "0.00",
                    "partkey": "64119382886",
                    "partqty": "1",
                    "price": "546.95",
                    "tprice": "546.95"
                }
            ],
            "systmessage": "",
            "usermessage": "",
            "vehInfo": {
                "modelDesc": "G20 330I",
                "z08vbrand": "BM",
                "zmatnr": "B-5R12",
                "zvehBodyNo": "0AK73435",
                "zvehEngineNo": "F1463346",
                "zvehOdoReading": "00085058"
            }
        }
    }))
}

function getDealerInfo() {
    return of(new HttpResponse({
        status: 200,
        body:{ 
            "code": "32717",
            "franchiseCode": "32717",
            "name": "BMW Joburg City",
            "webPage": "https://www.bmw.co.za/joburg_city",
            "associatedBrand": [
                {
                    "brandId": "BMI",
                    "businessType": "FU"
                },
                {
                    "brandId": "BM",
                    "businessType": "FU"
                },
                {
                    "brandId": "BMM",
                    "businessType": "FU"
                },
                {
                    "brandId": "MI",
                    "businessType": "FU"
                }
            ],
            "address": {
                "street": "93 Carr Street, Newtown",
                "city": "Johannesburg",
                "province": "Gauteng",
                "country": "South Africa",
                "postalCode": "2113",
                "countryCode": "ZA",
                "latitude": "-26.199924",
                "longitude": "28.030313"
            },
            "contactDetails": {
                "tellNumber": "011 2204900",
                "faxNumber": "011 2204945",
                "emailAddress": "info@bmw-joburgcity.co.za"
            }
        }
    }))
}
function hstDealerInfo() {
    return of(new HttpResponse({
        status: 200,
        body:[
                {
                    "provinceCode": "GP",
                    "provinceName": "Gauteng",
                    "dealers": [
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "26582",
                            "dealerName": "BMW Bedfordview",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "011 2010000",
                            "telephoneNumber2": "",
                            "faxNumber": "011 2010050",
                            "emailAddress": "info@bmwbedfordview.co.za",
                            "url": "https://www.bmw.co.za/bedfordview",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P O Box 751598",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Gardenview",
                                "province": "Gauteng",
                                "postalCode": "2047"
                            },
                            "streetAddress": {
                                "addressLine1": "8 Kings Road",
                                "addressLine2": "Bedfordview",
                                "addressLine3": "",
                                "city": "Bedfordview",
                                "province": "Gauteng",
                                "postalCode": "2008"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.173444",
                                "longitude": "28.133028"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "10304",
                            "dealerName": "BMW Bryanston",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "011 7009000",
                            "telephoneNumber2": "",
                            "faxNumber": "011 4633040",
                            "emailAddress": "info@jsn.co.za",
                            "url": "https://www.bmw.co.za/bryanston",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P O Box 76346",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Wendywood",
                                "province": "Gauteng",
                                "postalCode": "2144"
                            },
                            "streetAddress": {
                                "addressLine1": "Corner Ormonde and William Nicol",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Sandton",
                                "province": "Gauteng",
                                "postalCode": "2021"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.063653",
                                "longitude": "28.023725"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "48548",
                            "dealerName": "BMW Centurion",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "012 6834000",
                            "telephoneNumber2": "",
                            "faxNumber": "012 6634056",
                            "emailAddress": "info@bmwcenturion.co.za",
                            "url": "https://www.bmw.co.za/centurion",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Gauteng",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "2023 Lenchen Avenue South",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Centurion",
                                "province": "Gauteng",
                                "postalCode": "0157"
                            },
                            "geoCoordinates": {
                                "latitude": "-25.86096",
                                "longitude": "28.18848"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "30773",
                            "dealerName": "BMW East Rand (Boksburg)",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "011 4183300",
                            "telephoneNumber2": "",
                            "faxNumber": "011 8234576",
                            "emailAddress": "info@bmweastrand.co.za",
                            "url": "https://www.bmw.co.za/east_rand",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "Bartlett AH",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Gauteng",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "126 North Rand road",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Boksburg",
                                "province": "Gauteng",
                                "postalCode": "1459"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.178213",
                                "longitude": "28.251816"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "09613",
                            "dealerName": "BMW Fountains Circle",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "012 4441600",
                            "telephoneNumber2": "",
                            "faxNumber": "012 3201864",
                            "emailAddress": "clubfountains@bmwdealer.co.za",
                            "url": "https://www.bmw.co.za/fountains_circle",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O.Box 4915",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Gauteng",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "84 Harmony Street",
                                "addressLine2": "Muckleneuk",
                                "addressLine3": "",
                                "city": "Muckleneuk",
                                "province": "Gauteng",
                                "postalCode": "0001"
                            },
                            "geoCoordinates": {
                                "latitude": "-25.76348",
                                "longitude": "28.196054000000"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "49224",
                            "dealerName": "BMW Fourways",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "011 3671600",
                            "telephoneNumber2": "",
                            "faxNumber": "011 4677675",
                            "emailAddress": "info@cedarisle.co.za",
                            "url": "https://www.bmw.co.za/fourways",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "Suite 576",
                                "addressLine2": "Private Bag X153",
                                "addressLine3": "",
                                "city": "Fourways",
                                "province": "Gauteng",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "Corner Witkoppen  and Cedar Road",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Fourways",
                                "province": "Gauteng",
                                "postalCode": "2055"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.0129364",
                                "longitude": "27.9969091"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "32717",
                            "dealerName": "BMW Joburg City",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "011 2204900",
                            "telephoneNumber2": "",
                            "faxNumber": "011 2204945",
                            "emailAddress": "info@bmw-joburgcity.co.za",
                            "url": "https://www.bmw.co.za/joburg_city",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Gauteng",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "93 Carr Street",
                                "addressLine2": "Newtown",
                                "addressLine3": "",
                                "city": "Johannesburg",
                                "province": "Gauteng",
                                "postalCode": "2113"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.199924",
                                "longitude": "28.030313"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "23389",
                            "dealerName": "BMW Joburg South",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "011 6814100",
                            "telephoneNumber2": "",
                            "faxNumber": "011 6814141",
                            "emailAddress": "info@autoglenbmw.co.za",
                            "url": "https://www.bmw.co.za/joburg_south",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P O Box 375",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Rosettenville",
                                "province": "Gauteng",
                                "postalCode": "2130"
                            },
                            "streetAddress": {
                                "addressLine1": "Corner Oak and Middle Lane",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Oakdene",
                                "province": "Gauteng",
                                "postalCode": "2190"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.263783",
                                "longitude": "28.058805"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "02980",
                            "dealerName": "BMW Kempton Park",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "011 3926263",
                            "telephoneNumber2": "",
                            "faxNumber": "011 3926360",
                            "emailAddress": "autobahn@autobahnbmw.co.za",
                            "url": "https://www.bmw.co.za/kempton_park",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O.Box 1140",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Isando",
                                "province": "Gauteng",
                                "postalCode": "1600"
                            },
                            "streetAddress": {
                                "addressLine1": "Cnr Isando and Brabazon Road",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Kempton Park",
                                "province": "Gauteng",
                                "postalCode": "1619"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.13239",
                                "longitude": "28.20134099"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "44046",
                            "dealerName": "BMW Menlyn",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "012 4262700",
                            "telephoneNumber2": "",
                            "faxNumber": "086 5005580",
                            "emailAddress": "info@bmw-menlyn.co.za",
                            "url": "https://www.bmw.co.za/menlyn",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O. Box 36846",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Menlopark",
                                "province": "Gauteng",
                                "postalCode": "0010"
                            },
                            "streetAddress": {
                                "addressLine1": "Cnr Garstfontein Rd and Lois Ave",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Waterkloof",
                                "province": "Gauteng",
                                "postalCode": "0181"
                            },
                            "geoCoordinates": {
                                "latitude": "-25.788919",
                                "longitude": "28.274214"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "21730",
                            "dealerName": "BMW Midrand",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "011 6974700",
                            "telephoneNumber2": "",
                            "faxNumber": "011 3152223",
                            "emailAddress": "info@abmbmw.co.za",
                            "url": "https://www.bmw.co.za/midrand",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Gauteng",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "Cnr Allandale Rd and Pretorius Rd",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Waterfall Ridge",
                                "province": "Gauteng",
                                "postalCode": "1685"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.011156",
                                "longitude": "28.109102"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "31954",
                            "dealerName": "BMW Montana",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "012 5233600",
                            "telephoneNumber2": "",
                            "faxNumber": "012 5233609",
                            "emailAddress": "info@bmw-montana.co.za",
                            "url": "https://www.bmw.co.za/montana",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "PO Box 2804",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Montana Park",
                                "province": "Gauteng",
                                "postalCode": "0159"
                            },
                            "streetAddress": {
                                "addressLine1": "501 Breedt Street",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Montana Gardens Ext 2",
                                "province": "Gauteng",
                                "postalCode": "0159"
                            },
                            "geoCoordinates": {
                                "latitude": "-25.681224",
                                "longitude": "28.271062"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "08384",
                            "dealerName": "BMW Northcliff",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "011 7729800",
                            "telephoneNumber2": "",
                            "faxNumber": "011 4767473",
                            "emailAddress": "northcliffauto@northcliffbmw.co.za",
                            "url": "https://www.bmw.co.za/northcliff",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Gauteng",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "11 Cresta Lane",
                                "addressLine2": "Randburg",
                                "addressLine3": "",
                                "city": "Johannesburg",
                                "province": "Gauteng",
                                "postalCode": "2194"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.12711",
                                "longitude": "27.97253"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "48003",
                            "dealerName": "BMW Sandton",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "011 6766600",
                            "telephoneNumber2": "",
                            "faxNumber": "011 6766667",
                            "emailAddress": "info@sandtonbmw.co.za",
                            "url": "https://www.bmw.co.za/sandton",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "PO Box 412503",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Craighall",
                                "province": "Gauteng",
                                "postalCode": "2024"
                            },
                            "streetAddress": {
                                "addressLine1": "Cnr Rivonia and South Road",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Morningside",
                                "province": "Gauteng",
                                "postalCode": "2146"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.0932",
                                "longitude": "28.0567"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "28951",
                            "dealerName": "BMW Vereeniging",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "016 4505500",
                            "telephoneNumber2": "",
                            "faxNumber": "016 4505600",
                            "emailAddress": "vereeniging@sovereignbmw.co.za",
                            "url": "https://www.bmw.co.za/vereeniging",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "Box 2065",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Vereeniging",
                                "province": "Gauteng",
                                "postalCode": "1939"
                            },
                            "streetAddress": {
                                "addressLine1": "Cnr Johannesburg and Gen. Smuts",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Duncanville",
                                "province": "Gauteng",
                                "postalCode": "1939"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.661861",
                                "longitude": "27.934198"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "45848",
                            "dealerName": "BMW West Rand",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "011 7613500",
                            "telephoneNumber2": "",
                            "faxNumber": "011 7685249",
                            "emailAddress": "clearwater@bmwclearwater.co.za",
                            "url": "https://www.bmw.co.za/west_rand",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O Box 7286",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Westgate",
                                "province": "Gauteng",
                                "postalCode": "1734"
                            },
                            "streetAddress": {
                                "addressLine1": "Corner Shearwater rd and Cannet",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Roodepoort",
                                "province": "Gauteng",
                                "postalCode": "1724"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.10751",
                                "longitude": "27.8848"
                            }
                        }
                    ]
                },
                {
                    "provinceCode": "KN",
                    "provinceName": "KwaZulu-Natal",
                    "dealers": [
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "45849",
                            "dealerName": "BMW Ballito",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "032 9465300",
                            "telephoneNumber2": "",
                            "faxNumber": "032 9465300",
                            "emailAddress": "bmwballito@smg.co.za",
                            "url": "https://www.bmw.co.za/en/retailers-pages/bmw_ballito.html",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O Box 6361",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Zimbali",
                                "province": "KwaZulu-Natal",
                                "postalCode": "4418"
                            },
                            "streetAddress": {
                                "addressLine1": "5 Moffat Dr",
                                "addressLine2": "Ballito",
                                "addressLine3": "Business Park",
                                "city": "Ballito",
                                "province": "KwaZulu-Natal",
                                "postalCode": "4420"
                            },
                            "geoCoordinates": {
                                "latitude": "-29.52160631",
                                "longitude": "31.197361"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "27191",
                            "dealerName": "BMW Durban City",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "031 3145300",
                            "telephoneNumber2": "",
                            "faxNumber": "031 3686685",
                            "emailAddress": "smgdurban@smg.co.za",
                            "url": "https://www.bmw.co.za/en/retailers-pages/bmw_durban_city.html",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P O Box 111",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Durban",
                                "province": "KwaZulu-Natal",
                                "postalCode": "4000"
                            },
                            "streetAddress": {
                                "addressLine1": "41 Somtseu Road",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Durban",
                                "province": "KwaZulu-Natal",
                                "postalCode": "4001"
                            },
                            "geoCoordinates": {
                                "latitude": "-29.84533",
                                "longitude": "31.03044"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "02485",
                            "dealerName": "BMW Durban South",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "031 4651981",
                            "telephoneNumber2": "",
                            "faxNumber": "031 4653659",
                            "emailAddress": "concierge.dbn@supertechgroup.co.za",
                            "url": "https://www.bmw.co.za/durban_south",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O.Box 41033",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Rossburgh",
                                "province": "KwaZulu-Natal",
                                "postalCode": "4094"
                            },
                            "streetAddress": {
                                "addressLine1": "8 Cliff Crescent",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Durban",
                                "province": "KwaZulu-Natal",
                                "postalCode": "4094"
                            },
                            "geoCoordinates": {
                                "latitude": "-29.880041",
                                "longitude": "30.961502999999"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "42174",
                            "dealerName": "BMW Newcastle",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "034 3126060",
                            "telephoneNumber2": "",
                            "faxNumber": "034 3126094",
                            "emailAddress": "concierge.nn@supertechgroup.co.za",
                            "url": "https://www.bmw.co.za/newcastle",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O. Box 2693",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Newcastle",
                                "province": "KwaZulu-Natal",
                                "postalCode": "2940"
                            },
                            "streetAddress": {
                                "addressLine1": "1 Albert Wessels Drive",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Newcastle",
                                "province": "KwaZulu-Natal",
                                "postalCode": "2940"
                            },
                            "geoCoordinates": {
                                "latitude": "-27.766105",
                                "longitude": "29.944135"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "45850",
                            "dealerName": "BMW Pietermaritzburg",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "033 8978800",
                            "telephoneNumber2": "",
                            "faxNumber": "033 3944441",
                            "emailAddress": "concierge.pmb@supertechgroup.co.za",
                            "url": "https://www.bmw.co.za/pietermaritzburg",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O Box 84",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "PMB",
                                "province": "KwaZulu-Natal",
                                "postalCode": "323"
                            },
                            "streetAddress": {
                                "addressLine1": "9 Armitage Road",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Bird Sanctuary",
                                "province": "KwaZulu-Natal",
                                "postalCode": "3201"
                            },
                            "geoCoordinates": {
                                "latitude": "-29.5836",
                                "longitude": "30.374377999999"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "43760",
                            "dealerName": "BMW Pinetown",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "031 7020271",
                            "telephoneNumber2": "",
                            "faxNumber": "031 7017627",
                            "emailAddress": "concierge.ptn@supertechgroup.co.za",
                            "url": "https://www.bmw.co.za/pinetown",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "KwaZulu-Natal",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "6 Kirk Road",
                                "addressLine2": "New Germany",
                                "addressLine3": "",
                                "city": "Pinetown",
                                "province": "KwaZulu-Natal",
                                "postalCode": "3600"
                            },
                            "geoCoordinates": {
                                "latitude": "-29.812793",
                                "longitude": "30.850948"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "46199",
                            "dealerName": "BMW Richards Bay",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "035 4260020",
                            "telephoneNumber2": "",
                            "faxNumber": "",
                            "emailAddress": "dale.schrauwen@smg.co.za",
                            "url": "https://www.bmw.co.za/en/retailers-pages/bmw_richards_bay.html",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "KwaZulu-Natal",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "2 Bauxite Bay",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Alton",
                                "province": "KwaZulu-Natal",
                                "postalCode": "3900"
                            },
                            "geoCoordinates": {
                                "latitude": "-28.754287",
                                "longitude": "32.025162"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "45851",
                            "dealerName": "BMW Shelly Beach",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "039 3150020",
                            "telephoneNumber2": "",
                            "faxNumber": "039 3150260",
                            "emailAddress": "concierge.sb@supertechgroup.co.za",
                            "url": "https://www.bmw.co.za/shelly_beach",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O Box 299",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Shelly Beach",
                                "province": "KwaZulu-Natal",
                                "postalCode": "4265"
                            },
                            "streetAddress": {
                                "addressLine1": "55 Main Road",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Shelly Beach Business Park",
                                "province": "KwaZulu-Natal",
                                "postalCode": "4265"
                            },
                            "geoCoordinates": {
                                "latitude": "-30.798825",
                                "longitude": "30.398407"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "43057",
                            "dealerName": "BMW Umhlanga",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "031 5029800",
                            "telephoneNumber2": "",
                            "faxNumber": "053 9470031",
                            "emailAddress": "smgumhlanga@smg.co.za",
                            "url": "https://www.bmw.co.za/en/retailers-pages/bmw_umhlanga.html",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O Box 111",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Umhlanga Rocks",
                                "province": "KwaZulu-Natal",
                                "postalCode": "4000"
                            },
                            "streetAddress": {
                                "addressLine1": "Cnr Umhlanga Rocks Dr and Ncondo Pl",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Umhlanga Rocks",
                                "province": "KwaZulu-Natal",
                                "postalCode": "4320"
                            },
                            "geoCoordinates": {
                                "latitude": "-29.727275",
                                "longitude": "31.073036"
                            }
                        }
                    ]
                },
                {
                    "provinceCode": "WC",
                    "provinceName": "Western Cape",
                    "dealers": [
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "51003",
                            "dealerName": "BMW Cape Town City",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "021 4027700",
                            "telephoneNumber2": "",
                            "faxNumber": "021 4027877",
                            "emailAddress": "minicapetowncity@smg.co.za",
                            "url": "https://www.mini.co.za/en_ZA/home/retailer-capetowncity.html",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Western Cape",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "Cnr Heerengracht and Hertzog Blvd",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Cape Town",
                                "province": "Western Cape",
                                "postalCode": "7708"
                            },
                            "geoCoordinates": {
                                "latitude": "-33.91915",
                                "longitude": "18.42793"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "33519",
                            "dealerName": "BMW Century City",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "021 5256000",
                            "telephoneNumber2": "",
                            "faxNumber": "086 6877363",
                            "emailAddress": "smgcpt@smg.co.za",
                            "url": "https://www.bmw.co.za/en/retailers-pages/bmw_century_city.html",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P O BOX 294 Century City",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Western Cape",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "1 Century Way",
                                "addressLine2": "Century City",
                                "addressLine3": "",
                                "city": "Milnerton",
                                "province": "Western Cape",
                                "postalCode": "7441"
                            },
                            "geoCoordinates": {
                                "latitude": "-33.892701",
                                "longitude": "18.50441"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "51005",
                            "dealerName": "BMW Constantia",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "021 6701100",
                            "telephoneNumber2": "",
                            "faxNumber": "021 6701191",
                            "emailAddress": "miniconstantia@smg.co.za",
                            "url": "https://www.bmw.co.za/bmw_constantia",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Western Cape",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "215 Main Road",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Cape Town",
                                "province": "Western Cape",
                                "postalCode": "7708"
                            },
                            "geoCoordinates": {
                                "latitude": "-33.98296",
                                "longitude": "18.46601"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "49200",
                            "dealerName": "BMW George",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "044 8017900",
                            "telephoneNumber2": "",
                            "faxNumber": "051 8747718",
                            "emailAddress": "kelly.byles@bmwgeorge.co.za",
                            "url": "https://www.bmw.co.za/george",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O Box 4360",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "George East",
                                "province": "Western Cape",
                                "postalCode": "6539"
                            },
                            "streetAddress": {
                                "addressLine1": "3 Glaze street",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "George",
                                "province": "Western Cape",
                                "postalCode": "6530"
                            },
                            "geoCoordinates": {
                                "latitude": "-33.9611719",
                                "longitude": "22.4703906"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "09620",
                            "dealerName": "BMW Paarlberg",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "021 8722076",
                            "telephoneNumber2": "",
                            "faxNumber": "021 8711782",
                            "emailAddress": "simonne.dahl@paarlbergbmw.co.za",
                            "url": "https://www.bmw.co.za/paarlberg",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P O Box 63",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Paarl",
                                "province": "Western Cape",
                                "postalCode": "7620"
                            },
                            "streetAddress": {
                                "addressLine1": "199 Main Street",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Paarl",
                                "province": "Western Cape",
                                "postalCode": "7646"
                            },
                            "geoCoordinates": {
                                "latitude": "-33.74072719105",
                                "longitude": "18.962836170394"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "44456",
                            "dealerName": "BMW Somerset West",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "021 8530080",
                            "telephoneNumber2": "",
                            "faxNumber": "021 8530070",
                            "emailAddress": "info@donford.co.za",
                            "url": "https://www.bmw.co.za/somerset_west",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "Somerset West",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Western Cape",
                                "postalCode": "7110"
                            },
                            "streetAddress": {
                                "addressLine1": "10 Dynagel street",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Somerset West",
                                "province": "Western Cape",
                                "postalCode": "7110"
                            },
                            "geoCoordinates": {
                                "latitude": "-34.08049",
                                "longitude": "18.81832"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "09616",
                            "dealerName": "BMW Stellenbosch",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "021 8884200",
                            "telephoneNumber2": "",
                            "faxNumber": "021 8884222",
                            "emailAddress": "info@donford.co.za",
                            "url": "https://www.bmw.co.za/stellenbosch",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P O Box 84",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Stellenbosch",
                                "province": "Western Cape",
                                "postalCode": "7599"
                            },
                            "streetAddress": {
                                "addressLine1": "Cnr R44 and Webers Valley Road",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Stellenbosch",
                                "province": "Western Cape",
                                "postalCode": "7600"
                            },
                            "geoCoordinates": {
                                "latitude": "-33.975927",
                                "longitude": "18.842476"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "44643",
                            "dealerName": "BMW Tygervalley",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "021 9188700",
                            "telephoneNumber2": "",
                            "faxNumber": "021 9140030",
                            "emailAddress": "smgtygervalley@smg.co.za",
                            "url": "https://www.bmw.co.za/en/retailers-pages/bmw_tygervalley.html",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O. Box 778",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Bellville",
                                "province": "Western Cape",
                                "postalCode": "7535"
                            },
                            "streetAddress": {
                                "addressLine1": "1 Pasita Road",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Bellville",
                                "province": "Western Cape",
                                "postalCode": "7530"
                            },
                            "geoCoordinates": {
                                "latitude": "-33.86984",
                                "longitude": "18.6396799"
                            }
                        }
                    ]
                },
                {
                    "provinceCode": "FS",
                    "provinceName": "Free State",
                    "dealers": [
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "44214",
                            "dealerName": "BMW Bethlehem",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "051 4000000",
                            "telephoneNumber2": "",
                            "faxNumber": "051 4000099",
                            "emailAddress": "edebeer@sovereignbmw.co.za",
                            "url": "https://www.bmw.co.za/bethlehem",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Free State",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "2 Muller street",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "CBD",
                                "province": "Free State",
                                "postalCode": "9700"
                            },
                            "geoCoordinates": {
                                "latitude": "-28.234407",
                                "longitude": "28.313394"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "10237",
                            "dealerName": "BMW Bloemfontein",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "051 4000000",
                            "telephoneNumber2": "",
                            "faxNumber": "051 4000099",
                            "emailAddress": "EDeBeer@SovereignBMW.co.za",
                            "url": "https://www.bmw.co.za/bloemfontein",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Free State",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "8 C P Hoogenhout St",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Langenhovenpark",
                                "province": "Free State",
                                "postalCode": "9301"
                            },
                            "geoCoordinates": {
                                "latitude": "-29.090555",
                                "longitude": "26.167669"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "23228",
                            "dealerName": "BMW Welkom",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "057 3532785",
                            "telephoneNumber2": "",
                            "faxNumber": "057 3532789",
                            "emailAddress": "loraine.parodi@parkwelkom.co.za",
                            "url": "https://www.bmw.co.za/welkom",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P O Box 2763",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Welkom",
                                "province": "Free State",
                                "postalCode": "9460"
                            },
                            "streetAddress": {
                                "addressLine1": "Cnr Stateway and Tempest Road",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Welkom",
                                "province": "Free State",
                                "postalCode": "9459"
                            },
                            "geoCoordinates": {
                                "latitude": "-27.978864",
                                "longitude": "26.732869"
                            }
                        }
                    ]
                },
                {
                    "provinceCode": "EC",
                    "provinceName": "Eastern Cape",
                    "dealers": [
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "47524",
                            "dealerName": "BMW East London",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "043 7268730",
                            "telephoneNumber2": "",
                            "faxNumber": "043 7265675",
                            "emailAddress": "concierge.el@supertechgroup.co.za",
                            "url": "https://www.bmw.co.za/east_london",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Eastern Cape",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "Cnr Pearce Street and 4th Avenue",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Berea",
                                "province": "Eastern Cape",
                                "postalCode": "5241"
                            },
                            "geoCoordinates": {
                                "latitude": "-32.993634",
                                "longitude": "27.913045"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport",
                                "MI"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "52133",
                            "dealerName": "BMW Nelson Mandela Bay",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "041 5022200",
                            "telephoneNumber2": "",
                            "faxNumber": "041 5022399",
                            "emailAddress": "LST-ZA-3701-BMWNMB@cfao.com",
                            "url": "https://www.bmw.co.za/en/retailers-pages/bmw_nelson_mandela_bay.html",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "PO Box 27299",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Gqeberha",
                                "province": "Eastern Cape",
                                "postalCode": "6057"
                            },
                            "streetAddress": {
                                "addressLine1": "6 Ring Road",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Green Acres",
                                "province": "Eastern Cape",
                                "postalCode": "6045"
                            },
                            "geoCoordinates": {
                                "latitude": "-33.94965",
                                "longitude": "25.58268"
                            }
                        }
                    ]
                },
                {
                    "provinceCode": "LM",
                    "provinceName": "Limpopo",
                    "dealers": [
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "46784",
                            "dealerName": "BMW Polokwane",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "015 2998500",
                            "telephoneNumber2": "",
                            "faxNumber": "015 2998600",
                            "emailAddress": "info@bmw-polokwane.co.za",
                            "url": "https://www.bmw.co.za/polokwane",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "PO Box 1204",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Polokwane",
                                "province": "Limpopo",
                                "postalCode": "0700"
                            },
                            "streetAddress": {
                                "addressLine1": "12 Iran Avenue",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "N1 South",
                                "province": "Limpopo",
                                "postalCode": "0699"
                            },
                            "geoCoordinates": {
                                "latitude": "-23.912529",
                                "longitude": "29.444547"
                            }
                        }
                    ]
                },
                {
                    "provinceCode": "MP",
                    "provinceName": "Mpumalanga",
                    "dealers": [
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "02487",
                            "dealerName": "BMW Ermelo",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "017 8112242",
                            "telephoneNumber2": "",
                            "faxNumber": "017 8191313",
                            "emailAddress": "info@autobmw.co.za",
                            "url": "https://www.bmw.co.za/ermelo",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P.O.Box 1202",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Ermelo",
                                "province": "Mpumalanga",
                                "postalCode": "2350"
                            },
                            "streetAddress": {
                                "addressLine1": "114 Kerk Street",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Ermelo",
                                "province": "Mpumalanga",
                                "postalCode": "2351"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.518833",
                                "longitude": "29.984801"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "33302",
                            "dealerName": "BMW Mbombela",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "013 7576600",
                            "telephoneNumber2": "",
                            "faxNumber": "013 7576601",
                            "emailAddress": "shaun.brown@eastviewbmw.co.za",
                            "url": "https://www.bmw.co.za/mbombela",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "PO Box 13262",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Nelspruit",
                                "province": "Mpumalanga",
                                "postalCode": "1200"
                            },
                            "streetAddress": {
                                "addressLine1": "16 Naaldekoker Crescent",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Riverside Park",
                                "province": "Mpumalanga",
                                "postalCode": "1201"
                            },
                            "geoCoordinates": {
                                "latitude": "-25.435686",
                                "longitude": "30.965085"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "02979",
                            "dealerName": "BMW Secunda",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "017 6208000",
                            "telephoneNumber2": "",
                            "faxNumber": "017 6208111",
                            "emailAddress": "pinnacleauto@pinnacleauto.co.za",
                            "url": "https://www.bmw.co.za/secunda",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P O Box 7325",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Secunda",
                                "province": "Mpumalanga",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "Cnr Walter Sisulu and Kiewiet Str",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Secunda",
                                "province": "Mpumalanga",
                                "postalCode": "2302"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.5130017",
                                "longitude": "29.17285719999"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "45176",
                            "dealerName": "BMW eMalahleni",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "013 6584400",
                            "telephoneNumber2": "",
                            "faxNumber": "013 6564118",
                            "emailAddress": "eastview@eastviewbmw.co.za",
                            "url": "https://www.bmw.co.za/emalahleni",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "",
                                "province": "Mpumalanga",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "Route N4 Business Park",
                                "addressLine2": "Corridor Cr",
                                "addressLine3": "",
                                "city": "Ben Fleur",
                                "province": "Mpumalanga",
                                "postalCode": "1035"
                            },
                            "geoCoordinates": {
                                "latitude": "-25.887802",
                                "longitude": "29.275737"
                            }
                        }
                    ]
                },
                {
                    "provinceCode": "NC",
                    "provinceName": "Northern Cape",
                    "dealers": [
                        {
                            "associatedBrands": [
                                "BI",
                                "BM"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "10238",
                            "dealerName": "BMW Kimberley",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "053 8323194",
                            "telephoneNumber2": "",
                            "faxNumber": "053 8314806",
                            "emailAddress": "dmarais@motus.co.za",
                            "url": "https://www.bmw.co.za/kimberley",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P O Box 110712",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Hadison Park",
                                "province": "Northern Cape",
                                "postalCode": "8306"
                            },
                            "streetAddress": {
                                "addressLine1": "110 Long Street",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Kimberley",
                                "province": "Northern Cape",
                                "postalCode": "8301"
                            },
                            "geoCoordinates": {
                                "latitude": "-28.74408694536",
                                "longitude": "24.748821211347"
                            }
                        }
                    ]
                },
                {
                    "provinceCode": "NW",
                    "provinceName": "North West",
                    "dealers": [
                        {
                            "associatedBrands": [
                                "BI",
                                "BM"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "21819",
                            "dealerName": "BMW Klerksdorp",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "018 0111888",
                            "telephoneNumber2": "",
                            "faxNumber": "018 4622842",
                            "emailAddress": "ggh.reception@teamdaly.co.za",
                            "url": "https://www.bmw.co.za/klerksdorp",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P O Box 290",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Klerksdorp",
                                "province": "North West",
                                "postalCode": "2570"
                            },
                            "streetAddress": {
                                "addressLine1": "60 Margaretha Prinsloo Street",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Klerksdorp",
                                "province": "North West",
                                "postalCode": "2570"
                            },
                            "geoCoordinates": {
                                "latitude": "-26.86992",
                                "longitude": "26.66854"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM",
                                "MSport"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "09725",
                            "dealerName": "BMW Mahikeng",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "018 3812760",
                            "telephoneNumber2": "",
                            "faxNumber": "018 3811328",
                            "emailAddress": "shireen.dada@dadamotorsmahikeng.co.za",
                            "url": "https://www.bmw.co.za/mahikeng",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "P O Box 290",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Mafikeng",
                                "province": "North West",
                                "postalCode": "2745"
                            },
                            "streetAddress": {
                                "addressLine1": "Cnr Nelson Mandela and Aerodrome",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Industrial Sites",
                                "province": "North West",
                                "postalCode": "2745"
                            },
                            "geoCoordinates": {
                                "latitude": "-25.842713",
                                "longitude": "25.638367"
                            }
                        },
                        {
                            "associatedBrands": [
                                "BI",
                                "BM"
                            ],
                            "associatedVehicleTypes": [
                                "1"
                            ],
                            "dealerCode": "45877",
                            "dealerName": "BMW Rustenburg",
                            "registeredCompanyName": "",
                            "telephoneNumber1": "014 5372308",
                            "telephoneNumber2": "",
                            "faxNumber": "014 5372308",
                            "emailAddress": "info@bmw-rustenburg.co.za",
                            "url": "https://www.bmw.co.za/rustenburg",
                            "companyRegistrationNumber": "",
                            "finacialServicesProviderNumber": "",
                            "sfBranches": [],
                            "directors": [],
                            "postalAddress": {
                                "addressLine1": "Postnet Suite 281",
                                "addressLine2": "P Bag 82245",
                                "addressLine3": "",
                                "city": "Rustenburg",
                                "province": "North West",
                                "postalCode": ""
                            },
                            "streetAddress": {
                                "addressLine1": "Corner 4th Ave and R24",
                                "addressLine2": "",
                                "addressLine3": "",
                                "city": "Waterfall East Ext 27",
                                "province": "North West",
                                "postalCode": "0299"
                            },
                            "geoCoordinates": {
                                "latitude": "-25.704177",
                                "longitude": "27.256378"
                            }
                        }
                    ]
                }
            ]
        
    }))
}