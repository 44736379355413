import { Component, OnInit } from '@angular/core';
import { DataServiceService } from '@services/data-service.service';

@Component({
  selector: 'app-mobicred-modal',
  templateUrl: './mobicred-modal.component.html',
  styleUrls: ['./mobicred-modal.component.less']
})
export class MobicredModalComponent implements OnInit {

  brand: string = '';
  isMini: boolean = false;
  isBmw: boolean = false;
  default: boolean = false;

  constructor(private dataSvc: DataServiceService) { }

  ngOnInit(): void {
    this.brand = this.dataSvc.getBrand();
    this.checkBrand();
  }

  checkBrand(): void {
    if (this.brand === 'MINI') {
      this.isMini = true;
    } else if (this.brand === 'BMW') {
      this.isBmw = true;
    } else {
      this.default = true;
    }
  }

}
