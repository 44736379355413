import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-next-step',
  templateUrl: './next-step.component.html',
  styleUrls: ['./next-step.component.less']
})
export class NextStepComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  // @Input() incomingMsg: string = "";
  @Output() newItemEvent = new EventEmitter<string>();
  payment_res:boolean = false;
  show_res: boolean = false;
  incomingMsg: string = '';

  ngOnInit(): void {
    this.checkUrl();
  }


  checkUrl() {
    this.route.queryParams.subscribe(params => {
       if (params.paymentstatus.indexOf("success")  != -1) {
          this.showResponse("success")
        } else {
          this.showResponse("failed")
        }
    });
  }


  showResponse(response:string) {
    this.show_res = true;
    this.incomingMsg = response;
  }

}
