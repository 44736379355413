import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  cart_summary: any = {};

  constructor() { }
  
  getCartSummary(){
    if(this.cart_summary != null){
      return this.cart_summary;
    }
    return;
  }
}
