import {HttpClient, HttpHeaders, HttpXsrfTokenExtractor} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TechnicalSpecsService {
  httpClient: HttpClient;

  constructor(private http:HttpClient, private tokenExtractor: HttpXsrfTokenExtractor) {
    this.httpClient = http;
  }

  getTechnicalSpecs(params: any): Observable<any>{

    let csrfToken = this.tokenExtractor.getToken() as string;
    let headers = new HttpHeaders({'Content-Type': 'application/json',  'accept': 'application/json', "X-XSRF-TOKEN" : csrfToken, 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
    let configs = {headers: headers};
    return this.http.get<any>(environment.apiURL + "/customerportalapi/rest/cp/vehicles/$%7Bbrand%7D/$%7BmodelCode%7D/vts-data?modelCode="+params.modelCode+"&brand="+params.brand, configs)
    .pipe(map(data =>  data as any));
  }

  getVehicleModelCode(vinNumber:string): Observable<any>{
    let csrfToken = this.tokenExtractor.getToken() as string;
    let headers = new HttpHeaders({'Content-Type': 'application/json',  'accept': 'application/json', "X-XSRF-TOKEN" : csrfToken });
    let configs = {headers: headers};
    let regObjectJson={};

    regObjectJson ={"vin":vinNumber, "zbody":""}

    return this.http.post<any>(environment.apiURL +"/customerportalapi/rest/cp/vehicles/model-from-body", regObjectJson, configs)
      .pipe(map(data => {  return data as any }));
  }

}
