import {Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, AfterViewInit} from '@angular/core';
import { Router } from "@angular/router";
import { TyreOptionsService } from '@services/tyre-options.service';
import { data } from 'jquery';
import {DataServiceService} from "@services/data-service.service";

@Component({
  selector: 'app-dash-tyre-options',
  templateUrl: './dash-tyre-options.component.html',
  styleUrls: ['./dash-tyre-options.component.less']
})
export class DashTyreOptionsComponent implements AfterViewInit {

  constructor(private router: Router, private tyreOptionsSvc: TyreOptionsService, private dataSvc: DataServiceService,private cdr: ChangeDetectorRef) { }
  @Input() incomingVIN: any = {};
  @Input() tyreOptionsToChild: any;

  @Input()
  brandFromParent: any;

  tyer_data: any = {}
  empty_tyre_search:boolean = true;

  ngOnChanges(changes: SimpleChanges) {
    this.cdr.markForCheck();
  }
  ngAfterViewInit(): void {
    if (this.tyreOptionsToChild == undefined) {
      this.tyreOptionsToChild = this.dataSvc.getOptionalTyreData();
    }
  }

  gotServiceOptionsEnquiry() {
    var se_type = localStorage.getItem("se-type");
    if (se_type == null) {
      localStorage.setItem("se_type", "tyre-options");
    }
    else {
      localStorage.removeItem("se_type");
      localStorage.setItem("se_type", "tyre-options");
    }
    this.router.navigate(['/service-options-enquiry']);
  }
}
