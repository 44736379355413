import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-dash-tyre-plan',
  templateUrl: './dash-tyre-plan.component.html',
  styleUrls: ['./dash-tyre-plan.component.less']
})
export class DashTyrePlanComponent implements OnInit {

  @Input() incomingVIN:any = {};

  @Input() 
  brandFromParent: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
