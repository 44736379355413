<swiper [config]="config" *ngIf="brandFromParent==='BMW'">
    <ng-template swiperSlide>
        <div class="discover-item">
            <img src="../../../assets/BMW/images/my-bmw-app.jpg" alt="" class="img-fluid" />
            <div class="description">
                <h3>My BMW App</h3>
                <p>Discover a world of features at your fingertips, from navigation to remote operation of your
                    BMW, with the new My BMW App.</p>
            </div>
            <a href="https://www.bmw.co.za/en/topics/owners/my-bmw/my-bmw-app-overview.html" target="_blank" class="simple-link"><i class="icon icon-arrow-right"></i> More information</a>
        </div>
    </ng-template>
    <ng-template swiperSlide>
        <div class="discover-item">
            <img src="../../../assets/BMW/images/bmw-how-to.jpg" alt="" class="img-fluid" />
            <div class="description">
                <h3>BMW HOW-TO</h3>
                <p>Watch the latest tutorials and walkthroughs from the world of BMW.</p>
            </div>
            <a href="https://www.youtube.com/playlist?list=PLa5S6UwF22IIME5sb6La8Pfj6kj_3Q78R" target="_blank" class="simple-link"><i class="icon icon-arrow-right"></i> Watch now</a>
        </div>
    </ng-template>
    <ng-template swiperSlide>
        <div class="discover-item">
            <img src="../../../assets/BMW/images/bmw-driving-experience.jpg" alt="" class="img-fluid" />
            <div class="description">
                <h3>BMW Driving Experience</h3>
                <p>Book your course for Sheer Driving Pleasure today.</p>
            </div>
            <a href="http://www.bmwdrivingexperience.co.za/" target="_blank" class="simple-link"><i class="icon icon-arrow-right"></i> Book a course</a>
        </div>
    </ng-template>
    <div class="swiper-pagination"></div>
</swiper>


<swiper [config]="config" *ngIf="brandFromParent==='MINI'" class="mini-teasers">
    <ng-template swiperSlide>
        <div class="discover-item">
            <img src="../../../assets/MINI/images/connected-drive.jpg" alt="" class="img-fluid" />
            <div class="description">
                <h3>CONNECTED DRIVE</h3>
                <p>The MINI Connected drive app is free to download from the Apple App Store and Google Play Store.</p>
            </div>
            <a href="https://mini.co.za/en_ZA/home/range/mini-connected.html" target="_blank" class="simple-link">More information <i class="icon icon-arrow-right"></i></a>
        </div>
    </ng-template>
    <ng-template swiperSlide>
        <div class="discover-item">
            <img src="../../../assets/MINI/images/news.jpg" alt="" class="img-fluid" />
            <div class="description">
                <h3>MINI NEWS</h3>
                <p>Get the latest news and updates from the world of MINI.</p>
            </div>
            <a href="https://www.mini.co.za/en_ZA/home/news.html" target="_blank" class="simple-link">Watch now <i class="icon icon-arrow-right"></i></a>
        </div>
    </ng-template>
    <ng-template swiperSlide>
        <div class="discover-item">
            <img src="../../../assets/MINI/images/driving-experience.jpg" alt="" class="img-fluid" />
            <div class="description">
                <h3>MINI Driving Experience</h3>
                <p>Book your course for Sheer Driving Pleasure today.</p>
            </div>
            <a href="https://www.mini.co.za/en_ZA/home/mini-driving-experience.html" target="_blank" class="simple-link">Book a course <i class="icon icon-arrow-right"></i></a>
        </div>
    </ng-template>
    <div class="swiper-pagination"></div>
</swiper>