<div class="container main-container">
    <app-go-back></app-go-back>
</div>

<div class="vehicle-info">
    <div class="container main-container">
        <div class="row">
            <div class="col-lg-7 col-sm-12">
                <h1 class="d-none d-md-block">{{ vehicle_desc }} </h1>
                <div class="detailsWrapper">

                    <div class="img-wrapper d-md-none">
                        <img src="{{cosyURL}}" class="img-fluid vehicle-img" alt="" />
                    </div>

                    <div class="vehicle-detail-wrapper" [ngClass]="show_more==true ? 'expand' : ''">
                        <div class="vehicle-detail">
                            <table>

                                <tr>
                                    <td>VIN:</td>
                                    <td>
                                        <span class="d-none d-md-block">{{ vehicle_vin }}</span>
                                        <span (click)="showMore();" class="d-flex d-md-none">{{ vehicle_vin }} <i
                                                class="icon icon-arrow-down"></i></span>
                                    </td>
                                </tr>
                                <tr class="d-none d-md-table-row">
                                    <td>Vehicle Registration:</td>
                                    <td>{{ vehicle_reg }}</td>
                                </tr>
                            </table>
                            <table class="d-md-none">

                                <tr class="d-table-row d-none">
                                    <td>VIN:</td>
                                    <td>
                                        <span class="d-none d-md-block">{{ vehicle_vin }}</span>
                                        <span (click)="showMore();" class="d-flex d-md-none">{{ vehicle_vin }} <i
                                                class="icon icon-arrow-down"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vehicle Registration:</td>
                                    <td>{{ vehicle_reg }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-sm-12 d-none d-md-block">
                <div class="img-wrapper">
                    <img src="{{cosyURL}}" class="img-fluid vehicle-img" alt="" />
                </div>

            </div>
        </div>
    </div>
</div>

<!-- <div class="vehicle-info">
    <div class="container main-container">
        <div class="row">
            <div class="col-7">
                <h1>{{ vehicle_desc }}</h1>
                <div class="vehicle-detail">
                    <table>
                        <tr>
                            <td>VIN:</td>
                            <td>{{ vehicle_vin }}</td>
                        </tr>
                        <tr>
                            <td>Vehicle Registration:</td>
                            <td>{{ vehicle_reg }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-5">
                <div class="img-wrapper">
                    <img src="{{cosyURL}}" class="img-fluid vehicle-img" alt="" />
                </div>

            </div>
        </div>
    </div>
</div> -->

<div class="container main-container"  [ngClass]="brand=='MINI'?'md-container':''">
    <!--    <form class="row mb-5" [formGroup]="checkOutForm" (ngSubmit)="submitQuote()" *ngIf="make_payment==false">-->
    <form class="row mb-5" [formGroup]="checkOutForm" *ngIf="make_payment==false" data-tron-form="checkout-options-form" data-step-name="Step 2 : Checkout Pay Options">
        <div class="col-md-8">
            <div class="form-heading">
                <h2>HOW WOULD YOU LIKE TO PROCEED?</h2>
                <p>Please select one of the options below to continue.</p>
            </div>
            <div class="form-item">
                <input type="radio" class="form-check-input radio-btn" value="C" formControlName="paymentOption"
                    id="card-payment">
                <label for="card-payment" class="form-check-label">Secure Checkout (Card/Mobicred)</label>
            </div>
            <div class="form-item">
                <input type="radio" class="form-check-input radio-btn" value="F" formControlName="paymentOption"
                    id="finance-payment">
                <label for="finance-payment" class="form-check-label">Download Quote</label>
            </div>
            <div class="mb-5 form-check-label mini-black" *ngIf="checkOutForm.value.paymentOption=='C'">
                <h5>SECURE CHECKOUT</h5>
                    <P>You will be directed to our trusted payment partner, to complete your secure checkout. 
                        You have the choice between a once-off card payment or the use of Mobicred to pay off your purchase in monthly instalments. 
                        <a href="#" data-bs-toggle="modal" data-bs-target="#mobicredModal">More about Mobicred</a> </P>
                        <!-- Mobicred modal Component -->
                        <app-mobicred-modal></app-mobicred-modal>
            </div>
            <div class="mb-5" *ngIf="checkOutForm.value.paymentOption=='F'">
                <h5>DOWNLOAD QUOTE</h5>
                <p>By selecting this option, you'll be able to download a proforma invoice that you can use to apply for finance. 
                    Validity periods and terms can be found on the invoice.</p>
            </div>

            <div class="form-item mini-black">
                <input type="checkbox" name="tandcs" id="tandcs" formControlName="tandcs" class="form-check-input">
                <label for="tandcs" class="form-check-label" target="_blank">I accept the <a [href]="brand === 'BMW' ? 'https://www.bmw.co.za/en/topics/offers-and-services/vehicle-service-parts/black-oil-service-terms.html' : 'https://www.mini.co.za/en_ZA/home/terms-and-conditions/motorplan-terms-and-conditions.html'" class="blues" target="_blank">
                    Terms and Conditions
                </a></label>
            </div>

        </div>
        <div class="col-md-4">
            <div class="cart-summary">
                <h2>CART SUMMARY</h2>
                <table>
                    <tr>
                        <td>{{cart_result.productName}}</td>
                        <td>{{cart_result.productPrice | currency :'R '}}</td>
                    </tr>
                </table>
                <div class="cart-total">
                    <p>Total</p>
                    <h3>{{cart_result.cartTotal | currency :'R '}}<small>(incl. VAT)</small></h3>
                </div>
                <button type="button" class="buttons button-primary" (click)="createAcceptQuote()" *ngIf="checkOutForm.value.tandcs==true && checkOutForm.value.paymentOption==='F'">Download Proforma Invoice </button>
                <button type="submit" class="buttons button-primary" (click)="doPayment()" *ngIf="checkOutForm.value.tandcs==true && checkOutForm.value.paymentOption==='C'">Continue with payment</button>
                <button type="button" class="buttons button-primary" *ngIf="!checkOutForm.value.tandcs" disabled>
                    <span *ngIf="checkOutForm.value.paymentOption==='C'">Continue with payment</span> 
                    <span *ngIf="checkOutForm.value.paymentOption==='F'">Download Proforma Invoice</span>
                </button>
                
                <!-- <div class="form-item mt-3 text-center"
                    *ngIf="checkOutForm.value.tandcs==true && checkOutForm.value.paymentOption=='C'">
                    <a href="javascript:void(0);" class="simple-link" (click)="createAcceptQuote()">Download Proforma
                        Invoice</a>
                        <input type="checkbox" name="downloadProforma" id="downloadProforma" onclick="return;" (click)="createAcceptQuote()" class="form-check-input">
                    <label for="downloadProforma" class="form-check-label">Download Proforma Invoice</label>
                </div> -->
            </div>
        </div>
    </form>
    <!--
    <div class="row mb-5" *ngIf="make_payment==true">
        <div class="col-8">
            <app-make-payment></app-make-payment>
            <div class="buttons-section">
                <button type="submit" class="buttons button-primary" (click)="showResponse()">Show Success</button>
                <button type="submit" class="buttons button-primary" (click)="showError()">Show Error</button>
            </div>

        </div>
        <div class="col-4">
            <div class="cart-summary">
                <h2>CART SUMMARY</h2>
                <table>
                    <tr>
                        <td>{{cart_result.productName}}</td>
                        <td>{{cart_result.productPrice | currency :'R '}}</td>
                    </tr>
                </table>
                <div class="cart-total">
                    <p>Total</p>
                    <h3>{{cart_result.cartTotal | currency :'R '}}<small>(incl. VAT)</small></h3>
                </div>
            </div>
        </div>
    </div> -->

    <!--    <app-next-step *ngIf="show_res==true" [incomingMsg]="res_msg"></app-next-step>-->
</div>