import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceEnquiryService {

  constructor(private http: HttpClient) { }

  getAllServiceOptions(params: any): Observable<any> {
    return this.http.get("assets/BMW/json/service-options.json", params)
    .pipe(map(data =>  data));
  }

  getAllTyreOptions(params: any): Observable<any> {
    return this.http.get("assets/BMW/json/tyre-options.json",params)
    .pipe(map(data =>  data));
  }

}
