
<form class="vehicleSearch" [formGroup]="searchForm"  autocomplete="off"  (mouseleave)="hideSearch()">
    <input class="form-control searchInput" id="searchField" [(ngModel)]="searchVIN" formControlName="searchField" (focus)="showSearch()"
    (input)="searchVehicle($event)" placeholder="Search for your vehicle" [ngClass]="searchResults == false ? 'hide' :''">
    <span class="searchValue"(click)="showSearch()" *ngIf="searchResults == false && searchVehicleInfoFromParent.firstVehicleFromTheList?.modelDesc != ''">{{searchVehicleInfoFromParent.firstVehicleFromTheList?.modelDesc}} <i class="icon icon-arrow-down"></i></span>
 <div *ngIf="searchResults==true" class="searchResults">
        <div class="resultMsg" *ngIf="searchVehicleInfoFromParent.listOfVehicles.length==0">No vehicle found</div>
        <div *ngFor="let vehicle of searchVehicleInfoFromParent.listOfVehicles  | filter:searchVIN">
            <a href="javascript:void(0);" id="{{vehicle?.vinNumber}}" (click)="goToOverview(vehicle)" class="resultItem">
                <h4>{{vehicle?.modelDesc}}<small>VIN: {{vehicle?.vinNumber}}</small></h4>
                <img src='{{vehicle?.cosyURL}}' alt="" class="resultImg" [class.mini]="vehicle.brand=='MI'">
            </a>
        </div>
    </div>
</form>