import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-renew-motorplan',
  templateUrl: './renew-motorplan.component.html',
  styleUrls: ['./renew-motorplan.component.less']
})
export class RenewMotorplanComponent implements OnInit {

  constructor(public sanitizer: DomSanitizer) { }

  urlSafe: SafeResourceUrl | undefined;
  formUrl: string = 'https://forms.bmw.co.za/mini/renewmotorplan/minirenewmotorplan.html';
  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.formUrl);
  }

}
