import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { MotorplanContractService } from 'src/app/_services/motorplan-contract.service';
import { MyPlansService } from '@services/my-plans.service';
import { DataServiceService } from '../../../_services/data-service.service';
import { UserService } from '@services/user.service';
import {environment} from "../../../../environments/environment";
import {CosyService} from "@services/cosy.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-dash-motorplan-contract',
  templateUrl: './dash-motorplan-contract.component.html',
  styleUrls: ['./dash-motorplan-contract.component.less']
})
export class DashMotorplanContractComponent implements OnInit {

  @Input() incomingVIN: any = {};

  @Input() brandFromParent: any;

  @Input()
  motorplanContractDatesToChild: any;

  constructor(
    private motorPlanService: MotorplanContractService,
    private datePipe: DatePipe, private myPlansSvc: MyPlansService,
    private cosySvc: CosyService,
    private userSvc : UserService,
    private router: Router,
    private dataSvc: DataServiceService, private cdr: ChangeDetectorRef
  ) {

  }

  selected_Vehicle: any = '';
  motorplanContractData: any = "";
  endDate: string = "";
  endKM: string = "";
  currentDate: string = "";

  ngOnChanges(changes: SimpleChanges) {
    this.cdr.markForCheck();
  }

  ngOnInit(): void {

    let selectedVehicle = localStorage.getItem("selectedVehicle");
    if (selectedVehicle != null) {
      this.selected_Vehicle = JSON.parse(selectedVehicle);
    }

  }

  redirectToMotorPlanOptionalApp(VehicleMotorplan:any){

    let vehicleCosyImageObservable =   this.cosySvc.doGetCosyImage(VehicleMotorplan.vinNumber);

    vehicleCosyImageObservable.subscribe((vehicleCosyImage: any) => {  vehicleCosyImage;
      this.getCurrentUserProfile(vehicleCosyImage, VehicleMotorplan);
    }, error =>{
      this.getCurrentUserProfile("", VehicleMotorplan);
    });


  }

getCurrentUserProfile(vehicleCosyImage:any, VehicleMotorplan:any){
    console.log("** inside getCurrentUserProfile")
  let firstName:any;
  let emailAddress:any;
  let userInfo:any;
  let userServiceObservable = this.userSvc.getGcdmCustomerProfile();
  if(sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') ) {
    userServiceObservable.subscribe((customerDetails) => {
      customerDetails.forEach((o:any) => {
          firstName =  o.givenName;

          if (o.communications !== undefined) {
            if (o.communications.communicationEMails !== undefined) {
              const isPresent = o.communications.communicationEMails.find((x: any) => x.preferred);
              if (isPresent) {
                emailAddress = isPresent.value;
              } else {
                  if(isPresent.value !== undefined){
                      emailAddress = isPresent.value;
                  }
              }
            } else {

              let userAccountObservable = this.userSvc.getGcdmUserAccountFromASession();
              userAccountObservable.subscribe((customerDetails) => {
                console.log("doesn't have mail for preferred comms", customerDetails.mail);
                emailAddress = customerDetails.mail;
              })
           }
          }

      })
    userInfo = {
          firstName: firstName,
          email: emailAddress
        }
      localStorage.setItem("registeredEmail", emailAddress);
    console.log("what is the email address", emailAddress);
      this.postToMotorplanOptional(vehicleCosyImage, VehicleMotorplan, userInfo)
    })
  }
}
  postToMotorplanOptional(vehicleCosyImage:any, VehicleMotorplan:any, userInfo:any){
    let vehicleMotorplanServiceObservable = this.myPlansSvc.postToOptionalContractApp(VehicleMotorplan.motorPlanData, VehicleMotorplan.vehicleInfo, VehicleMotorplan.motorPlanData, VehicleMotorplan.motorPlanData.vinNumber, VehicleMotorplan.dealerCode, userInfo);
    vehicleMotorplanServiceObservable.subscribe((transactToMotorPlanApp: any) => {transactToMotorPlanApp;
      if(transactToMotorPlanApp){
        window.open(environment.apiURL+"/dashboard/home?transactionId="+transactToMotorPlanApp.transactionId, '_self');
      }
    });
  }

  goToRenewMotorPlan(){
    this.router.navigate(['/renew-motorplan']);
  }
}
