<div class="service-options-component componentWrapper" [ngClass]="brandFromParent=='MINI'?'md-container':''">
    <div class="row" [class.mx-0]="brandFromParent != 'MINI'">
        <div class="col-lg-6 col-md-12 component-header-image">
            <img src="./assets/{{brandFromParent}}/images/service-options.jpg">
        </div>
        <div class="col-lg-6 col-md-12 component-content-container">
            <div class="component-header">
                <h3>{{brandFromParent}} Service</h3>
            </div>
            <div class="service-icons">
                <div class="row">
                    <div class="col-2 icon-container">
                        <img src="./assets/BMW/images/icons/service/99510440MP.png">
                    </div>
                    <div class="col-2 icon-container">
                        <img src="./assets/BMW/images/icons/service/99210054MP.png">
                    </div>
                    <div class="col-2 icon-container">
                        <img src="./assets/BMW/images/icons/service/99110475MP.png">
                    </div>
                    <div class="col-2 icon-container">
                        <img src="./assets/BMW/images/icons/service/99210429MP.png">
                    </div>
                    <div class="col-2 icon-container">
                        <img src="./assets/BMW/images/icons/service/99510249MP.png">
                    </div>
                    <div class="col-2 icon-container">
                        <img src="./assets/BMW/images/icons/service/99122521MP.png">
                    </div>
                </div>
            </div>
            <div class="component-content">
                <div class="row options-list">
                    <div class="col-6">
                        <p>Engine oil service</p>
                    </div>
                    <div class="col-6">
                        <p>Wiper blade inserts</p>
                    </div>
                    <div class="col-6">
                        <p>Spark plugs</p>
                    </div>
                    <div class="col-6">
                        <p>Aircon micro-filter</p>
                    </div>
                </div>
                <p>All listed services are covered by an active {{brandFromParent}} Motorplan Contract or on selected My {{brandFromParent}} Plans. </p>

            </div>
            <div class="component-buttons-links link">
                <div class="row">
                    <div class="col-6" *ngIf="serviceOptionalToChild != ''">
                        <button class="buttons button-ghost" (click)="gotServiceOptionsEnquiry()">
                            View all options
                        </button>
                    </div>
                    <div class="col-6">
                      <a routerLink="/serviceonline" routerLinkActive="active-link" role="link" class="buttons button-grey" *ngIf="brandFromParent !== 'BMW'">
                        Book a service
                      </a>
                      
                      <a href="https://customer.bmwgroup.com/oneid/#/login?client=bmwwebcom&country=ZA&language=en&brand=bmw&scope=svds%20remote_services%20vehicle_data%20authenticate_user%20smacc%20fupo%20cesim%20perseus&response_type=code&redirect_uri=https%3A%2F%2Fwww.bmw.co.za%2Fstatic%2Flogin%2Ftoken-handler&state=eyJyZXR1cm5VUkwiOiJodHRwczovL3d3dy5ibXcuY28uemEvZW4vc2hvcC9scy9jcC9vbmxpbmUtYXBwb2ludG1lbnQ/Zmx5b3V0PW9wZW4iLCJkYXRhIjp7Imxhbmd1YWdlIjoiZW4iLCJjb3VudHJ5IjoiWkEiLCJicmFuZCI6ImJtdyJ9fQ==&acid=58256926270480782383445885253938270000&epaas_consenttoken=18de95842fe849b81296047039c000000030ZW5fWkF8Ym13X2NvX3phLV9lbl9zaG9w" target="_blank" class="buttons button-grey" *ngIf="brandFromParent === 'BMW'" >
                        Book a service
                      </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
