import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticationService } from './authentication.service';
import { ContactPolicyConsents } from "../_models/contact-policy-consents.model";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PolicyStoreService {
  httpClient: HttpClient;
  contactPolicyConsents!: ContactPolicyConsents;
  gcdm_env_hostname!: any;
  get_gcdm_config!: any;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    this.httpClient = http;
    this.gcdm_env_hostname = environment.gcdm_env_hostname;
    this.get_gcdm_config = this.authenticationService.get_config();
  }
  getPolicyStoreConsent(): Observable<any> {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
    var configs = { headers: headers };
    return this.http.get<any>(this.gcdm_env_hostname + "/gcdm/protected/v4/" + this.get_gcdm_config['businessContext'] + "/customers/businessPartners", configs)
      .pipe(map((data: any) => {

        return data as any
      }));
  }

  updatePolicyStoreConsent(contactPolicyConsents: any) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "Bearer " + sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') });
    var configs = { headers: headers };
    var contactPolicyConsentsJson = {
      "contactPolicyConsents": contactPolicyConsents
    };
    return this.http.put(environment.gcdm_env_hostname + "/gcdm/protected/v4/" + "customerportal/ZA-BMW-en" + "/customers", contactPolicyConsentsJson, configs)
      .pipe(map(function (data: any) { return data; }));
  };

}
