<div class="vehicle-info">
    <div class="container main-container">
        <div class="row">
            <div class="col-lg-7 col-sm-12">
                <div class="detailsWrapper">
                    <div class="search-form">
<!--                        <app-vehicle-search (vehicleChanged)="parentUpdateVehicleFunction($event)"></app-vehicle-search>-->
                      <app-vehicle-search (vehicleChanged)="parentUpdateVehicleFunction($event)" [searchVehicleInfoFromParent]="vehicleInfoToChild"></app-vehicle-search>
                    </div>

                    <div class="img-wrapper d-md-none">
                        <img src="{{vehicleInfoToChild.firstVehicleFromTheList?.cosyURL}}" class="img-fluid vehicle-img" alt="" />
                    </div>

                    <div class="vehicle-detail-wrapper" [ngClass]="show_more==true ? 'expand' : ''">
                        <div class="vehicle-detail">
                            <table>
                                <tr>
                                    <td>VIN:</td>
                                    <td>
                                        <span class="d-none d-md-block">{{vehicleInfoToChild.firstVehicleFromTheList?.vinNumber}}</span>
                                        <span (click)="showMore();" class="d-flex d-md-none">{{vehicleInfoToChild.firstVehicleFromTheList?.vinNumber}} <i class="icon icon-arrow-down"></i></span>
                                    </td>
                                </tr>
                                <tr class="d-none d-md-table-row">
                                    <td>Vehicle Registration:</td>
                                    <td>{{vehicleInfoToChild.firstVehicleFromTheList?.vehLpno}}</td>
                                </tr>
                            </table>
                            <table style="z-index: 9;">
                                <tr class="d-table-row d-md-none">
                                    <td>Vehicle Registration:</td>
                                    <td>{{vehicleInfoToChild.firstVehicleFromTheList?.vehLpno}}</td>
                                </tr>
                                <tr *ngIf="vehicleCampaignsChild?.last_service">
                                    <td>Last Serviced On:</td>
                                    <td>{{vehicleCampaignsChild.last_service}}</td>
                                </tr>
                                <!-- <tr *ngIf="vehicleCampaignsChild?.service_partner">
                                    <td>Service Partner:</td>
                                    <td>{{vehicleCampaignsChild.service_partner}}</td>
                                </tr> -->
                            </table>
                        </div>

                        <a class="simple-link" (click)="getManual(vehicleInfoToChild.firstVehicleFromTheList?.vinNumber)" href="javascript:void(0);"><i
                            class="icon icon-arrow-right" *ngIf="brandFromParent==='BMW'"></i> Owner's Manual<i
                            class="icon icon-arrow-right" *ngIf="brandFromParent==='MINI'"></i></a><br>

                        <a class="simple-link" data-bs-toggle="modal" href="#removeVehicle" role="button"><i
                                class="icon icon-arrow-right" *ngIf="brandFromParent==='BMW'"></i> I
                            no longer own this vehicle<i
                            class="icon icon-arrow-right" *ngIf="brandFromParent==='MINI'"></i></a><br>

                            <a class="simple-link d-md-none" href="#"><i
                                class="icon icon-arrow-right" *ngIf="brandFromParent==='BMW'"></i>Download technical data<i
                                class="icon icon-arrow-right" *ngIf="brandFromParent==='MINI'"></i></a><br class="d-md-none">

                        <a class="simple-link" data-bs-toggle="modal" href="#addNewVehicle"><i
                            class="icon icon-arrow-right" *ngIf="brandFromParent==='BMW'"></i>
                            Add another vehicle<i
                            class="icon icon-arrow-right" *ngIf="brandFromParent==='MINI'"></i></a>
                    </div>
                </div>

            </div>
            <div class="col-lg-5 col-sm-12 d-none d-md-block">
                <div class="img-wrapper">
                    <img src="{{vehicleInfoToChild.firstVehicleFromTheList?.cosyURL}}" class="img-fluid vehicle-img" alt="" />
                </div>

            </div>
        </div>
    </div>
</div>

<app-remove-vehicle-modal [vehicleInfoFromParent]="vehicleInfoToChild.firstVehicleFromTheList"></app-remove-vehicle-modal>
