import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@services/alert.service';
import { AuthenticationService } from '@services/authentication.service';
import { DataServiceService } from '@services/data-service.service';
import { UserService } from '@services/user.service';
import { environment } from 'src/environments/environment';
import { User } from '../../_models/user.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit {
  currentUser!: User;
  display='none';
  closeResult!: string;
  modalOptions!: NgbModalOptions;
  loading: Boolean = false;
  customerDetails: any;
  customerProfile: any;
  idNumber!: string;
  phonenumber!: string;
  emailAddress!: string;
  city!: string;
  country!: string;
  districtName!: string;
  postalCode!: string;
  strSuppl1!: string;
  street!: string;
  userAccountEmail!: string;
  userAccount: any;
  salutation!: string;
  givenName!: string;
  surname!: string;
  birthday!: string;
  correspondLanguageISO!: string;
  hideOndemand!: string;
  isNavShown: boolean = false;
  gcdm_env_hostname!: string;
  gcdm_login_env_hostname!: string;
  get_gcdm_config: any;
  loggedinuser: any =  '';
  brand: string = '';

  get_brand!: string;
  constructor(
    private authenticationService: AuthenticationService,
    private userSvc: UserService,
    public router: Router,
    private dataSvc: DataServiceService,) {
      this.brand = this.dataSvc.getBrand();
    }

  ngOnInit(): void {

    let userServiceObservable = this.userSvc.getGcdmCustomerProfile();
    if(sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') ) {
      userServiceObservable.subscribe((customerDetails) => {
        customerDetails.forEach((o:any) => {
          this.loggedinuser =  o.givenName;
        })
      })
    }
  }
}
