import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataServiceService } from '@services/data-service.service';

@Component({
  selector: 'app-plans-offers',
  templateUrl: './plans-offers.component.html',
  styleUrls: ['./plans-offers.component.less']
})
export class PlansOffersComponent implements OnInit {
  selectedVehicleFromChild: any;
  results_data: any = {};
  currentVehicle:any={};
  myPlanContractDataFromParent: any;
  onCallContractDataFromParent: any;
  tyreOptionsFromParent:any;
  serviceOptionalFromParent: any;
  brand: string = '';
  constructor(private activatedRoute: ActivatedRoute, private route: Router, private dataSvc: DataServiceService) {
    this.brand = this.dataSvc.getBrand();
   }

  ngOnInit(): void {
    let allPlansData = this.activatedRoute.snapshot.data['data']
    if (allPlansData !== undefined) {
      this.getPlansAll(allPlansData); //pass data through here 
    }
  }


  getPlansAll(dataPlans: any){
    if(dataPlans!==undefined){
      this.myPlanContractDataFromParent = dataPlans.myPlanContractDataFromParent;
      this.onCallContractDataFromParent = dataPlans.onCallContractDataFromParent;
      this.tyreOptionsFromParent = dataPlans.tyreOptionsFromParent;
      this.serviceOptionalFromParent = dataPlans.serviceOptionalFromParent;
    }
  }
}
