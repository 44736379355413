<div class="container go-back-container">
    <app-go-back></app-go-back>
</div>

<div class="container main-container">
    <div class="header">
        <img src="./assets/{{brand}}/images/icons/svg/plans-offers.svg" alt="" class="headerIcon">
        <h1>{{brand}} Plans and Offers</h1>
    </div>
    
    <div class="col-12">
        <h1>ADDITIONAL PLANS FOR YOUR {{brand}}.</h1>
        <div class="text-copy">
            <p>Getting behind the wheel of your {{brand}} is exhilarating, every single time. But that exhilaration doesn’t have to end when the Motorplan expires. Purchase one of our service products online to help you keep your {{brand}}, that's out of Motorplan, running smoothly. </p>
        </div>
    </div>
    <div class="col-12 dashboard-components">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div>
                    <app-dash-my-plans [myPlanContractDataToChild]="myPlanContractDataFromParent" [brandFromParent]="brand"></app-dash-my-plans>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div>
                            <app-dash-on-call [onCallContractDataToChild]="onCallContractDataFromParent"[brandFromParent]="brand"></app-dash-on-call>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div>
                            <app-dash-tyre-plan [incomingVIN]="selectedVehicleFromChild" [brandFromParent]="brand"></app-dash-tyre-plan>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <div class="col-12">
        <h1>ONCE-OFF SERVICE OFFERS.</h1>
        <div class="text-copy">
            <p>With a once-off service from {{brand}}, you benefit from {{brand}} Original Parts and servicing expertise from our network of retailers. It's the {{brand}} excellence you'd expect at an affordable price.</p>
        </div>
    </div>

    
    <div class="col-12 dashboard-components">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div>
                    <app-dash-service-options [serviceOptionalToChild]="serviceOptionalFromParent"></app-dash-service-options>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div>
                    <!-- <app-dash-tyre-options  [incomingVIN]="selectedVehicleFromChild"></app-dash-tyre-options> -->
                    <app-dash-tyre-options [tyreOptionsToChild]="tyreOptionsFromParent"></app-dash-tyre-options>
                </div>
            </div>
        </div>
    </div>
</div>
<app-modals></app-modals>