import {Component, Input, OnInit, SimpleChanges, ChangeDetectorRef} from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-dash-service-options',
  templateUrl: './dash-service-options.component.html',
  styleUrls: ['./dash-service-options.component.less']
})
export class DashServiceOptionsComponent implements OnInit {

  constructor(private router: Router, private cdr: ChangeDetectorRef) { }

  @Input() serviceOptionalToChild: any;

  @Input() 
  brandFromParent: any;
  
  ngOnChanges(changes: SimpleChanges) {
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
  }


  gotServiceOptionsEnquiry() {
    var se_type = localStorage.getItem("se-type");
    if (se_type == null) {
      localStorage.setItem("se_type", "service-options");
    }
    else {
      localStorage.removeItem("se_type");
      localStorage.setItem("se_type", "service-options");
    }
    this.router.navigate(['/service-options-enquiry']);
  }

}
