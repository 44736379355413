import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CosyService } from '../../_services/cosy.service';
import { CampaignsService } from '../../_services/campaigns.service';
import { DealerService } from '@services/dealer.service';
import { DataServiceService } from '@services/data-service.service';
import { MyPlansService } from '@services/my-plans.service';

@Component({
  selector: 'app-vehicle-info',
  templateUrl: './vehicle-info.component.html',
  styleUrls: ['./vehicle-info.component.less']
})
export class VehicleInfoComponent implements OnInit {

  @Output()
  vinChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  vehicleInfoToChild: any;

  @Input()
  vehicleCampaignsChild: any;

  @Input() 
  brandFromParent: any;
  
  selectedVehicleFromChild: any;
  parentUpdateVehicleFunction(valueEmitted: any) {
    if(valueEmitted!== undefined){
    this.selectedVehicleFromChild = valueEmitted;
    // this.updateView(this.selectedVehicleFromChild); //updates vehicle detail
    this.vinChanged.emit(this.selectedVehicleFromChild);
    }
  }

  constructor(private http: HttpClient, private cosySvc: CosyService,
    private campaignsSvc: CampaignsService, private dealerSvc: DealerService,
    private dataSvc: DataServiceService, private myPlanSvc: MyPlansService) {
  }

  vehicle_vin: string = '';
  last_service: string = '';
  vehicle_reg: string = '';
  service_partner: string = '';
  serviceDealerCode: any;
  serviceDealer: any;
  parentServiceDealer: any;
  isServiceDealerLoaded: boolean = false;

  cosyURL: string = '';
  show_more: boolean = false;
  selected_Vehicle: any = {};

  ngOnInit(): void {

  }

  showMore() {
    if (this.show_more === false) {
      this.show_more = true;
    }
    else {
      this.show_more = false;
    }
  }

  getManual(vin:string){
    if(vin){
      window.open('https://bao.bmwgroup.com/bao-rd/manual/?portalId=bmwstage2zaprivate&allowedBrands='+this.brandFromParent+'&locale=en-GB&currentVin='+vin, '_blank');
    }
  }
}
