import { Component, OnInit, ViewChild } from '@angular/core';
import {FormArray, FormBuilder, FormControl, ValidatorFn, Validators} from '@angular/forms';
import { SwiperOptions } from 'swiper';
import Swiper, { Autoplay, Pagination, Navigation } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import {CustomerProfileService} from "@services/customer-profile.service";
import {first} from "rxjs/operators";
import {DataServiceService} from "@services/data-service.service";
import {OndemandLoginService} from "@services/ondemand-login.service";
import { environment } from 'src/environments/environment';
import {UserService} from "@services/user.service";
Swiper.use([Autoplay, Pagination, Navigation]);

@Component({
  selector: 'app-on-demand',
  templateUrl: './on-demand.component.html',
  styleUrls: ['./on-demand.component.less']
})
export class OnDemandComponent implements OnInit {

  @ViewChild('mySwiper', { static: true }) mySwiper: SwiperComponent | undefined;

  errorMessage: string = '';
  emailErrorMessage: string = '';
  emailList: any = [];
  submitted: boolean = false;
  ondemand_merge_accounts: any = [];
  customerEmailToBeMerge: any = [];
  idVersion :string = "ID";

  gcdmIdNo: string = '';
  gcdmCustNameLast: string = '';
  gcdmEmail: string = '';
  gcdmCustTitle: string = '';

  constructor(private _formBuilder: FormBuilder, private custProfileSer: CustomerProfileService,
              private dataServ: DataServiceService, private ondemandServ: OndemandLoginService, private userSvc: UserService) { }

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
    idtype: ['', Validators.required],
  });
   secondFormGroup = this._formBuilder.group({
    emailList: new FormArray([], this.minSelectedCheckboxes(1))
  });

  formSteps = ['Enter your ID', 'Select your Email', 'Done'];

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 30,
    autoHeight: false,
    allowTouchMove: false,
        autoplay: false,
        loop: false,
    centeredSlides: false,
    pagination: {
      clickable: false,
      renderBullet: (index, className)=> {
        return '<div class="' + className + '"><span class="pagination-item mx-2"><span class="bullet-number">' + (index + 1) + '</span> '+ (this.formSteps[index]) +'</span></div>';
      }
    },
  }

  // @ts-ignore
  passChange(idValue: any){
    if(idValue.target.value === "FS0002"){
      this.idVersion="Passport";
      return this.idVersion;
    }else if(idValue.target.value === "SAID"){
      this.idVersion="SA ID";
      return this.idVersion;
    }
  }

  obscure_email(email:string) {
    let skipFirstChars = 3;
    let firstThreeChar = email.slice(0, skipFirstChars);

    let domainIndexStart = email.lastIndexOf("@");
    let maskedEmail = email.slice(skipFirstChars, domainIndexStart)
    maskedEmail = maskedEmail.replace(/./g, '*')
    let domain = email.slice(domainIndexStart, email.length);

    return firstThreeChar.concat(maskedEmail).concat(domain);

  }

  validateEmail(email: string)
  {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  titleList(title: string) {
    if (title === 'SAL_MR') {
      return 'Mr'
    }

    if (title === 'SAL_MS') {
      return 'Ms'
    }

    if (title === 'SAL_MRS') {
      return 'Mrs'
    }

    if (title === 'SAL_MISS') {
      return 'Miss'
    }

    if (title === 'SAL_DR') {
      return 'Dr'
    }
    return "";
  }

  ngOnInit(): void {

    let userServiceObservable = this.userSvc.getGcdmCustomerProfile();
    if(sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') ) {
      userServiceObservable.subscribe((customerDetails) => {
        customerDetails.forEach((o:any) => {

          let userAccountObservable = this.userSvc.getGcdmUserAccountFromASession();
          userAccountObservable.subscribe((account) => {

            this.gcdmEmail = account.mail;
          });


          // this.dataSvc.setGcdmCustNameFirst(o.businessPartner.givenName);
          this.gcdmCustNameLast = o.surname;
          this.gcdmCustTitle  = this.titleList(o.salutation);

          if (o.profile !== undefined) {
            if (o.profile.personalIdentifications !== undefined) {
              o.profile.personalIdentifications.personalIdentifications.forEach((p: { id: string; }, i: any) => {
                this.gcdmIdNo = p.id;
              });
            }
          }
        })
      })
    }

  }

  nextSlide() {
    if (this.mySwiper && this.mySwiper.swiperRef) { // add null check and swiperRef
      // @ts-ignore
    const swiper: Swiper = this.mySwiper.swiperRef;

      var idformat = /^[0-9]+$/;
      if (this.firstFormGroup.value.idtype === "SAID") {
        if (!idformat.test(this.firstFormGroup.value.firstCtrl) == true || this.firstFormGroup.value.firstCtrl.length !== 13) {
          this.errorMessage = "ID Number must be numeric and 13 digits.";
          swiper.slideReset();
          return;
        }
      }

      if (this.gcdmIdNo && this.gcdmIdNo !== this.firstFormGroup.value.firstCtrl) {
        this.errorMessage = "ID number does not match, please insert a correct ID number.";
        swiper.slideReset();
        return;
      } else {
        this.custProfileSer.getOnDemandGetCustInfo(this.firstFormGroup.value.firstCtrl, this.firstFormGroup.value.idtype).pipe(first())
          .subscribe(
            data => {
              if (data.bpFound !== null) {
                data.bpFound.forEach((o: { emailAddr: string; bpNo: string; nameLast: string; }, i: any) => {
                  if (o.emailAddr !== undefined && this.validateEmail(o.emailAddr)) {
                    const list = {
                      maskEmail: this.obscure_email(o.emailAddr),
                      emailAddress: o.emailAddr,
                      disabled: false,
                      checked: false,
                      bpNo: o.bpNo
                    };
                    if (o.nameLast.trim().toUpperCase() === this.gcdmCustNameLast.trim().toUpperCase()
                      && o.bpNo !== this.dataServ.getGcdmCustBp()) {
                      this.emailList.push(list);
                    } else {
                        this.errorMessage = 'Your details do not match the account information, contact us on 0800 600 555 for assistance.';
                        swiper.slideReset();
                        return;
                    }
                  }
                });

                // if (this.emailList) {
                //   this.errorMessage = 'Your details do not match the account information, contact us on 0800 600 555 for assistance.';
                //   swiper.slideReset();
                //   return;
                // }

                this.emailList.forEach((o: any, i: any) => {
                  const control = new FormControl(o);
                  (this.secondFormGroup.controls?.['emailList'] as FormArray).push(control);
                  swiper.slideNext(); // call slideNext() on Swiper instance
                });

              } else {
                this.errorMessage = 'Your details do not match the account information, contact us on 0800 600 555 for assistance.';
                swiper.slideReset();
                return;
              }
            }
          );

      }

    }

  }

  prevSlide() {
    // if (this.mySwiper) {
    // this.mySwiper.s_swiper.next();
    // }


    if (this.mySwiper && this.mySwiper.swiperRef) { // add null check and swiperRef
      const swiper: Swiper = this.mySwiper.swiperRef;
      swiper.slidePrev(); // call slideNext() on Swiper instance
    }
  }


  submitForm(){

    if (this.mySwiper && this.mySwiper.swiperRef) { // add null check and swiperRef
      // @ts-ignore
      const swiper: Swiper = this.mySwiper.swiperRef;

      this.submitted = true;
      if (this.secondFormGroup.invalid) {
        this.emailErrorMessage = 'Please select an email address.';
        return;
      }

      let pbsToMergeobj: any = [];

      this.ondemand_merge_accounts.forEach((o: { checked: any; emailAddress: any; bpNo: any; }, i: any) => {
        if (o.checked) {
          this.customerEmailToBeMerge.push(o);
          pbsToMergeobj.push({emailAddress: o.emailAddress, bpTobeMerge: o.bpNo});
        }
      });

      let objToBeMerge = {
        emailList: pbsToMergeobj,
        titleNameSurname: this.gcdmCustTitle + " " + this.gcdmCustNameLast,
        customer_email: this.gcdmEmail,
        customer_pb: this.dataServ.getGcdmCustBp(),
        brand: 'BM',
        urlLink: environment.apiURL + "/psteml/"
      };
      this.ondemandServ.postCustomerRecordsToBeMerge(objToBeMerge).pipe(first()).subscribe(
        (data: any) => {
          // this.activeModal.close(this.InsertIDFormFld.value);
          swiper.slideNext(); // call slideNext() on Swiper instance
        }
      );
    }
  }

  get f() {
    return this.secondFormGroup.controls;
  }



  list_change(event: any, emailAddress:string, bpNo:string){

    const list = {emailAddress: emailAddress, checked: event.target.checked, bpNo: bpNo, maskEmail:this.obscure_email(emailAddress)};

    this.ondemand_merge_accounts.push(list);

    this.ondemand_merge_accounts.forEach((o: { emailAddress: string; }, i: any) => {
      if(!event.target.checked && o.emailAddress === emailAddress){
        this.ondemand_merge_accounts.splice(i, 1);
      }
    });

  }

  minSelectedCheckboxes(min = 1) {
    // @ts-ignore
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => (next ? prev + next : prev), 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

}


