import { Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { TyreOptionsService } from 'src/app/_services/tyre-options.service';
import { VehicleInfoService } from '@services/vehicle-info.service';
import { CosyService } from "@services/cosy.service";
import { MyPlansService } from "@services/my-plans.service";
import { DealerService } from "src/app/_services/dealer.service";
import { TechnicalSpecsService } from "@services/technical-specs.service";
import { DataServiceService } from '@services/data-service.service';
import { ServiceOptionsService } from "@services/service-options.service";
import { environment } from "../../../environments/environment";
import { Observable, forkJoin } from "rxjs";
import { TronGenericPageTrackingService } from 'src/app/_helpers/TRON-Analytics-tracking/page-tracking/tron-generic-page-tracking.service';

declare const bootstrap: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {

  selectedVehicleFromChild: any;
  vehicleTyreSearch: any;
  vehicleTyreSearchParent: any;
  isHideTyreSearch: boolean = false;
  parentIsEmptyTyreSearch: boolean = false;
  vehicleInfoFromParent: any = [];
  vehicleCampaignsParent: any;

  vehicle_results: any = [];
  currentVehicle: string = '';
  firstVehicleFromTheList: any;
  last_service: string = '';
  service_partner: string = '';
  vinToChild: string = '';
  servicePartnerDetailsFromParent: any;
  dealersFromParent: any;
  techDataFromParent: any;
  motorplanContractDataFromParent: any;
  motorplanContractDatesFromParent: any;
  myPlanContractDataFromParent: any;
  onCallContractDataFromParent: any;
  serviceOptionalFromParent: any;
  tyreOptionsFromParent: any;
  loadpage: boolean = false;
  brandToChild: string = '';
  brand:string = '';
  @ViewChild('preferredRetailer')
  preferredRetailer!: ElementRef;

  getVIN(valueEmitted: any) {
    if (valueEmitted !== undefined) {
      // this.servicePartnerDetailsFromParent = {};
      this.motorplanContractDataFromParent = undefined;
      this.myPlanContractDataFromParent = undefined;
      this.onCallContractDataFromParent = undefined;
      this.serviceOptionalFromParent = undefined;
      this.tyreOptionsFromParent = undefined;
      this.selectedVehicleFromChild = valueEmitted;
      this.onBrandSwitch(this.getBrand_Switch(this.brandToChild, this.selectedVehicleFromChild.brand), this.selectedVehicleFromChild);
      this.updateCosy(this.vehicle_results, this.selectedVehicleFromChild);
      this.getServicePlans(this.selectedVehicleFromChild);
    }
  }
  results_data: any = {};
  constructor(
    private tyresSvc: TyreOptionsService,
    private vehSvc: VehicleInfoService,
    private cosySvc: CosyService,
    private myPlansSvc: MyPlansService,
    private serviceDealerSvc: DealerService,
    private technicalSpecsSvc: TechnicalSpecsService,
    private dataSvc: DataServiceService,
    private router: Router,
    private servOptSvc: ServiceOptionsService,
    private tyreOptionsSvc: TyreOptionsService,
    private tronGenericPageTrackingService: TronGenericPageTrackingService,
  ) {
    this.brandToChild = this.dataSvc.getBrand();
    this.brand = this.brandToChild === 'BMW'? 'BM': this.brandToChild === 'MINI'?'MI':'MO';
  }

  ngOnInit(): void {
    this.getVehicleFromCp();
    const brand = this.dataSvc.getBrand();
    // this.tronGenericPageTrackingService.setBrand(brand);
    // console.log(this.tronGenericPageTrackingService.brand);
    // this.tronGenericPageTrackingService.setPageName(`'Customer Portal | ${this.brand} | Service Dashboard'`);
    // this.tronGenericPageTrackingService.updatePageNameFromUrl();

  }

  getVehicleFromCp() {
    let prospectObservable = this.vehSvc.getVehicleInfo();
    prospectObservable.subscribe(
      (vehicleInfo: any) => {
        this.loadpage = true;
        this.vehicle_results = vehicleInfo.vehicles.findvehicle;
        this.dataSvc.setBpNo(vehicleInfo.bpNo);
        this.getVehicleDetails(this.vehicle_results);
      },
      (error) => {
        console.error('Error occurred:', error);
      }
    );
  }

  getVehicleDetails(vehicle_results: any) {
    let vehicles = vehicle_results;
    vehicles.sort(function (a: { modelYear: number; }, b: { modelYear: number; }) { return a.modelYear > b.modelYear; });
    let isVehilcePresent: any;
    let presentVehicle = this.dataSvc.getSelectedVehicle();
    if (presentVehicle !== undefined && presentVehicle !== null && presentVehicle !== "") {
      isVehilcePresent = JSON.parse(presentVehicle);
    }
    else {
      isVehilcePresent = vehicles.find((p: { brand: string; currentVehicle: string; }) => p.brand === this.getBrand_Switch(this.brandToChild, p.brand) && p.currentVehicle === "X");
    }

    if (isVehilcePresent) {
      this.getCosyImage(isVehilcePresent, isVehilcePresent.vinNumber.trim());
    }

    let filterVehicles = vehicles.filter((vehicle: any) => vehicle.vinNumber !== isVehilcePresent.vinNumber);
    if (filterVehicles !== null) {

      for (let vehicle of filterVehicles) {
        this.getCosyImage(vehicle, vehicle.vinNumber.trim());
      }

      this.currentVehicle = isVehilcePresent.modelDesc.trim();
      sessionStorage.setItem("selectedVehicle", JSON.stringify(isVehilcePresent));
      this.getVehicleInfoData(filterVehicles, isVehilcePresent);
      this.getServicePlans(isVehilcePresent);
    }
  }

  getCosyImage(vehicle: any, vin: string) {

    let result = "";
    this.cosySvc.doGetCosyImage(vin).subscribe((data => {
      let res = data;
      result = res.cosyImageUrlList[0];
      // if (result.indexOf("https://prod.cosy.bmw.cloud/za/cosySec?") == -1) {
      //   vehicle.cosyURL = res.cosyImageUrlList[0].replace('https://cosy-internal.bmwgroup.net','https://prod.cosy.bmw.cloud');
      // } else {
        vehicle.cosyURL = res.cosyImageUrlList[0];
      // }
    }), (error) => {
      vehicle.cosyURL = "../assets/"+  this.ssoBrandSwitch(vehicle.brand).toUpperCase() +"/images/no-image-available.png";
    })
  }

  async updateCosy(vehicles: any, isVehilcePresent: any){

  if (isVehilcePresent) {
    this.getCosyImage(isVehilcePresent, isVehilcePresent.vinNumber.trim());
  }

  let filterVehicles = vehicles.filter((vehicle: any) => vehicle.vinNumber !== isVehilcePresent.vinNumber);
    if (filterVehicles !== null) {

      for (let vehicle of filterVehicles) {
        this.getCosyImage(vehicle, vehicle.vinNumber.trim());
      }
      this.getVehicleInfoData(filterVehicles, isVehilcePresent);
    }
}

  async getVehicleInfoData(vehicles: any, isVehilcePresent: any) {
    this.getTechnicalData(isVehilcePresent.vinNumber, isVehilcePresent.brand);
    let sortVehicles = [isVehilcePresent, ...vehicles.filter((vehicle: any) => vehicle !== isVehilcePresent)]
    this.vehicleInfoFromParent = { "listOfVehicles": sortVehicles, "firstVehicleFromTheList": isVehilcePresent };
    this.loadServiceDealer(isVehilcePresent);

  }

  getServicePlans(isVehilcePresent: any) {
    let res: any = {};
    this.vinToChild = isVehilcePresent.vinNumber;
    this.myPlansSvc.getCampaigns(isVehilcePresent.vinNumber).subscribe((data) => {
      res = data;
      if (res !== null) {
        this.last_service = res.claimList.list[0].claimDate;
        this.service_partner = res.claimList.list[0].claimPartnerName;
        this.vehicleCampaignsParent = { "last_service": this.last_service, "service_partner": this.service_partner }
      }
    });
  }

  async loadServiceDealer(isVehilcePresent: any) {
    let res: any = {};
    this.getTyreOptions(isVehilcePresent.vinNumber);
    this.serviceDealerSvc.getServiceCodeDealer().subscribe((data) => {
      res = data;
      if(res.evResult === "E"){
        this.getDealers(this.brandToChild);
        // console.log("evMessage :::", res.evMessage);
        // console.log("result :::", res);
        const preferredRetailer = window.document.getElementById('preferred_Retailer') as HTMLElement;
        preferredRetailer.click();
        // const modalElement = this.preferredRetailer.nativeElement;
        // const modal = new bootstrap.Modal(modalElement);
        // modal.show();
      }
      else{
      this.getMotorplanContract(res.evDealerRelation.dealerId, isVehilcePresent);
      this.dataSvc.setDealerId(res.evDealerRelation.dealerId);
      this.dataSvc.setSelectedVehicle(isVehilcePresent);
      this.getOptionalService(isVehilcePresent);
      let serviceDealerObservable = this.serviceDealerSvc.getDealerAgent(isVehilcePresent.brand, res.evDealerRelation.dealerId);
      serviceDealerObservable.subscribe((serviceDealer: any) => {
        serviceDealer;

        var dealerName = "";
        var dealerTel = "";
        var dealerWebsite = "";
        var dealerEmail = "";
        var dealerFax = "";
        if (serviceDealer !== null) {
          this.getDealers(this.brandToChild);
          if (serviceDealer.name.indexOf("Closed") !== -1) {
            // this.storageService.setDealerId("");
            this.getDealers(this.brandToChild);
          } else {
            dealerName = serviceDealer.name === null ? dealerName : serviceDealer.name;
            dealerTel = serviceDealer.tellNumber === null ? dealerTel : serviceDealer.contactDetails.tellNumber;
            dealerFax = serviceDealer.cellNumber === null ? dealerFax : serviceDealer.contactDetails.cellNumber;
            dealerWebsite = serviceDealer.webPage === null ? dealerWebsite : serviceDealer.webPage;
            dealerEmail = serviceDealer.emailAddress === null ? dealerEmail : serviceDealer.contactDetails.emailAddress;

            this.servicePartnerDetailsFromParent = {
              dealerName: dealerName,
              dealerFax: dealerFax,
              dealerTel: dealerTel,
              dealerWebsite: dealerWebsite,
              dealerEmail: dealerEmail,
              dealerInformation: serviceDealer
            };
          }
        }
        else{
          this.getDealers(this.brandToChild);
        }
      });
    }
    });
  }

  getTechnicalData(vin: string, brand:string) {

    // let data;

    if (vin !== undefined) {
      let modelCode = "";

      this.technicalSpecsSvc.getVehicleModelCode(vin).subscribe((params: any) => {
        if (params.zmodel !== "" || params.zmodel !== undefined) {
          let techData = {
            "modelCode": params.zmodel,
            "brand": brand
          }
          this.technicalSpecsSvc.getTechnicalSpecs(techData).subscribe(data => {
            console.log("data from technical specs", data);
            this.techDataFromParent = { "techInfo": data, "modelCode": params.zmodel };
          })
        }
      })
    }
  }

  getOptionalService(vehicleData: any) {
    this.servOptSvc.getVehicleServiceOption(this.dataSvc.getDealerId(), vehicleData.modelYear, vehicleData.bodyNumber).subscribe(data => {

      let res = data.defect;
      if (res) {
        this.serviceOptionalFromParent = res;
      } else {
        this.serviceOptionalFromParent = '';
      }
    })
  }

  getTyreOptions(vinNumber: any) {
    sessionStorage.removeItem("optionalTyreData");
    let vehicleServiceTyreSearchObservable = this.tyreOptionsSvc.getVehicleTyreSearch(vinNumber);
    vehicleServiceTyreSearchObservable.subscribe((vehicleTyreSearch: any) => {
      this.vehicleTyreSearch = vehicleTyreSearch;
      this.isHideTyreSearch = true;
      if (this.vehicleTyreSearch.etReturn && this.vehicleTyreSearch.etReturn.length === 0)  {
        this.vehicleTyreSearch.z55TyreInfo.forEach((o: { rimdescr: string | string; fitted: string; retailPrice: any; manufacturer: any; }, i: number) => {
          this.isHideTyreSearch = false;
          this.parentIsEmptyTyreSearch = false;
          let frontPrice;
          let rearPrice;
          let fullPrice;
          if (o.fitted === 'F') {
            if (i === 0) {
              frontPrice = (Number(o.retailPrice) * 2) * 1.15;
              const isRearPresent = this.vehicleTyreSearch.z55TyreInfo.find((p: { manufacturer: any; fitted: string; }) => p.manufacturer === o.manufacturer  && p.fitted === 'R');
              rearPrice = (Number(isRearPresent.retailPrice) * 2) * 1.15;
              fullPrice = frontPrice + rearPrice;
              this.tyreOptionsFromParent = { tyreInformation: o, frontTyre: o, rearTyre: isRearPresent, retailPrice: fullPrice, vehicleTyreSearch: this.vehicleTyreSearch };
              this.dataSvc.setOptionalTyreData(this.tyreOptionsFromParent);
            }
          } else if (o.fitted === 'A') {
            if (i === 0) {

              this.tyreOptionsFromParent = { tyreInformation: o, frontTyre: null, rearTyre: null, retailPrice: (Number(o.retailPrice) * 4) * 1.15, vehicleTyreSearch: this.vehicleTyreSearch };
              this.dataSvc.setOptionalTyreData(this.tyreOptionsFromParent);
            }
          }

        });
      }
    });
  }

  getMotorplanContract(dealerCode: string, isVehilcePresent: any) {

    // MOTORPLAN VARIABLES
    let optionalContracts: any;
    let optContract: any;
    let motorPlanData:any;
    let motorplanContractDates:any;
    let odometerReading:string;

    // MY PLANS VARIABLES
    let myPlansData: any;
    let displayMyPlansButton = false;
    let myPlansLowestPricedItem:any;
    let plansData:any;
    let latestMyPlansData:any;
    // ON-CALL VARIABLES
    let displayOnCallButton = false;
    let onCallPlansData:any [] = [];
    let onCallLowestPricedItem:any;
    let onCallData:any;



    const latestContractDetail =  this.myPlansSvc.latestContractDetail(isVehilcePresent.vinNumber);
    const getAvailableContract = this.myPlansSvc.getAvailContracts(dealerCode, false, '0', isVehilcePresent.vinNumber);
    const getOptionalContracts = this.myPlansSvc.getOptionalContracts(isVehilcePresent.vinNumber);
    const getOncallPlans = this.myPlansSvc.contractVisiblePrices(dealerCode, false, '100', isVehilcePresent.vinNumber);
    forkJoin([latestContractDetail, getOptionalContracts, getAvailableContract, getOncallPlans]).subscribe(([_latestContractDetail,_getOptionalContracts, _getAvailableContract, _getOncallPlans]) => {
      let latestContractDetail_Res = _latestContractDetail.categoryList;
      let availableContractDetail_Res = _getAvailableContract.categoryList;
      odometerReading =  _getAvailableContract.vehicle.zvehOdoReading;

      if (latestContractDetail_Res !== undefined && latestContractDetail_Res.length > 0) {

        //DISPLAY ACTIVE CONTRACT
        let filterContractCategory: any = latestContractDetail_Res.find((i: any) => i.contractCategory === 'M');

        if (filterContractCategory) {

          let motorplanContract_Data = filterContractCategory.activeContractList.list;
          if (motorplanContract_Data !== undefined  && latestContractDetail_Res.length > 0) {
            let motorPlan_Data = motorplanContract_Data.filter((x: any) => x.contractCategory === 'M');
            motorPlan_Data = motorPlan_Data[motorPlan_Data.length - 1];
            if (motorPlan_Data) {
              motorplanContractDates = {
                "endDate": motorPlan_Data.validToDate,
                "endKM": Number(motorPlan_Data.validKmTo) + ' KM',
                "contractStatus": motorPlan_Data.contractStatus
              };
            }
          }
        }



        let findOnCallContractCategory: any = latestContractDetail_Res.find((i: any) => i.contractCategory === 'O');
        if (findOnCallContractCategory) {
          let onCallContractList = findOnCallContractCategory.activeContractList.list;
          if (onCallContractList != undefined && onCallContractList.length != 0) {

            let findOnCallData = onCallContractList.filter((x: any) => x.contractCategory === 'O');
            if (findOnCallData) {
              onCallData = findOnCallData[findOnCallData.length - 1];
            }
          }
        }


        let findMyPlansContractCategory: any = latestContractDetail_Res.find((i: any) => i.contractCategory === 'S');
        if (findMyPlansContractCategory) {
          let myPlansContractList = findMyPlansContractCategory.activeContractList.list;
          if (myPlansContractList != undefined && myPlansContractList.length != 0) {

            let findMyPlansData = myPlansContractList.filter((x: any) => x.contractCategory === 'S');
            if (findMyPlansData) {
              latestMyPlansData = findMyPlansData[findMyPlansData.length - 1];
            }
          }
        }

      }

      optionalContracts = _getOptionalContracts.contractTypeList.list;
      optContract = optionalContracts.find((x: any) => x.contractType === 'TC003');

      if (availableContractDetail_Res !== undefined && availableContractDetail_Res.length > 0) {
        //GET OPTIONAL CONTRACT
       let filterContractCategory: any = availableContractDetail_Res.find((i: any) => i.contractCategory === 'M');

        if (filterContractCategory) {

          let motorplanContractData = filterContractCategory.activeContractList.list;
          if (motorplanContractData !== undefined  && availableContractDetail_Res.length > 0) {
            motorPlanData = motorplanContractData.filter((x: any) => x.contractCategory === 'M');
            motorPlanData = motorPlanData[motorPlanData.length - 1];
          }
        }



        //GET ALL MY PLANS
        if(!latestMyPlansData){
        let filterMyPlansContractCategory: any = availableContractDetail_Res.find((i: any) => i.contractCategory === 'S');


        if (filterMyPlansContractCategory) {
          let myPlanContractData = filterMyPlansContractCategory.activeContractList.list;

          if (myPlanContractData !== undefined && myPlanContractData.length != 0) {
            let findMyPlanData = myPlanContractData.find((x: any) => x.contractCategory === 'S');

            if (findMyPlanData) {
              plansData = findMyPlanData[findMyPlanData.length - 1];
              let filterAllowedPlanData = findMyPlanData.allowedContractTypesForContractPricing.list.find((x: any) => x.contractCategory === "S")
              if (filterAllowedPlanData) {
                displayMyPlansButton = true;
                myPlansData = filterAllowedPlanData.contractTypeList.list;
                myPlansLowestPricedItem = this.getLowestPlan(myPlansData);
              }


            }

          }

        }
      }

        let onCallContractCategory: any = availableContractDetail_Res.find((i: any) => i.contractCategory === 'O');
        if (onCallContractCategory) {
          let onCallContractData = onCallContractCategory.activeContractList.list;
          if (onCallContractData != undefined && onCallContractData.length != 0) {

            let findOnCallData = onCallContractData.filter((x: any) => x.contractCategory === 'O');
            if (findOnCallData) {
              let onCall_Data = findOnCallData[findOnCallData.length - 1];
              let findallowedContractTypes = onCall_Data.allowedContractTypesForContractPricing.list.find((x: any) => x.contractCategory === "O");
              if (findallowedContractTypes) {
                displayOnCallButton = true;
                let onCall_PlansData = findallowedContractTypes.contractTypeList.list;
                onCallLowestPricedItem = this.getLowestPlan(onCall_PlansData);
              }


            }
          }
        }

      }

    let onCallPlans_Res:any = _getOncallPlans.contractPriceList.list;
    if(onCallPlans_Res){
      let onCallPlans_Adjust: any [] = [];
      onCallPlans_Res.forEach((e:any)=>{
        // let restructuData = {
        //   contractCategory:'O',
        //   contractType: e.inputContractType
        // }
        let restructData = {
          contractCategory: "O",
          contractType: e.inputContractType,
          contKmLimit: e.contKmLimit,
          contractCKunnr: e.contractCKunnr,
          contractExpiryDate: e.contractExpiryDate,
          contractExpiryMileage: e.contractExpiryMileage,
          contractFrequency: e.contractFrequency,
          contractMonths: e.contractMonths,
          contractOnCallPrice: e.contractOnCallPrice,
          contractPrice: e.contractPrice,
          contractTotalPrice: e.contractTotalPrice,
          endKm: e.endKm,
          equnr: e.equnr,
          inputContractType: e.inputContractType,
          inputDealerNo: e.inputDealerNo,
          inputSurchargeId: e.inputSurchargeId,
          orgTotalPrice: e.orgTotalPrice,
          quoteLoadStatus: e.quoteLoadStatus,
          quoteNumber: e.quoteNumber,
          quoteType: e.quoteType,
          startDate: e.startDate,
          startKm: e.startKm,
          // vatTotalPrice: e.vatTotalPrice,
          vatTotalPrice: Number(e.orgTotalPrice)*1.15,
          vin: e.vin,
          vwundat: e.vwundat
        }
        onCallPlansData.push(restructData)
      })
      // onCallPlansData = {onCallPlans_Res, "contractCategory":"O"};
    }

  //ASSIGN MOTORPLAN DATA
  this.motorplanContractDataFromParent = { "optContract": optContract, "motorplanContractDates": motorplanContractDates, "motorPlanData": motorPlanData, "vehicleInfo": isVehilcePresent, "dealerCode": dealerCode }

  //ASSIGN MYPLANS DATA
  this.myPlanContractDataFromParent = { "myPlansData": myPlansData, "plansData": plansData, "displayButton": displayMyPlansButton, "lowestPricePlan": myPlansLowestPricedItem, "motorPlanData": this.motorplanContractDataFromParent.motorplanContractDates, "odometerReading": odometerReading, "latestPlansData": latestMyPlansData }

  //ASSIGN ON CALL DATA
  this.onCallContractDataFromParent = { "myPlansData": onCallPlansData, "onCallData": onCallData, "displayButton": displayOnCallButton, "lowestPricePlan": onCallLowestPricedItem, "odometerReading": odometerReading, "contractCategory":"O" }

  //ASSIGN MOTORPLAN DATA TO SELECTED VEHICLE
  this.vehicleInfoFromParent.firstVehicleFromTheList = {...this.vehicleInfoFromParent.firstVehicleFromTheList, ...motorplanContractDates}

});

  }
addVat(picer:string){

  return Number()
}
  onBrandSwitch(brand:string, vehicleDetails: any) {
    if (brand !== vehicleDetails.brand) {
      // if ("MI" === vehicleDetails.brand || "MO" === vehicleDetails.brand) {
      this.dataSvc.removeItems();
      this.dataSvc.clearStore();
      sessionStorage.clear();
      this.dataSvc.setCurrentVehicles('');
      window.location.href = environment.gcdm_login_env_hostname + "/oneid/#/login?client=customerportal&country=ZA&language=en&brand=" + this.ssoBrandSwitch(vehicleDetails.brand) + "&scope=authenticate_user%20openid&response_type=code&redirect_uri=" + this.ssoPageSwitch(vehicleDetails.brand) + "%2Fgcdm%2Findex%2Fauth&state=dfskfjklsdjflk345089ujjfsd";
    }
  }

  getBrandSwitch(val: string) {
    var answer = ["BMW"];
    switch (val) {
      case "MINI":
        answer = ["MI"];
        break;
      case "BMW":
        answer = ["BM","BI"];
        break;
      case "MOT":
        answer = ["MO"];
        break;
    }

    return answer;
  }

ssoPageSwitch(val: string) {
    var url = environment.apiURL;
    switch (val) {
      case "MI":
        url = environment.apiURL.replace('bmw.co.za','mini.co.za').replace('bmw-motorrad.co.za', 'mini.co.za');

        // url = environment.apiURLMini
        break;
      case "BM":
        url = environment.apiURL.replace('mini.co.za','bmw.co.za').replace('bmw-motorrad.co.za','bmw.co.za');
        // url = 'https://www.account-d.bmw.co.za';
        break;

      case "BI":
        url = environment.apiURL.replace('mini.co.za','bmw.co.za').replace('bmw-motorrad.co.za','bmw.co.za');
        // url = 'https://www.account-d.bmw.co.za';
        break;
      case "MO":
        url =  environment.apiURL.replace('mini.co.za','bmw-motorrad.co.za').replace('bmw.co.za','bmw-motorrad.co.za');
        // url = environment.apiURLMotorrad
        break;
    }
    return url;
  }

  ssoBrandSwitch(val:string) {
    var answer = "bmw";
    switch( val ) {
      case"MI":
        answer = "mini";
        break;
      case "BM":
        answer = "bmw";
        break;
      case "BI":
        answer = "bmw";
        break;
      case "MO":
        answer = "bmwmotorrad";
        break;
    }
    return answer;
  }

  getBrand_Switch(brand: string, vehicleBrand: string) {
    if (brand === 'BMW') {
      if(vehicleBrand === 'BI') {
        return 'BI';
      }
      else{
        return 'BM';
      }
    }

    if (brand === 'MINI') {
      return 'MI'
    }

    if (brand === 'MOT') {
      return 'MO'
    }
    return "";
  }

  goToPlanOffer() {
    let allData = { "myPlanContractDataFromParent": this.myPlanContractDataFromParent, "onCallContractDataFromParent": this.onCallContractDataFromParent, "tyreOptionsFromParent":this.tyreOptionsFromParent, "serviceOptionalFromParent": this.serviceOptionalFromParent }
    this.dataSvc.setAllPlans(allData);
    this.router.navigate(['/plans-offers']);
  }

  getLowestPlan(data: any) {
    if(data){
    let lowestPrice = Number.MAX_VALUE;
    for (const item of data) {
      const currentPrice = parseFloat(item.lowestPrice.vatTotalPrice);
      if (currentPrice < lowestPrice) {
        lowestPrice = currentPrice;
        return item;
      }
    }
    return;
  }
}


getDealers(brand:string) {
  this.serviceDealerSvc.getDealerList(brand).subscribe((data) => {
    let listOfDealers:any[] = [];
    data.forEach((o: any) => {

      o.dealers.forEach((d: any) => {
        listOfDealers.push(
          {
            dealerCode: d.dealerCode,
            dealerName: d.dealerName

          });
      })

    })

    listOfDealers.sort((a, b) => a.dealerName > b.dealerName ? 1 : - 1);
    this.dealersFromParent = listOfDealers;
  })
}
}
