
import { DOCUMENT } from '@angular/common';

declare global {
  interface Window {
    setPageNameFromUrl?: () => void;
  }
}

import { Inject, Injectable, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
import { DataServiceService } from '@services/data-service.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class TronGenericPageTrackingService {
  private _renderer2: Renderer2;
  brand: string;
  private router: Router;

  constructor(
    private dataSvc: DataServiceService,
    private ngZone: NgZone,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document,
    router: Router
  ) {
    this.router = router;
    this._renderer2 = rendererFactory.createRenderer(null, null);

    this.brand = '';
    //set pageBrand on the global window object
    (window as any).pageBrand = this.brand;

  }

  // brand: string = '';

  // private tron: any;
  // private tronForms: any;

  setBrand(brand: string): void {
    this.brand = brand;
    console.log('brand::: ', this.brand);
  }
  
  // setPageName(pageName: string) {
  //   let script = this._renderer2.createElement('script');
  //   script.text = `window.page_name = ${pageName}`;
  //   this._renderer2.appendChild(this._document.body, script);
  // }

  // setFirstFormInteraction(pageName: string) {
  //   let script = this._renderer2.createElement('script');
  //   script.text = `window.firstFormInteraction_${pageName} = true`;
  //   this._renderer2.appendChild(this._document.body, script);
  // }

  // setFormFinish(pageName: string) {
  //   const script = this._renderer2.createElement('script');
  //   const encodedPageName = encodeURIComponent(pageName);
  //   this._renderer2.appendChild(this._document.body, script);
  //   this.ngZone.run(() => {
  //     (window as Window & Record<string, any>)['formFinish_myplans' + encodedPageName] = true;
  //   });
  // }

  // setFormFinishIntent(pageName: string) {
  //   const script = this._renderer2.createElement('script');
  //   const encodedPageName = encodeURIComponent(pageName);
  //   this._renderer2.appendChild(this._document.body, script);
  //   this.ngZone.run(() => {
  //     (window as Window & Record<string, any>)['formFinishIntent_myplans' + encodedPageName] = true;
  //   });
  // }

  // setFormSteps(pageName: string, steps: number) {
  //   const script = this._renderer2.createElement('script');
  //   const encodedPageName = encodeURIComponent(pageName);
  //   this._renderer2.appendChild(this._document.body, script);
  //   this.ngZone.run(() => {
  //     (window as Window & Record<string, any>)['formStep_myplans' + encodedPageName] = steps;
  //   });
  // }
}