import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CustomerProfileService } from '@services/customer-profile.service';
import {UserService} from "@services/user.service";
import {DatePipe} from "@angular/common";
import {DataServiceService} from "@services/data-service.service";
import {Timestamp} from "rxjs";

@Component({
  selector: 'app-profile-preferences',
  templateUrl: './profile-preferences.component.html',
  styleUrls: ['./profile-preferences.component.less']
})
export class ProfilePreferencesComponent implements OnInit {

  constructor(private customerProfileSvc: CustomerProfileService, private userSvc: UserService, private datasvc: DataServiceService) {

    this.brand = this.datasvc.getBrand();
   }
  preferencesData: any = {};
  contactPolicyConsents: any = {};
  policyConsentsDataNsc: any = [];
  policyConsentsDataSf: any = [];
  nscConsentsData: any = [];
  sfConsentsData: any = [];
  editMode: boolean = false;
  Edit_BMW_Finance_Data: boolean = false;
  Edit_BMW_Data: boolean = false;
  fsSaveData:any = [];
  nscSaveData:any = [];
  alertData: string = '';
  alertResponse: string = '';
  alertMsg: string ='';
  loading: boolean = false;
  brand: string = '';

  commsMethodForm = new FormGroup({
    contactPolicyConsents: new FormArray([])
  });
  ngOnInit(): void {
    const token = sessionStorage.getItem("CP_OAUTH_BEARER_TOKEN");
    if (token !== null) {
      // this.getPreferences(token);
    }
  }

  getPreferences(token:string){
    let custGcdmServiceObservable = this.userSvc.getGcdmCustomerProfileOnePage(token);
    custGcdmServiceObservable.subscribe((customerDetails: { contactPolicyConsents: undefined; }[]) => {

      customerDetails.forEach((o: { contactPolicyConsents: undefined; }, i: any) => {

        this.contactPolicyConsents = o.contactPolicyConsents;
        if (this.contactPolicyConsents !== undefined) {
          this.contactPolicyConsents.forEach((e: {legalEntityId: undefined; policyId: undefined;})=>{
            if (e.legalEntityId === "BMW_SOUTH_AFRICA_PTY_LTD_" && (e.policyId === "Privacy_Statement" || e.policyId === "Marketing")) {
              this.policyConsentsDataNsc.push(this.extractData(e));
            }
            if (e.legalEntityId === "BMW_FINANCIAL_SERVICES_SOUTH_AFRICA_PTY_LTD_" && (e.policyId === "Privacy_Statement" || e.policyId === "Marketing")) {
              this.policyConsentsDataSf.push(this.extractData(e));
            }
          })
        }

      })
      this.sortAndRemoveDuplicatesNsc(this.policyConsentsDataNsc);
      this.sortAndRemoveDuplicatesSf(this.policyConsentsDataSf);

    })
  }

  sortAndRemoveDuplicatesNsc(data: any) {
    const sortBeforeRemoveDup = data.sort((a: { consentDate: any; }, b: { consentDate: any }) =>{
      const dateA = new Date(a.consentDate).getTime();
      const dateB = new Date(b.consentDate).getTime();
      return dateB - dateA;
    });
    const cleanConsntData = this.removeDuplicates(sortBeforeRemoveDup);
    const sortedNSC = this.sortByLetter(cleanConsntData);
    sortedNSC.forEach((e: any) => {
      this.nscConsentsData.push(e);
    });
  }

  sortAndRemoveDuplicatesSf(data: any) {
    const sortBeforeRemoveDup = data.sort((a: { consentDate: any; }, b: { consentDate: any }) =>{
      const dateA = new Date(a.consentDate).getTime();
      const dateB = new Date(b.consentDate).getTime();
      return dateB - dateA;
    });
    const cleanConsntData = this.removeDuplicates(sortBeforeRemoveDup);
    const sortedNSC = this.sortByLetter(cleanConsntData);
    sortedNSC.forEach((e: any) => {
      this.sfConsentsData.push(e);
    });
  }


  extractData(data: any) {
    data["label"] = data.usageId === 'PHONE_MESSENGER_SERVICES' ? 'SMS' : data.usageId === 'PHONE_MESSENGER_SERVICES' ? 'SMS'
      : data["label"] = data.usageId === 'EMAIL' ? 'Email'
        : data["label"] = data.usageId === 'APP' ? 'In-app'
         : data["label"] = data.usageId === 'MAIL' ? 'Post'
          : data["label"] = data.usageId === 'ANALYTICS' ? 'Analytics' : data["label"] = data.usageId === 'IN_CAR' ? 'In-vehicle'
            : data.usageId;

    return data;
  }

  sortByLetter(data: any[]) {
    return data.sort((a, b) => {
      const nameA = a.usageId.charAt(0).toUpperCase() + a.usageId.slice(1).toLowerCase();
      const nameB = b.usageId.charAt(0).toUpperCase() + b.usageId.slice(1).toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  removeDuplicates(inputArray: any) {
    let testObject: { [key: string]: any } = {};
    let returnItems: any[] = [];

    for (let i = 0; i < inputArray.length; i++) {
      const item = inputArray[i];
      const itemPropertyName = item.usageId;

      if (!testObject.hasOwnProperty(itemPropertyName)) {
        testObject[itemPropertyName] = item;
        returnItems.push(item);
      }
    }

    return returnItems;
  }


  Edit(WhatToEDit: string) {
    if (WhatToEDit === "BMW_Data") {
      this.Edit_BMW_Data = !this.Edit_BMW_Data;
    }
    if (WhatToEDit === "BMW_Finance_Data") {
      this.Edit_BMW_Finance_Data = !this.Edit_BMW_Finance_Data;
    }
  }

  Cancel(WhatToCancel: string) {
    if (WhatToCancel === "BMW_Data") {
      this.Edit_BMW_Data = false;
      if(!this.Edit_BMW_Data){
       window.location.reload();
      }
    }
    if (WhatToCancel === "BMW_Finance_Data") {
      this.Edit_BMW_Finance_Data = false;
      if(!this.Edit_BMW_Finance_Data){
        window.location.reload();
      }
    }

  }

  toggleVisibilityNsc(event:any, nsc_preference:any){
    let ncs_data = event.target.checked? nsc_preference.consent ="ACCEPT": nsc_preference.consent ="REJECT";
   this.nscConsentsData.find((val:any) => nsc_preference.usageId === val.usageId? val.consent = ncs_data: val.consent = val.consent);
  }

  toggleVisibilitySf(event:any, sf_preference:any){
    let sf_data = event.target.checked? sf_preference.consent ="ACCEPT": sf_preference.consent ="REJECT";
    this.sfConsentsData.find((val:any) => sf_preference.usageId === val.usageId? val.consent = sf_data: val.consent = val.consent);
  }

  updateNscConsentsData(){
     let policy = this.nscConsentsData.concat(this.sfConsentsData);
     let nscSaveData:any = [];
    policy.forEach((o: any, i: any) => {
      nscSaveData.push(this.onCheckChange(o));
    })
    this.userSvc.updatePolicyStoreConsent(nscSaveData)
      .subscribe(
        data => {
          // display the success message or failure
          let Msg = 'Thank you, your '+this.brand+' preferences was updated successfully.';
          this.showMessage('BMW_Data', Msg, 'success');
          this.Edit_BMW_Data = false;
        }

      );
  }

  updateSfConsentsData(){
    let policy = this.nscConsentsData.concat(this.sfConsentsData);
    let fsSaveData: any = [];
    policy.forEach((o: any, i: any) => {
      fsSaveData.push(this.onCheckChange(o));
    })
    this.userSvc.updatePolicyStoreConsent(fsSaveData)
      .subscribe(
        data => {
        // display the success message or failure
        let Msg = 'Thank you, your '+this.brand+' Finance preferences was updated successfully.';
        this.showMessage('BMW_Finance_Data', Msg, 'success');
          this.Edit_BMW_Finance_Data = false;
        }

      );
  }

  onCheckChange(obj:any) {
    var datePipe = new DatePipe("en-US");

    let contactPolicyConsent = {
      consent: obj.consent,
      consentDate: datePipe.transform(
        new Date(),
        "yyy-MM-ddTHH:mm:ss"
      ),
      country: obj.country,
      language: obj.language,
      legalEntityId: obj.legalEntityId,
      majorVersion: obj.majorVersion,
      minorVersion: obj.minorVersion,
      policyId: obj.policyId,
      touchpointId: 'CUSTOMER_PORTAL_WEB_SITE_ZA',
      purposeId: obj.purposeId,
      usageId: obj.usageId
    };
    return contactPolicyConsent;
  }


checkDuplicateInObject(inputArray:any) {
  let seenDuplicate = false;
      let testObject:any = {};

      let returnItems:any = [];

  inputArray.map((item:any)=>{
  let itemPropertyName:any = item["usageId"];
  if (itemPropertyName in testObject) {
      testObject[itemPropertyName].duplicate = true;
      item.duplicate = true;
      seenDuplicate = true;
  }
  else {
      testObject[itemPropertyName] = item;
      delete item.duplicate;
      returnItems.push(item);
  }
  });
  return returnItems;
}

showMessage(target:string, message: string, status:string){
  if(message!=''){
  this.alertMsg = message;
  }

  if(status!=''){
  this.alertData = target;
  this.alertResponse = status;
  }

  this.Edit(target)
  setTimeout(()=>{
    this.alertData = '';
    this.alertMsg = '';
    this.alertResponse  = '';
  }, 10000);
  this.loading = false;
}
}
