import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ServiceOptionsService {
  httpClient: HttpClient;
  private shttpClient: HttpClient;


  constructor(private http:HttpClient, private sHttp: HttpBackend) {
    this.httpClient = http;
    this.shttpClient = new HttpClient(sHttp);
   }

  getVehicleServiceOption(dealerCode:string,modelYear:string,vehicleBodyNo:string){
    return this.httpClient.get<any>(environment.apiURL+"/WebAPI/vehicle/v1/detail/getciamodelkits?agmodel=&dealerCode="+dealerCode+"&modelYear="+modelYear+"&pricing=X&vehicleBodyNo="+vehicleBodyNo)
    .pipe(map(data => data as any))
   }

  getServiceApiKey(brand:string): Observable<any>{
    // let csrfToken = this.tokenExtractor.getToken() as string;
    let headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    });
    var configs = { headers: headers };

    return this.http.get<any>(environment.apiURL +"/customerportalapi/rest/cp/crm/customer/getServiceApiKey/"+brand)
      .pipe(map(data =>  data as any));
  }

  getContactApiKey(brand:string): Observable<any>{
    // let csrfToken = this.tokenExtractor.getToken() as string;
    let headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-type': 'application/json'
    });
    var configs = { headers: headers };

    return this.http.get<any>(environment.apiURL +"/customerportalapi/rest/cp/crm/customer/getContactApiKey/"+brand)
      .pipe(map(data =>  data as any));
  }
}
